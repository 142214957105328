import React, { useCallback } from "react";
import { Box } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";

import { systemsService } from "../../../../../services/systems-service";

import { PageContentBox } from "../../../../common/page-content-box";
import { DropdownSearchHelpInput, TextInput } from "../../../../common/form-controls";
import { READ_MODE, ADD_MODE, EntityFormSubtitle } from "../../../../common/entity-form";

const GroupIdInput = ({ id, setId, disabled, error }) => {
    const intl = useIntl();

    return (
        <TextInput
            label={intl.formatMessage({ id: "group-form-page-group.input-group-id" })}
            value={id}
            onChange={(event) => setId(event.target.value)}
            disabled={disabled}
            error={error?.value === id}
            errorMessage={error?.message}
        />
    );
};

const SystemIdsInput = ({ ids, setIds, disabled, error }) => {
    const intl = useIntl();

    const label = intl.formatMessage({ id: "group-form-page-group.input-systems-list" });

    const getData = useCallback((params, signal) => {
        return systemsService.getSystems(params, signal);
    }, []);

    return (
        <DropdownSearchHelpInput
            ids={ids}
            setIds={setIds}
            disabled={disabled}
            getData={getData}
            dataKey="systems"
            error={error}
            label={label}
            icon={null}
        />
    );
};

const GroupDescriptionInput = ({ description, setDescription, disabled, error }) => {
    const intl = useIntl();

    return (
        <TextInput
            multiline
            rows={3}
            label={intl.formatMessage({ id: "group-form-page-group.input-description" })}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            disabled={disabled}
            error={error?.value === description}
            errorMessage={error?.message}
        />
    );
};

const GroupFormPageGroup = ({ form, setForm, mode, validationErrors }) => {
    const systemIds = form.systems.map(system => system.id);

    const setId = value => setForm({ ...form, id: value });
    const setDescription = value => setForm({ ...form, description: value });
    const setSystems = ids => setForm({ ...form, systems: ids.map(id => ({ id })) });

    const formDisabled = mode === READ_MODE;
    const idDisabled = mode !== ADD_MODE;

    return (
        <PageContentBox className="group-form-page-group">
            <EntityFormSubtitle>
                <FormattedMessage id="group-form-page-group.title" />
            </EntityFormSubtitle>

            <Box className="group-form-page-group-inputs">
                <GroupIdInput 
                    id={form.id}   
                    setId={setId} 
                    disabled={idDisabled} 
                    error={validationErrors && validationErrors["group_id"]}/>

                <SystemIdsInput
                    ids={systemIds}
                    setIds={setSystems}
                    disabled={formDisabled}
                    error={validationErrors && validationErrors["systems"]}
                />

                <GroupDescriptionInput
                    description={form.description}
                    setDescription={setDescription}
                    disabled={formDisabled}
                    error={validationErrors && validationErrors["description"]}
                />
            </Box>
        </PageContentBox>
    );
};

GroupIdInput.propTypes = {
    id: PropTypes.string,
    setId: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object
};

SystemIdsInput.propTypes = {
    ids: PropTypes.arrayOf(PropTypes.string),
    setIds: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object
};

GroupDescriptionInput.propTypes = {
    description: PropTypes.string,
    setDescription: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object
};

GroupFormPageGroup.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func,
    mode: PropTypes.string,
    validationErrors: PropTypes.object,
};

export default GroupFormPageGroup;
