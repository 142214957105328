import React from "react";
import PropTypes from "prop-types";

import { PageContentBox } from "../page-content-box";

import "./style.less";

const EntityFormPageContent = ({ className="", ...props }) => (
    <PageContentBox
        className={`entity-form-page-content ${className}`}
        {...props}
    />
);

EntityFormPageContent.propTypes = {
    className: PropTypes.string
};

export default EntityFormPageContent;