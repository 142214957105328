import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { CheckboxInput } from "../../common/form-controls";

const OnlineSyncCheckbox = ({ checked, disabled, onChange }) => {
    const intl = useIntl();
    const label = intl.formatMessage({ id: "report-parameters.online-sync" });

    return (
        <CheckboxInput
            label={label}
            checked={checked}
            disabled={disabled}
            onChange={onChange}
        />
    );
};

OnlineSyncCheckbox.propTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};

export default OnlineSyncCheckbox;