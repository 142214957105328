import { IconButton, InputAdornment } from "@mui/material";
import React, { useState } from "react";
import { VisibilityIcon } from "../icons";
import Input from "./input.jsx";

const PasswordInput = (props) => {
    const [showPassword, setShowPassword] = useState(false);

    return(
        <Input
            {...props}
            type={showPassword ? "text" : "password"}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        <VisibilityIcon
                            off={showPassword}
                            sx={{
                                color: "var(--stroke-1)"
                            }}
                        />
                    </IconButton>
                </InputAdornment>
            }
        />
    );
};

export default PasswordInput;
