import React from "react";
import { SvgIcon } from "@mui/material";

const AddLeafIcon = (props) => (
    <SvgIcon
        viewBox="0 0 30 27"
        {...props}
    >
        <svg
            viewBox="0 0 30 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.651 8.54167L15.651 6.8125H14.7865C14.3279 6.8125 13.888 6.99468 13.5638 7.31896C13.2395 7.64324 13.0573 8.08306 13.0573 8.54167V15.4583C13.0573 15.9169 13.2395 16.3568 13.5638 16.681C13.888 17.0053 14.3279 17.1875 14.7865 17.1875H15.651V15.4583C15.651 14.9997 15.8332 14.5599 16.1575 14.2356C16.4818 13.9113 16.9216 13.7292 17.3802 13.7292L22.5677 13.7292C23.0263 13.7292 23.4661 13.9113 23.7904 14.2356C24.1147 14.5599 24.2969 14.9997 24.2969 15.4583V20.6458C24.2969 21.1044 24.1147 21.5443 23.7904 21.8685C23.4661 22.1928 23.0263 22.375 22.5677 22.375H17.3802C16.9216 22.375 16.4818 22.1928 16.1575 21.8685C15.8332 21.5443 15.651 21.1044 15.651 20.6458V18.9167H14.7865C13.8693 18.9167 12.9896 18.5523 12.341 17.9037C11.6925 17.2552 11.3281 16.3755 11.3281 15.4583V12.8646H7.86979V13.7292C7.86979 14.1878 7.68761 14.6276 7.36333 14.9519C7.03905 15.2762 6.59923 15.4583 6.14063 15.4583H2.68229C2.22369 15.4583 1.78387 15.2762 1.45959 14.9519C1.1353 14.6276 0.953125 14.1878 0.953125 13.7292L0.953125 10.2708C0.953125 9.81223 1.1353 9.37241 1.45959 9.04813C1.78387 8.72385 2.22369 8.54167 2.68229 8.54167H6.14063C6.59923 8.54167 7.03905 8.72385 7.36333 9.04813C7.68761 9.37241 7.86979 9.81223 7.86979 10.2708V11.1354H11.3281V8.54167C11.3281 7.62446 11.6925 6.74482 12.341 6.09626C12.9896 5.44769 13.8693 5.08333 14.7865 5.08333H15.651V3.35417C15.651 2.89556 15.8332 2.45574 16.1575 2.13146C16.4818 1.80718 16.9216 1.625 17.3802 1.625L22.5677 1.625C23.0263 1.625 23.4661 1.80718 23.7904 2.13146C24.1147 2.45574 24.2969 2.89556 24.2969 3.35417V8.54167C24.2969 9.00027 24.1147 9.44009 23.7904 9.76437C23.4661 10.0887 23.0263 10.2708 22.5677 10.2708L17.3802 10.2708C16.9216 10.2708 16.4818 10.0887 16.1575 9.76437C15.8332 9.44009 15.651 9.00027 15.651 8.54167Z"
                fill="currentColor"
            />
            <rect
                x="19"
                y="16"
                width="11"
                height="11"
                rx="5.5"
                fill="#E6EFF7"
            />
            <rect
                x="23.7793"
                y="18.1422"
                width="1.58251"
                height="6.85755"
                fill="currentColor"
            />
            <rect
                x="28"
                y="20.779"
                width="1.58251"
                height="6.85755"
                transform="rotate(90 28 20.779)"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

export default AddLeafIcon;
