import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import {
    deleteModelingSelectedUserRole,
    selectModelingSelectedRoleId,
    setModelingSelectedRoleById,
    selectModelingSelectedUser
} from "../../../../reducers/reports-modeling/userLevelSlice";

import { CommonDialogTitle } from "../../../common/dialogs";

import { EntitiesSelectDialog, EntitiesSelectDialogContent } from "../../entities-select-dialog";

import UserLevelModelingRolesDialogActions from "./user-level-modeling-roles-dialog-actions.jsx";
import UserLevelModelingRolesToolbar from "./user-level-modeling-roles-toolbar.jsx";
import CreateNewRoleDialog from "./create-new-role-dialog.jsx";
import AddRoleDialog from "./add-role-dialog.jsx";
import RolesWrapper from "./roles-wrapper.jsx";

import "./styles.less";

const DIALOG_TYPES = {
    CREATE_NEW_ROLE: "create_new_role",
    ADD_ROLE: "add_role"
};

const UserLevelModelingRolesDialog = ({
    open,
    onClose,
}) => {
    const intl = useIntl();
    const title = intl.formatMessage({ id: "user-level-modeling.role-search-help-dialog.title" });

    const savedSelectedRoleId = useSelector(selectModelingSelectedRoleId)
    const [selectedRoleId, setSelectedRoleId] = useState("");
    const [dialog, setDialog] = useState(null);
    const [searchString, setSearchString] = useState("");

    const selectedUser = useSelector(selectModelingSelectedUser)

    useEffect(() => {
        setSelectedRoleId(savedSelectedRoleId);
    }, [savedSelectedRoleId]);

    const handleCloseDialog = () => setDialog(null);

    const createDialogOpen = dialog === DIALOG_TYPES.CREATE_NEW_ROLE;
    const addDialogOpen = dialog === DIALOG_TYPES.ADD_ROLE;

    const dispatch = useDispatch();

    const handleSelectClick = () => {
        dispatch(
            setModelingSelectedRoleById(selectedRoleId)
        );
        onClose();
    };

    const handleCancelClick = !selectedRoleId ? handleSelectClick : onClose;

    const handleDeleteRole = (roleId) => {
        dispatch(
            deleteModelingSelectedUserRole(roleId)
        );

        if (selectedRoleId === roleId) {
            setSelectedRoleId("");
        }
    };

    const roles = useMemo(() => {
        return selectedUser.roles.filter(role => role.role.toLowerCase().includes(searchString.toLowerCase()))
    }, [searchString, selectedUser])

    return (
        <>
            <EntitiesSelectDialog
                className="user-level-modeling-roles-dialog"
                open={open}
            >
                <CommonDialogTitle
                    title={title}
                    onClose={handleCancelClick}
                />

                <EntitiesSelectDialogContent>
                    <UserLevelModelingRolesToolbar
                        onSearch={(event) => setSearchString(event.target.value)}
                        onAddRole={() => setDialog(DIALOG_TYPES.ADD_ROLE)}
                        onCreateNewRole={() => setDialog(DIALOG_TYPES.CREATE_NEW_ROLE)}
                    />
                    <RolesWrapper
                        selectedRoleId={selectedRoleId}
                        onSelectRole={(event) => setSelectedRoleId(event.target.value)}
                        onDeleteRole={handleDeleteRole}
                        onRowClick={(row) => setSelectedRoleId(row.id)}
                        roles={roles}
                    />
                </EntitiesSelectDialogContent>

                <UserLevelModelingRolesDialogActions
                    selectedRoleId={selectedRoleId}
                    onUnselectRole={() => setSelectedRoleId("")}
                    onSelectClick={handleSelectClick}
                    onCancelClick={handleCancelClick}
                />
            </EntitiesSelectDialog>

            {createDialogOpen && (
                <CreateNewRoleDialog
                    open={createDialogOpen}
                    onClose={handleCloseDialog}
                />
            )}
            
            {addDialogOpen && (
                <AddRoleDialog
                    open={addDialogOpen}
                    onClose={handleCloseDialog}
                />
            )}
        </>
    );
};

UserLevelModelingRolesDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

export default UserLevelModelingRolesDialog;