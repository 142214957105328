import React, { forwardRef } from 'react';
import { MuiTelInput } from 'mui-tel-input';
import PropTypes from "prop-types";

import { inputLabelClassName } from './input-label.jsx';
import { formControlClassName } from './form-control.jsx';
import { getInputClassName } from './input.jsx';
import { FormHelperTextContent, formHelperTextClassName } from "./form-helper-text.jsx";

const PhoneInput = forwardRef(({
    className="",
    fullWidth=true,
    shrink=true,
    label,
    value,
    error=false,
    errorMessage,
    warning=false,
    warningMessage,
    showFormHelpers=true,
    disabled=false,
    onChange
}, ref) => {
    const inputClassName = getInputClassName({
        error,
        warning
    });

    const helperText = showFormHelpers && (error && errorMessage || warning && warningMessage) ? (
        <FormHelperTextContent
            error={error}
            errorMessage={errorMessage}
            warning={warning}
            warningMessage={warningMessage}
        />
    ) : undefined;

    return (
        <MuiTelInput
            ref={ref} 
            className={`${formControlClassName} ${className}`}
            fullWidth={fullWidth}
            shrink={shrink}
            label={label}
            value={value}
            disabled={disabled}
            onChange={onChange}
            InputLabelProps={{
                className: inputLabelClassName,
                shrink: shrink
            }}
            InputProps={{
                className: inputClassName,
                startAdornment: null
            }}
            FormHelperTextProps={{
                className: formHelperTextClassName
            }}
            helperText={helperText}
        />
    )
});

PhoneInput.propTypes = {
    className: PropTypes.string,
    fullWidth: PropTypes.bool,
    shrink: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    warning: PropTypes.bool,
    warningMessage: PropTypes.string,
    showFormHelpers: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func
};

export default PhoneInput;
