import React from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import {
    fetchFunctions,
    fetchFunctionColumns,
    setSelectedIds as setSelectedIdsAction,
    setSearchString as setSearchStringAction,
    setPageNumber as setPageNumberAction,
    setLimitNumber as setLimitNumberAction,
    setSortData as setSortDataAction,
    setFilterData as setFilterDataAction,
    setError as setErrorAction,
} from "../../../../reducers/functionSlice";

import useFilters from "../../../common/hooks/useFilters";
import useReduxTable from "../../../common/hooks/useReduxTable";
import CommonTable from "../../../common/table";
import { ProgressDialog, ErrorsDialog } from "../../../common/dialogs";

import "./styles.less";


const getCellValue = (row, column) => {
    switch (column.id) {
        case "functionId":
            return row.id;

        case "updTime":
            return row.updatedTime;

        case "updUserName":
            return row.user;

        default:
            return row[column.id];
    }
};

const FunctionListPage = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    const {
        rows, columns,
        total, busy,
        selectedIds,
        searchString, setSearchString,
        pageNumber, setPageNumber,
        limitNumber, setLimitNumber,
        filterData, setFilterData,
        sortData, setSortData,
        error, setError
    } = useReduxTable(
        (state) => state.functions.functions, fetchFunctions,
        (state) => state.functions.columns, fetchFunctionColumns,
        (state) => state.functions.selectedIds, setSelectedIdsAction,
        (state) => state.functions.searchString, setSearchStringAction,
        (state) => state.functions.pageNumber, setPageNumberAction,
        (state) => state.functions.limitNumber, setLimitNumberAction,
        (state) => state.functions.sortData, setSortDataAction,
        (state) => state.functions.filterData, setFilterDataAction,
        (state) => state.functions.error, setErrorAction,
        (state) => state.functions.functionsTotal,
        (state) => state.functions.busy,
    );

    const busyType = useSelector((state) => state.functions.busyType);

    const getFilters = useFilters("function");

    const handleAddFunctionClick = () => {
        navigate("form")
    };

    const handleRowClick = (func) => {
        navigate(`form/${func.id}`);
    };

    const getTableRowId = (row) => row.id;

    const toolbarActions = [
        {
            id: "title",
            title: intl.formatMessage({ id: "function-list-page.toolbar.text.title" }),
            type: "text",
            position: "begin"
        },
        {
            id: "add",
            title: intl.formatMessage({ id: "function-list-page.toolbar.btn-create-func" }),
            callback: handleAddFunctionClick,
            position: "end"
        }
    ];

    return (
        <>
            <CommonTable
                rows={rows}
                columns={columns}
                toolbarActions={toolbarActions}
                selectedIds={selectedIds}
                getRowId={getTableRowId}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                limitNumber={limitNumber}
                setLimitNumber={setLimitNumber}
                total={total}
                onRowClick={handleRowClick}
                filterData={filterData}
                setFilterData={setFilterData}
                sortData={sortData}
                setSortData={setSortData}
                searchString={searchString}
                setSearchString={setSearchString}
                getCellValue={getCellValue}
                getFilters={getFilters}
            />

            <ProgressDialog
                open={busy}
                busyType={busyType}
            />

            <ErrorsDialog
                error={error}
                open={error?.type === "error"}
                onClose={() => setError(null)}
            />
        </>
    )
}

export default FunctionListPage;