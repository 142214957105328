import React from "react";
import { SvgIcon } from "@mui/material";
import PropTypes from "prop-types";

const RadioButtonIcon = ({ checked, ...props }) => {
    const innerColor = checked ? "currentColor" : "transaprent";

    return(
        <SvgIcon
            viewBox="0 0 16 17"
            {...props}
        >
            <svg
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                    cx="8"
                    cy="8.5"
                    r="7.33333"
                    stroke="currentColor"
                    strokeWidth="1.33333"
                />

                <circle
                    cx="8.00044"
                    cy="8.50068"
                    r="4.8"
                    fill={innerColor}
                />
            </svg>
        </SvgIcon>
    );
};

RadioButtonIcon.propTypes = {
    checked: PropTypes.bool
};

export default RadioButtonIcon;
