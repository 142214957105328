import React from "react";
import PropTypes from "prop-types";

import { EntityFormActions, EntityFormHeader } from "../../../../common/entity-form";

import GroupFormHeaderTitle from "./group-form-header-title.jsx";

const GroupFormPageHeader = ({ groupId, mode, onBackClick, onDeleteClick, onSaveClick, onEditClick, onShowLogsClick }) => {
    return (
        <EntityFormHeader>
            <GroupFormHeaderTitle
                groupId={groupId}
                mode={mode}
                onBackClick={onBackClick}
            />

            <EntityFormActions
                mode={mode}
                onDeleteClick={onDeleteClick}
                onSaveClick={onSaveClick}
                onEditClick={onEditClick}
                onShowLogsClick={onShowLogsClick}
            />
        </EntityFormHeader>
    );
};

GroupFormPageHeader.propTypes = {
    groupId: PropTypes.string,
    mode: PropTypes.string,
    onBackClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onSaveClick: PropTypes.func,
    onEditClick: PropTypes.func,
    onShowLogsClick: PropTypes.func,
}

export default GroupFormPageHeader;
