import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { risksService } from "../services/risks-service";
import LockManager from "../utils/lockManager";
import { BUSY_TYPES } from "../utils/busy";

const lockManager = new LockManager("risk");

const initialState = {
    risks: [],
    columns: [],
    risksTotal: 0,
    selectedIds: [],
    searchString: "",
    pageNumber: 1,
    limitNumber: 10,
    sortData: [],
    filterData: [],
    busy: false,
    busyType: null,
    error: null,
    submit: false,
    systems: [],
    transactions: [],
    currentRisk: null,
    currentRiskEditable: false,
    currentRiskDeleted: false,
    validationErrors: null,
};

export const fetchRisks = createAsyncThunk(
    "risk/fetchRisks",
    async ({ params }) => {
        const response = await risksService.getRisks(params);
        return response;
    }
);

export const fetchRiskColumns = createAsyncThunk(
    "risk/fetchRiskColumns",
    async () => {
        const response = await risksService.getRiskColumns();
        return response;
    }
);

export const fetchRiskDetailed = createAsyncThunk(
    "risk/fetchRiskDetailed",
    async (riskId) => {
        const risk = await risksService.getRiskDetailed(riskId);

        const [lock, relatedObjsLocks] = await Promise.allSettled([
            lockManager.lockObject(riskId),
            risksService.checkRelatedObjsLock(riskId)
        ]);

        return { 
            risk, 
            lock: lock.value, 
            relatedObjsLocks: relatedObjsLocks.status === 'rejected' ? relatedObjsLocks.reason : null };
    }
);

export const syncRiskWithSap = createAsyncThunk(
    "risk/syncRiskWithSap",
    async (riskId, {rejectWithValue}) => {
        let response

        try {
            response = await risksService.syncRiskWithSap(riskId);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return response;
    }
);

export const createRisk = createAsyncThunk(
    "risk/createRisk",
    async (risk, {rejectWithValue}) => {
        let response

        try {
            response = await risksService.createRisk(risk);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return response;
    }
);

export const editRisk = createAsyncThunk(
    "risk/editRisk",
    async (risk, {rejectWithValue}) => {
        let response

        try {
            response = await risksService.editRisk(risk);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return response;
    }
);

export const deleteRisk = createAsyncThunk(
    "risk/deleteRisks",
    async (riskId, {rejectWithValue}) => {
        try {
            await risksService.deleteRisk(riskId);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return riskId;   
    }
);

export const clearCurrentRisk = createAsyncThunk(
    "risk/clearCurrentRisk",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        const currentRisk = state.risks.currentRisk;
        const currentRiskEditable = state.risks.currentRiskEditable;
        const currentRiskDeleted = state.risks.currentRiskDeleted;

        if (!currentRisk) return thunkAPI.rejectWithValue(["Current risk is not initialized"]);

        if (currentRiskDeleted || !currentRiskEditable || !currentRisk.id) return;

        const lockResponse = await lockManager.unlockObject(currentRisk.id);

        if (!lockResponse.result) {
            return thunkAPI.rejectWithValue(lockResponse.messages);
        }
    }
);

const riskSlice = createSlice({
    name: "risk",
    initialState: initialState,
    reducers: {
        setSubmit(state, action) {
            state.submit = action.payload;
        },

        setInitialCurrentRisk(state) {
            state.currentRisk = {
                id: "",
                description: "",
                riskLevel: null,
                riskType: "common",
                active: true,
                functions: [],
                matrices: []
            };

            state.error = null;
            state.validationErrors = null;
            state.submit = false;
            state.currentRiskEditable = true;
            state.currentRiskDeleted = false;
        },

        setError(state, action) {
            state.error = action.payload;
        },

        setValidationErrors(state, action) {
            state.validationErrors = action.payload;
        },

        setSelectedIds(state, action) {
            state.selectedIds = action.payload;
        },

        setSearchString(state, action) {
            if (state.searchString !== action.payload) {
                state.pageNumber = 1;
                state.searchString = action.payload;
            }
        },

        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },

        setLimitNumber(state, action) {
            state.pageNumber = 1;
            state.limitNumber = action.payload;
        },

        setSortData(state, action) {
            state.pageNumber = 1;
            state.sortData = action.payload;
        },

        setFilterData(state, action) {
            state.pageNumber = 1;
            state.filterData = action.payload;
        },
    },
    extraReducers: {
        //get all risks
        [fetchRisks.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchRisks.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },

        [fetchRisks.fulfilled]: (state, action) => {
            state.busy = false;
            state.risks = action.payload.risks;
            state.risksTotal = action.payload.total;
            state.error = null;
            state.validationErrors = null;
        },

        // fetch risk detailed
        [fetchRiskDetailed.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.currentRiskDeleted = false;
            state.submit = false;
        },

        [fetchRiskDetailed.fulfilled]: (state, action) => {
            const { risk, lock, relatedObjsLocks } = action.payload;

            state.currentRisk = risk;
            state.busy = false;
            state.currentRiskEditable = lock.result;
            state.error = lock.result ? relatedObjsLocks : lock.messages;
        },

        [fetchRiskDetailed.rejected]: (state, action) => {
            state.currentRisk = null;
            state.error = action.error;
            state.busy = false;
            state.currentRiskEditable = false;
        },

        // syncRiskWithSap
        [syncRiskWithSap.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [syncRiskWithSap.fulfilled]: (state) => {
            state.busy = false;
            state.error = null;
        },

        [syncRiskWithSap.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },

        // clear current risk
        [clearCurrentRisk.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [clearCurrentRisk.fulfilled]: (state) => {
            state.busy = false;
            state.currentRisk = null;
            state.currentRiskEditable = false;
            state.currentRiskDeleted = false;
            state.error = null;
            state.validationErrors = null;
            state.submit = false;
        },

        [clearCurrentRisk.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },

        // delete risk
        [deleteRisk.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
        },

        [deleteRisk.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },

        [deleteRisk.fulfilled]: (state, action) => {
            state.busy = false;
            state.error = null;
            state.validationErrors = null;
            state.risks = state.risks.filter(risk => risk.id !== action.payload);

            if (state.currentRisk?.id === action.payload) {
                state.submit = true;
                state.currentRiskDeleted = true;
            }
        },

        // create risk
        [createRisk.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
            state.submit = false;
        },

        [createRisk.rejected]: (state, action) => {
            state.busy = false;
            const error = action.payload;

            if (!error) return;

            const validationCodes = new Set(["validation"])

            if (error.code && validationCodes.has(error.code)){
                state.validationErrors = error.errors;
            }

            state.error = error
        },

        [createRisk.fulfilled]: (state, action) => {
            state.busy = false;
            state.submit = true;
            state.currentRisk = action.payload;
            state.error = null;
            state.validationErrors = null;
        },

        // edit risk
        [editRisk.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
            state.submit = false;
        },

        [editRisk.rejected]: (state, action) => {
            state.busy = false;
            const error = action.payload;

            if (!error) return;

            const validationCodes = new Set(["validation"])

            if (error.code && validationCodes.has(error.code)){
                state.validationErrors = error.errors;
            }

            state.error = error
        },
        
        [editRisk.fulfilled]: (state, action) => {
            state.busy = false;
            state.submit = true;
            state.currentRisk = action.payload;
            state.error = null;
            state.validationErrors = null;
        },

        [fetchRiskColumns.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchRiskColumns.fulfilled]: (state, action) => {
            state.busy = false;
            state.columns = action.payload;
        },

        [fetchRiskColumns.rejected]: (state, action) => {
            state.busy = false;
            state.errors = action.error;
        },
    }
})

export const {
    setSubmit, setInitialCurrentRisk, setError, setValidationErrors,
    setSelectedIds, setSearchString, setPageNumber, setLimitNumber,
    setSortData, setFilterData
} = riskSlice.actions;

export default riskSlice.reducer;
