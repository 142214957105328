import React from 'react';
import { createSvgIcon } from '@mui/material';

const SharpRightChevronIcon = createSvgIcon(
    <path
        d="M9.64582 6.14708C9.84073 5.95147 10.1573 5.9509 10.3529 6.14582L15.8374 11.6108C16.0531 11.8257 16.0531 12.1751 15.8374 12.39L10.3529 17.855C10.1573 18.0499 9.84073 18.0493 9.64582 17.8537C9.4509 17.6581 9.45147 17.3415 9.64708 17.1466L14.8117 12.0004L9.64708 6.85418C9.45147 6.65927 9.4509 6.34269 9.64582 6.14708Z"
        fill="currentColor"
    />,
    "SharpRightChevron"
);

export default SharpRightChevronIcon;
