import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import ToolbarActions from "../../common/table/action-toolbar.jsx";
import PaginationBar from "../../common/table/pagination-bar.jsx";
import { PageContentBox } from "../../common/page-content-box";
import ErrorsDialog from "../../common/dialogs/errors-dialog.jsx";
import ProgressDialog from "../../common/dialogs/progress-dialog.jsx";
import useIntegrationTable from "../../common/hooks/useIntegrationTable";

import IntegrationList from "./integration-list.jsx";

import "./styles.less";

const IntegrationSettingsPage = () => {
    const {
        rows, total, busy,
        searchString, setSearchString,
        pageNumber, setPageNumber,
        limitNumber, setLimitNumber,
        error, setError
    } = useIntegrationTable();

    const busyType = useSelector((state) => state.admin.integration.busyType);

    const toolbarActions = [
        {
            id: "title",
            title: <FormattedMessage id="integration-settings-page.toolbar.text" />,
            type: "text",
            position: "begin",
        },
        {
            id: "search",
            type: "search",
            position: "end",
            value: searchString,
            callback: (value) => {
                setSearchString(value);
            },
        },
    ];

    return (
        <PageContentBox className="integration-settings-page-content">
            <ToolbarActions toolbarActions={toolbarActions} />

            <IntegrationList
                integrationItems={rows}
                sx={{ marginTop: "60px" }}
                setError={setError} />

            <PaginationBar
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                limitNumber={limitNumber}
                setLimitNumber={setLimitNumber}
                total={total}
                sx={{ marginTop: "36px" }}
            />

            <ErrorsDialog
                error={error}
                open={error?.type === "error"}
                onClose={() => setError(null)}
            />

            <ProgressDialog
                open={busy}
                busyType={busyType}
            />
        </PageContentBox>
    );
};

export default IntegrationSettingsPage;
