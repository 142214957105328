import SodService from "./sod-service";

class RiskLevelsService extends SodService {
    async getRiskLevels() {
        const data = await this.get('/sod/risks/risk-levels');
        return data && data.map(riskLevel => this.parseRiskLevel(riskLevel));
    }

    async getRiskLevelsFilters(params) {
        return this.getFilters("/sod/risks/risk-levels/filters", params);
    }

    async updateRiskLevels(riskLevels) {
        const riskLevelsToServer = riskLevels.map(riskLevel => this.parseRiskLevelToServer(riskLevel));
        const data = await this.post('/sod/risks/risk-levels', riskLevelsToServer);
        return data && data.map(riskLevel => this.parseRiskLevel(riskLevel));
    }

    parseRiskLevelToServer(riskLevel) {
        return {
            risk_level_id: riskLevel.id,
            description: riskLevel.description,
            color: riskLevel.color
        }
    }

    parseRiskLevel(riskLevel) {
        return {
            id: riskLevel.risk_level_id,
            description: riskLevel.description,
            color: riskLevel.color
        }
    }
}

const riskLevelsService = new RiskLevelsService();

export { riskLevelsService };
export default RiskLevelsService;