import React from "react";
import { LocalizationProvider as MuiLocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ruRU } from "@mui/x-date-pickers/locales";
import ru from "dayjs/locale/ru";
import PropTypes from "prop-types";

const LocalizationProvider = ({ children }) => {
    return (
        <MuiLocalizationProvider
            dateAdapter={AdapterDayjs} 
            adapterLocale={ru}
            localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
        >
            {children}
        </MuiLocalizationProvider>
    );
};

LocalizationProvider.propTypes = {
    children: PropTypes.node
}

export default LocalizationProvider;