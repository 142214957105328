import { combineReducers } from "redux";

import userLevelReducer from "./userLevelSlice.js";
import roleLevelReducer from "./roleLevelSlice.js";
import userBusynessLevelReducer from "./userBusynessLevelSlice.js";


const reportReducer = combineReducers({
    userLevel: userLevelReducer,
    roleLevel: roleLevelReducer,
    userBusynessLevel: userBusynessLevelReducer
});

export default reportReducer;
