import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { setSubmit, createGroup, deleteGroup, editGroup } from "../../../../../reducers/admin/systemGroupReducer";
import { fetchGroupDetailed, setInitialCurrentGroup, clearCurrentGroup } from "../../../../../reducers/admin/systemGroupReducer";
import { setError } from "../../../../../reducers/admin/systemGroupReducer";

import { DeleteDialog, ProgressDialog, ErrorsDialog, UserLogDialog } from "../../../../common/dialogs";
import { ADD_MODE, EDIT_MODE, getCurrentMode } from "../../../../common/entity-form";
import useLogs from "../../../../common/hooks/useLogs";

import GroupFormPageHeader from "./group-form-page-header.jsx";
import GroupFormPageGroup from "./group-form-page-group.jsx";

import "./styles.less"

const GroupFormPage = () => {
    const { groupId } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const intl = useIntl();
    const busy = useSelector(state => state.admin.systemGroups.busy);
    const busyType = useSelector(state => state.admin.systemGroups.busyType);
    const error = useSelector(state => state.admin.systemGroups.error);
    const submit = useSelector(state => state.admin.systemGroups.submit);
    const group = useSelector(state => state.admin.systemGroups.currentGroup);
    const groupEditable = useSelector(state => state.admin.systemGroups.currentGroupEditable);
    const groupDeleted = useSelector(state => state.admin.systemGroups.currentGroupDeleted);
    const validationErrors = useSelector(state => state.admin.systemGroups.validationErrors);

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [form, setForm] = useState(null);

    const { logs, getLogs, openLogs, setOpenLogs } = useLogs("system_group", groupId)

    const mode = getCurrentMode(!groupId, groupEditable);

    const [formErrors, setFormErrors] = useState(null)

    useEffect(() => {
        if (!validationErrors) return;

        const formErrosTmp = validationErrors.reduce((accumulator, error) => {
            accumulator[error.field] = error;

            return accumulator
        }, {});

        setFormErrors(formErrosTmp)

    }, [validationErrors, form])

    useEffect(() => {
        if (groupId) {
            dispatch(fetchGroupDetailed(groupId));
        } else {
            dispatch(setInitialCurrentGroup());
        }

        return (() => {
            dispatch(clearCurrentGroup());
        });
    }, [groupId, dispatch]);

    useEffect(() => {
        if (!group) {
            return;
        }

        setForm({
            id: group?.id || "",
            description: group?.description || "",
            systems: group?.systems || []
        });
    }, [group]);

    useEffect(() => {
        if (!submit) return;

        dispatch(setSubmit(false))

        if (mode === ADD_MODE || groupDeleted) {
            handleBackClick();
        }
    }, [submit, dispatch, mode, groupDeleted, handleBackClick])

    const handleSaveGroup = () => {
        const formGroup = {
            id: form.id?.trim(),
            description: form.description,
            systems: [...form.systems]
        };

        if (formGroup.id !== form.id) {
            setForm({
                ...form,
                id: formGroup.id
            });
        }

        if (mode === EDIT_MODE) {
            dispatch(editGroup(formGroup));
        } else {
            dispatch(createGroup(formGroup));
        }
    };

    const backPath = useMemo(() => (mode === ADD_MODE
        ? ".."
        : "../.."
    ), [mode]);

    const handleBackClick = useCallback(() => {
        navigate(backPath, { relative: "path" });
    }, [navigate, backPath]);

    const handleDeleteClick = () => {
        setShowDeleteDialog(true);
    };

    const handleDeleteCancelClick = () => {
        setShowDeleteDialog(false);
    };

    const handleDeleteConfirmClick = () => {
        dispatch(deleteGroup(groupId))
    };

    const handleEditClick = async () => {
        dispatch(fetchGroupDetailed(groupId));
    };

    if (!group && error) return <Navigate to={backPath} relative="path" />;

    return (
        <>
            {form && (
                <>
                    <GroupFormPageHeader
                        mode={mode}
                        groupId={groupId}
                        onBackClick={handleBackClick}
                        onDeleteClick={handleDeleteClick}
                        onSaveClick={handleSaveGroup}
                        onEditClick={handleEditClick}
                        onShowLogsClick={getLogs}
                    />

                    <GroupFormPageGroup
                        form={form}
                        setForm={setForm}
                        mode={mode}
                        validationErrors={formErrors}
                    />
                </>
            )}

            <DeleteDialog
                open={showDeleteDialog}
                title={intl.formatMessage({ id: "system-groups-page.delete-dialog.title" })}
                text={intl.formatMessage(
                    { id: "group-form-page-group.delete-group-dialog.text" },
                    { name: groupId }
                )}
                onCancelClick={handleDeleteCancelClick}
                onDeleteClick={handleDeleteConfirmClick}
            />

            <ProgressDialog
                open={busy}
                busyType={busyType}
            />

            <ErrorsDialog
                error={error}
                open={error?.type === "error"}
                onClose={() => { dispatch(setError(null)) }}
            />

            <UserLogDialog
                open={openLogs}
                logs={logs}
                onClose={() => setOpenLogs(false)}
            />
        </>
    )
}

export default GroupFormPage;