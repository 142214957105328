import React from "react";
import { SvgIcon } from "@mui/material";

const ArrowDownIcon = (props) => (
    <SvgIcon
        viewBox="0 0 14 14"
        {...props}
    >
        <svg
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.9165 5.25L6.52801 8.34558C6.79952 8.5783 7.20016 8.5783 7.47166 8.34558L11.0832 5.25"
                stroke="currentColor"
                strokeWidth="0.90625"
                strokeLinecap="round"
            />
        </svg>
    </SvgIcon>
);

export default ArrowDownIcon;