import React from "react";
import { useIntl } from "react-intl";
import PropTypes from 'prop-types';

import { reportsService } from "../../../services/reports-service";

import { DropdownSearchHelpInput } from "../../common/form-controls";

const getRisks = (params, signal) => {
    return reportsService.getRisksSearchHelp(params, signal);
};

const RiskIdsInput = ({ ids, setIds }) => {
    const intl = useIntl();
    const label = intl.formatMessage({ id: "report-parameters.risks-label" });

    return (
        <DropdownSearchHelpInput
            ids={ids}
            setIds={setIds}
            getData={getRisks}
            label={label}
        />
    );
};

RiskIdsInput.propTypes = {
    ids: PropTypes.arrayOf(PropTypes.string),
    setIds: PropTypes.func
};

export default RiskIdsInput;
