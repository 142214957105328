import React, { useMemo } from "react";
import PropTypes from "prop-types";

import CommonTable from "../table";

const getRowId = (row) => row.rowId;

const TreeTable = ({ tree, ...props }) => {
    const rows = useMemo(() => {
        const res = [];

        const addRow = (row) => {
            res.push(row);

            if (!row.children || !row.open) {
                return;
            }

            for (const child of row.children) {
                addRow(child);
            }
        }

        for (const row of tree) {
            addRow(row);
        }

        return res;
    }, [tree]);

    return (
        <CommonTable
            rows={rows}
            getRowId={getRowId}
            {...props}
        />
    );
};

const nodeShape = {
    open: PropTypes.bool,
};

const nodeArray = PropTypes.arrayOf(PropTypes.shape(nodeShape));

nodeShape.children = nodeArray;

TreeTable.propTypes = {
    tree: nodeArray
};

export default TreeTable;