import React from "react";
import { useIntl } from "react-intl";
import PropTypes from 'prop-types';

import { reportsService } from "../../../services/reports-service";

import { DropdownSearchHelpInput } from "../../common/form-controls";

const getMatrices = (params, signal) => {
    return reportsService.getMatrixSearchHelp(params, signal);
};

const MatrixIdsInput = ({ ids, setIds }) => {
    const intl = useIntl();
    const label = intl.formatMessage({ id: "report-parameters.matrix-label" });

    return (
        <DropdownSearchHelpInput
            ids={ids}
            setIds={setIds}
            getData={getMatrices}
            label={label}
        />
    );
};

MatrixIdsInput.propTypes = {
    ids: PropTypes.arrayOf(PropTypes.string),
    setIds: PropTypes.func
};

export default MatrixIdsInput;
