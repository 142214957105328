import React, { useEffect, useRef } from "react";

import CommonTable from "../table";

const EntityFormViewTable = ({
    errorIndex=null,
    className="",
    limitNumber=null,
    virtualize=true,
    virtualizationProps,
    showFiltersActions=false,
    showPagination=false,
    setErrorScroll,
    ...props
}) => {
    const virtualizerRef = useRef(null);

    useEffect(() => {
        if (errorIndex !== null) {
            setTimeout(() => {
                virtualizerRef.current.scrollToIndex({
                    index: errorIndex,
                    behavior: "auto",
                    align: "center"
                });
            }, 400);

            setErrorScroll(null);
        }
    }, [errorIndex, setErrorScroll]);

    return (
        <CommonTable
            className={`entity-form-table ${className}`}
            limitNumber={limitNumber}
            virtualize={virtualize}
            virtualizationProps={{
                overscan: 560,
                fixedItemHeight: 56,
                ref: virtualizerRef,
                ...virtualizationProps
            }}
            showFiltersActions={showFiltersActions}
            showPagination={showPagination}
            useKeyboardNavigation
            {...props}
        />
    );
}

EntityFormViewTable.propTypes = CommonTable.propTypes;

export default EntityFormViewTable;
