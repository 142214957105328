import React from "react";
import { SvgIcon } from "@mui/material";

const SearchHelpIcon = (props) => (
    <SvgIcon
        viewBox="0 0 16 21"
        {...props}
    >
        <svg
            viewBox="0 0 16 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.88235 20.5C1.36471 20.5 0.921412 20.3043 0.552471 19.913C0.184157 19.521 0 19.05 0 18.5V4.5H1.88235V18.5H12.2353V20.5H1.88235ZM5.64706 16.5C5.12941 16.5 4.68643 16.3043 4.31812 15.913C3.94918 15.521 3.76471 15.05 3.76471 14.5V2.5C3.76471 1.95 3.94918 1.479 4.31812 1.087C4.68643 0.695667 5.12941 0.5 5.64706 0.5H14.1176C14.6353 0.5 15.0786 0.695667 15.4475 1.087C15.8158 1.479 16 1.95 16 2.5V14.5C16 15.05 15.8158 15.521 15.4475 15.913C15.0786 16.3043 14.6353 16.5 14.1176 16.5H5.64706Z"
                fill="currentColor"
            />
        </svg>

    </SvgIcon>
);

export default SearchHelpIcon;