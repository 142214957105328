const breakpoints = {
    minMobileBreakpoint: "320px",
    maxMobileBreakpoint: "1023px",

    minLaptopBreakpoint: "1024px",
    maxLaptopBreakpoint: "1919px",

    minDesktopBreakpoint: "1920px",
};

export default breakpoints;