import React, { useState } from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

import GroupRoleTable from "./group-role-table.jsx";
import GroupRoleActions from "./group-role-actions.jsx";
import GroupRoleAddRolesDialog from "./group-role-add-roles-dialog.jsx";

import "./styles.less";

const GroupRoleModelingBox = ({
    role,
    onAddChildRole,
    onRemoveChildRoles
}) => {
    const [selectedIds, setSelectedIds] = useState([]);
    const [addRolesDialogOpen, setAddRolesDialogOpen] = useState(false);

    const handleRemoveChildRoles = () => {
        onRemoveChildRoles(selectedIds);
        setSelectedIds([]);
    };

    return (
        <Box className="group-role-modeling-box">
            <GroupRoleActions
                onAddClick={() => setAddRolesDialogOpen(true)}
                onRemoveClick={handleRemoveChildRoles}
            />

            <GroupRoleTable
                rows={role.children}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
            />

            {addRolesDialogOpen && (
                <GroupRoleAddRolesDialog
                    open={addRolesDialogOpen}
                    systemId={role.systemId}
                    onClose={() => setAddRolesDialogOpen(false)}
                    onSubmit={onAddChildRole}
                />
            )}
        </Box>
    );
};

GroupRoleModelingBox.propTypes = {
    role: PropTypes.object,
    onAddChildRole: PropTypes.func,
    onRemoveChildRoles: PropTypes.func,
};

export default GroupRoleModelingBox;