import React from "react";
import { SvgIcon } from "@mui/material";

const LeftChevronIcon = (props) => (
    <SvgIcon
        viewBox="0 0 16 16"
        {...props}
    >
        <svg
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.875 3.625L6.54337 7.51191C6.30262 7.79277 6.30262 8.20723 6.54337 8.48809L9.875 12.375"
                stroke="currentColor"
                strokeWidth="0.9375"
                strokeLinecap="round"
            />
        </svg>
    </SvgIcon>
);

export default LeftChevronIcon;
