import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { Box } from '@mui/material';
import PropTypes from "prop-types";

import { functionsService } from "../../../../services/functions-service";
import { matrixService } from "../../../../services/matrix-service";

import { DropdownSearchHelpInput, SelectInput, TextInput } from '../../../common/form-controls';
import { ADD_MODE, READ_MODE, EntityFormSubtitle } from "../../../common/entity-form";
import { MenuItem } from "../../../common/menu";
import useRiskLevels from "../../../common/hooks/useRiskLevels";

const RiskIdInput = ({ id, setId, disabled, error }) => {
    const intl = useIntl();

    return (
        <TextInput
            label={intl.formatMessage({ id: "risk-form-page-risk.input-risk-id" })}
            value={id}
            onChange={(event) => setId(event.target.value)}
            disabled={disabled}
            error={error?.value === id}
            errorMessage={error?.message}
        />
    );
};

const FunctionIdInput = ({ riskType, ids, setIds, disabled, error }) => {
    const getData = useCallback((params, signal) => {
        const paramsNew = {
            ...params, risk_type: riskType
        }
        return functionsService.getFunctionsByRiskType(paramsNew, signal);
    }, [riskType])

    return (
        <DropdownSearchHelpInput
            ids={ids}
            setIds={setIds}
            disabled={disabled}
            getData={getData}
            dataKey="functions"
            error={error && [...ids].sort().toString() === [...error.value].sort().toString() ? error : null}
            errorMessage={error && error.message}
            label={<FormattedMessage id="risk-form-page-risk.input-functions" />}
        />
    );
};

const MatrixHeaderIdInput = ({ ids, setIds, disabled, error }) => {
    const getData = useCallback((params, signal) => {
        return matrixService.getMatrixHeaders(params, signal);
    }, []);

    return (
        <DropdownSearchHelpInput
            ids={ids}
            setIds={setIds}
            disabled={disabled}
            getData={getData}
            dataKey="matrixHeaders"
            error={error}
            errorMessage={error?.message}
            label={<FormattedMessage id="risk-form-page-risk.input-matrix-headers" />}
        />
    );
};

const RiskLevelInput = ({ riskLevels, riskLevelId, setRiskLevelId, disabled, error }) => {
    const intl = useIntl();

    return (
        <SelectInput
            label={intl.formatMessage({ id: "risk-form-page-risk.input-risk-lvl" })}
            value={riskLevelId}
            onChange={(event) => setRiskLevelId(event.target.value)}
            disabled={disabled}
            error={error ? riskLevelId === error.value : false}
            errorMessage={error && error.message}
        >
            {riskLevels.map(item => (
                <MenuItem
                    value={item.id}
                    key={item.id}
                    sx={{
                        minHeight: "initial",
                        height: "36px"
                    }}
                >
                    {item.description}
                </MenuItem>
            ))}
        </SelectInput>
    )
};

const RiskTypeInput = ({ riskTypes, riskTypeId, setRiskTypeId, disabled, error }) => {
    const intl = useIntl();

    return (
        <SelectInput
            label={intl.formatMessage({ id: "risk-form-page.input-risk-type" })}
            value={riskTypeId}
            onChange={(event) => setRiskTypeId(event.target.value)}
            disabled={disabled}
            error={error ? riskTypeId === error.value : false}
            errorMessage={error && error.message}
        >
            {riskTypes.map(item => (
                <MenuItem
                    value={item.id}
                    key={item.id}
                    sx={{
                        minHeight: "initial",
                        height: "36px"
                    }}
                >
                    {item.description}
                </MenuItem>
            ))}
        </SelectInput>
    )
};

const RiskStatusInput = ({ active, setActive, disabled, error }) => {
    const intl = useIntl();

    const riskStatuses = [
        {
            id: "true",
            description: intl.formatMessage({ id: "common.table.active.true"})
        },
        {
            id: "false",
            description: intl.formatMessage({ id: "common.table.active.false"})
        }
    ];

    const value = active.toString();

    const handleChange = (event) => {
        setActive(event.target.value === "true");
    };

    return (
        <SelectInput
            label={intl.formatMessage({ id: "risk-form-page.input-status" })}
            value={value}
            onChange={handleChange}
            disabled={disabled}
            error={error ? value === error.value : false}
            errorMessage={error && error.message}
        >
            {riskStatuses.map(item => (
                <MenuItem
                    value={item.id}
                    key={item.id}
                    sx={{
                        minHeight: "initial",
                        height: "36px"
                    }}
                >
                    {item.description}
                </MenuItem>
            ))}
        </SelectInput>
    )
};

const RiskDescriptionInput = ({ description, setDescription, disabled, error }) => {
    const intl = useIntl();

    return (
        <TextInput
            multiline
            rows={3}
            label={intl.formatMessage({ id: "risk-form-page-risk.input-description" })}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            disabled={disabled}
            error={error?.value === description}
            errorMessage={error?.message}
        />
    );
};

const RiskFormPageRisk = ({ form, mode, setForm, validationErrors }) => {
    const intl = useIntl();
    const setId = value => setForm({ ...form, id: value });
    const setDescription = value => setForm({ ...form, description: value });
    const setRiskLevelId = value => setForm({ ...form, riskLevel: value })
    const setRiskTypeId = value => setForm({ ...form, riskType: value })
    const setActive = value => setForm({ ...form, active: value })

    const setFunctions = ids => setForm({...form, functions: [...ids]})
    const setMatrixHeaders = ids => setForm({...form, matrixHeaders: [...ids]})

    const riskLevels = useRiskLevels();

    const riskTypes = [{
            id: "common", description: intl.formatMessage({ id: "risk-form-page-risk.risk-types.common"})
        },{
            id: "crit_action", description: intl.formatMessage({ id: "risk-form-page-risk.risk-types.crit_action"})
        },{
            id: "crit_permission", description: intl.formatMessage({ id: "risk-form-page-risk.risk-types.crit_permission"})
        }

    ];

    const formDisabled = mode === READ_MODE;
    const idDisabled = mode !== ADD_MODE;

    return (
        <>
            <EntityFormSubtitle className="risk-form-page-risk-subtitle">
                <FormattedMessage id="risk-form-page-risk.title" />
            </EntityFormSubtitle>

            <Box className="risk-form-page-risk-inputs">
                <RiskIdInput 
                    id={form.id} 
                    setId={setId} 
                    disabled={idDisabled}
                    error={validationErrors && validationErrors["risk_id"]} />
                
                <RiskTypeInput
                    riskTypes={riskTypes}
                    riskTypeId={form.riskType}
                    setRiskTypeId={setRiskTypeId}
                    disabled={formDisabled}
                    error={validationErrors && validationErrors["type"]}
                />

                <RiskLevelInput
                    riskLevels={riskLevels}
                    riskLevelId={form.riskLevel}
                    setRiskLevelId={setRiskLevelId}
                    disabled={formDisabled}
                    error={validationErrors && validationErrors["risk_level_id"]}
                />

                <RiskStatusInput
                    active={form.active}
                    setActive={setActive}
                    disabled={formDisabled}
                    error={validationErrors && validationErrors["active"]}
                />

                <FunctionIdInput
                    riskType={form.riskType}
                    ids={form.functions}
                    setIds={setFunctions}
                    disabled={formDisabled}
                    error={validationErrors && validationErrors["functions"]}
                />

                <MatrixHeaderIdInput
                    ids={form.matrixHeaders}
                    setIds={setMatrixHeaders}
                    disabled={formDisabled}
                    error={validationErrors && validationErrors["matrix_headers"]}
                />

                <RiskDescriptionInput
                    description={form.description}
                    setDescription={setDescription}
                    disabled={formDisabled}
                    error={validationErrors && validationErrors["description"]}
                />
            </Box>
        </>
    );
};

RiskFormPageRisk.propTypes = {
    form: PropTypes.object,
    mode: PropTypes.string,
    setForm: PropTypes.func,
    validationErrors: PropTypes.object
}

RiskIdInput.propTypes = {
    id: PropTypes.string,
    setId: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object
}

RiskTypeInput.propTypes = {
    riskTypes: PropTypes.arrayOf(PropTypes.object),
    riskTypeId: PropTypes.string,
    setRiskTypeId: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object
}

RiskStatusInput.propTypes = {
    active: PropTypes.boolean,
    setActive: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object
}

RiskDescriptionInput.propTypes = {
    description: PropTypes.string,
    setDescription: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object
}

FunctionIdInput.propTypes = {
    riskType: PropTypes.string,
    ids: PropTypes.arrayOf(PropTypes.string),
    setIds: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object
}

FunctionIdInput.propTypes = {
    ids: PropTypes.arrayOf(PropTypes.string),
    setIds: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object
}

MatrixHeaderIdInput.propTypes = {
    ids: PropTypes.arrayOf(PropTypes.string),
    setIds: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object
}

RiskLevelInput.propTypes = {
    riskLevels: PropTypes.arrayOf(PropTypes.object),
    riskLevelId: PropTypes.number,
    setRiskLevelId: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object
}

export default RiskFormPageRisk;
