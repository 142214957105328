import React from 'react'
import { DateRange as ReactDateRange } from 'react-date-range';
import PropTypes from 'prop-types'

import renderDatePickerNavigator from './navigator';

import "./style.less";

const DateRange = ({ ranges, onChange, rangeColors, locale }) => {
    return (
        <ReactDateRange
            showDateDisplay={false}
            navigatorRenderer={(currFocusedDate, changeShownDate) =>
                renderDatePickerNavigator(locale, currFocusedDate, changeShownDate)
            }
            weekdayDisplayFormat="EEEEE"
            showMonthAndYearPickers={false}
            onChange={onChange}
            ranges={ranges}
            locale={locale}
            rangeColors={rangeColors}
            className="date-range"
        />
    );
};

DateRange.propTypes = {
    ranges: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    onChange: PropTypes.func,
    rangeColors: PropTypes.arrayOf(PropTypes.string),
    locale: PropTypes.string
};

export default DateRange;
