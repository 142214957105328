import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

import { PageContentBox } from "../../../../common/page-content-box";
import { TextInput } from "../../../../common/form-controls";
import { ADD_MODE, EntityFormSubtitle } from "../../../../common/entity-form";

const BusynessProcessIdInput = ({ id, setId, disabled, error }) => {
    const intl = useIntl();

    return(
        <TextInput
            label={intl.formatMessage({ id: "busyness-process-form-page-busyness-process.input-busyness-process-id" })}
            value={id}
            onChange={(event) => setId(event.target.value)}
            disabled={disabled}
            error={error?.value === id}
            errorMessage={error?.message}
        />
    );
};

const BusynessProcessDescriptionInput = ({ description, setDescription, disabled, error }) => {
    const intl = useIntl();

    return(
        <TextInput
            multiline
            rows={3}
            label={intl.formatMessage({ id: "busyness-process-form-page-busyness-process.input-description" })}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            disabled={disabled}
            error={error?.value === description}
            errorMessage={error?.message}
        />
    );
};

const BusynessProcessFormPageBusynessProcess = ({ form, setForm, disabled, mode, validationErrors }) => {
    const setId = value => setForm({...form, id: value});
    const setDescription = value => setForm({...form, description: value});

    const idDisabled = mode !== ADD_MODE;

    return(
        <PageContentBox className="busyness-process-form-page-busyness-process">
            <EntityFormSubtitle>
                <FormattedMessage id="busyness-process-form-page-busyness-process.title" />
            </EntityFormSubtitle>

            <Box className="busyness-process-form-page-busyness-process-inputs">
                <BusynessProcessIdInput 
                    id={form.id} 
                    setId={setId} 
                    disabled={idDisabled}
                    error={validationErrors && validationErrors["business_process_id"]} />

                <BusynessProcessDescriptionInput
                    description={form.description}
                    setDescription={setDescription}
                    disabled={disabled}
                    error={validationErrors && validationErrors["description"]}
                />
            </Box>
        </PageContentBox>
    );
};

BusynessProcessIdInput.propTypes = {
    id: PropTypes.string,
    setId: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object,
};

BusynessProcessDescriptionInput.propTypes = {
    description: PropTypes.string,
    setDescription: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object,
};

BusynessProcessFormPageBusynessProcess.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func,
    disabled: PropTypes.bool,
    mode: PropTypes.string,
    validationErrors: PropTypes.object,
}

export default BusynessProcessFormPageBusynessProcess;