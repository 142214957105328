import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";

import {
    deleteLock,
    fetchLocks,
    fetchLocksColumns,
    setSelectedIds as setSelectedIdsAction,
    setSearchString as setSearchStringAction,
    setPageNumber as setPageNumberAction,
    setLimitNumber as setLimitNumberAction,
    setSortData as setSortDataAction,
    setFilterData as setFilterDataAction,
    setError as setErrorAction,
} from "../../../reducers/admin/locksSlice";

import { TrashIcon } from "../../common/icons";
import CommonTable from "../../common/table";
import useReduxTable from "../../common/hooks/useReduxTable";
import useFilters from "../../common/hooks/useFilters";
import { ErrorsDialog } from "../../common/dialogs";


const LocksPage = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const {
        rows, columns, total,
        selectedIds,
        searchString, setSearchString,
        pageNumber, setPageNumber,
        limitNumber, setLimitNumber,
        filterData, setFilterData,
        error, setError
    } = useReduxTable(
        (state) => state.admin.locks.locks, fetchLocks,
        (state) => state.admin.locks.columns, fetchLocksColumns,
        (state) => state.admin.locks.selectedIds, setSelectedIdsAction,
        (state) => state.admin.locks.searchString, setSearchStringAction,
        (state) => state.admin.locks.pageNumber, setPageNumberAction,
        (state) => state.admin.locks.limitNumber, setLimitNumberAction,
        (state) => state.admin.locks.sortData, setSortDataAction,
        (state) => state.admin.locks.filterData, setFilterDataAction,
        (state) => state.admin.locks.error, setErrorAction,
        (state) => state.admin.locks.total,
        (state) => state.admin.locks.busy,
    );

    const getFilters = useFilters("lock");

    const columnsInner = useMemo(() => [
        ...columns,
        {
            id: "actions",
            type: "actions",
            actions: [
                {
                    id: "delete",
                    IconComponent: TrashIcon,
                    onClick: (_, row) => dispatch(deleteLock(row))
                }
            ]
        }
    ], [columns, dispatch]);

    const toolbarActions = useMemo(() => [
        {
            id: "title",
            title: intl.formatMessage({ id: "locks-page.toolbar.title" }),
            type: "text",
            position: "begin"
        }
    ], [intl]);

    return (
        <>
            <CommonTable
                rows={rows}
                columns={columnsInner}
                getFilters={getFilters}
                filterData={filterData}
                setFilterData={setFilterData}
                toolbarActions={toolbarActions}
                selectedIds={selectedIds}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                limitNumber={limitNumber}
                setLimitNumber={setLimitNumber}
                total={total}
                searchString={searchString}
                setSearchString={setSearchString}
            />

            <ErrorsDialog
                error={error}
                open={error?.type === "error"}
                onClose={() => setError(null)}
            />
        </>
    );
};

export default LocksPage;
