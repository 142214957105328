import { nanoid } from "@reduxjs/toolkit";

export const REPORT_TYPES = {
    FINAL: "final",
    DETAILED: "detailed",
    BUSINESS: "business"
};

export const SUMMARY_REPORT_VIEWS = {
    LIST: "list",
    DETAILED: "detailed"
}

export const REPORT_LEVELS = {
    OPERATIONS: "action",
    PERMISSIONS: "permission",
    CRITICAL_OPERATIONS: "critical_action",
    CRITICAL_PERMISSIONS: "critical_permission",
    CRITICAL_OBJECTS: "critical_objects",
};

export const REPORT_VARIANT_TYPES = {
    USERS: "users",
    ROLES: "roles",
    USERS_MODELING: "users_modeling",
    ROLES_MODELING: "roles_modeling"
};

export const USER_REPORT_ACTIONS_TYPES = {
    SOD_REPORTS: "sod_reports",
    REPORTS_MODELING: "sod_modeling"
};

export const DIALOG_TYPES = {
    SAVE: "save",
    FAVORITES: "favorites",
    DELETE: "delete",
    VARIANTS: "variants",
    COLUMN_SETTINGS: "column-settings"
};

export const PERMISSIONS_TREE_VARIANT_LEVEL = {
    USERS: "user",
    ROLES: "role",
};

export const getFieldValidationError = (validationErrors, field) => {
    return validationErrors?.find(error => error.field === field);
}

export const getReportVariantRoute = (variantName, variantType) => {
    const encodedVariantName = encodeURIComponent(variantName);

    switch (variantType) {
        case REPORT_VARIANT_TYPES.USERS:
        case REPORT_VARIANT_TYPES.USERS_MODELING:
            return `/user-level/${encodedVariantName}`;
        
        case REPORT_VARIANT_TYPES.ROLES:
        case REPORT_VARIANT_TYPES.ROLES_MODELING:
            return `/role-level/${encodedVariantName}`;
        
        default:
            return encodedVariantName;
    }
};

export const getActionTypeForVariantType = (variantType) => {
    switch (variantType) {
        case REPORT_VARIANT_TYPES.USERS:
        case REPORT_VARIANT_TYPES.ROLES:
            return USER_REPORT_ACTIONS_TYPES.SOD_REPORTS;

        case REPORT_VARIANT_TYPES.USERS_MODELING:
        case REPORT_VARIANT_TYPES.ROLES_MODELING:
            return USER_REPORT_ACTIONS_TYPES.REPORTS_MODELING;
    }
};

export const getReportVariantAction = (variantName, variantRoute, actionType) => ({
    id: nanoid(),
    type: actionType,
    title: variantName,
    route: variantRoute
});
