import React from "react";
import PropTypes from "prop-types";

import { EntityFormHeader } from "../../../../common/entity-form";

import BusynessProcessFormHeaderTitle from "./busyness-process-form-header-title.jsx";
import BusynessProcessFormActions from "./busyness-process-form-actions.jsx";

const BusynessProcessFormPageHeader = ({
    busynessProcessId,
    mode,
    onBackClick,
    onDeleteClick,
    onSaveClick,
    onEditClick,
    onShowLogsClick
}) => {    
    return (
        <EntityFormHeader>
            <BusynessProcessFormHeaderTitle
                busynessProcessId={busynessProcessId}
                mode={mode}
                onBackClick={onBackClick}
            />

            <BusynessProcessFormActions
                mode={mode}
                onBackClick={onBackClick}
                onDeleteClick={onDeleteClick}
                onSaveClick={onSaveClick}
                onEditClick={onEditClick}
                onShowLogsClick={onShowLogsClick}
            />
        </EntityFormHeader>
    );
};

BusynessProcessFormPageHeader.propTypes = {
    busynessProcessId: PropTypes.string,
    mode: PropTypes.string,
    onBackClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onSaveClick: PropTypes.func,
    onEditClick: PropTypes.func,
    onShowLogsClick: PropTypes.func,
};

export default BusynessProcessFormPageHeader;