import React, { useState, useCallback } from "react";
import { useMediaQuery } from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import PropTypes from 'prop-types';

import { systemsService } from "../../../../services/systems-service";
import { risksService } from "../../../../services/risks-service";
import { criticalRuleService } from "../../../../services/critical-rule-service";

import { getUpdatedFormForDeleteEntity, getUpdatedFormForEntity, getUpdatedFormForMassDeleteEntity, getVisibleEntities } from "../../../../utils/formViews";

import { CHANGE_STATUS } from "../../../common/const";
import { EntityFormPageViews, EntityFormTabs } from "../../../common/entity-form";
import breakpoints from "../../../common/styles/breakpoints.js";

import RisksView from "../risks-view";
import CriticalRolesView from "../critical-roles-view";
import CriticalProfilesView from "../critical-profiles-view";

const tabs = [
    {
        id: "risks",
        titleId: "matrix-headers-form-content.tabs.risk"
    },
    {
        id: "critical-roles",
        titleId: "matrix-headers-form-content.tabs.critical-roles"
    },
    {
        id: "critical-profiles",
        titleId: "matrix-headers-form-content.tabs.critical-profiles"
    }
];

const getUpdatedFormForRisk = (form, risk, field, value) => {
    return getUpdatedFormForEntity(
        form, risk, field, value, "risks", ["riskId"]
    );
};

const getUpdatedFormForCritRole = (form, criticalRole, field, value) => {
    return getUpdatedFormForEntity(
        form, criticalRole, field, value, "criticalRoles", ["role", "system"]
    );
};

const getUpdatedFormForCritProfile = (form, criticalProfile, field, value) => {
    return getUpdatedFormForEntity(
        form, criticalProfile, field, value, "criticalProfiles", ["profile", "system"]
    );
};

const getValidationErrorMessage = (error) => error.detail || error.title;

const validateRisk = (item, risk, error) => {
    if (error) {
        item["error_riskId"] = {
            value: item["riskId"],
            type: "validation",
            message: getValidationErrorMessage(error)
        };

        item["description"] = "";

        return;
    }

    item["error_riskId"] = null;
    item["description"] = risk?.description ?? "";
};

const validateCriticalRole = (item, criticalRole, errorSystem, errorRole) => {
    if (errorSystem) {
        item["error_system"] = {
            value: item["system"],
            type: "validation",
            message: getValidationErrorMessage(errorSystem)
        };
    } else {
        item["error_system"] = null;
    }

    if (errorRole) {
        item["error_role"] = {
            value: item["role"],
            type: "validation",
            message: getValidationErrorMessage(errorRole)
        };
    } else {
        item["error_role"] = null;
    }

    item["description"] = criticalRole?.description ?? "";
};

const validateCriticalProfile = (item, criticalProfile, errorSystem, errorProfile) => {
    if (errorSystem) {
        item["error_system"] = {
            value: item["system"],
            type: "validation",
            message: getValidationErrorMessage(errorSystem)
        };
    } else {
        item["error_system"] = null;
    }

    if (errorProfile) {
        item["error_profile"] = {
            value: item["profile"],
            type: "validation",
            message: getValidationErrorMessage(errorProfile)
        };
    } else {
        item["error_profile"] = null;
    }

    item["description"] = criticalProfile?.description ?? "";
};

const getSystemForEditRow = async (field, value, item) => {
    let system, systemId, errorSystem;

    if (field === "system") {
        if (value) {
            try {
                system = await systemsService.getSystemDetailed(value);
            } catch (e) {
                errorSystem = e
            }
        }

        systemId = system?.id ?? value;
    } else {
        systemId = item["system"];
        errorSystem = item["error_system"];
    }

    return { systemId, errorSystem };
};

const MatrixHeadersFormViews = ({ form, setForm, formDisabled, validationErrors, errorScroll, setErrorScroll }) => {
    const matches1024 = useMediaQuery(`(min-width:  ${breakpoints.minLaptopBreakpoint})`);

    const [currentView, setCurrentView] = useState(tabs[0].id);

    const addRisk = () => {
        const risk = {
            id: nanoid(),
            riskId: "",
            description: "",
            active: true,
            changeStatus: CHANGE_STATUS.ADD
        };

        setForm(form => ({
            ...form,
            risks: [...form.risks, risk]
        }));
    };

    const deleteRisk = (id) => {
        setForm(form => {
            const { newForm } = getUpdatedFormForDeleteEntity(form, id, "risks");
            return newForm;
        });
    };

    const deleteRiskMass = useCallback((ids) => {
        setForm(form => {
            const newForm = getUpdatedFormForMassDeleteEntity(form, ids, "risks")
            return newForm;
        });
    }, [setForm]);

    const editRisk = async (row, field, value) => {
        if (field === "active") {
            setForm(form => {
                const { newForm } = getUpdatedFormForRisk(form, row, field, value);
                return newForm;
            });
            return;
        }

        let risk, error;

        if (value) {
            try {
                risk = await risksService.getRiskDetailed(value)
            } catch (e) {
                error = e
            }
        }

        const newRiskValue = risk ? risk.id : value;

        setForm(form => {
            const { newForm, item } = getUpdatedFormForRisk(form, row, field, newRiskValue);
            validateRisk(item, risk, error);
            return newForm;
        });
    };

    const addCriticalRole = () => {
        const critRole = {
            id: nanoid(),
            role: "",
            system: "",
            description: "",
            active: true,
            changeStatus: CHANGE_STATUS.ADD
        };

        setForm(form => ({
            ...form,
            criticalRoles: [...form.criticalRoles, critRole]
        }));
    };

    const deleteCriticalRole = (id) => {
        setForm(form => {
            const { newForm } = getUpdatedFormForDeleteEntity(form, id, "criticalRoles");
            return newForm;
        });
    };

    const deleteCriticalRoleMass = useCallback((ids) => {
        setForm(form => {
            const newForm = getUpdatedFormForMassDeleteEntity(form, ids, "criticalRoles")
            return newForm;
        });
    }, [setForm]);

    const editCriticalRole = async (row, field, value) => {
        if (field === "active") {
            setForm(form => {
                const { newForm } = getUpdatedFormForCritRole(form, row, field, value);
                return newForm;
            });
            return;
        }

        let critRole, errorRole;
        let newValue;

        const item = form.criticalRoles.find(item => item.id === row.id);
        const { systemId, errorSystem } = await getSystemForEditRow(field, value, item);
        const critRoleName = field === "role" ? value : item["role"];

        if (critRoleName) {
            try {
                critRole = await criticalRuleService.getCriticalRoleDetailed(critRoleName, systemId);
            } catch (e) {
                errorRole = e;
            }
        }

        switch (field) {
            case "system":
                newValue = systemId;
                break;
            case "role":
                newValue = critRole?.name ?? value;
                break;
        }

        setForm(form => {
            const { newForm, item } = getUpdatedFormForCritRole(form, row, field, newValue);
            validateCriticalRole(item, critRole, errorSystem, errorRole);
            return newForm;
        });
    };


    const addCriticalProfile = () => {
        const critProfile = {
            id: nanoid(),
            profile: "",
            system: "",
            description: "",
            active: true,
            changeStatus: CHANGE_STATUS.ADD
        };

        setForm(form => ({
            ...form,
            criticalProfiles: [...form.criticalProfiles, critProfile]
        }));
    };

    const deleteCriticalProfile = (id) => {
        setForm(form => {
            const { newForm } = getUpdatedFormForDeleteEntity(form, id, "criticalProfiles");
            return newForm;
        });
    };

    const deleteCriticalProfileMass = useCallback((ids) => {
        setForm(form => {
            const newForm = getUpdatedFormForMassDeleteEntity(form, ids, "criticalProfiles")
            return newForm;
        });
    }, [setForm]);

    const editCriticalProfile = async (row, field, value) => {
        if (field === "active") {
            setForm(form => {
                const { newForm } = getUpdatedFormForCritProfile(form, row, field, value);
                return newForm;
            });
            return;
        }

        let critProfile, errorProfile;
        let newValue;

        const item = form.criticalProfiles.find(item => item.id === row.id);
        const { systemId, errorSystem } = await getSystemForEditRow(field, value, item);
        const critProfileName = field === "profile" ? value : item["profile"];

        if (critProfileName) {
            try {
                critProfile = await criticalRuleService.getCriticalProfileDetailed(critProfileName, systemId);
            } catch (e) {
                errorProfile = e;
            }
        }

        switch (field) {
            case "system":
                newValue = systemId;
                break;
            case "profile":
                newValue = critProfile?.name ?? value;
                break;
        }

        setForm(form => {
            const { newForm, item } = getUpdatedFormForCritProfile(form, row, field, newValue);
            validateCriticalProfile(item, critProfile, errorSystem, errorProfile);
            return newForm;
        });
    };

    const visibleRisks = getVisibleEntities(form.risks);
    const visibleCriticalRoles = getVisibleEntities(form.criticalRoles);
    const visibleCriticalProfiles = getVisibleEntities(form.criticalProfiles);

    const risksErrorIndex = errorScroll?.errorTab === "risks"
        ? errorScroll.errorIndex : null;

    const criticalRolesErrorIndex = errorScroll?.errorTab === "critical-roles"
        ? errorScroll.errorIndex : null;
    
    const criticalProfilesErrorIndex = errorScroll?.errorTab === "critical-profiles"
        ? errorScroll.errorIndex : null;

    return (
        <EntityFormPageViews
            errorScroll={errorScroll}
            setCurrentView={setCurrentView}
        >
            <EntityFormTabs
                tabs={tabs}
                tabId={currentView}
                setTabId={id => setCurrentView(id)}
                oneVisibleTab={!matches1024}
            />

            {currentView === "risks" && (
                <RisksView
                    risks={visibleRisks}
                    addRisk={addRisk}
                    deleteRisk={deleteRisk}
                    deleteRiskMass={deleteRiskMass}
                    editRisk={editRisk}
                    disabled={formDisabled}
                    validationErrors={validationErrors}
                    errorIndex={risksErrorIndex}
                    setErrorScroll={setErrorScroll}
                />
            )}

            {currentView === "critical-roles" && (
                <CriticalRolesView
                    criticalRoles={visibleCriticalRoles}
                    addCriticalRole={addCriticalRole}
                    deleteCriticalRole={deleteCriticalRole}
                    deleteCriticalRoleMass={deleteCriticalRoleMass}
                    editCriticalRole={editCriticalRole}
                    disabled={formDisabled}
                    validationErrors={validationErrors}
                    errorIndex={criticalRolesErrorIndex}
                    setErrorScroll={setErrorScroll}
                />
            )}

            {currentView === "critical-profiles" && (
                <CriticalProfilesView
                    criticalProfiles={visibleCriticalProfiles}
                    addCriticalProfile={addCriticalProfile}
                    deleteCriticalProfile={deleteCriticalProfile}
                    deleteCriticalProfileMass={deleteCriticalProfileMass}
                    editCriticalProfile={editCriticalProfile}
                    disabled={formDisabled}
                    validationErrors={validationErrors}
                    errorIndex={criticalProfilesErrorIndex}
                    setErrorScroll={setErrorScroll}
                />
            )}
        </EntityFormPageViews>
    )

}

MatrixHeadersFormViews.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func,
    formDisabled: PropTypes.bool,
    validationErrors: PropTypes.object,
    errorScroll: PropTypes.shape({
        errorTab: PropTypes.string,
        errorIndex: PropTypes.number
    }),
    setErrorScroll: PropTypes.func
}

export default MatrixHeadersFormViews;