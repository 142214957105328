// import useReduxValue from "./useReduxValue";
// import useReduxTable from "./useReduxTable";
// import { useDispatch, useSelector } from "react-redux";
// import { useEffect, useState } from "react";

// import { REPORT_TYPES, REPORT_LEVELS } from "../../../utils/sodReports";

import useReduxValue from "./useReduxValue";
import useReduxTable from "./useReduxTable";

const useReportBusynessDetailedReduxTable = (
    rowsSelector,
    fetchRowsAction,
    columnsSelector,
    fetchColumnsAction,
    selectedIdsSelector,
    setSelectedIdsAction,
    searchStringSelector,
    setSearchStringAction,
    pageNumberSelector,
    setPageNumberAction,
    limitNumberSelector,
    setLimitNumberAction,
    sortDataSelector,
    setSortDataAction,
    filterDataSelector,
    setFilterDataAction,
    errorSelector,
    setErrorAction,
    totalSelector,
    busySelector,
    reportLevelSelector,
    setReportLevelAction,
    reportTypeSelector,
    setReportTypeAction,
) => {
    const {
        rows, columns,
        total, busy,
        selectedIds, setSelectedIds,
        searchString, setSearchString,
        pageNumber, setPageNumber,
        limitNumber, setLimitNumber,
        filterData, setFilterData,
        sortData, setSortData,
        error, setError
    } = useReduxTable(
        rowsSelector, fetchRowsAction,
        columnsSelector, fetchColumnsAction,
        selectedIdsSelector, setSelectedIdsAction,
        searchStringSelector, setSearchStringAction,
        pageNumberSelector, setPageNumberAction,
        limitNumberSelector, setLimitNumberAction,
        sortDataSelector, setSortDataAction,
        filterDataSelector, setFilterDataAction,
        errorSelector, setErrorAction,
        totalSelector,
        busySelector,
    );
    
    const [reportLevel, setReportLevel] = useReduxValue(reportLevelSelector, setReportLevelAction);
    const [reportType, setReportType] = useReduxValue(reportTypeSelector, setReportTypeAction);

    return {
        rows, columns,
        total, busy,
        selectedIds, setSelectedIds,
        searchString, setSearchString,
        pageNumber, setPageNumber,
        limitNumber, setLimitNumber,
        sortData, setSortData,
        filterData, setFilterData,
        error, setError,
        reportLevel, setReportLevel,
        reportType, setReportType,
    }
};

export default useReportBusynessDetailedReduxTable;

