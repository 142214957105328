import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import produce from "immer";
import PropTypes from "prop-types";

import { reportsModelingService } from "../../../services/reports-modeling-service";

import { filterCheckedTreeItems } from "../../../utils/reports-modeling-utils";
import { modifyCopyMenuTree } from "../../../utils/role-menu";

import { CommonDialog, CommonDialogActions, CommonDialogContent, CommonDialogTitle } from "../../common/dialogs"
import { Button } from "../../common/buttons";

import CopyMenuTable from "./copy-menu-table.jsx";

import "./styles.less";

const CopyMenuDialog = ({
    open,
    role,
    systemId,
    onClose,
    onSubmit
}) => {
    const [menu, setMenu] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();

        const fetchMenu = async () => {
            try {
                const menu = await reportsModelingService.getCopyRoleMenu(
                    role, systemId, abortController.signal
                );
                setMenu(menu);
            } catch (e) {
                console.error(e);
                setMenu(null);
            }
        };

        fetchMenu();

        return () => abortController.abort();
    }, [role, systemId]);

    const handleItemFieldChange = (itemPath, field, value) => {
        setMenu(produce(draft => {
            modifyCopyMenuTree(draft, itemPath, field, value);
        }));
    };

    const handleSubmit = () => {
        const checkedMenuItems = filterCheckedTreeItems(menu);
        onSubmit(checkedMenuItems);
    };

    const intl = useIntl();
    const dialogTitle = intl.formatMessage({ id: "role-menu.copy-menu-dialog.title" });
    const submitTitle = intl.formatMessage({ id: "role-menu.copy-menu-dialog.submit" });
    const cancelTitle = intl.formatMessage({ id: "role-menu.copy-menu-dialog.cancel" });

    return (
        <CommonDialog
            className="copy-menu-dialog"
            open={open}
        >
            <CommonDialogTitle
                title={dialogTitle}
                onClose={onClose}
            />

            <CommonDialogContent>
                {menu && (
                    <CopyMenuTable
                        tree={menu}
                        onItemFieldChange={handleItemFieldChange}
                    />
                )}
            </CommonDialogContent>

            <CommonDialogActions>
                <Button
                    className="copy-menu-dialog-submit"
                    variant="contained"
                    disabled={!role}
                    onClick={handleSubmit}
                >
                    {submitTitle}
                </Button>
                <Button
                    className="copy-menu-dialog-cancel"
                    variant="outlined"
                    onClick={onClose}
                >
                    {cancelTitle}
                </Button>
            </CommonDialogActions>
        </CommonDialog>
    );
};

CopyMenuDialog.propTypes = {
    open: PropTypes.bool,
    role: PropTypes.string,
    systemId: PropTypes.string,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func
};

export default CopyMenuDialog;