import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import {
    fetchSystems,
    fetchSystemColumns,
    setSelectedIds as setSelectedIdsAction,
    setSearchString as setSearchStringAction,
    setPageNumber as setPageNumberAction,
    setLimitNumber as setLimitNumberAction,
    setSortData as setSortDataAction,
    setFilterData as setFilterDataAction,
    setError as setErrorAction,
} from "../../../../../reducers/admin/systemsSlice";

import useFilters from "../../../../common/hooks/useFilters";
import useReduxTable from "../../../../common/hooks/useReduxTable";
import CommonTable from "../../../../common/table";
import { ProgressDialog, ErrorsDialog } from "../../../../common/dialogs";
import { EmptyTabContent } from "../../../../common/empty-tab-content";

const getCellValue = (row, column) => {
    switch (column.id) {
        case "systemId":
            return row.id;

        default:
            return row[column.id];
    }
};

const EmptySystemsPage = ({ handleAddSystemClick }) => {
    const intl = useIntl();

    const text = intl.formatMessage({ id: "empty-systems-page.text" });
    const buttonText = intl.formatMessage({ id: "systems-list-page.btn-create-system" });

    return (
        <EmptyTabContent
            text={text}
            buttonText={buttonText}
            onButtonClick={handleAddSystemClick}
        />
    );
};

const SystemsListPage = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    const {
        rows, columns,
        total, busy,
        selectedIds,
        searchString, setSearchString,
        pageNumber, setPageNumber,
        limitNumber, setLimitNumber,
        filterData, setFilterData,
        sortData, setSortData,
        error, setError
    } = useReduxTable(
        (state) => state.admin.systems.systems, fetchSystems,
        (state) => state.admin.systems.columns, fetchSystemColumns,
        (state) => state.admin.systems.selectedIds, setSelectedIdsAction,
        (state) => state.admin.systems.searchString, setSearchStringAction,
        (state) => state.admin.systems.pageNumber, setPageNumberAction,
        (state) => state.admin.systems.limitNumber, setLimitNumberAction,
        (state) => state.admin.systems.sortData, setSortDataAction,
        (state) => state.admin.systems.filterData, setFilterDataAction,
        (state) => state.admin.systems.error, setErrorAction,
        (state) => state.admin.systems.total,
        (state) => state.admin.systems.busy,
    );

    const busyType = useSelector((state) => state.admin.systems.busyType);

    const getFilters = useFilters("system");

    const handleAddSystemClick = () => {
        navigate("form")
    };

    const handleRowClick = (system) => {
        navigate(`form/${system.id}`)
    };

    const getTableRowId = (row) => row.id;

    const toolbarActions = [
        {
            id: "title",
            title: intl.formatMessage({ id: "systems-list-page.toolbar.text.title" }),
            type: "text",
            position: "begin"
        },
        {
            id: "add",
            type: "button",
            position: "end",
            title: intl.formatMessage({ id: "systems-list-page.toolbar.btn-create-system" }),
            callback: () => {
                handleAddSystemClick()
            }
        }
    ];

    if (total === 0 && !searchString && filterData.length === 0 && !busy) {
        return <EmptySystemsPage handleAddSystemClick={handleAddSystemClick} />;
    }

    return (
        <>
            <CommonTable
                rows={rows}
                columns={columns}
                filterData={filterData}
                setFilterData={setFilterData}
                sortData={sortData}
                setSortData={setSortData}
                toolbarActions={toolbarActions}
                selectedIds={selectedIds}
                getRowId={getTableRowId}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                limitNumber={limitNumber}
                setLimitNumber={setLimitNumber}
                total={total}
                onRowClick={handleRowClick}
                searchString={searchString}
                setSearchString={setSearchString}
                getCellValue={getCellValue}
                getFilters={getFilters}
            />

            <ProgressDialog
                open={busy}
                busyType={busyType}
            />

            <ErrorsDialog
                error={error}
                open={error?.type === "error"}
                onClose={() => setError(null)}
            />
        </>
    );
};

EmptySystemsPage.propTypes = {
    handleAddSystemClick: PropTypes.func,
}

export default SystemsListPage;
