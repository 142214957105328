import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { REPORT_MODELING_CONFLICT_LEVELS } from "../../../utils/reports-modeling-utils";

import { ButtonsSwitch } from "../../common/buttons";

import "./styles.less";

const conflictLevels = Object.values(REPORT_MODELING_CONFLICT_LEVELS);

const ConflictLevelsPicker = ({ value, onChange }) => {
    const intl = useIntl();

    const buttonsList = conflictLevels.map(level => ({
        id: level,
        title: intl.formatMessage({ id: `conflict-levels-picker.${level}` })
    }));

    return (
        <ButtonsSwitch
            className="conflict-levels-picker"
            buttonsList={buttonsList}
            value={value}
            onChange={onChange}
        />
    );
};

ConflictLevelsPicker.propTypes = {
    value: PropTypes.oneOf(conflictLevels),
    onChange: PropTypes.func
};

export default ConflictLevelsPicker;