import SodSocketService from "../services/sod-socket-service";
const ACCESS_TOKEN_KEY = "accessToken";
const REFRESH_TOKEN_KEY = "refreshToken";

export const USER_SCREEN_BUTTONS = {
    RESET_INTEGR_STATUS: "reset_integr_status",
    DOWNLOAD_INTEGR_DATA: "download_integr_data",
    GEN_RULES: "gen_rules",
    CLEAR_MATRICES: "clear_matrices",
    SYNC_RISKS: "sync_risks",
    COMPARE_REPORTS: "compare_reports",
    DOWNLOAD_MATRICES: "download_matrices"
}

export function setTokens(accessToken, refreshToken) {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
}

export function removeTokens() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
}

export function getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export function getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
}

export const logOut = async () => {
    removeTokens();
    const socketService = SodSocketService.getInstance()
    socketService.closeConnection()
    window.location.reload();
};