import React from 'react';
import { createSvgIcon } from '@mui/material';

const SharpLeftChevronIcon = createSvgIcon(
    <path
        d="M14.3544 17.8529C14.1594 18.0485 13.8429 18.0491 13.6472 17.8542L8.16276 12.3892C7.94705 12.1743 7.94705 11.8249 8.16276 11.61L13.6472 6.14502C13.8429 5.95011 14.1594 5.95067 14.3544 6.14628C14.5493 6.34189 14.5487 6.65848 14.3531 6.85339L9.18851 11.9996L14.3531 17.1458C14.5487 17.3407 14.5493 17.6573 14.3544 17.8529Z"
        fill="currentColor"
    />,
    "SharpLeftChevron"
);

export default SharpLeftChevronIcon;
