import React from "react";
import { useIntl } from "react-intl";
import { Tab, Tabs } from "@mui/material";
import PropTypes from "prop-types";

import "./styles.less";

const CommonTabs = ({ tabs, value, onChange, className="", ...props }) => {
    const intl = useIntl();

    const getTabTitle = (tab) => {
        if (tab.label) return tab.label;

        if (tab.titleId) return intl.formatMessage({ id: tab.titleId });

        return "";
    };

    return (
        <Tabs
            className={`common-tabs ${className}`}
            value={value}
            onChange={(_, newTabId) => onChange(newTabId)}
            {...props}
        >
            {tabs.map(tab => (
                <Tab
                    className="common-tab"
                    key={tab.id}
                    value={tab.id}
                    label={getTabTitle(tab)}
                />
            ))}
        </Tabs>
    );
};

CommonTabs.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.object),
    value: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string
};

export default CommonTabs;
