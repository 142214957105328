import React from "react";
import { SvgIcon } from "@mui/material";

const SettingsOutlinedIcon = (props) => (
    <SvgIcon
        viewBox="0 0 18 19"
        {...props}
    >
        <svg
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9 6.19922C7.32857 6.19922 6 7.52779 6 9.19922C6 10.8706 7.32857 12.1992 9 12.1992C10.6714 12.1992 12 10.8706 12 9.19922C12 7.52779 10.6714 6.19922 9 6.19922ZM9 11.3421C7.8 11.3421 6.85714 10.3992 6.85714 9.19922C6.85714 7.99922 7.8 7.05636 9 7.05636C10.2 7.05636 11.1429 7.99922 11.1429 9.19922C11.1429 10.3992 10.2 11.3421 9 11.3421Z"
                fill="currentColor"
            />
            <path
                d="M15.6608 7.715L14.4008 7.31L14.1308 6.635L14.7608 5.465C14.8958 5.195 14.8508 4.835 14.6258 4.61L13.5458 3.53C13.3208 3.305 12.9608 3.26 12.6908 3.395L11.5208 4.025L10.8458 3.755L10.4408 2.495C10.3508 2.225 10.0808 2 9.76578 2H8.23578C7.92078 2 7.65078 2.225 7.60578 2.54L7.20078 3.8C6.93078 3.845 6.70578 3.935 6.48078 4.07L5.31078 3.44C5.04078 3.305 4.68078 3.35 4.45578 3.575L3.37578 4.655C3.15078 4.88 3.10578 5.24 3.24078 5.51L3.82578 6.635C3.73578 6.86 3.64578 7.13 3.55578 7.355L2.29578 7.76C2.02578 7.85 1.80078 8.12 1.80078 8.435V9.965C1.80078 10.28 2.02578 10.55 2.34078 10.64L3.60078 11.045L3.87078 11.72L3.24078 12.89C3.10578 13.16 3.15078 13.52 3.37578 13.745L4.45578 14.825C4.68078 15.05 5.04078 15.095 5.31078 14.96L6.48078 14.33L7.15578 14.6L7.56078 15.905C7.65078 16.175 7.92078 16.4 8.23578 16.4H9.76578C10.0808 16.4 10.3508 16.175 10.4408 15.905L10.8458 14.6L11.5208 14.33L12.6908 14.96C12.9608 15.095 13.3208 15.05 13.5458 14.825L14.6258 13.745C14.8508 13.52 14.8958 13.16 14.7608 12.89L14.1308 11.72L14.4008 11.045L15.7058 10.64C15.9758 10.55 16.2008 10.28 16.2008 9.965V8.435C16.2008 8.12 15.9758 7.805 15.6608 7.715ZM15.3008 9.83L13.6808 10.325L13.6358 10.55L13.2308 11.495L13.0958 11.72L13.9058 13.205L13.0058 14.105L11.5208 13.295L11.2958 13.43C10.9808 13.61 10.6658 13.745 10.3508 13.835L10.1258 13.88L9.63078 15.5H8.37078L7.87578 13.88L7.65078 13.835L6.70578 13.43L6.48078 13.295L4.99578 14.105L4.09578 13.205L4.90578 11.72L4.77078 11.495C4.59078 11.18 4.45578 10.865 4.36578 10.55L4.32078 10.325L2.70078 9.83V8.57L4.23078 8.12L4.32078 7.895C4.41078 7.535 4.54578 7.22 4.72578 6.905L4.86078 6.68L4.09578 5.195L4.99578 4.295L6.43578 5.105L6.66078 4.97C6.97578 4.79 7.29078 4.655 7.65078 4.565L7.87578 4.475L8.37078 2.9H9.63078L10.1258 4.475L10.3508 4.565C10.6658 4.655 10.9808 4.79 11.2958 4.97L11.5208 5.105L13.0058 4.295L13.9058 5.195L13.0958 6.68L13.2308 6.905C13.4108 7.22 13.5458 7.535 13.6358 7.85L13.6808 8.075L15.3008 8.57V9.83Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

export default SettingsOutlinedIcon;