import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, Typography, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";

import { Switch } from "../../common/buttons";
import { CheckboxInput } from "../../common/form-controls";
import breakpoints from "../../common/styles/breakpoints.js";
import { getEntityTextWithIntl, getIntegrationIcon, getSystemSyncTitle } from "../../../utils/integration-utils";

import { PlanIntegrationButton } from "./integration-common.jsx";

const DetailsTitleItemRow = () => {
    return (
        <>
            <Box className="integration-details-row-grid-title-item integration-details-row-empty-title" />

            <Typography className="integration-details-row-grid-title-item integration-details-row-title hidden">
                <FormattedMessage id="integration-details-row.load-delta.text" />
            </Typography>

            <Typography className="integration-details-row-grid-title-item integration-details-row-title hidden">
                <FormattedMessage id="integration-details-row.sync-future.text" />
            </Typography>

            <Box className="integration-details-row-grid-title-item" />
        </>
    );
}

const DetailsHeaderItemRow = ({ label, onPlanClick }) => {
    return (
        <>
            <Box className="integration-details-row-grid-header-item" />

            <Typography className="integration-details-row-grid-header-item integration-details-row-header-title">
                {label}
            </Typography>

            <Box className="integration-details-row-grid-header-item integration-details-row-empty-header-item-box" />

            <PlanIntegrationButton
                onClick={onPlanClick}
                className="integration-details-row-grid-header-item"
            />
        </>
    );
}

const DetailsListItemChecboxAndSync = ({
    entity,
    entityData,
    editField,
    isHeader,
    gridItemClassName="",
    onHistoryClick,
    disabled
}) => {
    const intl = useIntl();

    const checkboxLabel = getEntityTextWithIntl(intl, entity);
    const lastSyncTitle = getSystemSyncTitle(intl, entityData.status, entityData.lastSyncDate, "entity");

    const checkboxClassName = isHeader
        ? `${gridItemClassName} integration-details-row-checkbox integration-details-row-checkbox-header`
        : `${gridItemClassName} integration-details-row-checkbox`;

    const syncClassName = isHeader
        ? `${gridItemClassName} integration-details-row-sync-title integration-details-row-sync-title-header`
        : `${gridItemClassName} integration-details-row-sync-title`;

    return (
        <>
            <CheckboxInput
                className={checkboxClassName}
                label={checkboxLabel}
                checked={entityData.checked}
                onChange={() => editField(entity, "checked", !entityData.checked)}
                disabled={disabled}
            />

            <Typography
                component="span"
                className={syncClassName}
                onClick={onHistoryClick}
            >
                {lastSyncTitle}
            </Typography>
        </>
    );
};

const DetailsListItemRow = ({ entity, entityData, editField, onPlanClick, onHistoryClick, noPlan=false, isHeader=false, isIntegrationPending }) => {
    const matches1920 = useMediaQuery(`(min-width: ${breakpoints.minDesktopBreakpoint})`);
    const Icon = getIntegrationIcon(entityData.status);
    const gridItemClassName = isHeader
        ? "integration-details-row-grid-object-header-item"
        : "integration-details-row-grid-object-item";

    return (
        <>
            <Box className={`${gridItemClassName} integration-details-row-sync-icon-wrapper`}>
                <Icon
                    className="integration-system-box-sync-icon"
                    size="18px"
                />
            </Box>

            {matches1920 ? (
                <DetailsListItemChecboxAndSync
                    gridItemClassName={gridItemClassName}
                    entity={entity}
                    entityData={entityData}
                    editField={editField}
                    isHeader={isHeader}
                    onHistoryClick={onHistoryClick}
                    disabled={isIntegrationPending}
                />
            ) : (
                <Box
                    className={`${gridItemClassName} integration-details-row-checkbox-wrapper`}
                >
                    <DetailsListItemChecboxAndSync
                        entity={entity}
                        entityData={entityData}
                        editField={editField}
                        isHeader={isHeader}
                        onHistoryClick={onHistoryClick}
                        disabled={isIntegrationPending}
                    />
                </Box>
            )}
            

            <Switch
                className={`${gridItemClassName} hidden`}
                checked={entityData.loadDelta}
                onChange={() => editField(entity, "loadDelta", !entityData.loadDelta)}
            />

            <Switch
                className={`${gridItemClassName} hidden`}
                checked={entityData.syncFuture}
                onChange={() => editField(entity, "syncFuture", !entityData.syncFuture)}
            />
            
            {noPlan ? (
                <Box className={gridItemClassName} />
            ) : (
                <PlanIntegrationButton
                    className={gridItemClassName}
                    onClick={onPlanClick}
                />
            )}
            
        </>
    );
};

DetailsHeaderItemRow.propTypes = {
    label: PropTypes.string,
    onPlanClick: PropTypes.func
};

DetailsListItemChecboxAndSync.propTypes = {
    entity: PropTypes.string,
    entityData: PropTypes.object,
    editField: PropTypes.func,
    isHeader: PropTypes.bool,
    gridItemClassName: PropTypes.string,
    onHistoryClick: PropTypes.func,
    disabled: PropTypes.bool
};

DetailsListItemRow.propTypes = {
    entity: PropTypes.string,
    entityData: PropTypes.object,
    editField: PropTypes.func,
    onPlanClick: PropTypes.func,
    onHistoryClick: PropTypes.func,
    noPlan: PropTypes.bool,
    isHeader: PropTypes.bool,
    isIntegrationPending: PropTypes.bool
};

export { DetailsListItemRow, DetailsTitleItemRow, DetailsHeaderItemRow };
