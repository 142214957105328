import React from "react";
import { Outlet } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import { NavigationTabs } from "../common/tabs";
import breakpoints from "../common/styles/breakpoints.js";

const tabs = [
    {
        id: "functions",
        titleId: "access-rules-page.tabs.function-maintenance.title",
        route: "functions"
    },
    {
        id: "matrix-headers",
        titleId: "access-rules-page.tabs.matrix-header-maintenance.title",
        route: "matrix-headers"
    },
    {
        id: "risks",
        titleId: "access-rules-page.tabs.risk-maintenance.title",
        route: "risks"
    }
];

const AccessRulesPage = () => {
    const matches1024 = useMediaQuery(`(min-width: ${breakpoints.minLaptopBreakpoint})`);

    return(
        <>
            <NavigationTabs
                tabs={tabs}
                oneVisibleTab={!matches1024}
            />
            <Outlet />
        </>
    );
};

export default AccessRulesPage;
