import React from "react";
import { createSvgIcon } from '@mui/material';

const CloseIcon = createSvgIcon(
    <>
        <rect x="16.4234" y="6.24062" width="1.88521" height="14.4" transform="rotate(45 16.4234 6.24062)" fill="currentColor"/>
        <rect x="17.7484" y="16.4328" width="1.85804" height="14.4" transform="rotate(135 17.7484 16.4328)" fill="currentColor"/>
    </>,
    "Close"
);

export default CloseIcon;
