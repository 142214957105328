import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { KeyboardArrowRight, KeyboardArrowDown } from "@mui/icons-material";
import PropTypes from "prop-types";

import "./style.less";

const ReportAccordion = ({ title, open, onOpenClick }) => {   
    const CollapseIcon = open ? KeyboardArrowDown : KeyboardArrowRight;

    return (
        <Box className="report-accordion">
            <IconButton
                className="report-accordion-button"
                variant="outlined"
                onClick={onOpenClick}
            >
                <CollapseIcon />
            </IconButton>

            <Typography className="report-accordion-title">
                {title}
            </Typography>
        </Box>
    );
};

ReportAccordion.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    onOpenClick: PropTypes.func,
    sx: PropTypes.object
};

export default ReportAccordion;
