import React from "react";
import PropTypes from "prop-types";

import CommonTable, { getCellClassNameInner } from "../../common/table";

import "./styles.less";

const getEntityRowId = row => row.id;

export const getEntityCellClassName = (row, column, selected) => {
    let className = getCellClassNameInner(row, column, selected);

    const status = row.status;

    if (status) {
        className += ` entities-select-table-cell-${status}`;
    }

    return className;
};

const EntitiesSelectTable = ({
    className="",
    limitNumber=null,
    virtualize=true,
    showFiltersActions=false,
    showPagination=false,
    rowHeight=64,
    headerHeight=25,
    getRowId=getEntityRowId,
    getCellClassName=getEntityCellClassName,
    ...props
}) => (
    <CommonTable
        className={`${className} entities-select-table`}
        limitNumber={limitNumber}
        virtualize={virtualize}
        showFiltersActions={showFiltersActions}
        showPagination={showPagination}
        rowHeight={rowHeight}
        headerHeight={headerHeight}
        getRowId={getRowId}
        getCellClassName={getCellClassName}
        {...props}
    />
);

EntitiesSelectTable.propTypes = {
    className: PropTypes.string,
    limitNumber: PropTypes.number,
    virtualize: PropTypes.bool,
    showFiltersActions: PropTypes.bool,
    showPagination: PropTypes.bool,
    rowHeight: PropTypes.number,
    headerHeight: PropTypes.number,
    getRowId: PropTypes.func,
    getCellClassName: PropTypes.func,
};

export default EntitiesSelectTable;