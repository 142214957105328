import React from "react";
import { List, ListItemButton, ListItemText, Popover } from "@mui/material";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

export const signItems = [
    {
        key: "OR",
        text: <FormattedMessage id="permission-view.sign-items.or" />
    },
    {
        key: "AND",
        text: <FormattedMessage id="permission-view.sign-items.and" />
    },
    {
        key: "NOT",
        text: <FormattedMessage id="permission-view.sign-items.not" />
    }
];

export const PermissionsMassSignPopover = ({ anchorEl, onClose, onSelect }) => {
    const open = Boolean(anchorEl);

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            sx={{
                ".MuiPopover-paper": {
                    width: anchorEl.clientWidth
                }
            }}
        >
            <List>
                {signItems.map(signItem => (
                    <ListItemButton
                        key={signItem.key}
                        onClick={() => onSelect(signItem.key)}
                    >
                        <ListItemText>
                            {signItem.text}
                        </ListItemText>
                    </ListItemButton>
                ))}
            </List>
        </Popover>
    );
};

PermissionsMassSignPopover.propTypes = {
    anchorEl: PropTypes.any,
    onClose: PropTypes.func,
    onSelect: PropTypes.func
};