import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { CommonDialog, CommonDialogActions, CommonDialogContent, CommonDialogTitle } from "../../../common/dialogs";
import { Button } from "../../../common/buttons";
import { TextInput } from "../../../common/form-controls";
import { SelectInput } from "../../../common/form-controls";
import { MenuItem } from "../../../common/menu";

import "./styles.less";
import { useDispatch } from "react-redux";
import { createModelingSelectedUserRole } from "../../../../reducers/reports-modeling/userLevelSlice";

const ROLE_TYPE = {
    SINGLE: "single",
    GROUP: "group"
}


const CreateNewRoleDialog = ({
    open,
    onClose
}) => {
    const intl = useIntl();
    const title = intl.formatMessage({ id: "create-new-role-dialog.title" });
    const cancelTitle = intl.formatMessage({ id: "common.btn-cancel" });
    const submitTitle = intl.formatMessage({ id: "create-new-role-dialog.submit" });
    const roleLabel = intl.formatMessage({ id: "create-new-role-dialog.role-input.label" });
    const descriptionLabel = intl.formatMessage({ id: "create-new-role-dialog.description-input.label" });

    const [role, setRole] = useState("");
    const [description, setDescription] = useState("");
    const [roleType, setRoleType] = useState(ROLE_TYPE.SINGLE)
    const [error, setError] = useState(null);

    const riskTypes = useMemo(() =>[{
        id: ROLE_TYPE.SINGLE,
        description: intl.formatMessage({ id: "create-new-role-dialog.role-type-single.description" })
    },{
        id: ROLE_TYPE.GROUP,
        description: intl.formatMessage({ id: "create-new-role-dialog.role-type-group.description" })
    }], [intl])

    const dispatch = useDispatch();

    const handleSubmit = async () => {
        try {
            await dispatch(createModelingSelectedUserRole({ role, description, isComplex: roleType === ROLE_TYPE.GROUP })).unwrap();
            onClose();
        } catch (e) {
            const message = e.useIntl
                ? intl.formatMessage({ id: e.errorMessageId })
                : e.message;

            setError(message);
        }
    };

    return (
        <CommonDialog
            className="create-new-role-dialog"
            open={open}
        >
            <CommonDialogTitle
                title={title}
                onClose={onClose}
            />

            <CommonDialogContent>
                <TextInput
                    label={roleLabel}
                    value={role}
                    error={Boolean(error)}
                    errorMessage={error}
                    onChange={(e) => setRole(e.target.value)}
                />

                <SelectInput
                    value={roleType}
                    label={intl.formatMessage({ id: "create-new-role-dialog.role-type-input.label" })}
                    onChange={(event) => setRoleType(event.target.value)}>
                    {riskTypes.map(item => (
                        <MenuItem
                            value={item.id}
                            key={item.id}
                            sx={{
                                minHeight: "initial",
                                height: "36px"
                            }}
                        >
                            {item.description}
                        </MenuItem>
                    ))}

                </SelectInput>

                <TextInput
                    multiline
                    rows={3}
                    label={descriptionLabel}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </CommonDialogContent>

            <CommonDialogActions>
                <Button
                    variant="outlined"
                    className="create-new-role-dialog-cancel-button"
                    onClick={onClose}
                >
                    {cancelTitle}
                </Button>
                <Button
                    variant="contained"
                    className="create-new-role-dialog-submit-button"
                    onClick={handleSubmit}
                >
                    {submitTitle}
                </Button>
            </CommonDialogActions>
        </CommonDialog>
    );
};

CreateNewRoleDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
};

export default CreateNewRoleDialog;