import React from "react";
import { useIntl } from "react-intl";
import PropTypes from 'prop-types';

import { reportsService } from "../../../services/reports-service";

import { DropdownSearchHelpInput } from "../../common/form-controls";

const getUsers = (params, signal) => {
    return reportsService.getUsersSearchHelp(params, signal);
};

const UsersInput = ({ ids, setIds, error }) => {
    const intl = useIntl();
    const label = intl.formatMessage({ id: "report-parameters.users-label" });

    return (
        <DropdownSearchHelpInput
            ids={ids}
            setIds={setIds}
            getData={getUsers}
            error={Boolean(error)}
            errorMessage={error?.message}
            label={label}
        />
    );
};

UsersInput.propTypes = {
    ids: PropTypes.arrayOf(PropTypes.string),
    setIds: PropTypes.func,
    error: PropTypes.object
};

export default UsersInput;
