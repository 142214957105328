import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useIntl } from "react-intl";

import { createUser, deleteUser, setSubmit, clearCurrentUser, changeUserPassword } from '../../../../reducers/admin/usersSlice';
import { editUser, fetchUserDetailed, setInitialCurrentUser, setError } from '../../../../reducers/admin/usersSlice';

import { PageContentBox } from '../../../common/page-content-box';
import { ProgressDialog, ErrorsDialog, DeleteDialog, ChangePasswordDialog, UserLogDialog } from '../../../common/dialogs';
import { ADD_MODE, EDIT_MODE, getCurrentMode } from '../../../common/entity-form';
import useLogs from '../../../common/hooks/useLogs';

import UserFormHeader from './user-form-page-header.jsx';
import UserFormContent from './user-form-page-content.jsx';

import "./style.less";

const UserFormPage = () => {
    const { username } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const intl = useIntl();

    const busy = useSelector(state => state.admin.users.busy);
    const busyType = useSelector(state => state.admin.users.busyType);
    const error = useSelector(state => state.admin.users.error);
    const submit = useSelector(state => state.admin.users.submit);
    const user = useSelector(state => state.admin.users.currentUser);
    const userEditable = useSelector(state => state.admin.users.currentUserEditable);
    const userDeleted = useSelector(state => state.admin.users.currentUserDeleted);
    const validationErrors = useSelector(state => state.admin.users.validationErrors);

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showChangePasswordDialog, setShowChangePasswordDialog] = useState(false);
    const [form, setForm] = useState(null);

    const mode = getCurrentMode(!username, userEditable);

    const [formErrors, setFormErrors] = useState(null);

    const fullName = user ? `${user.lastName} ${user.firstName}` : "";

    const { logs, getLogs, openLogs, setOpenLogs } = useLogs("user", username)

    useEffect(() => {
        if (!validationErrors) return;

        const formErrosTmp = validationErrors.reduce((accumulator, error) => {
            accumulator[error.field] = error;

            return accumulator
        }, {});

        setFormErrors(formErrosTmp)

    }, [validationErrors, form])

    useEffect(() => {
        if (username) {
            dispatch(fetchUserDetailed(username));
        } else {
            dispatch(setInitialCurrentUser());
        }

        return (() => {
            dispatch(clearCurrentUser());
        });
    }, [username, dispatch]);

    useEffect(() => {
        if (user) {
            setForm({ ...user });
        }
    }, [user]);

    useEffect(() => {
        if (!submit) return;

        dispatch(setSubmit(false));

        if (mode === ADD_MODE || userDeleted) {
            handleBackClick();
        }
    }, [submit, dispatch, mode, userDeleted, handleBackClick]);

    const handleSaveClick = () => {
        const userToSave = {
            ...form,
            username: form.username?.trim(),
            phone: form.phone?.split(" ").join("")
        };

        if (userToSave.username !== form.username) {
            setForm({
                ...form,
                username: userToSave.username
            });
        }

        if (mode === EDIT_MODE) {
            userToSave.password = null;
            dispatch(editUser(userToSave));
        } else {
            dispatch(createUser(userToSave));
        }
    };

    const backPath = useMemo(() => (mode === ADD_MODE
        ? ".."
        : "../.."
    ), [mode]);

    const handleBackClick = useCallback(() => {
        navigate(backPath, { relative: "path" });
    }, [navigate, backPath]);

    const handleEditClick = async () => {
        dispatch(fetchUserDetailed(username));
    };

    const handleDeleteClick = () => {
        setShowDeleteDialog(true);
    };

    const handleCancelDeleteClick = () => {
        setShowDeleteDialog(false);
    };

    const handleConfirmDeleteClick = () => {
        dispatch(deleteUser(username));
    };

    const handleChangePasswordClick = () => {
        setShowChangePasswordDialog(true);
    };

    const handleChangePasswordCancel = () => {
        setShowChangePasswordDialog(false);
    };

    const handleChangePasswordConfirm = (newPassword) => {
        dispatch(changeUserPassword({ username, newPassword }));
        setShowChangePasswordDialog(false);
    };

    if (!user && error) return <Navigate to={backPath} relative="path" />;

    return (
        <>
            {form && (
                <PageContentBox sx={{
                    padding: "20px 14px 21px"
                }}>
                    <UserFormHeader
                        mode={mode}
                        fullName={fullName}
                        onSaveClick={handleSaveClick}
                        onBackClick={handleBackClick}
                        onEditClick={handleEditClick}
                        onDeleteClick={handleDeleteClick}
                        onChangePasswordClick={handleChangePasswordClick}
                        onShowLogsClick={getLogs}
                    />

                    <UserFormContent
                        form={form}
                        setForm={setForm}
                        mode={mode}
                        validationErrors={formErrors}
                    />
                </PageContentBox>
            )}

            <ProgressDialog
                open={busy}
                busyType={busyType}
            />

            <ErrorsDialog
                error={error}
                open={error?.type === "error"}
                onClose={() => { dispatch(setError(null)) }}
            />

            {showDeleteDialog && (
                <DeleteDialog
                    open={showDeleteDialog}
                    title={intl.formatMessage({ id: "user-form-page.delete-dialog.title" })}
                    text={intl.formatMessage(
                        { id: "user-form-page.delete-dialog.text" },
                        { user: fullName }
                    )}
                    onCancelClick={handleCancelDeleteClick}
                    onDeleteClick={handleConfirmDeleteClick}
                />
            )}

            {showChangePasswordDialog && (
                <ChangePasswordDialog
                    open={showChangePasswordDialog}
                    onCancel={handleChangePasswordCancel}
                    onConfirm={handleChangePasswordConfirm}
                />
            )}

            <UserLogDialog
                open={openLogs}
                logs={logs}
                onClose={() => setOpenLogs(false)}
            />
        </>
    );
};

export default UserFormPage;
