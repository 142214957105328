import React, { useMemo } from "react";
import { TableBody, TableHead } from "@mui/material";
import { TableVirtuoso } from "react-virtuoso";
import PropTypes from "prop-types";

import { CommonTableColumnRow, CommonTableComponent, CommonTableRow, Column, createBodyRowContent, CommonTableContainer } from "./table-components.jsx";

const VirtualizedTableRowWithOnClick = (props) => {
    const { context: { rows }, "data-index": index, onClick } = props;
    const row = rows[index];

    const onClickInner = onClick && (() => onClick(row));

    return (
        <CommonTableRow {...props} onClick={onClickInner} />
    );
};

const TableComponents = {
    Scroller: CommonTableContainer,
    Table: CommonTableComponent,
    TableHead: TableHead,
    TableRow: CommonTableRow,
    TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

const VirtualisedTable = ({
    rows,
    columns,
    filterData,
    sortData,
    selectedIds,
    selectedAll,
    getRowId,
    getCellValue,
    createRowCellContent,
    onSelectRow,
    onSelectAll,
    onFilterClick,
    getCellClassName,
    virtualizationProps,
    onRowClick
}) => {
    const TableComponentsInner = useMemo(() => {
        if (!onRowClick) return TableComponents;

        return {
            ...TableComponents,
            TableRow: (props) => <VirtualizedTableRowWithOnClick {...props} onClick={onRowClick} />
        };
    }, [onRowClick]);

    return (
        <TableVirtuoso
            context={{ rows }}
            components={TableComponentsInner}
            data={rows}
            fixedHeaderContent={() => (
                <CommonTableColumnRow>
                    {columns && columns.map((column, columnIndex) => (
                        <Column
                            key={column.id}
                            columnIndex={columnIndex}
                            column={column}
                            filterData={filterData}
                            sortData={sortData}
                            onSelect={onSelectAll}
                            checked={selectedAll}
                            onFilterClick={onFilterClick}
                        />
                    ))}
                </CommonTableColumnRow>
            )}
            itemContent={(rowIndex, row) => createBodyRowContent({
                rowIndex,
                row,
                getRowId,
                selectedIds,
                columns,
                onSelectRow,
                getCellValue,
                createRowCellContent,
                getCellClassName
            })}
            {...virtualizationProps}
        />
    )
};

VirtualizedTableRowWithOnClick.propTypes = {
    context: PropTypes.object,
    "data-index": PropTypes.number,
    onClick: PropTypes.func
};

VirtualisedTable.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object),
    columns: PropTypes.arrayOf(PropTypes.object),
    filterData: PropTypes.arrayOf(PropTypes.object),
    sortData: PropTypes.arrayOf(PropTypes.object),
    selectedIds: PropTypes.arrayOf(PropTypes.number),
    selectedAll: PropTypes.bool,
    getRowId: PropTypes.func,
    getCellValue: PropTypes.func,
    createRowCellContent: PropTypes.func,
    onRowClick: PropTypes.func,
    onSelectRow: PropTypes.func,
    onSelectAll: PropTypes.func,
    onFilterClick: PropTypes.func,
    getCellClassName: PropTypes.func,
    virtualizationProps: PropTypes.object
};

export default VirtualisedTable;