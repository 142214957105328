import React from "react";
import { useIntl } from "react-intl";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";

import { CommonDialog, CommonDialogContent, CommonDialogTitle } from "../../common/dialogs";

import "./styles.less";

const SuccessSaveDialog = ({ open, onClose }) => {
    const intl = useIntl();
    const title = intl.formatMessage({ id: "report-columns-dialog.success.title" });
    const content = intl.formatMessage({ id: "report-columns-dialog.success.content" });

    return (
        <CommonDialog open={open}>
            <CommonDialogTitle
                title={title}
                onClose={onClose}
            />

            <CommonDialogContent className="report-columns-success-dialog-content">
                <Typography className="report-columns-success-dialog-content-text">
                    {content}
                </Typography>
            </CommonDialogContent>
        </CommonDialog>
    );
};

SuccessSaveDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
};

export default SuccessSaveDialog;