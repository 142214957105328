import React from "react";
import { IntlProvider } from "react-intl";
import PropTypes from "prop-types";

import messages from "../../../i18n/messages";
import locales from "../../../i18n/locales";

const SODIntlProvider = ({children}) => {
    return (
        <IntlProvider defaultLocale={locales.RUSSIAN} locale={locales.RUSSIAN} messages={messages[locales.RUSSIAN]}>
            {children}
        </IntlProvider>
    )
}

SODIntlProvider.propTypes = {
    children: PropTypes.node
}

export default SODIntlProvider
