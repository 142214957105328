import ReportActions, {
    ReportActionsBox,
    SaveTemplateButton,
    AnalyzeButton,
    CompareReportsButton,
    ColumnSettingsButton,
    CalcReportManualButton,
    CalcReportBackgroundButton
} from "./report-actions.jsx";

export {
    ReportActionsBox,
    SaveTemplateButton,
    AnalyzeButton,
    CompareReportsButton,
    ColumnSettingsButton,
    CalcReportManualButton,
    CalcReportBackgroundButton
};

export default ReportActions;
