import { integrationService } from "../services/integration-service";
import { reportsModelingService } from "../services/reports-modeling-service";

export const findRoleInList = (role, systemId, list) => {
    return list.find(item => (
        item.role === role &&
        item.systemId === systemId
    ));
};

export const checkRoleExists = async (role, systemId, rolesDelta) => {
    const changedRole = findRoleInList(role, systemId, rolesDelta);
    
    if (changedRole) {
        return true;
    }

    let existingRole

    try {
        existingRole = await integrationService.getRoleDetailed(role, systemId);
    } catch (error) {
        return false
    }
    
    return Boolean(existingRole);
};

export const getModelingRole = async (role, systemId, rolesDelta, variant, reportLevel, isManual) => {
    const changedRole = findRoleInList(role, systemId, rolesDelta);

    if (changedRole) {
        return changedRole;
    }

    const modelingRoles = await reportsModelingService.getModelingRoles(
        [role], [systemId], null, variant, reportLevel
    );

    const modelingRole = modelingRoles.find(item => item.role === role && item.systemId === systemId)

    if (modelingRole){
        modelingRole["manual"] = isManual
        modelingRole.systemId = systemId
    }

    return modelingRole;
}

export const generateProfileForNewRole = (role) => {
    const profileEnd = role.toUpperCase();
    return `PROFILE_${profileEnd}`;
}