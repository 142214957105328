import React, { useEffect, useMemo, useRef } from "react";
import { useIntl } from "react-intl";
import { Box, IconButton, Typography, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";

import { getIntegrationIcon, getSystemSyncTitle } from "../../../utils/integration-utils.js";

import breakpoints from "../../common/styles/breakpoints.js";

import { DetailsListItemRow, DetailsTitleItemRow, DetailsHeaderItemRow } from "./integration-details-row.jsx"
import IntegrationSystemBoxSmall from "./integration-system-box-small.jsx";
import ActionsBox from "./actions-box.jsx";
import { PlanIntegrationButton, OpenSelectIcon } from "./integration-common.jsx";

import "./styles.less";
import Button from "../../common/buttons/button.jsx";

const IntegrationSystemBox = ({
    integrationItem,
    editField,
    showResetStatus,
    onResetStatus,
    onStartClick,
    onDownloadClick,
    onPlanClick,
    onPlanAllClick,
    onHistoryClick,
    onUploadMatrixClick,
    onOpen,
    open
}) => {
    const isIntegrationPending = useMemo(() => [
        integrationItem.common.status,
        integrationItem.manualIntegration.status
    ].includes("pending"), [
        integrationItem.common.status,
        integrationItem.manualIntegration.status
    ]);

    const runIntegrationDisabled = useMemo(() => {
        const entitiesForCheck = [
            "permissionDetail",
            "role",
            "employee",
            "profile"
        ];

        return isIntegrationPending || (
            entitiesForCheck.every(entity => !integrationItem[entity].checked)
        );
    }, [isIntegrationPending, integrationItem]);

    const matches1024 = useMediaQuery(`(min-width:  ${breakpoints.minLaptopBreakpoint})`);

    const handleOpenClick = () => {
        onOpen()
    };

    const contaiterRef = useRef()

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                contaiterRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
            }, 500)
        }
    }, [open]);

    if (!matches1024) return (
        <IntegrationSystemBoxSmall
            ref={contaiterRef}
            integrationItem={integrationItem}
            open={open}
            editField={editField}
            isIntegrationPending={isIntegrationPending}
            runIntegrationDisabled={runIntegrationDisabled}
            showResetStatus={showResetStatus}
            onResetStatus={onResetStatus}
            onOpenClick={handleOpenClick}
            onPlanClick={onPlanClick}
            onHistoryClick={onHistoryClick}
            onStartClick={onStartClick}
            onPlanAllClick={onPlanAllClick}
        />
    )

    return (
        <Box
            className="integration-system-box"
            ref={contaiterRef}
        >
            <SystemBoxHeader
                integrationItem={integrationItem}
                isIntegrationPending={isIntegrationPending}
                open={open}
                showResetStatus={showResetStatus}
                onResetStatus={onResetStatus}
                onOpenClick={handleOpenClick}
                onPlanClick={onPlanClick}
                onHistoryClick={onHistoryClick}
                onUploadMatrixClick={onUploadMatrixClick}
            />

            {open && (
                <SystemBoxContent
                    integrationItem={integrationItem}
                    editField={editField}
                    isIntegrationPending={isIntegrationPending}
                    runIntegrationDisabled={runIntegrationDisabled}
                    onStartClick={onStartClick}
                    onDownloadClick={onDownloadClick}
                    onPlanClick={onPlanClick}
                    onPlanAllClick={onPlanAllClick}
                    onHistoryClick={onHistoryClick}
                />
            )}
        </Box>
    );
};

const SystemBoxHeader = ({
    integrationItem,
    isIntegrationPending,
    open,
    showResetStatus,
    onResetStatus,
    onOpenClick,
    onPlanClick,
    onHistoryClick,
    onUploadMatrixClick
}) => {
    const intl = useIntl();
    const resetTitle = intl.formatMessage({ id: "integration-details-row.btn-reset-status" });
    const uploadMatrixTitle = intl.formatMessage({ id: "integration-details-row.btn-upload-matrix" });

    return (
        <Box className="integration-system-box-header">
            <IconButton
                className="integration-system-box-open"
                onClick={onOpenClick}
            >
                <OpenSelectIcon open={open} />
            </IconButton>

            <Box className="integration-system-box-header-system">
                <Typography
                    className="integration-system-box-system-title"
                >
                    {integrationItem.id}
                </Typography>

                <Typography
                    className="integration-system-box-system-description"
                >
                    {integrationItem.description}
                </Typography>
            </Box>

            <SystemSyncCommonBox
                integrationItem={integrationItem}
                onHistoryClick={onHistoryClick}
            />

            <SystemSyncManualBox
                integrationItem={integrationItem}
                onHistoryClick={onHistoryClick}
            />

            <Box className="integration-system-box-header-actions">
                {showResetStatus && (
                    <SystemBoxHeaderAction
                        title={resetTitle}
                        onClick={onResetStatus}
                    />
                )}

                <SystemBoxHeaderAction
                    title={uploadMatrixTitle}
                    onClick={onUploadMatrixClick}
                    disabled={isIntegrationPending}
                />

                <PlanIntegrationButton onClick={() => onPlanClick()} />
            </Box>
        </Box>
    );
};

const SystemBoxHeaderAction = ({ title, onClick, disabled }) => (
    <Button
        variant="outlined"
        className="integration-system-box-header-action"
        onClick={onClick}
        disabled={disabled}
    >
        {title}
    </Button>
);

const SystemSyncBox = ({ title, Icon, onClick }) => {
    return (
        <Box
            className="integration-system-box-sync-box"
            onClick={onClick}
        >
            {Icon && (
                <Box className="integration-system-box-sync-icon-wrapper">
                    <Icon
                        className="integration-system-box-sync-icon"
                        size="20px"
                    />
                </Box>
            )}

            <Typography
                className="integration-system-box-sync-title"
            >
                {title}
            </Typography>
        </Box>
    );
};

const SystemSyncCommonBox = ({ integrationItem, onHistoryClick }) => {
    const { status, lastSyncDate } = integrationItem.common;

    const intl = useIntl();
    const title = getSystemSyncTitle(intl, status, lastSyncDate, "common");
    const Icon = getIntegrationIcon(status);

    return (
        <SystemSyncBox
            title={title}
            Icon={Icon}
            onClick={() => onHistoryClick("common")}
        />
    );
};

const SystemSyncManualBox = ({ integrationItem, onHistoryClick }) => {
    const { status, lastSyncDate } = integrationItem.manualIntegration;
    const intl = useIntl();

    if (!status) {
        return <Box />;
    }

    const title = getSystemSyncTitle(intl, status, lastSyncDate, "manual");
    const Icon = getIntegrationIcon(status);

    return (
        <SystemSyncBox
            title={title}
            Icon={Icon}
            onClick={() => onHistoryClick("manual_integration")}
        />
    );
};

const SystemBoxContent = ({
    integrationItem,
    editField,
    onStartClick,
    onDownloadClick,
    onPlanClick,
    onPlanAllClick,
    onHistoryClick,
    isIntegrationPending,
    runIntegrationDisabled
}) => {
    const checkAll = (value) => {
        if (isIntegrationPending) return;
        editField("permissionDetail", "checked", value);
        editField("role", "checked", value);
        editField("employee", "checked", value);
        editField("profile", "checked", value);
    };

    const calcCheckedAll = () => {
        let result = true
        if (integrationItem.permissionDetail.status !== "pending") { result = result && integrationItem.permissionDetail.checked }
        if (integrationItem.role.status !== "pending") { result = result && integrationItem.role.checked }
        if (integrationItem.employee.status !== "pending") { result = result && integrationItem.profile.checked }
        if (integrationItem.profile.status !== "pending") { result = result && integrationItem.employee.checked }

        if (integrationItem.permissionDetail.status === "pending" &&
            integrationItem.role.status === "pending" &&
            integrationItem.employee.status === "pending" &&
            integrationItem.profile.status === "pending") {
            result = false
        }

        return result;
    }

    const checkedAll = calcCheckedAll()

    return (
        <Box className="integration-system-box-content">
            <DetailsTitleItemRow />

            <DetailsListItemRow
                key={"permissionDetail"}
                entity={"permissionDetail"}
                entityData={integrationItem["permissionDetail"]}
                isHeader={true}
                editField={editField}
                onPlanClick={() => {
                    onPlanClick("permission_details")
                }}
                onHistoryClick={() => {
                    onHistoryClick("permission_details")
                }}
                isIntegrationPending={isIntegrationPending}
            />

            <DetailsHeaderItemRow
                label="Объекты репозитория"
                onPlanClick={() => {
                    onPlanClick("repository_objects")
                }}
            />

            <DetailsListItemRow
                key={"profile"}
                entity={"profile"}
                entityData={integrationItem["profile"]}
                editField={editField}
                onHistoryClick={() => {
                    onHistoryClick("profile")
                }}
                noPlan
                isIntegrationPending={isIntegrationPending}
            />

            <DetailsListItemRow
                key={"role"}
                entity={"role"}
                entityData={integrationItem["role"]}
                editField={editField}
                onHistoryClick={() => {
                    onHistoryClick("role")
                }}
                noPlan
                isIntegrationPending={isIntegrationPending}
            />

            <DetailsListItemRow
                key={"employee"}
                entity={"employee"}
                entityData={integrationItem["employee"]}
                editField={editField}
                onHistoryClick={() => {
                    onHistoryClick("employee")
                }}
                noPlan
                isIntegrationPending={isIntegrationPending}
            />

            <ActionsBox
                checkedAll={checkedAll}
                isIntegrationPending={isIntegrationPending}
                runIntegrationDisabled={runIntegrationDisabled}
                onCheckedAllChange={() => checkAll(!checkedAll)}
                onDownloadClick={onDownloadClick}
                onStartClick={onStartClick}
                onPlanClick={onPlanAllClick}
            />
        </Box>
    );
};

IntegrationSystemBox.propTypes = {
    integrationItem: PropTypes.object,
    editField: PropTypes.func,
    showResetStatus: PropTypes.bool,
    onResetStatus: PropTypes.func,
    onDownloadClick: PropTypes.func,
    onStartClick: PropTypes.func,
    onPlanClick: PropTypes.func,
    onPlanAllClick: PropTypes.func,
    onHistoryClick: PropTypes.func,
    open: PropTypes.bool,
    onOpen: PropTypes.func,
    onUploadMatrixClick: PropTypes.func
};

SystemBoxHeader.propTypes = {
    integrationItem: PropTypes.object,
    isIntegrationPending: PropTypes.bool,
    open: PropTypes.bool,
    showResetStatus: PropTypes.bool,
    onResetStatus: PropTypes.func,
    onOpenClick: PropTypes.func,
    onPlanClick: PropTypes.func,
    onHistoryClick: PropTypes.func,
    onUploadMatrixClick: PropTypes.func
};

SystemBoxHeaderAction.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool
};

SystemSyncBox.propTypes = {
    title: PropTypes.node,
    Icon: PropTypes.elementType,
    onClick: PropTypes.func
};

SystemSyncCommonBox.propTypes = {
    integrationItem: PropTypes.object,
    onHistoryClick: PropTypes.func,
};

SystemSyncManualBox.propTypes = {
    integrationItem: PropTypes.object,
    onHistoryClick: PropTypes.func,
};

SystemBoxContent.propTypes = {
    integrationItem: PropTypes.object,
    editField: PropTypes.func,
    isIntegrationPending: PropTypes.bool,
    runIntegrationDisabled: PropTypes.bool,
    onStartClick: PropTypes.func,
    onDownloadClick: PropTypes.func,
    onPlanClick: PropTypes.func,
    onPlanAllClick: PropTypes.func,
    onHistoryClick: PropTypes.func,
};

export default IntegrationSystemBox;
