import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { fetchUserRoles } from "../../../reducers/appSlice";

const useUserRoles = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const userRoles = useSelector(state => state.app.userRoles);

    const userRolesText = {
        admin: intl.formatMessage({ id: "common.user-roles.admin"}),
        employee: intl.formatMessage({ id: "common.user-roles.employee"}),
        consultant: intl.formatMessage({ id: "common.user-roles.consultant"}),
    };

    useEffect(() => {
        if (userRoles.length === 0) {
            dispatch(fetchUserRoles());
        }
    }, [dispatch, userRoles])
    
    return { userRoles, userRolesText };
}

export default useUserRoles;
