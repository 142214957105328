import React, { useId } from "react";
import PropTypes from "prop-types";

import Input from "./input.jsx";
import PasswordInput from "./password-input.jsx";
import InputWrapper from "./input-wrapper.jsx";

const TextInput = ({
    className="",
    fullWidth=true,
    shrink=true,
    multiline=false,
    rows,
    label,
    value,
    type="text",
    error=false,
    errorMessage,
    warning=false,
    warningMessage,
    showFormHelpers=true,
    showInnerHelpers=false,
    disabled,
    endAdornment,
    onChange
}) => {
    const id = useId();

    const InputComponent = type === "password"
        ? PasswordInput
        : Input;

    return (
        <InputWrapper
            id={id}
            className={className}
            fullWidth={fullWidth}
            shrink={shrink}
            label={label}
            error={error}
            errorMessage={errorMessage}
            warning={warning}
            warningMessage={warningMessage}
            showFormHelpers={showFormHelpers}
            InputComponent={
                <InputComponent
                    multiline={multiline}
                    rows={rows}
                    id={id}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    error={error}
                    errorMessage={errorMessage}
                    warning={warning}
                    warningMessage={warningMessage}
                    showInnerHelpers={showInnerHelpers}
                    endAdornment={endAdornment}
                />
            }
        />
    );
};

TextInput.propTypes = {
    className: PropTypes.string,
    fullWidth: PropTypes.bool,
    shrink: PropTypes.bool,
    multiline: PropTypes.bool,
    rows: PropTypes.number,
    label: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    warning: PropTypes.bool,
    warningMessage: PropTypes.string,
    showFormHelpers: PropTypes.bool,
    showInnerHelpers: PropTypes.bool,
    disabled: PropTypes.bool,
    endAdornment: PropTypes.node,
    onChange: PropTypes.func
};

export default TextInput;
