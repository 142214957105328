import React from 'react';
import { createSvgIcon } from '@mui/material';

const TimeIcon = createSvgIcon(
    
<path fill="currentColor" d="M10.99 1C5.47 1 1 5.48 1 11C1 16.52 5.47 21 10.99 21C16.52 21 21 16.52 21 11C21 5.48 16.52 1 10.99 1ZM11 19C6.58 19 3 15.42 3 11C3 6.58 6.58 3 11 3C15.42 3 19 6.58 19 11C19 15.42 15.42 19 11 19ZM11.5 6H10V12L15.25 15.15L16 13.92L11.5 11.25V6Z"/>
,
    "Time"
);

export default TimeIcon;



