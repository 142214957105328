import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";

import breakpoints from "../../common/styles/breakpoints";

import "./style.less";

const ReportHeader = ({
    parameters,
    variants,
    parametersOpen
}) => {
    const matches1024 = useMediaQuery(`(min-width: ${breakpoints.minLaptopBreakpoint})`);

    return (
        <Box className="report-header">  
            {parameters}
            {parametersOpen && matches1024 && variants}
        </Box>
    );
};

ReportHeader.propTypes = {
    parameters: PropTypes.node,
    variants: PropTypes.node,
    parametersOpen: PropTypes.bool
};

export default ReportHeader;