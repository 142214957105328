import React, { useId } from "react";
import { useIntl } from "react-intl";
import { RadioGroup } from "@mui/material";
import PropTypes from "prop-types";

import { RadioInput, FormControl } from "../../common/form-controls";
import { REPORT_MODELING_SUBVIEWS } from "../../../utils/reports-modeling-utils";

import "./styles.less";

const subviewValues = Object.values(REPORT_MODELING_SUBVIEWS);

const SubviewsPicker = ({
    currentSubview,
    onChange
}) => {
    const id = useId();
    const intl = useIntl();

    const getSubviewLabel = (subview) => intl.formatMessage({
        id: `report-modeling-subviews-picker.${subview}`
    })

    return (
        <FormControl
            variant="standard"
            className="subviews-picker"
        >
            <RadioGroup
                row
                value={currentSubview}
                onChange={onChange}
                id={id}
            >
                {
                    subviewValues.map(subview => (
                        <RadioInput
                            className="subviews-picker-radio"
                            key={subview}
                            value={subview}
                            label={getSubviewLabel(subview)}
                        />
                    ))
                }
            </RadioGroup>
        </FormControl>
    );
};

SubviewsPicker.propTypes = {
    currentSubview: PropTypes.oneOf(subviewValues),
    onChange: PropTypes.func
};

export default SubviewsPicker;