import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { selectModelingUserById } from "../../../../reducers/reports-modeling/userLevelSlice";

import { Button } from "../../../common/buttons";

import { EntitiesSelectDialogActions, SelectedEntityTag } from "../../entities-select-dialog";

import "./styles.less";

const UserLevelModelingUsersDialogActions = ({
    selectedUserId,
    onUnselectUser,
    onCancelClick,
    onSubmitClick
}) => {
    const selectedUser = useSelector(state => selectModelingUserById(state, selectedUserId));
    
    const intl = useIntl();
    const cancelTitle = intl.formatMessage({ id: "common.btn-cancel" });
    const submitTitle = intl.formatMessage({ id: "user-level-modeling.user-search-help-dialog.submit" });
    const selectedUserTitle = intl.formatMessage({ id: "user-level-modeling.user-search-help-dialog.selected-user" });

    return (
        <EntitiesSelectDialogActions>
            {selectedUser && (
                <SelectedEntityTag
                    title={selectedUserTitle}
                    tag={selectedUser.employee}
                    onDelete={onUnselectUser}
                />
            )}
            <Button
                variant="outlined"
                className="user-level-modeling-users-dialog-cancel-button"
                onClick={onCancelClick}
            >
                {cancelTitle}
            </Button>
            <Button
                variant="contained"
                className="user-level-modeling-users-dialog-submit-button"
                onClick={onSubmitClick}
            >
                {submitTitle}
            </Button>
        </EntitiesSelectDialogActions>
    );
};

UserLevelModelingUsersDialogActions.propTypes = {
    selectedUserId: PropTypes.string,
    onUnselectUser: PropTypes.func,
    onCancelClick: PropTypes.func,
    onSubmitClick: PropTypes.func
};

export default UserLevelModelingUsersDialogActions;