import React from "react";
import PropTypes from "prop-types";

import { CommonDialogContent } from "../../common/dialogs";

import "./styles.less";

const EntitiesSelectDialogContent = ({
    className="",
    ...props
}) => (
    <CommonDialogContent
        className={`${className} entities-select-dialog-content`}
        {...props}
    />
);

EntitiesSelectDialogContent.propTypes = {
    className: PropTypes.string,
};

export default EntitiesSelectDialogContent;