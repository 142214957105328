import React from "react";
import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { CommonDialogActions } from "../../../common/dialogs";
import { Button } from "../../../common/buttons";
import { Tag } from "../../../common/form-controls";

import "./styles.less";

const UserLevelModelingRolesDialogActions = ({
    selectedRole,
    onUnselectRole,
    onCancelClick,
    onSelectClick
}) => {
    const intl = useIntl();
    const cancelTitle = intl.formatMessage({ id: "common.btn-cancel" });
    const selectTitle = intl.formatMessage({ id: "user-level-modeling.role-search-help-dialog.select" });
    const selectedRoleTitle = intl.formatMessage({ id: "user-level-modeling.role-search-help-dialog.selected-role" });

    return (
        <CommonDialogActions>
            {selectedRole && (
                <Box className="user-level-modeling-roles-dialog-selected-role-box">
                    <Typography className="user-level-modeling-roles-dialog-selected-role-title">
                        {selectedRoleTitle}
                    </Typography>
                    <Tag
                        tag={selectedRole}
                        onDelete={onUnselectRole}
                    />
                </Box>
            )}
            <Button
                variant="outlined"
                className="user-level-modeling-roles-dialog-cancel-button"
                onClick={onCancelClick}
            >
                {cancelTitle}
            </Button>
            <Button
                variant="contained"
                className="user-level-modeling-roles-dialog-select-button"
                onClick={onSelectClick}
            >
                {selectTitle}
            </Button>
        </CommonDialogActions>
    );
};

UserLevelModelingRolesDialogActions.propTypes = {
    selectedRole: PropTypes.string,
    onUnselectRole: PropTypes.func,
    onCancelClick: PropTypes.func,
    onSelectClick: PropTypes.func
};

export default UserLevelModelingRolesDialogActions;