import React from "react";
import { FormHelperText as MuiFormHelperText } from "@mui/material";
import PropTypes from "prop-types";

import { ErrorIcon, WarningIcon } from "../icons";

import "./styles.less";

export const formHelperTextClassName = "form-helper-text";

export const FormHelperTextError = ({ message }) => (
    <span className="form-helper-text-content form-helper-text-content-error">
        <ErrorIcon />
        {message}
    </span>
);

export const FormHelperTextWarning = ({ message }) => (
    <span className="form-helper-text-content form-helper-text-content-warning">
        <WarningIcon />
        {message}
    </span>
);

export const FormHelperTextContent = ({
    error,
    errorMessage,
    warning,
    warningMessage,
}) => {
    return (
        <>
            {error && errorMessage && (
                <FormHelperTextError message={errorMessage} />
            )}
            {warning && warningMessage && (
                <FormHelperTextWarning message={warningMessage} />
            )}
        </>
    );
};

export const FormHelperTextEmpty = ({
    className="",
    ...props
}) => (
    <MuiFormHelperText
        className={`${formHelperTextClassName} ${className}`}
        {...props}
    />
);

const FormHelperText = ({
    className="",
    error,
    errorMessage,
    warning,
    warningMessage
}) => {
    return (
        <FormHelperTextEmpty
            className={className}
        >
            <FormHelperTextContent
                error={error}
                errorMessage={errorMessage}
                warning={warning}
                warningMessage={warningMessage}
            />
        </FormHelperTextEmpty>
    )
};

FormHelperTextError.propTypes = {
    message: PropTypes.string
};

FormHelperTextWarning.propTypes = {
    message: PropTypes.string
};

FormHelperTextContent.propTypes = {
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    warning: PropTypes.bool,
    warningMessage: PropTypes.string
};

FormHelperTextEmpty.propTypes = {
    className: PropTypes.string
};

FormHelperText.propTypes = {
    className: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    warning: PropTypes.bool,
    warningMessage: PropTypes.string
};

export default FormHelperText;
