import React from "react";
import PropTypes from "prop-types";

import { SearchInput } from "../../common/form-controls";

import "./styles.less";

const VariantsSearchInput = ({ onSearch }) => (
    <SearchInput
        className="variants-search-input"
        onSearchClick={onSearch}
    />
);

VariantsSearchInput.propTypes = {
    onSearch: PropTypes.func
};

export default VariantsSearchInput;