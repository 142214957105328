import React from "react";
import { Checkbox } from "../buttons";
import { FormControlLabel } from "@mui/material";
import PropTypes from "prop-types";

const CheckboxInput = ({ sx, ...props }) => {
    return (
        <FormControlLabel
            control={<Checkbox sx={{ padding: 0 }} />}
            sx={{
                "& .MuiTypography-root": {
                    fontFamily: "Mulish",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "150%",
                    color: "var(--font-4)",
                    marginLeft: "12px",
                },
                marginLeft: 0,
                marginRight: 0,
                ...sx,
            }}
            {...props}
        />
    );
};

CheckboxInput.propTypes = {
    sx: PropTypes.object,
};

export default CheckboxInput;
