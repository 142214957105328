import React from "react";
import PropTypes from "prop-types";

import { ScrollableTabs } from "../tabs";

import "./style.less";

const EntityFormTabs = ({ tabs, tabId, setTabId, className="", oneVisibleTab }) => {
    return(
        <ScrollableTabs
            className={`entity-form-tabs ${className}`}
            tabs={tabs}
            value={tabId}
            onChange={setTabId}
            oneVisibleTab={oneVisibleTab}
        />
    );
};

EntityFormTabs.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.object),
    tabId: PropTypes.string,
    setTabId: PropTypes.func,
    className: PropTypes.string,
    oneVisibleTab: PropTypes.bool
};

export default EntityFormTabs;
