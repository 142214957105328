import React from "react";
import PropTypes from "prop-types";

import { SearchInput } from "../../common/form-controls";

import "./styles.less";

const EntitiesSelectDialogSearch = ({
    className="",
    ...props
}) => (
    <SearchInput
        className={`${className} entities-select-dialog-search`}
        {...props}
    />
);

EntitiesSelectDialogSearch.propTypes = {
    className: PropTypes.string,
};

export default EntitiesSelectDialogSearch;