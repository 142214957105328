import SodService from "./sod-service";

class BusynessProcessesService extends SodService {
    async getBusynessProcesses(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/sod/business-processes/list", config);

        // const data = {
        //     rows: [{id: "X5.XUI", description: "xui xui"}],
        //     total: 1
        // }

        const busynessProcesses = data && data.rows.map(item => this.parseBusynessProcess(item));
        const total = data && data.total;

        return { busynessProcesses, total };
    }

    async getBusynessProcessFilters(params, signal) {
        return this.getFilters("/sod/business-processes/filters", params, signal);
    }


    async getBusynessProcessColumns() {
        return await this.getColumns("/sod/business-processes/columns");
        // return [
        //     {
        //         "id": "id",
        //         "title": "Идентификатор бизнес-процесса",
        //         "type": "string",
        //         "sortable": true,
        //         "filterable": true,
        //         "export_field": "id"
        //     },
        //     {
        //         "id": "description",
        //         "title": "Описание бизнес-процесса",
        //         "type": "string",
        //         "sortable": true,
        //         "filterable": true,
        //         "export_field": "description"
        //     }
        // ]
    }
    
    async getBusynessProcessChangeHistory(key) {
        const data = await this.get(`/sod/business-processes/detailed/change-history/${key}`)
        return data && data.map(item => this.parseUserActionLog(item))
    }

    async getBusynessProcessDetailed(busynessProcessId) {
        const data = await this.get(`/sod/business-processes/detailed/${encodeURIComponent(busynessProcessId)}`);

        return this.parseBusynessProcess(data);
    }

    async getBusynessProcessesSearchHelp(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/sod/business-processes/list", config);

        const busynessProcesses = data && data.rows.map(item => this.parseBusynessProcessSearchHelp(item));
        const total = data && data.total;

        return { rows: busynessProcesses, total }
    }

    async deleteBusynessProcess(id) {
        return this.delete(`/sod/business-processes/detailed/${encodeURIComponent(id)}`);
    }

    async editBusynessProcess(busynessProcess) {
        const config = {
            params: {}
        }
        const data = await this.put(`/sod/business-processes/detailed/${encodeURIComponent(busynessProcess.id)}`, this.parseBusynessProcessToServer(busynessProcess), config);

        return this.parseBusynessProcess(data);
    }

    async createBusynessProcess(busynessProcess) {
        const config = {
            params: {}
        }
        const data = await this.post(`/sod/business-processes/detailed/${encodeURIComponent(busynessProcess.id)}`, this.parseBusynessProcessToServer(busynessProcess), config);

        return this.parseBusynessProcess(data);
    }

    parseBusynessProcessSearchHelp(item) {
        return {
            key: item.business_process_id,
            text: item.description
        }
    }

    parseBusynessProcess(item) {
        return {
            id: item.business_process_id,
            description: item.description,
        };
    }

    parseBusynessProcessToServer(busynessProcess) {
        return {
            business_process_id: busynessProcess.id,
            description: busynessProcess.description,
        };
    }
}

const busynessProcessesService = new BusynessProcessesService();

export { busynessProcessesService };
export default BusynessProcessesService;