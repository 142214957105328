import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

import { ClassBuilderIcon } from "../../common/icons";

import "./styles.less";

const RoleMenuLeafIconBox = ({ sx }) => {
    return (
        <Box
            className="pfcg-view-leaf-icon-box"
            sx={sx}
        >
            <ClassBuilderIcon className="pfcg-view-leaf-icon-box-leaf-icon" />
        </Box>
    );
};

RoleMenuLeafIconBox.propTypes = {
    sx: PropTypes.object
};

export default RoleMenuLeafIconBox;