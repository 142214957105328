import React from "react";
import { ClickAwayListener } from "@mui/material";
import PropTypes from "prop-types";

const ClickAwayListenerWrapper = ({ onClickAway, children }) => {
    if (!onClickAway) {
        return children;
    }

    return (
        <ClickAwayListener onClickAway={onClickAway}>
            {children}
        </ClickAwayListener>
    );
};

ClickAwayListenerWrapper.propTypes = {
    onClickAway: PropTypes.func,
    children: PropTypes.node
};

export default ClickAwayListenerWrapper;