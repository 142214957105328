import React from "react";
import { SvgIcon } from "@mui/material";

const LeftScrollButtonIcon = (props) => (
    <SvgIcon
        viewBox="0 0 8 14"
        {...props}
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 8 14"
            fill="none"
        >
            <path
                d="M-0.0823078 7.00367C-0.0823078 7.28422 0.0203333 7.51687 0.2393 7.73584L5.5082 12.8884C5.68611 13.0663 5.89824 13.1484 6.15142 13.1484C6.66462 13.1484 7.08203 12.7447 7.08203 12.2315C7.08203 11.9783 6.97255 11.7457 6.78779 11.5609L2.10737 7.01051L6.78779 2.44641C6.97255 2.26165 7.08203 2.03584 7.08203 1.77582C7.08203 1.26262 6.66462 0.852051 6.15142 0.852051C5.89824 0.852051 5.68611 0.941007 5.5082 1.11892L0.2393 6.27149C0.0134902 6.49046 -0.0823078 6.72311 -0.0823078 7.00367Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

export default LeftScrollButtonIcon;
