import React from "react";
import { SvgIcon } from "@mui/material";

const ReportModelingLeafIcon = (props) => (
    <SvgIcon
        viewBox="0 0 12 16"
        {...props}
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 12 16"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.74194 0.646673C7.74194 0.595341 7.72154 0.546111 7.68525 0.509814C7.64895 0.473517 7.59972 0.453125 7.54839 0.453125H2.12903C1.56438 0.453125 1.02285 0.677433 0.623579 1.0767C0.224308 1.47598 0 2.0175 0 2.58216V13.4209C0 13.9855 0.224308 14.527 0.623579 14.9263C1.02285 15.3256 1.56438 15.5499 2.12903 15.5499H9.87097C10.4356 15.5499 10.9771 15.3256 11.3764 14.9263C11.7757 14.527 12 13.9855 12 13.4209V5.79274C12 5.74141 11.9796 5.69218 11.9433 5.65588C11.907 5.61958 11.8578 5.59919 11.8065 5.59919H8.32258C8.16858 5.59919 8.0209 5.53801 7.912 5.42912C7.80311 5.32023 7.74194 5.17254 7.74194 5.01854V0.646673ZM8.32258 8.19506C8.47658 8.19506 8.62427 8.25624 8.73316 8.36513C8.84205 8.47402 8.90323 8.62171 8.90323 8.77571C8.90323 8.9297 8.84205 9.07739 8.73316 9.18628C8.62427 9.29518 8.47658 9.35635 8.32258 9.35635H3.67742C3.52342 9.35635 3.37573 9.29518 3.26684 9.18628C3.15795 9.07739 3.09677 8.9297 3.09677 8.77571C3.09677 8.62171 3.15795 8.47402 3.26684 8.36513C3.37573 8.25624 3.52342 8.19506 3.67742 8.19506H8.32258ZM8.32258 11.2918C8.47658 11.2918 8.62427 11.353 8.73316 11.4619C8.84205 11.5708 8.90323 11.7185 8.90323 11.8725C8.90323 12.0265 8.84205 12.1742 8.73316 12.2831C8.62427 12.392 8.47658 12.4531 8.32258 12.4531H3.67742C3.52342 12.4531 3.37573 12.392 3.26684 12.2831C3.15795 12.1742 3.09677 12.0265 3.09677 11.8725C3.09677 11.7185 3.15795 11.5708 3.26684 11.4619C3.37573 11.353 3.52342 11.2918 3.67742 11.2918H8.32258Z"
                fill="currentColor"
                fillOpacity="0.55"
            />
            <path
                d="M8.90323 0.898109C8.90323 0.755657 9.05265 0.665076 9.16336 0.754109C9.25704 0.82998 9.34142 0.918238 9.41342 1.01888L11.7461 4.26817C11.7987 4.3425 11.7414 4.4385 11.6501 4.4385H9.09678C9.04544 4.4385 8.99621 4.4181 8.95992 4.38181C8.92362 4.34551 8.90323 4.29628 8.90323 4.24495V0.898109Z"
                fill="currentColor"
                fillOpacity="0.55"
            />
        </svg>
    </SvgIcon>
);

export default ReportModelingLeafIcon