import React from 'react'
import { format } from 'date-fns';
import { Box, Typography, IconButton, styled } from '@mui/material';

import { SharpLeftChevronIcon, SharpRightChevronIcon } from '../icons';


const NavigatorIconButton = styled(IconButton)({
    padding: "6px",

    "& .MuiSvgIcon-root": {
        fontSize: "20px",
        color: "#242424"
    }
});


const renderDatePickerNavigator = (locale, currFocusedDate, setShownDate) => {
    const title = format(currFocusedDate, "LLLL yyyy", { locale });
    const currentMonth = currFocusedDate.getMonth();

    return(
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "4px 8px 4px 18px"
            }}
        >
            <Typography className="date-pricer-navigator-title">
                {title}
            </Typography>
            
            <Box>
                <NavigatorIconButton
                    onClick={() => setShownDate(currentMonth - 1, "setMonth")}
                >
                    <SharpLeftChevronIcon />
                </NavigatorIconButton>

                <NavigatorIconButton
                    onClick={() => setShownDate(currentMonth + 1, "setMonth")}
                >
                    <SharpRightChevronIcon />
                </NavigatorIconButton>
            </Box>
        </Box>
    );
};

export default renderDatePickerNavigator;
