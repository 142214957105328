import React, { useRef } from "react";
import { useIntl } from "react-intl";
import { Box, Radio } from "@mui/material";
import PropTypes from "prop-types";

import { getEntityDeleteIcon } from "../../../../utils/reports-modeling-utils";

import { createRowCellContentInner, getCellClassNameInner } from "../../../common/table";
import { IconButton } from "../../../common/buttons";

import { EntitiesSelectTable } from "../../entities-select-dialog";

import "./styles.less";


const getEntityCellClassName = (row, column, selected) => {
    let className = getCellClassNameInner(row, column, selected);

    const status = row.status;
    const changed = row.changed

    if (status) {
        className += ` entities-select-table-cell-${status}`;
    } else if (changed){
        className += ` entities-select-table-cell-changed`;
    }

    return className;
};

const RolesTable = ({
    rows,
    selectedRoleId,
    onSelectRole,
    onDeleteRole,
    onRowClick
}) => {
    const intl = useIntl();

    const columns = [
        {
            id: "radio",
            type: "radio"
        },
        {
            id: "role",
            title: intl.formatMessage({ id: "user-level-modeling.role-search-help-dialog.columns.role" }),
            type: "text",
        },
        {
            id: "description",
            title: intl.formatMessage({ id: "user-level-modeling.role-search-help-dialog.columns.description" }),
            type: "text",
        },
        {
            id: "delete",
            type: "delete",
        }
    ];

    const createRowCellContent = (row, column, selected, onSelect, getCellValue) => {
        switch (column.type) {
            case "radio": {
                return (
                    <Radio
                        checked={row.id === selectedRoleId}
                        value={row.id}
                        onChange={onSelectRole}
                    />
                );
            }

            case "delete": {
                const Icon = getEntityDeleteIcon(row.status);
                return (
                    <Box className="table-row-cell-actions">
                        <IconButton
                            className="table-row-cell-actions-item"
                            onClick={(e) => {
                                e.stopPropagation();
                                onDeleteRole(row.id);
                            }}
                        >
                            <Icon />
                        </IconButton>
                    </Box>
                )
            }

            default:
                return createRowCellContentInner(row, column, selected, onSelect, getCellValue);
        }
    };

    const tableRef = useRef();

    return (
        <EntitiesSelectTable
            className="user-level-modeling-roles-table"
            containerRef={tableRef}
            rows={rows}
            columns={columns}
            createRowCellContent={createRowCellContent}
            onRowClick={onRowClick}
            getCellClassName={getEntityCellClassName}
        />
    )
};

RolesTable.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object),
    selectedRoleId: PropTypes.string,
    onSelectRole: PropTypes.func,
    onDeleteRole: PropTypes.func,
    onRowClick: PropTypes.func
};

export default RolesTable;