import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { createRisk, editRisk, clearCurrentRisk, deleteRisk } from "../../../../reducers/riskSlice";
import { fetchRiskDetailed, setInitialCurrentRisk, setSubmit } from "../../../../reducers/riskSlice";
import { syncRiskWithSap } from "../../../../reducers/riskSlice";
import { setError } from "../../../../reducers/riskSlice";

import { DeleteDialog, ProgressDialog } from "../../../common/dialogs";
import { ErrorsDialog, UserLogDialog } from "../../../common/dialogs";
import { ADD_MODE, EDIT_MODE, getCurrentMode } from "../../../common/entity-form";
import { PageContentBox } from "../../../common/page-content-box";
import useLogs from "../../../common/hooks/useLogs";

import RiskFormPageHeader from "./risk-form-page-header.jsx";
import RiskFormPageRisk from "./risk-form-page-risk.jsx";

import "./style.less";

const RiskFormPage = () => {
    const { riskId } = useParams();
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const busy = useSelector(state => state.risks.busy);
    const busyType = useSelector(state => state.risks.busyType);
    const error = useSelector(state => state.risks.error);
    const submit = useSelector(state => state.risks.submit);
    const risk = useSelector(state => state.risks.currentRisk);
    const riskEditable = useSelector(state => state.risks.currentRiskEditable);
    const riskDeleted = useSelector(state => state.risks.currentRiskDeleted);
    const validationErrors = useSelector(state => state.risks.validationErrors);

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [form, setForm] = useState(null);

    const { logs, getLogs, openLogs, setOpenLogs } = useLogs("risk", riskId)

    const mode = getCurrentMode(!riskId, riskEditable);

    const [formErrors, setFormErrors] = useState(null)

    useEffect(() => {
        if (!validationErrors) {
            setFormErrors(null);
            return;
        }

        const formErrosTmp = validationErrors.reduce((accumulator, error) => {
            accumulator[error.field] = error;

            return accumulator
        }, {});

        setFormErrors(formErrosTmp)

    }, [validationErrors, form])

    useEffect(() => {
        if (riskId) {
            dispatch(fetchRiskDetailed(riskId));
        } else {
            dispatch(setInitialCurrentRisk());
        }

        return (() => {
            dispatch(clearCurrentRisk());
        });
    }, [riskId, dispatch]);

    useEffect(() => {
        if (risk) {
            setForm({
                id: risk?.id || "",
                description: risk?.description || "",
                riskLevel: risk?.riskLevel ?? null,
                riskType: risk?.riskType || "",
                active: risk?.active || true,
                functions: risk?.functions || [],
                matrixHeaders: risk?.matrixHeaders || []
            })
        }
    }, [risk]);

    useEffect(() => {
        if (!submit) return;

        dispatch(setSubmit(false));

        if (mode === ADD_MODE || riskDeleted) {
            handleBackClick();
        }
    }, [submit, mode, riskDeleted, dispatch, handleBackClick]);

    const handleSaveRisk = () => {
        const formRisk = {
            id: form.id?.trim(),
            description: form.description,
            riskLevel: form.riskLevel,
            riskType: form.riskType,
            active: form.active,
            functions: form.functions,
            matrixHeaders: form.matrixHeaders
        };

        if (formRisk.id !== form.id) {
            setForm({
                ...form,
                id: formRisk.id
            });
        }

        if (mode === EDIT_MODE) {
            dispatch(editRisk(formRisk));
        } else {
            dispatch(createRisk(formRisk));
        }
    };

    const backPath = useMemo(() => (mode === ADD_MODE
        ? ".."
        : "../.."
    ), [mode]);

    const handleBackClick = useCallback(() => {
        navigate(backPath, { relative: "path" });
    }, [navigate, backPath]);

    const handleDeleteClick = () => {
        setShowDeleteDialog(true);
    };

    const handleDeleteCancelClick = () => {
        setShowDeleteDialog(false);
    };

    const handleDeleteConfirmClick = () => {
        dispatch(deleteRisk(riskId));
    };

    const handleEditClick = async () => {
        dispatch(fetchRiskDetailed(riskId));
    };

    const handleSyncClick = async () => {
        dispatch(syncRiskWithSap(riskId));
    };

    if (!risk && error) return <Navigate to={backPath} relative="path" />;

    return (
        <>
            {form && (
                <PageContentBox className="risk-form-page-risk">
                    <RiskFormPageHeader
                        riskId={riskId}
                        mode={mode}
                        onBackClick={handleBackClick}
                        onDeleteClick={handleDeleteClick}
                        onSaveClick={handleSaveRisk}
                        onEditClick={handleEditClick}
                        onShowLogsClick={getLogs}
                        onSyncRiskClick={handleSyncClick}
                    />

                    <RiskFormPageRisk
                        form={form}
                        mode={mode}
                        setForm={setForm}
                        validationErrors={formErrors}
                    />
                </PageContentBox>
            )}

            <DeleteDialog
                open={showDeleteDialog}
                title={intl.formatMessage({ id: "risk-list-page.delete-dialog.title" })}
                text={intl.formatMessage(
                    { id: "risk-list-page.delete-risk.text" },
                    { name: riskId }
                )}
                onCancelClick={handleDeleteCancelClick}
                onDeleteClick={handleDeleteConfirmClick}
            />

            <ProgressDialog
                open={busy}
                busyType={busyType}
            />

            <ErrorsDialog
                error={error}
                open={error?.type === "error" || error?.type === "warning"}
                onClose={() => { dispatch(setError(null)) }}
            />

            <UserLogDialog
                open={openLogs}
                logs={logs}
                onClose={() => setOpenLogs(false)}
            />
        </>
    );
};

export default RiskFormPage;
