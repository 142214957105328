import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { CommonDialog, CommonDialogContent, CommonDialogTitle } from "../../common/dialogs";

import VariantsList from "./variants-list.jsx";
import VariantsSearchInput from "./variants-search-input.jsx";

import "./styles.less";

const VariantsListDialog = ({
    open,
    variants,
    onDeleteClick,
    onFavoriteClick,
    onItemClick,
    onSearch,
    onClose
}) => {
    const intl = useIntl();
    const title = intl.formatMessage({ id: "show-variants-dialog.title" });

    return (
        <CommonDialog
            className="show-variants-dialog"
            open={open}
        >
            <CommonDialogTitle
                title={title}
                onClose={onClose}
            />

            <CommonDialogContent>
                <VariantsSearchInput
                    onSearch={onSearch}
                />

                <VariantsList
                    variants={variants}
                    onDeleteClick={onDeleteClick}
                    onFavoriteClick={onFavoriteClick}
                    onItemClick={onItemClick}
                />
            </CommonDialogContent>
        </CommonDialog>
    );
};

VariantsListDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSearch: PropTypes.func,
    ...VariantsList.propTypes,    
};

export default VariantsListDialog;