import React from "react";
import { Box } from "@mui/material";

import ReportModelingViewsSwitch from "./report-modeling-views-switch.jsx";

import "./styles.less";

const ReportModelingViewsToolbar = ({
    view,
    onChangeView,
}) => {
    return (
        <Box className="report-modeling-views-toolbar">
            <ReportModelingViewsSwitch
                value={view}
                onChange={onChangeView}
            />


        </Box>
    );
};

ReportModelingViewsToolbar.propTypes = {
    view: ReportModelingViewsSwitch.propTypes.value,
    onChangeView: ReportModelingViewsSwitch.propTypes.onChange,
};

export default ReportModelingViewsToolbar;