import React from "react";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";

import { PageContentBox } from "../page-content-box";
import { Button } from "../buttons";

import "./style.less";

const EmptyTabContent = ({ text,  buttonText, buttonDisabled, onButtonClick }) => (
    <PageContentBox className="empty-tab-content">
        <Typography component="p" className="empty-tab-content-text">
            {text}
        </Typography>

        <Button
            className="empty-tab-content-button"
            variant="contained"
            disabled={buttonDisabled}
            onClick={onButtonClick}
        >
            {buttonText}
        </Button>
    </PageContentBox>
);

EmptyTabContent.propTypes = {
    text: PropTypes.string,
    buttonText: PropTypes.string,
    buttonDisabled: PropTypes.bool,
    onButtonClick: PropTypes.func
};

export default EmptyTabContent;
