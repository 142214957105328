import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { Box, ClickAwayListener, useMediaQuery } from "@mui/material";

import { USER_REPORT_ACTIONS_TYPES } from "../../../utils/sodReports";

import { IntegrationIcon, SettingsIcon, SodMatrixtIcon, SodReportsIcon, UserIcon, ReportsModelingIcon } from "../icons";
import { adminService } from "../../../services/admin-service";
import breakpoints from "../styles/breakpoints.js";

import TabContent from "./tab-content.jsx";
import HeaderBar from "./header-bar.jsx";
import AppDrawerHeader from "./app-drawer-header.jsx";
import AppDrawerMenu from "./app-drawer-menu.jsx";
import { useSelector } from "react-redux";
import { AppVersionDialog } from "../dialogs";

const actionTypes = [
    {
        id: USER_REPORT_ACTIONS_TYPES.SOD_REPORTS,
        titleId: "common-toolbar-sod-reports.title",
        route: "/sod-reports",
        icon: SodReportsIcon,
    },
    {
        id: USER_REPORT_ACTIONS_TYPES.REPORTS_MODELING,
        titleId: "common-toolbar-reports-modeling.title",
        route: "/reports-modeling",
        icon: ReportsModelingIcon,
    },
    {
        id: "sod_matrix_maintenance",
        titleId: "common-toolbar-sod-matrix.title",
        route: "/sod-maintenance",
        icon: SodMatrixtIcon,
    },
    {
        id: "integrations",
        titleId: "common-toolbar-settings-integration.title",
        route: "/integration",
        icon: IntegrationIcon,
    },
    {
        id: "users",
        titleId: "common-toolbar-users.title",
        route: "/users",
        icon: UserIcon,
    },
    {
        id: "settings",
        titleId: "common-toolbar-settings.title",
        route: "/settings",
        icon: SettingsIcon,
    }
];

const actionsRoutes = {
    user_level_report: "/user-level",
    role_level_report: "/role-level",
    user_level_modeling: "/user-level",
    role_level_modeling: "/role-level",
    access_rules: "/access-rules",
    critical_rules: "/critical-rules",
    integrations: "",
    users: "",
    systems_settings: "/systems",
    risk_levels_settings: "/risk-levels",
    locks_settings: "/locks",
    admin_settings: "/admin",
    business_process_maintenance: "/busyness-processes",
    user_level_busyness_report: "/user-level-busyness",
};

const getActionRoute = (actionType, action) => {
    const routeEnd = action.route ? action.route : actionsRoutes[action.id];

    if (!routeEnd) {
        return actionType.route;
    }

    return actionType.route + routeEnd;
};

const createTab = (actionType, userActions, intl) => {
    const tab = {
        id: actionType.id,
        title: intl.formatMessage({ id: actionType.titleId }),
        icon: actionType.icon
    };

    const children = userActions
        .filter(action => action.type === actionType.id)
        .map(action => ({
            id: action.id,
            title: action.title,
            route: getActionRoute(actionType, action),
            parent: tab
        }));

    if (children.length === 1) {
        tab.route = children[0].route;
    } else {
        tab.children = children;
        tab.open = false;
    }

    return tab;
}

const updateTab = (existingTab, actionType, userActions) => {
    const { open, ...tab } = existingTab;
    delete existingTab.children;

    const children = userActions
        .filter(action => action.type === actionType.id)
        .map(action => ({
            id: action.id,
            title: action.title,
            route: getActionRoute(actionType, action),
            parent: tab
        }));

    if (children.length === 1) {
        tab.route = children[0].route;
    } else {
        tab.children = children;
        tab.open = open;
    }

    return tab;
}

const AppDrawerPage = () => {
    const matches1920 = useMediaQuery(`(min-width: ${breakpoints.minDesktopBreakpoint})`);
    const matches1024 = useMediaQuery(`(min-width:  ${breakpoints.minLaptopBreakpoint})`);

    const navigate = useNavigate();
    const location = useLocation();

    const intl = useIntl();

    const user = useSelector(state => state.auth.user);

    const title = intl.formatMessage({ id: "common-toolbar-main-menu.title" });

    const [tabs, setTabs] = useState([]);

    useEffect(() => {
        if (!user?.actions) {
            setTabs([]);
            return;
        }

        setTabs(tabs => actionTypes
            .map(actionType => {
                const existingTab = tabs.find(tab => tab.id === actionType.id);

                if (existingTab) {
                    return updateTab(existingTab, actionType, user.actions);
                } else {
                    return createTab(actionType, user.actions, intl);
                }
            })
            .filter(tab => tab.route || tab.children?.length)
        );
    }, [user, intl]);

    const activeTab = useMemo(() => {
        if (location.pathname === activeTab?.route || tabs.length === 0) {
            return null;
        }

        const allChildren = tabs.reduce((accumulator, tab) => {
            if (tab.route) {
                accumulator.push(tab);
            }

            if (tab.children?.length > 0) {
                accumulator.push(...tab.children);
            }

            return accumulator;
        }, []);

        let newActiveTab =
            allChildren.find(tab =>
                tab.route === location.pathname
            ) ||
            allChildren.find(tab =>
                location.pathname.startsWith(tab.route) &&
                tab.id !== activeTab?.id
            ) ||
            null;

        return newActiveTab;
    }, [location, tabs]);

    const [menuOpen, setMenuOpen] = useState(false);
    const [showAppVersionDialog, setShowAppVersionDialog] = useState(false);
    const [coreAppVersion, setCoreAppVersion] = useState("");
    const [sodAppVersion, setSodAppVersion] = useState("");

    useEffect(() => {
        setMenuOpen(matches1920);
    }, [matches1920]);

    const handleHamburgerClick = (event) => {
        event.stopPropagation();
        setMenuOpen(!menuOpen);
    };

    const handleClickAway = () => {
        setMenuOpen(false);
    };

    const handleLeafTabClick = (tab) => {
        if (!tab.route) {
            return;
        }

        navigate(tab.route);

        if (!matches1920) {
            setMenuOpen(false);
        }
    };

    const handleAboutAppClick = async () => {

        const [coreResponse, sodResponse] = await Promise.allSettled([adminService.getCoreAppVersion(), adminService.getSodAppVersion()])

        if (coreResponse.status === "fulfilled") {
            setCoreAppVersion(coreResponse.value.current_version)
        } else {
            setCoreAppVersion(null)
        }
        

        if (sodResponse.status === "fulfilled") {
            setSodAppVersion(sodResponse.value.current_version)
        } else {
            setSodAppVersion(null)
        }

        setShowAppVersionDialog(true)
    }

    const handleCancelClick = () => {
        setShowAppVersionDialog(false);
    };

    const handleParentTabClick = (tab) => {
        if (!tab.children) {
            handleLeafTabClick(tab);
            return;
        }

        setTabs(tabs => {
            return tabs.map(currentTab => currentTab.id === tab.id
                ? { ...currentTab, open: !currentTab.open }
                : currentTab
            );
        });
    };

    return (
        <Box className="app-drawer-page">
            <AppDrawerHeader
                showHome={matches1920}
                showHamburger={!matches1920}
                onHamburgerClick={handleHamburgerClick}
            />

            <HeaderBar
                showSearch={matches1024}
                onAboutAppClick={handleAboutAppClick}
            />

            <AppVersionDialog
                open={showAppVersionDialog}
                coreVersion={coreAppVersion}
                sodVersion={sodAppVersion}
                onCancelClick={handleCancelClick}
            />

            {matches1920 ? (
                <AppDrawerMenu
                    showSearch={!matches1024}
                    title={title}
                    tabs={tabs}
                    activeTab={activeTab}
                    onParentTabClick={handleParentTabClick}
                    onLeafTabClick={handleLeafTabClick}
                    open={menuOpen}
                />
            ) : (
                <ClickAwayListener onClickAway={handleClickAway}>
                    <AppDrawerMenu
                        showSearch={!matches1024}
                        title={title}
                        tabs={tabs}
                        activeTab={activeTab}
                        onParentTabClick={handleParentTabClick}
                        onLeafTabClick={handleLeafTabClick}
                        open={menuOpen}
                    />
                </ClickAwayListener>
            )}

            <TabContent
                tabs={tabs}
                menuTitle={title}
                activeTab={activeTab}
            />
        </Box>
    )
}

export default AppDrawerPage;