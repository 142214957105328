const downloadFile = (data, filename) => {
    // const link = document.createElement('a');

    // link.download = filename
    // link.href = data

    // link.click()
    

    const href = URL.createObjectURL(data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
};

export const getFilenameFromResponse = (response) => {
    const contentDisposition = response.headers.get("content-disposition");

    if (!contentDisposition) {
        return "";
    }

    const regexp = /filename=(.*?)(;|$)/;
    const matches = contentDisposition.match(regexp);
    const filename = matches && matches.length > 0 ? matches[1] : "";

    return filename;
};

export default downloadFile