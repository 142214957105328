import React from "react";
import { SvgIcon } from "@mui/material";

const AddFolderIcon = (props) => (
    <SvgIcon
        viewBox="0 0 24 24"
        {...props}
    >
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13 19C13 19.34 13.04 19.67 13.09 20H4C3.46957 20 2.96086 19.7893 2.58579 19.4142C2.21071 19.0391 2 18.5304 2 18V6C2 4.89 2.89 4 4 4H10L12 6H20C20.5304 6 21.0391 6.21071 21.4142 6.58579C21.7893 6.96086 22 7.46957 22 8V13.81C21.12 13.3 20.1 13 19 13C15.69 13 13 15.69 13 19ZM20 18V15H18V18H15V20H18V23H20V20H23V18H20Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

export default AddFolderIcon;
