import React from 'react';
import { createSvgIcon } from '@mui/material';

const HomeIcon = createSvgIcon(
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M21.1196 8.69972C21.3599 8.88936 21.5 9.17856 21.5 9.4846V20.5C21.5 21.0523 21.0523 21.5 20.5 21.5H16C15.4477 21.5 15 21.0523 15 20.5V16.5C15 15.9477 14.5523 15.5 14 15.5H10C9.44772 15.5 9 15.9477 9 16.5V20.5C9 21.0523 8.55228 21.5 8 21.5H3.5C2.94772 21.5 2.5 21.0523 2.5 20.5L2.5 9.4846C2.5 9.17856 2.64014 8.88936 2.88036 8.69972L11.3804 1.98919C11.7437 1.70237 12.2563 1.70237 12.6196 1.98919L21.1196 8.69972Z"
            fill="currentColor"
            stroke="currentColor"
        />
    </svg>,
    "Home"
);

export default HomeIcon;
