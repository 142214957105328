import React, { useState } from "react";
import { useIntl } from "react-intl";
import { CircularProgress } from "@mui/material";
import PropTypes from "prop-types";

import { CommonDialog, CommonDialogContent, CommonDialogTitle, ErrorsDialog } from "../../common/dialogs";

import ReportForm from "./report-form.jsx";
import ColumnsList from "./columns-list.jsx";
import ReportColumnsDialogActions from "./report-columns-dialog-actions.jsx";
import useColumnsReducer, { initColumns } from "./use-columns-reducer";
import SuccessSaveDialog from "./success-save-dialog.jsx";

import "./styles.less";

const RESULT_DIALOG_TYPES = {
    SUCCESS: "success",
    ERROR: "error"
};

const ReportColumnsDialog = ({
    open,
    onClose,
    getColumns,
    saveColumns,
}) => {
    const intl = useIntl();
    const title = intl.formatMessage({ id: "report-columns-dialog.title" });

    const [reportLevel, setReportLevel] = useState("");
    const [reportType, setReportType] = useState("");
    const [columnsState, columnsStateDispatch] = useColumnsReducer();
    const [busy, setBusy] = useState(false);
    const [resultDialog, setResultDialog] = useState(null);

    const handleSubmitReportForm = async (defaultColumns=false) => {
        setBusy(true);

        try {
            const newColumns = await getColumns(reportLevel, reportType, defaultColumns);

            columnsStateDispatch(initColumns(
                newColumns, reportLevel, reportType
            ));
        } catch (e) {
            console.error(e);
        }

        setBusy(false);
    };

    const handleResetColumnsClick = () => handleSubmitReportForm(true);

    const handleApplyColumns = async () => {
        setBusy(true);

        try {
            await saveColumns(
                columnsState.columns,
                columnsState.reportLevel,
                columnsState.reportType
            );

            setResultDialog({ type: RESULT_DIALOG_TYPES.SUCCESS });
        } catch (e) {
            setResultDialog({
                type: RESULT_DIALOG_TYPES.ERROR,
                error: e.detail ? e : {
                    detail: e.message
                }
            });
        }

        setBusy(false);
    };

    const handleCloseResultDialog = () => setResultDialog(null);

    const columnsAreEmty = !columnsState.columns.length;
    const columnsActionsDisabled = columnsAreEmty || busy;
    const successSaveDialogOpen = resultDialog?.type === RESULT_DIALOG_TYPES.SUCCESS;
    const errorDialogOpen = resultDialog?.type === RESULT_DIALOG_TYPES.ERROR;

    return (
        <>
            <CommonDialog
                className="report-columns-dialog"
                open={open}
            >
                <CommonDialogTitle
                    title={title}
                    onClose={onClose}
                />

                <CommonDialogContent>
                    {busy && (
                        <CircularProgress
                            className="report-columns-dialog-circular-progress"
                            size={32}
                        />
                    )}

                    <ReportForm
                        reportLevel={reportLevel}
                        setReportLevel={setReportLevel}
                        reportType={reportType}
                        setReportType={setReportType}
                        disabled={busy}
                        onSubmit={handleSubmitReportForm}
                    />

                    {!columnsAreEmty && (
                        <ColumnsList
                            disabled={busy}
                            columnsState={columnsState}
                            columnsStateDispatch={columnsStateDispatch}
                        />
                    )}
                </CommonDialogContent>

                <ReportColumnsDialogActions
                    resetDisabled={columnsActionsDisabled}
                    applyDisabled={columnsActionsDisabled}
                    onApplyClick={handleApplyColumns}
                    onResetClick={handleResetColumnsClick}
                />
            </CommonDialog>

            {successSaveDialogOpen && (
                <SuccessSaveDialog
                    open={successSaveDialogOpen}
                    onClose={handleCloseResultDialog}
                />
            )}

            {errorDialogOpen && (
                <ErrorsDialog
                    open={errorDialogOpen}
                    error={resultDialog.error}
                    onClose={handleCloseResultDialog}
                />
            )}
        </>
    );
};

ReportColumnsDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    getColumns: PropTypes.func,
    saveColumns: PropTypes.func,
};

export default ReportColumnsDialog;