import React from 'react';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types'; 

import Button from './button.jsx';
import breakpoints from "../styles/breakpoints.js";

const ResetFiltersButton = ({ onClick, title, filtersNumber }) => {
    const intl = useIntl();
    const resetTitle = title || intl.formatMessage({ id: "common.reset-filters-title" });

    return (
        <Button
            className="reset-filters-button"
            variant="text"
            onClick={onClick}
            sx={{
                color: "#737373",
                fontSize: "12px",
                fontFamily: "Manrope",
                fontWeight: 500,

                "&:has(.MuiButton-endIcon)": {
                    padding: 0,
                },

                [`@media(min-width: ${breakpoints.minLaptopBreakpoint})`]: {
                    fontSize: "16px"
                }
            }}
            endIcon={filtersNumber ? (
                <Box
                    component="span"
                    sx={{
                        fontFamily: "Roboto",
                        fontWeight: 900,
                        textDecoration: "none",
                        color: "#FFF",
                        backgroundColor: "#929292",
                        borderRadius: "100px",
                        width: "20px",
                        height: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",

                        ".MuiButton-endIcon &": {
                            fontSize: "12px"
                        }
                    }}
                >
                    {filtersNumber}
                </Box>
            ) : null}
        >
            <Box
                component="span"
                sx={{ textDecoration: "underline", }}
            >
                {resetTitle}
            </Box>
        </Button>
    );
};

ResetFiltersButton.propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string,
    filtersNumber: PropTypes.number
}

export default ResetFiltersButton;
