import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { selectModelingRoleById } from "../../../../reducers/reports-modeling/roleLevelSlice";

import { Button } from "../../../common/buttons";

import { EntitiesSelectDialogActions, SelectedEntityTag } from "../../entities-select-dialog";

import "./styles.less";

const RoleLevelModelingRolesDialogActions = ({
    selectedRoleId,
    onUnselectRole,
    onCancelClick,
    onSubmitClick
}) => {
    const selectedRole = useSelector(state => selectModelingRoleById(state, selectedRoleId));
    
    const intl = useIntl();
    const cancelTitle = intl.formatMessage({ id: "common.btn-cancel" });
    const submitTitle = intl.formatMessage({ id: "role-level-modeling.role-search-help-dialog.submit" });
    const selectedRoleTitle = intl.formatMessage({ id: "role-level-modeling.role-search-help-dialog.selected-role" });

    return (
        <EntitiesSelectDialogActions>
            {selectedRole && (
                <SelectedEntityTag
                    title={selectedRoleTitle}
                    tag={selectedRole.role}
                    onDelete={onUnselectRole}
                />
            )}
            <Button
                variant="outlined"
                className="role-level-modeling-roles-dialog-cancel-button"
                onClick={onCancelClick}
            >
                {cancelTitle}
            </Button>
            <Button
                variant="contained"
                className="role-level-modeling-roles-dialog-submit-button"
                onClick={onSubmitClick}
            >
                {submitTitle}
            </Button>
        </EntitiesSelectDialogActions>
    );
};

RoleLevelModelingRolesDialogActions.propTypes = {
    selectedRoleId: PropTypes.string,
    onUnselectRole: PropTypes.func,
    onCancelClick: PropTypes.func,
    onSubmitClick: PropTypes.func
};

export default RoleLevelModelingRolesDialogActions;