import React from "react";
import PropTypes from "prop-types";

import { EntityFormActions, EntityFormHeader } from "../../../common/entity-form";

import ProfileFormHeaderTitle from "./profile-form-header-title.jsx";

const ProfileFormPageHeader = ({
    name,
    mode,
    onBackClick,
    onDeleteClick,
    onSaveClick,
    onEditClick,
    onShowLogsClick
}) => {
    return (
        <EntityFormHeader>
            <ProfileFormHeaderTitle
                name={name}
                mode={mode}
                onBackClick={onBackClick}
            />

            <EntityFormActions
                mode={mode}
                onDeleteClick={onDeleteClick}
                onSaveClick={onSaveClick}
                onEditClick={onEditClick}
                onShowLogsClick={onShowLogsClick}
            />
        </EntityFormHeader>
    );
};

ProfileFormPageHeader.propTypes = {
    name: PropTypes.string,
    mode: PropTypes.string,
    onBackClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onSaveClick: PropTypes.func,
    onEditClick: PropTypes.func,
    onShowLogsClick: PropTypes.func,
}

export default ProfileFormPageHeader;
