import React from "react";
import PropTypes from "prop-types";

import { CommonDialogActions } from "../../common/dialogs";

import "./styles.less";

const EntitiesSelectDialogActions = ({
    className="",
    ...props
}) => (
    <CommonDialogActions
        className={`${className} entities-select-dialog-actions`}
        {...props}
    />
);

EntitiesSelectDialogActions.propTypes = {
    className: PropTypes.string,
};

export default EntitiesSelectDialogActions;