import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { CommonDialog, CommonDialogActions, CommonDialogContent, CommonDialogTitle } from "../../common/dialogs"
import { TextInput } from "../../common/form-controls";
import { Button } from "../../common/buttons";

import "./styles.less";

const AddFolderDialog = ({
    open,
    onClose,
    onAdd
}) => {
    const [folder, setFolder] = useState("");

    const intl = useIntl();
    const dialogTitle = intl.formatMessage({ id: "role-menu.add-folder-dialog.title" });
    const folderLabel = intl.formatMessage({ id: "role-menu.add-folder-dialog.folder-label" });
    const addTitle = intl.formatMessage({ id: "common.btn-add" });
    const cancelTitle = intl.formatMessage({ id: "common.btn-cancel" });

    return (
        <CommonDialog open={open}>
            <CommonDialogTitle
                title={dialogTitle}
                onClose={onClose}
            />

            <CommonDialogContent>
                <TextInput
                    label={folderLabel}
                    value={folder}
                    onChange={(e) => setFolder(e.target.value)}
                />
            </CommonDialogContent>

            <CommonDialogActions>
                <Button
                    className="add-folder-dialog-submit"
                    variant="contained"
                    disabled={!folder}
                    onClick={() => onAdd(folder)}
                >
                    {addTitle}
                </Button>
                <Button
                    className="add-folder-dialog-cancel"
                    variant="outlined"
                    onClick={onClose}
                >
                    {cancelTitle}
                </Button>
            </CommonDialogActions>
        </CommonDialog>
    );
};

AddFolderDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onAdd: PropTypes.func
};

export default AddFolderDialog;