import React, { useCallback,useEffect, useMemo, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { editBusynessProcess, createBusynessProcess, deleteBusynessProcess, clearCurrentBusynessProcess } from "../../../../../reducers/admin/busynessProcessesSlice.js"
import { setSubmit, fetchBusynessProcessDetailed, setInitialCurrentBusynessProcess } from "../../../../../reducers/admin/busynessProcessesSlice.js";
import { setError } from "../../../../../reducers/admin/busynessProcessesSlice.js";

import { DeleteConfirmDialog, DeleteDialog, ProgressDialog } from "../../../../common/dialogs/index.js";
import { ErrorsDialog, UserLogDialog } from "../../../../common/dialogs/index.js"
import { ADD_MODE, EDIT_MODE, READ_MODE, getCurrentMode } from "../../../../common/entity-form/index.js";
import useLogs from "../../../../common/hooks/useLogs.js";

import BusynessProcessFormPageBusynessProcess from "./busyness-process-form-page-busyness-process.jsx";
import BusynessProcessFormPageHeader from "./busyness-process-form-page-header.jsx";

import "./style.less";
import { useIntl } from "react-intl";



const DIALOG_TYPES = {
    DELETE: "DELETE",
    DELETE_CONFIRM: "DELETE_CONFIRM",
};

const BusynessProcessFormPage = () => {
    const { busynessProcessId } = useParams();
    const dispatch = useDispatch();
    const intl = useIntl();
    const navigate = useNavigate();

    const { logs, getLogs, openLogs, setOpenLogs } = useLogs("busynessProcess", busynessProcessId) 

    const busy = useSelector(state => state.admin.busynessProcesses.busy);
    const busyType = useSelector(state => state.admin.busynessProcesses.busyType);
    const error = useSelector(state => state.admin.busynessProcesses.error);
    const submit = useSelector(state => state.admin.busynessProcesses.submit);
    const busynessProcess = useSelector(state => state.admin.busynessProcesses.currentBusynessProcess);
    const busynessProcessEditable = useSelector(state => state.admin.busynessProcesses.currentBusynessProcessEditable);
    const busynessProcessDeleted = useSelector(state => state.admin.busynessProcesses.currentBusynessProcessDeleted);
    const validationErrors = useSelector(state => state.admin.busynessProcesses.validationErrors);

    const [form, setForm] = useState(null);
    const [dialogType, setDialogType] = useState(null);

    const mode = getCurrentMode(!busynessProcessId, busynessProcessEditable);
    const formDisabled = mode === READ_MODE;

    useEffect(() => {
        if (busynessProcessId) {
            dispatch(fetchBusynessProcessDetailed(busynessProcessId));
        } else {
            dispatch(setInitialCurrentBusynessProcess());
        }

        return (() => {
            dispatch(clearCurrentBusynessProcess());
        });
    }, [busynessProcessId, dispatch]);

    useEffect(() => {
        if (!busynessProcess) {
            return;
        }

        setForm({
            id: busynessProcess?.id || "",
            description: busynessProcess?.description || "",
        });
    }, [busynessProcess]);

    useEffect(() => {
        if (!submit) return;

        dispatch(setSubmit(false))

        if (mode === ADD_MODE || busynessProcessDeleted) {
            handleBackClick();
        }
    }, [submit, dispatch, mode, busynessProcessDeleted, handleBackClick]);


    const [formErrors, setFormErrors] = useState(null)

    useEffect(() => {
        if (!validationErrors) return;

        const formErrosTmp = validationErrors.reduce((accumulator, error) => {
            accumulator[error.field] = error;

            return accumulator
        }, {});

        setFormErrors(formErrosTmp)

    }, [validationErrors, form])

    const handleSaveBusynessProcess = () => {
        const formBusynessProcess = {
            id: form.id?.trim(),
            description: form.description,
        };

        if (formBusynessProcess.id !== form.id) {
            setForm({
                ...form,
                id: formBusynessProcess.id
            });
        }

        if (mode === EDIT_MODE) {
            dispatch(editBusynessProcess(formBusynessProcess));
        } else {
            dispatch(createBusynessProcess(formBusynessProcess));
        }
    };

    const backPath = useMemo(() => (mode === ADD_MODE
        ? ".."
        : "../.."
    ), [mode]);

    const handleBackClick = useCallback(() => {
        navigate(backPath, { relative: "path" });
    }, [navigate, backPath]);

    const handleDeleteClick = () => {
        setDialogType(DIALOG_TYPES.DELETE);
    };

    const handleDialogCancel = () => {
        setDialogType(null);
    }

    // const handleDeleteDialogDelete = () => {
    //     setDialogType(DIALOG_TYPES.DELETE_CONFIRM);
    // };

    const handleDeleteConfirmDialogDelete = () => {
        dispatch(deleteBusynessProcess(busynessProcessId));
        setDialogType(null);
    };

    const handleEditClick = () => {
        dispatch(fetchBusynessProcessDetailed(busynessProcessId));
    };


    if (!busynessProcess && error) return <Navigate to={backPath} relative="path" />;

    return (
        <>
            {form && (
                <>
                    <BusynessProcessFormPageHeader
                        mode={mode}
                        busynessProcessId={busynessProcessId}
                        onBackClick={handleBackClick}
                        onDeleteClick={handleDeleteClick}
                        onSaveClick={handleSaveBusynessProcess}
                        onEditClick={handleEditClick}
                        onShowLogsClick={getLogs}
                    />

                    <BusynessProcessFormPageBusynessProcess
                        form={form}
                        setForm={setForm}
                        mode={mode}
                        disabled={formDisabled}
                        validationErrors={formErrors}
                    />
                </>
            )}

            <ProgressDialog
                open={busy}
                busyType={busyType}
            />

            <DeleteDialog
                open={dialogType === DIALOG_TYPES.DELETE}
                title={intl.formatMessage({ id: "delete-busyness-process-dialog.title" })}
                text={intl.formatMessage(
                    { id: "delete-busyness-process-dialog.text" },
                    { name: busynessProcessId }
                )}
                onCancelClick={handleDialogCancel}
                onDeleteClick={handleDeleteConfirmDialogDelete}
            />

            <DeleteConfirmDialog
                open={dialogType === DIALOG_TYPES.DELETE_CONFIRM}
                title={intl.formatMessage({ id: "delete-busyness-process-dialog.title" })}
                text={intl.formatMessage(
                    { id: "delete-busyness-process-dialog.text" },
                    { name: busynessProcessId }
                )}
                onCancelClick={handleDialogCancel}
                onDeleteClick={handleDeleteConfirmDialogDelete}
            />

            <ErrorsDialog
                error={error}
                open={error?.type === "error"}
                onClose={() => { dispatch(setError(null)) }}
            />


            <UserLogDialog
                open={openLogs}
                logs={logs}
                onClose={() => setOpenLogs(false)}
            />
        </>
    );
};

export default BusynessProcessFormPage;