import { combineReducers } from "redux";

import roleLevelReducer from "./roleLevelSlice.js";
import userLevelReducer from "./userLevelSlice.js";

const reportReducer = combineReducers({
    roleLevel: roleLevelReducer,
    userLevel: userLevelReducer
});

export default reportReducer;
