import React from "react";
import { useIntl } from "react-intl";
import { Box, IconButton, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { HamburgerIcon, HomeIcon } from "../icons";

const AppDrawerHeader = ({
    className="",
    showHome=false,
    showHamburger=false,
    onHamburgerClick
}) => {
    const intl  = useIntl();
    const title = intl.formatMessage({ id: "common-toolbar.title" });

    return (
        <Box className={`app-drawer-header ${className}`}>
            {showHamburger && (
                <IconButton
                    className="app-drawer-header-button"
                    onClick={onHamburgerClick}
                >
                    <HamburgerIcon className="app-drawer-header-hamburger-icon" />
                </IconButton>
            )}

            {showHome && (
                <IconButton className="app-drawer-header-button">
                    <HomeIcon className="app-drawer-header-home" />
                </IconButton>
            )}

            <Typography className="app-drawer-title">
                {title}
            </Typography>
        </Box>
    );
};

AppDrawerHeader.propTypes = {
    className: PropTypes.string,
    showHome: PropTypes.bool,
    showHamburger: PropTypes.bool,
    onHamburgerClick: PropTypes.func
};

export default AppDrawerHeader;
