import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { Button } from "../../common/buttons";

import "./styles.less";

const ReportModelingHeaderSaveButton = ({ disabled, onClick }) => {
    const intl = useIntl();
    const title = intl.formatMessage({ id: "report-modeling-header.save-title" });

    return (
        <Button
            className="report-modeling-header-save-button"
            variant="contained"
            disabled={disabled}
            onClick={onClick}
        >
            {title}
        </Button>
    );
};

ReportModelingHeaderSaveButton.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func
};

export default ReportModelingHeaderSaveButton;