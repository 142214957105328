import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

import { Button } from "../../common/buttons";
import { CurvedArrowIcon, SmallPlusIcon } from "../../common/icons";

import "./styles.less";

const AddOrgLevelButton = ({ onClick }) => {
    return (
        <Box className="add-org-level-button-wrapper">
            <Button
                className="add-org-level-button"
                variant="contained"
                onClick={onClick}
            >
                <SmallPlusIcon />
            </Button>
            <CurvedArrowIcon className="curved-arrow-icon" />
        </Box>
    );
};

AddOrgLevelButton.propTypes = {
    onClick: PropTypes.func
};

export default AddOrgLevelButton;