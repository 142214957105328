import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";

import { integrationService } from "../../../services/integration-service";

import SearchHelpTable from "../../common/search-helps/search-help-table.jsx";
import useScrollPagination from "../../common/hooks/useScrollPagination";

const getRowId = row => row.role;

const PersistedRolesTable = ({
    columns,
    systemId,
    searchString,
    onRowClick,
    showSingleRoles,
    showManual,
}) => {
    const getData = useCallback(async (page, signal) => {
        const params = {
            page,
            limit: 100,
            search: searchString,
            filters: [
                { field: "systemId", sign: "EQ", value: systemId },
            ]
        };

        if (typeof showManual === "boolean"){
            params.filters.push({field: "manual", sign: "EQ", value: "false"})
        }

        if (showSingleRoles){
            params["filters"].push({ field: "is_complex_role", sign: "EQ", value: "false"})
        }

        return integrationService.getModelingRoles(params, signal);
    }, [systemId, searchString]);

    const {
        items,
        busy,
        handleScroll
    } = useScrollPagination({ open, getData });

    const tableRef = useRef();

    return (
        <SearchHelpTable
            rows={items}
            columns={columns}
            onRowClick={onRowClick}
            onScroll={handleScroll}
            busy={busy}
            selectOneRow
            listContainerRef={tableRef}
            getRowId={getRowId}
        />
    )
};

PersistedRolesTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    systemId: PropTypes.string,
    searchString: PropTypes.string,
    onRowClick: PropTypes.func,
    showSingleRoles: PropTypes.bool,
    showManual: PropTypes.any
};

export default PersistedRolesTable;