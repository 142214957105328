import React from "react";
import { useIntl } from "react-intl";

import { SearchInput } from "../form-controls";

const AppDrawerSearch = () => {
    const intl = useIntl();
    const searchTitle = intl.formatMessage({ id: "common.search-input.title" });

    return (
        <SearchInput
            className="app-drawer-search"
            iconColor="var(--font-1)"
            placeholder={searchTitle} 
        />
    );
};

export default AppDrawerSearch;
