import React from "react";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";

import { Button } from "../buttons";

import { CommonDialog, CommonDialogTitle, CommonDialogContent, CommonDialogActions } from "./common-dialog.jsx";

import "./style.less";

const getButton = ({text, type, onClick}) => {
    switch(type){
        case "apply":
            return (
                <Button
                    key={type}
                    variant="contained"
                    onClick={onClick}
                    sx={{
                        color: 'var(--bg-2)',
                        backgroundColor: 'var(--font-2)',
                        '&:hover': {
                            backgroundColor: 'var(--font-2)',
                        }
                    }}
                >
                    {text}
                </Button>
            )
        default: 
            return (
                <Button
                    key={type}
                    variant="outlined"
                    onClick={onClick}
                    sx={{
                        color: 'var(--font-4)',
                        borderColor: 'var(--font-4)',
                    }}
                >
                    {text}
                </Button>
            )
    }
}

const SubmitDialog = ({ title, open, message, onCancelClick,buttons, ...props }) => {
    return(
        <CommonDialog open={!!open} className="submit-dialog" {...props}>
            <CommonDialogTitle
                title={title}
                onClose={onCancelClick}
            />

            <CommonDialogContent>
                <Typography component="p" className="submit-dialog-text">
                    {message}
                </Typography>
            </CommonDialogContent>

            <CommonDialogActions>
                {
                    buttons && buttons.map(btn => getButton(btn))
                }
            </CommonDialogActions>
        </CommonDialog>
    )
};

SubmitDialog.propTypes = {
    title: PropTypes.node,
    open: PropTypes.bool,
    message: PropTypes.string,
    onCancelClick: PropTypes.func,
    buttons: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string,
        type: PropTypes.string,
        onClick: PropTypes.func
    })),
};

export default SubmitDialog;
