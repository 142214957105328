import React from "react";
import { SvgIcon } from "@mui/material";

const ReportModelingNodeFolderIcon = (props) => (
    <SvgIcon
        viewBox="0 0 18 18"
        {...props}
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
            fill="none"
        >
            <path
                d="M10.7558 4.96154H14.5794C14.8462 4.96151 15.1102 5.01829 15.3544 5.12826C15.5985 5.23823 15.8176 5.39897 15.9976 5.60024C16.1775 5.8015 16.3145 6.03886 16.3996 6.29719C16.4848 6.55552 16.5163 6.82914 16.4921 7.1006L15.8805 13.966C15.8371 14.4533 15.617 14.9065 15.2632 15.2366C14.9095 15.5667 14.4478 15.7498 13.9687 15.75H4.03147C3.55236 15.7498 3.09063 15.5667 2.73691 15.2366C2.3832 14.9065 2.16305 14.4533 2.11969 13.966L1.50804 7.1006C1.46712 6.64629 1.58331 6.19169 1.83643 5.81579L1.79898 4.96154C1.79898 4.44131 2.00131 3.94238 2.36146 3.57452C2.72161 3.20666 3.21007 3 3.7194 3H7.24529C7.75458 3.00011 8.24297 3.20685 8.60303 3.57473L9.39809 4.38681C9.75815 4.75469 10.2465 4.96143 10.7558 4.96154ZM2.76495 5.07923C2.97044 5.00273 3.19129 4.96154 3.42174 4.96154H8.60303L7.92416 4.26813C7.74413 4.08419 7.49994 3.98082 7.24529 3.98077H3.7194C3.46787 3.98072 3.22636 4.08149 3.0468 4.26141C2.86724 4.44133 2.76397 4.68603 2.75919 4.9429L2.76495 5.07923Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

export default ReportModelingNodeFolderIcon;