import PropTypes from "prop-types";

import { REPORT_MODELING_VALUE_TYPES } from "../../../utils/reports-modeling-utils";

export const permissionObjectPropTypes = PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string
});

export const valuesPropTypes = PropTypes.shape({
    rowId: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.string
});

export const fieldPropTypes = PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    valueType:  PropTypes.oneOf(Object.values(REPORT_MODELING_VALUE_TYPES)),
    values: PropTypes.arrayOf(valuesPropTypes)
});