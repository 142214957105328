import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { lockService } from "../../services/lock-service";

const initialState = {
    locks: [],
    columns: [],
    total: 0,
    selectedIds: [],
    searchString: "",
    pageNumber: 1,
    limitNumber: 10,
    sortData: [],
    filterData: [],
    busy: false,
    error: null,
};

export const fetchLocks = createAsyncThunk(
    "admin/fetchLocks",
    async ({ params }) => {
        const response = await lockService.getLocks(params);
        return response;
    }
);

export const fetchLocksColumns = createAsyncThunk(
    "admin/fetchLocksColumns",
    async () => {
        const response = await lockService.getLocksColumns();
        return response;
    }
);

export const deleteLock = createAsyncThunk(
    "admin/deleteLock",
    async ({ entityId, key }, { rejectWithValue }) => {
        try {
            await lockService.deleteLock(entityId, key);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return { entityId, key };
    }
);

const locksSlice = createSlice({
    name: "locks",
    initialState: initialState,
    reducers: {
        setError(state, action) {
            state.error = action.payload;
        },

        setSelectedIds(state, action) {
            state.selectedIds = action.payload;
        },

        setSearchString(state, action) {
            if (state.searchString !== action.payload) {
                state.pageNumber = 1;
                state.searchString = action.payload;
            }
        },

        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },

        setLimitNumber(state, action) {
            state.pageNumber = 1;
            state.limitNumber = action.payload;
        },

        setSortData(state, action) {
            state.pageNumber = 1;
            state.sortData = action.payload;
        },

        setFilterData(state, action) {
            state.pageNumber = 1;
            state.filterData = action.payload;
        },
    },
    extraReducers: {
        //get all locks
        [fetchLocks.pending]: (state) => {
            state.busy = true;
        },

        [fetchLocks.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },

        [fetchLocks.fulfilled]: (state, action) => {
            state.busy = false;
            state.locks = action.payload.locks;
            state.total = action.payload.total;
            state.error = null;
        },

        //delete lock
        [deleteLock.fulfilled]: (state, action) => {
            state.busy = false;
            state.error = null;

            const { entityId, key } = action.payload;
            state.locks = state.locks.filter(lock => lock.entityId !== entityId || lock.key !== key);
        },

        [deleteLock.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },

        [deleteLock.pending]: (state) => {
            state.busy = true;
        },

        [fetchLocksColumns.pending]: (state) => {
            state.busy = true;
        },

        [fetchLocksColumns.fulfilled]: (state, action) => {
            state.busy = false;
            state.columns = action.payload;
        },

        [fetchLocksColumns.rejected]: (state, action) => {
            state.busy = false;
            state.errors = action.error;
        },
    }
});


export const {
    setError, setSelectedIds, setSearchString, setPageNumber, setLimitNumber,
    setSortData, setFilterData
} = locksSlice.actions;

export default locksSlice.reducer;
