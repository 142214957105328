import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, CircularProgress, RadioGroup, Typography, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";

import { resetTestConnectionStatus, testSystemConnection } from "../../../../../reducers/admin/systemsSlice";
import { CHECK_STATUS_ERROR, CHECK_STATUS_NONE, CHECK_STATUS_PENDING, CHECK_STATUS_SUCCESS } from "../../../../../reducers/admin/systemsSlice";

import { InputLabel, RadioInput, FormControl, TextInput } from "../../../../common/form-controls";
import { Button } from "../../../../common/buttons";
import { ErrorIcon, PinIcon, SuccessIcon } from "../../../../common/icons";
import { ADD_MODE, EDIT_MODE, EntityFormSubtitle } from "../../../../common/entity-form";
import { PageContentBox } from "../../../../common/page-content-box";
import breakpoints from "../../../../common/styles/breakpoints.js";
import { SYSTEM_TYPES, AUTH_TYPES } from "./system-form-page.jsx";

const AuthTypeInput = ({ authTypes, authTypeId, setAuthTypeId, systemTypeId, shrink }) => {
    let authTypesCurrent = authTypes

    if (systemTypeId === SYSTEM_TYPES.FC){
        authTypesCurrent = authTypes.filter(item => item.id === AUTH_TYPES.HTTP)
    }

    return (
        <FormControl variant="standard">
            <InputLabel htmlFor="authTypeIdInput" shrink={shrink}>
                <FormattedMessage id="system-form-page-auth.input-integration-type" />
            </InputLabel>

            <RadioGroup
                row
                value={authTypeId}
                onChange={(event) => setAuthTypeId(event.target.value)}
                id="authTypeIdInput"
                className="system-form-page-auth-type-group"
            >
                {
                    authTypesCurrent.map(authType => (
                        <RadioInput
                            key={authType.id}
                            value={authType.id}
                            label={authType.text}
                        />
                    ))
                }
            </RadioGroup>
        </FormControl>
    );
};

const AuthInput = ({
    form,
    setForm,
    field,
    label,
    disabled,
    error,
    inputType="text",
    shrink
}) => {
    return (
        <TextInput
            fullWidth={shrink}
            shrink={shrink}
            label={label}
            value={form[field]}
            type={inputType}
            error={error?.value === form[field]}
            errorMessage={error?.message}
            disabled={disabled}
            onChange={(event) => setForm({ ...form, [field]: event.target.value })}
            className="system-form-page-auth-input"
        />
    );
};

const AuthRfcInputs = ({ form, setForm, disabled, validationErrors, shrink }) => {
    const intl = useIntl();
    return (
        <>
            <AuthInput
                form={form}
                setForm={setForm}
                disabled={disabled}
                field="host"
                error={validationErrors?.host}
                label={intl.formatMessage({ id: "system-form-page-auth.input-host" })}
                shrink={shrink}
            />

            <AuthInput
                form={form}
                setForm={setForm}
                disabled={disabled}
                field="instanceNumber"
                error={validationErrors?.instance_number}
                label={intl.formatMessage({ id: "system-form-page-auth.input-instance-number" })}
                shrink={shrink}
            />

            <AuthInput
                form={form}
                setForm={setForm}
                disabled={disabled}
                field="sapSystemId"
                error={validationErrors?.sap_system_id}
                label={intl.formatMessage({ id: "system-form-page-auth.input-sap-system-id" })}
                shrink={shrink}
            />

            <AuthInput
                form={form}
                setForm={setForm}
                disabled={disabled}
                field="sapRouter"
                error={validationErrors?.sap_router}
                label={intl.formatMessage({ id: "system-form-page-auth.input-saprouter" })}
                shrink={shrink}
            />

            <AuthInput
                form={form}
                setForm={setForm}
                disabled={disabled}
                field="mandt"
                error={validationErrors?.mandt}
                label={intl.formatMessage({ id: "system-form-page-auth.input-mandt" })}
                shrink={shrink}
            />
        </>
    );
};

const AuthTestProgress = ({ status }) => {
    const intl = useIntl();

    const getTitle = () => {
        if (status === CHECK_STATUS_PENDING) {
            return intl.formatMessage({ id: "system-form-page-auth.test-status-pending.text" })
        }

        if (status === CHECK_STATUS_SUCCESS) {
            return intl.formatMessage({ id: "system-form-page-auth.test-status-success.text" })
        }

        if (status === CHECK_STATUS_ERROR) {
            return intl.formatMessage({ id: "system-form-page-auth.test-status-error.text" })
        }
    };

    const getIcon = () => {
        if (status === CHECK_STATUS_PENDING) {
            return (
                <CircularProgress
                    sx={{ color: "var(--font-4)" }}
                    size="24px"
                />
            )
        }

        if (status === CHECK_STATUS_SUCCESS) {
            return <SuccessIcon color="var(--font-4)" />;
        }

        if (status === CHECK_STATUS_ERROR) {
            return <ErrorIcon color="var(--font-4)" />;
        }

    };

    return (
        <Box className="system-form-page-auth-test-progress">
            <PinIcon className="system-form-page-auth-test-progress-pin" />
            <Box className="system-form-page-auth-test-progress-content">
                {getIcon()}
                <Typography className="system-form-page-auth-test-progress-text">
                    {getTitle()}
                </Typography>
            </Box>
        </Box>
    );
};

const SystemFormPageAuth = ({ form, setForm, disabled, validationErrors, mode }) => {
    const matches1024 = useMediaQuery(`(min-width:  ${breakpoints.minLaptopBreakpoint})`);
    const shrinkInputs = !matches1024;

    const intl = useIntl();
    const dispatch = useDispatch();
    const checkStatus = useSelector(state => state.admin.systems.testConnectionStatus);

    const setAuthTypeId = value => {
        if (checkStatus != CHECK_STATUS_PENDING) {
            setForm({
                ...form,
                authTypeId: value,
            });

            dispatch(resetTestConnectionStatus());
        }
    };

    const handleAuthTestClick = () => {
        dispatch(testSystemConnection({
            ...form,
            password: mode === ADD_MODE ? form.password : null,
            editFlag: mode === EDIT_MODE 
        }));
    };

    return (
        <PageContentBox className="system-form-page-auth">
            <EntityFormSubtitle>
                <FormattedMessage id="system-form-page-auth.title" />
            </EntityFormSubtitle>

            <Box className="system-form-page-auth-inputs">
                <AuthTypeInput
                    authTypes={form.authTypes}
                    authTypeId={form.authTypeId}
                    systemTypeId={form.systemTypeId}
                    setAuthTypeId={setAuthTypeId}
                    shrink={shrinkInputs}
                />

                {form.authTypeId === "RFC" && (
                    <AuthRfcInputs
                        form={form}
                        setForm={setForm}
                        disabled={disabled}
                        validationErrors={validationErrors}
                        shrink={shrinkInputs}
                    />
                )}

                {form.authTypeId === "HTTP" && (
                    <AuthInput
                        form={form}
                        setForm={setForm}
                        disabled={disabled}
                        field="url"
                        error={validationErrors && validationErrors["url"]}
                        label={intl.formatMessage({ id: "system-form-page-auth.input-url" })}
                        shrink={shrinkInputs}
                    />
                )}

                <AuthInput
                    form={form}
                    setForm={setForm}
                    disabled={disabled}
                    field="login"
                    error={validationErrors && validationErrors["login"]}
                    label={intl.formatMessage({ id: "system-form-page-auth.input-login" })}
                    shrink={shrinkInputs}
                />

                {mode === ADD_MODE && (
                    <AuthInput
                        form={form}
                        setForm={setForm}
                        disabled={disabled}
                        field="password"
                        error={validationErrors && validationErrors["password"]}
                        label={intl.formatMessage({ id: "sign-in-form.password" })}
                        inputType="password"
                        shrink={shrinkInputs}
                    />
                )}
            </Box>

            <Box className="system-form-page-auth-test">
                <Button
                    variant="contained"
                    onClick={handleAuthTestClick}
                    sx={{
                        width: "100%",
                        backgroundColor: "var(--font-2)",
                        "&:hover": {
                            backgroundColor: "var(--font-2)"
                        },
                        [`@media (min-width: ${breakpoints.minLaptopBreakpoint})`]: {
                            width: "auto"
                        }
                    }}
                >
                    <FormattedMessage id="system-form-page-auth.btn-run-test-connection" />
                </Button>

                {checkStatus && checkStatus !== CHECK_STATUS_NONE && (
                    <AuthTestProgress status={checkStatus} />
                )}
            </Box>
        </PageContentBox>
    );
};

AuthTypeInput.propTypes = {
    authTypes: PropTypes.arrayOf(PropTypes.object),
    authTypeId: PropTypes.string,
    systemTypeId: PropTypes.string,
    setAuthTypeId: PropTypes.func,
    shrink: PropTypes.bool
};

AuthInput.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func,
    field: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.object,
    inputType: PropTypes.string,
    shrink: PropTypes.bool
};

AuthRfcInputs.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func,
    disabled: PropTypes.bool,
    validationErrors: PropTypes.object,
    shrink: PropTypes.bool
};

AuthTestProgress.propTypes = {
    status: PropTypes.string,
};

SystemFormPageAuth.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func,
    disabled: PropTypes.bool,
    validationErrors: PropTypes.object,
    mode: PropTypes.string,
};

export default SystemFormPageAuth;