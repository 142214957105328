import React from "react";
import { Outlet } from "react-router-dom";

import { NavigationTabs } from "../../common/tabs";


const tabs = [
    {
        id: "systems",
        titleId: "transaction-settings-page.tabs.systems.title",
        route: "systems"
    },
    {
        id: "system-groups",
        titleId: "transaction-settings-page.tabs.system-groups.title",
        route: "system-groups"
    },
];

const  TransactionSettingsPage = () => {
    return(
        <>
            <NavigationTabs tabs={tabs} />
            <Outlet />
        </>
    );
};

export default TransactionSettingsPage;
