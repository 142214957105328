import React from "react";
import { useIntl } from "react-intl";

import ReportModelingEditDialogTable from "./report-modeling-edit-dialog-table.jsx";

import "./styles.less";

const getRowId = row => row.key;

const ReportModelingEditDialogTableSelect = ({
    rows,
    selectedIds,
    onSelectRow,
    onSelectAll
}) => {
    const intl = useIntl();

    const columns = [
        {
            id: "select",
            type: "select",
        },
        {
            id: "key",
            title: intl.formatMessage({ id: "report-modeling-edit-dialog.select-table.columns.key" }),
            type: "text",
        },
        {
            id: "text",
            title: intl.formatMessage({ id: "report-modeling-edit-dialog.select-table.columns.text" }),
            type: "text",
        }
    ];

    const toolbarActions = [
        {
            id: "title",
            title: intl.formatMessage({ id: "report-modeling-edit-dialog.select-table.title" }),
            type: "text",
            position: "begin"
        }
    ];

    return (
        <ReportModelingEditDialogTable
            className="report-modeling-edit-dialog-table-select"
            columns={columns}
            rows={rows}
            toolbarActions={toolbarActions}
            selectedIds={selectedIds}
            onSelectRow={onSelectRow}
            onSelectAll={onSelectAll}
            getRowId={getRowId}
            virtualize
        />
    );
};

ReportModelingEditDialogTableSelect.propTypes = {
    rows: ReportModelingEditDialogTable.propTypes.rows,
    selectedIds: ReportModelingEditDialogTable.propTypes.selectedIds,
    onSelectRow: ReportModelingEditDialogTable.propTypes.onSelectRow,
    onSelectAll: ReportModelingEditDialogTable.propTypes.onSelectAll,
};

export default ReportModelingEditDialogTableSelect;