import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

import { TRAFFIC_LIGHT_STATUSES } from "../../../utils/traffic-light";

import "./styles.less";

const statuses = Object.values(TRAFFIC_LIGHT_STATUSES);

const TraffictLightItem = ({ status, active }) => {
    let className = `traffic-light-item traffic-light-item-${status}`;

    if (active) {
        className += " traffic-light-item-active";
    }

    return (
        <Box className={className} />
    )
};

const TrafficLight = ({ status }) => {
    return (
        <Box className="traffic-light">
            {statuses.map(statusItem => (
                <TraffictLightItem
                    key={statusItem}
                    status={statusItem}
                    active={statusItem === status}
                />
            ))}
        </Box>
    );
};

TraffictLightItem.propTypes = {
    status: PropTypes.oneOf(statuses),
    active: PropTypes.bool
};

TrafficLight.propTypes = {
    status: PropTypes.oneOf(statuses)
};

export default TrafficLight;