import React from "react";
import { SvgIcon } from "@mui/material";

const MinusIcon = (props) => {
    return(
        <SvgIcon
            viewBox="0 0 18 18"
            {...props}
        >
            <svg
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="14.4004"
                    y="7.75"
                    width="2.49231"
                    height="10.8"
                    transform="rotate(90 14.4004 7.75)"
                    fill="currentColor"
                />
            </svg>
        </SvgIcon>
    )
};

export default MinusIcon;
