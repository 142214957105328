import React from "react";
import { List, ListItemButton, ListItemText, Popover } from "@mui/material";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const statusItems = [
    {
        key: "true",
        value: true,
        text: <FormattedMessage id="common.table.active.true" />
    },
    {
        key: "false",
        value: false,
        text: <FormattedMessage id="common.table.active.false" />
    }
];

const PermissionsMassStatusPopover = ({ anchorEl, onClose, onSelect }) => {
    const open = Boolean(anchorEl);

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            sx={{
                ".MuiPopover-paper": {
                    width: anchorEl.clientWidth
                }
            }}
        >
            <List>
                {statusItems.map(statusItem => (
                    <ListItemButton
                        key={statusItem.key}
                        onClick={() => onSelect(statusItem.value)}
                    >
                        <ListItemText>
                            {statusItem.text}
                        </ListItemText>
                    </ListItemButton>
                ))}
            </List>
        </Popover>
    );
};

PermissionsMassStatusPopover.propTypes = {
    anchorEl: PropTypes.any,
    onClose: PropTypes.func,
    onSelect: PropTypes.func
};

export default PermissionsMassStatusPopover;