import React from "react";
import { SvgIcon } from "@mui/material";

const RightScrollButtonIcon = (props) => (
    <SvgIcon
        viewBox="0 0 8 14"
        {...props}
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 8 14"
            fill="none"
        >
            <path
                d="M8.08231 6.99633C8.08231 6.71578 7.97967 6.48313 7.7607 6.26416L2.4918 1.11159C2.31389 0.933675 2.10176 0.851562 1.84858 0.851562C1.33538 0.851562 0.917969 1.25528 0.917969 1.76849C0.917969 2.02167 1.02745 2.25432 1.21221 2.43908L5.89263 6.98949L1.21221 11.5536C1.02745 11.7383 0.917969 11.9642 0.917969 12.2242C0.917969 12.7374 1.33538 13.1479 1.84858 13.1479C2.10176 13.1479 2.31389 13.059 2.4918 12.8811L7.7607 7.72851C7.98651 7.50954 8.08231 7.27689 8.08231 6.99633Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

export default RightScrollButtonIcon;
