import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types'; 

import TabsListItemParent from './tabs-list-item-parent.jsx';
import TabsListItemLeaf from './tabs-llist-item-leaf.jsx';

const TabsListItem = ({ tab, activeTab, onLeafClick, onParentClick }) => {
    return (
        <Box className="tabs-list-item">
            <TabsListItemParent
                tab={tab}
                onClick={onParentClick}
                activeTab={activeTab}
            />

            {tab.open && tab.children?.map(child =>
                <TabsListItemLeaf
                    key={child.id}
                    currentTab={child}
                    activeTab={activeTab}
                    handleLeafTabClick={onLeafClick}
                />
            )}
        </Box>
    );
};

TabsListItem.propTypes = {
    tab: PropTypes.object,
    activeTab: PropTypes.object,
    onLeafClick: PropTypes.func,
    onParentClick: PropTypes.func
};

export default TabsListItem;
