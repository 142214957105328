import React from 'react';
import PropTypes from 'prop-types';

import { EDIT_MODE, EntityFormActions, EntityFormHeader } from "../../../common/entity-form";

import MatrixHeadersFormHeaderTitle from "./matrix-headers-form-header-title.jsx";
import SyncWithSapButton from '../../../common/buttons/sync-with-sap-button.jsx';
import DownloadmatrixButton from '../../../common/buttons/download-matrix-button.jsx';

const MatrixHeadersFormHeader = ({
    mode,
    matrixHeaderId,
    onSaveClick,
    onBackClick,
    onEditClick,
    onDeleteClick,
    onShowLogsClick,
    onSyncMatrixClick,
    onDownloadMatrixClick
}) => {
    return (
        <EntityFormHeader>
            <MatrixHeadersFormHeaderTitle
                matrixHeaderId={matrixHeaderId}
                mode={mode}
                onBackClick={onBackClick}
            />

            <EntityFormActions
                mode={mode}
                onDeleteClick={onDeleteClick}
                onSaveClick={onSaveClick}
                onEditClick={onEditClick}
                onShowLogsClick={onShowLogsClick}
                customActions={mode === EDIT_MODE && (
                    <>
                        <SyncWithSapButton
                            onClick={onSyncMatrixClick}
                        />
                        <DownloadmatrixButton
                            onClick={onDownloadMatrixClick}
                        />
                    </>
                )}
            />
        </EntityFormHeader>
    );
};

MatrixHeadersFormHeader.propTypes = {
    mode: PropTypes.string,
    matrixHeaderId: PropTypes.string,
    onSaveClick: PropTypes.func,
    onBackClick: PropTypes.func,
    onEditClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onShowLogsClick: PropTypes.func,
    onSyncMatrixClick: PropTypes.func,
    onDownloadMatrixClick: PropTypes.func
}

export default MatrixHeadersFormHeader;
