import { Button as MuiButton, styled } from "@mui/material";

const Button = styled(MuiButton)({
    padding: '7px 8px 8px 8px',
    borderRadius: 4,
    textTransform: 'none',
    boxShadow: 'none',
    fontFamily: 'Mulish',
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '150%',

    '&.MuiButton-outlined': {
        paddingTop: '6px',
        paddingBottom: '7px',
    },

    '&:hover': {
        backgroundColor: 'initial',
        borderColor: 'initial'
    },

    '&:has(.MuiButton-startIcon), &:has(.MuiButton-endIcon)': {
        padding: '7px 16px 8px'
    },

    '& .MuiButton-startIcon': {
        marginRight: 4,
    },

    '& .MuiButton-endIcon': {
        marginLeft: 4,
    }
});

export default Button;
