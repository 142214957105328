import React from "react";
import { SvgIcon } from "@mui/material";

const ClassBuilderIcon = (props) => (
    <SvgIcon
        viewBox="0 0 18 18"
        {...props}
    >
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.991304 4.07391C0.991304 3.52163 1.43902 3.07391 1.9913 3.07391H4.0087C4.56098 3.07391 5.0087 3.52163 5.0087 4.07391V6.42609C5.0087 6.97837 4.56098 7.42609 4.0087 7.42609H1.9913C1.43902 7.42609 0.991304 6.97837 0.991304 6.42609V4.07391Z"
                fill="currentColor"
            />
            <path
                d="M6.9913 4.07391C6.9913 3.52163 7.43902 3.07391 7.9913 3.07391H10.0087C10.561 3.07391 11.0087 3.52163 11.0087 4.07391V6.42609C11.0087 6.97837 10.561 7.42609 10.0087 7.42609H7.9913C7.43902 7.42609 6.9913 6.97837 6.9913 6.42609V4.07391Z"
                fill="currentColor"
            />
            <path
                d="M6.9913 10.5739C6.9913 10.0216 7.43902 9.57391 7.9913 9.57391H10.0087C10.561 9.57391 11.0087 10.0216 11.0087 10.5739V12.9261C11.0087 13.4784 10.561 13.9261 10.0087 13.9261H7.9913C7.43902 13.9261 6.9913 13.4784 6.9913 12.9261V10.5739Z"
                fill="currentColor"
            />
            <path
                d="M12.9913 10.5739C12.9913 10.0216 13.439 9.57391 13.9913 9.57391H16.0087C16.561 9.57391 17.0087 10.0216 17.0087 10.5739V12.9261C17.0087 13.4784 16.561 13.9261 16.0087 13.9261H13.9913C13.439 13.9261 12.9913 13.4784 12.9913 12.9261V10.5739Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

export default ClassBuilderIcon