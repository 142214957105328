import { useCallback } from "react";

import { usersService } from "../../../services/users-service";
import { criticalRuleService } from "../../../services/critical-rule-service";
import { lockService } from "../../../services/lock-service";
import { reportsService } from "../../../services/reports-service";
import { systemsService } from "../../../services/systems-service";
import { systemGroupsService } from "../../../services/system-groups-service";
import { riskLevelsService } from "../../../services/risk-levels-service";
import { functionsService } from "../../../services/functions-service";
import { risksService } from "../../../services/risks-service";
import { matrixService } from "../../../services/matrix-service";
import { busynessProcessesService } from "../../../services/busyness-processes-service";

const useFilters = (entity, entityParams) => {
    const getFilters = useCallback(
        (params) => {
            switch(entity){
                case "system":
                    return systemsService.getSystemFilters(params);
    
                case "system-group":
                    return systemGroupsService.getGroupsFilters(params);
    
                case "function":
                    return functionsService.getFunctionsFilters(params);
    
                case "matrix-header":
                    return matrixService.getMatrixHeadersFilters(params);
    
                case "risk":
                    return risksService.getRisksFilters(params);
    
                case "risk-levels":
                    return riskLevelsService.getRiskLevelsFilters(params);
                
                case "critical-role":
                    return criticalRuleService.getCriticalRoleFilters(params);
                
                case "critical-profile":
                    return criticalRuleService.getCriticalProfileFilters(params);
    
                case "user":
                    return usersService.getUsersFilters(params);

                case "lock":
                    return lockService.getLocksFilters(params);

                case "user-report":
                    return reportsService.getUserReportFilters(params, entityParams);
                
                case "role-report":
                    return reportsService.getRoleReportFilters(params, entityParams);
                    
                case "busynessProcess":
                    return busynessProcessesService.getBusynessProcessFilters(params, entityParams)

                case "user-busyness-report":

                    return reportsService.getUserBusynessReportFilters(params, entityParams)
                case "user-busyness-detailed-report":

                    return reportsService.getUserBusynessDetailedReportFilters(params, entityParams)
            }
        },
        [entity, entityParams]
    );

    return getFilters;
};

export default useFilters;
