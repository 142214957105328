import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { ADD_MODE, EDIT_MODE, READ_MODE, EntityFormHeaderTitle } from "../../../common/entity-form";

const FunctionFormHeaderTitle = ({
    functionId,
    mode,
    onBackClick
}) => {
    const intl = useIntl();

    const titleValues ={ functionId };

    const titleMap = {
        [ADD_MODE]: intl.formatMessage({ id: "function-form-page-header.new.title" }),
        [EDIT_MODE]: intl.formatMessage({ id: "function-form-page-header.edit.title" }, titleValues),
        [READ_MODE]: intl.formatMessage({ id: "function-form-page-header.read.title" }, titleValues),
    };

    return (
        <EntityFormHeaderTitle
            title={titleMap[mode]}
            onBackClick={onBackClick}
        />
    );
};

FunctionFormHeaderTitle.propTypes = {
    functionId: PropTypes.string,
    mode: PropTypes.string,
    onBackClick: PropTypes.func
};

export default FunctionFormHeaderTitle;
