import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { Button } from "../../common/buttons";
import { TextInput } from "../../common/form-controls";
import { CommonDialog, CommonDialogActions, CommonDialogContent, CommonDialogTitle } from "../../common/dialogs";

import "./styles.less";

const VariantNameInput = ({ name, setName, error }) => {
    const intl = useIntl();

    return(
        <TextInput
            label={intl.formatMessage({ id: "save-variant-dialog.input.variant-name" })}
            value={name}
            onChange={(event) => setName(event.target.value)}
            error={error?.value === name}
            errorMessage={error?.message}
        />
    );
};

const SaveVariantDialog = ({ defaultName="", validationErrors, onSaveClick, onCancelClick, ...props }) => {
    const intl = useIntl();

    const [name, setName] = useState(defaultName);

    const handleSaveClick = () => {
        const trimmedName = name.trim();

        if (name !== trimmedName) {
            setName(trimmedName);
        }

        onSaveClick(trimmedName);
    };

    const title = intl.formatMessage({ id: "save-variant-dialog.title" });
    const cancelTitle = intl.formatMessage({ id: "common.btn-cancel" });
    const saveTitle = intl.formatMessage({ id: "common.btn-save" });

    const variantNameError = validationErrors?.find(error => error.field === "variant_name");

    return(
        <CommonDialog
            className="save-variant-dialog"
            {...props}
        >
            <CommonDialogTitle
                title={title}
                onClose={onCancelClick}
            />

            <CommonDialogContent>
                <VariantNameInput
                    name={name}
                    setName={setName}
                    error={variantNameError}
                />
            </CommonDialogContent>

            <CommonDialogActions>
                <Button
                    className="save-variant-dialog-button-cancel"
                    variant="outlined"
                    onClick={onCancelClick}
                >
                    {cancelTitle}
                </Button>
                <Button
                    className="save-variant-dialog-button-save"
                    variant="contained"
                    onClick={handleSaveClick}
                >
                    {saveTitle}
                </Button>
            </CommonDialogActions>
        </CommonDialog>
    )
};

VariantNameInput.propTypes = {
    name: PropTypes.string,
    setName: PropTypes.func,
    error: PropTypes.object
};

SaveVariantDialog.propTypes = {
    defaultName: PropTypes.string,
    validationErrors: PropTypes.arrayOf(PropTypes.object),
    onSaveClick: PropTypes.func,
    onCancelClick: PropTypes.func
};

export default SaveVariantDialog;
