import React, { forwardRef } from "react";
import { InputBase } from "@mui/material";
import PropTypes from "prop-types";

import InputEndAdornment from "./input-end-adornment.jsx";

import "./styles.less";

export const getInputClassName = ({ className, error, warning }) => {
    const classNames = ["input"];

    if (className) classNames.push(className);

    if (error) classNames.push("input-error");

    if (warning) classNames.push("input-warning");

    return classNames.join(" ");
};

const Input = forwardRef(({
    className,
    error=false,
    errorMessage,
    warning=false,
    warningMessage,
    showInnerHelpers=false,
    endAdornment,
    ...rest
}, ref) => {
    const classNameFull = getInputClassName({
        className,
        error,
        warning
    });

    return (
        <InputBase 
            ref={ref}
            className={classNameFull}
            endAdornment={
                <InputEndAdornment
                    error={error}
                    errorMessage={errorMessage}
                    warning={warning}
                    warningMessage={warningMessage}
                    showInnerHelpers={showInnerHelpers}
                    endAdornment={endAdornment}
                />
            }
            {...rest}
        />
    );
});

Input.propTypes = {
    InputComponent: PropTypes.elementType,
    className: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    warning: PropTypes.bool,
    warningMessage: PropTypes.string,
    showInnerHelpers: PropTypes.bool,
    endAdornment: PropTypes.node
};

export default Input;
