import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import Button from './button.jsx';

const ChangePasswordButton = ({ onClick }) => {
    const intl = useIntl();
    const text = intl.formatMessage({ id: "user-form-page.change-password-btn" });

    return (
        <Button
            variant="outlined"
            sx={{
                borderColor: "var(--font-4)",
                color: "var(--font-4)",
            }}
            onClick={onClick}
        >
            {text}
        </Button>
    );
};

ChangePasswordButton.propTypes = {
    onClick: PropTypes.func,
};

export default ChangePasswordButton;
