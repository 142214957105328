import React from "react";
import { SvgIcon } from "@mui/material";

const SearchPlusIcon = (props) => (
    <SvgIcon
        viewBox="0 0 18 19"
        {...props}
    >
        <svg
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.65078 13.1008C8.30082 13.1008 8.9445 12.9727 9.54506 12.724C10.1456 12.4752 10.6913 12.1106 11.151 11.651C11.6106 11.1913 11.9752 10.6456 12.224 10.0451C12.4727 9.4445 12.6008 8.80082 12.6008 8.15078C12.6008 7.50074 12.4727 6.85706 12.224 6.2565C11.9752 5.65594 11.6106 5.11025 11.151 4.6506C10.6913 4.19095 10.1456 3.82634 9.54506 3.57758C8.9445 3.32882 8.30082 3.20078 7.65078 3.20078C6.33796 3.20078 5.07891 3.7223 4.1506 4.6506C3.2223 5.57891 2.70078 6.83796 2.70078 8.15078C2.70078 9.4636 3.2223 10.7227 4.1506 11.651C5.07891 12.5793 6.33796 13.1008 7.65078 13.1008ZM13.5008 8.15078C13.5008 9.7023 12.8844 11.1903 11.7874 12.2874C10.6903 13.3844 9.2023 14.0008 7.65078 14.0008C6.09927 14.0008 4.61129 13.3844 3.51421 12.2874C2.41712 11.1903 1.80078 9.7023 1.80078 8.15078C1.80078 6.59927 2.41712 5.11129 3.51421 4.01421C4.61129 2.91712 6.09927 2.30078 7.65078 2.30078C9.2023 2.30078 10.6903 2.91712 11.7874 4.01421C12.8844 5.11129 13.5008 6.59927 13.5008 8.15078Z"
                fill="currentColor"
            />
            <path
                d="M11.1094 12.8685C11.1364 12.9045 11.1652 12.9387 11.1976 12.972L14.6626 16.437C14.8313 16.6059 15.0603 16.7008 15.299 16.7009C15.5378 16.7009 15.7667 16.6062 15.9356 16.4374C16.1045 16.2687 16.1994 16.0397 16.1995 15.801C16.1996 15.5622 16.1048 15.3333 15.9361 15.1644L12.4711 11.6994C12.4389 11.6668 12.4043 11.6367 12.3676 11.6094C12.0145 12.0908 11.5903 12.5156 11.1094 12.8694V12.8685Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.65 5C7.76935 5 7.88381 5.04741 7.9682 5.1318C8.05259 5.21619 8.1 5.33065 8.1 5.45V7.7H10.35C10.4693 7.7 10.5838 7.74741 10.6682 7.8318C10.7526 7.91619 10.8 8.03065 10.8 8.15C10.8 8.26935 10.7526 8.38381 10.6682 8.4682C10.5838 8.55259 10.4693 8.6 10.35 8.6H8.1V10.85C8.1 10.9693 8.05259 11.0838 7.9682 11.1682C7.88381 11.2526 7.76935 11.3 7.65 11.3C7.53065 11.3 7.41619 11.2526 7.3318 11.1682C7.24741 11.0838 7.2 10.9693 7.2 10.85V8.6H4.95C4.83065 8.6 4.71619 8.55259 4.6318 8.4682C4.54741 8.38381 4.5 8.26935 4.5 8.15C4.5 8.03065 4.54741 7.91619 4.6318 7.8318C4.71619 7.74741 4.83065 7.7 4.95 7.7H7.2V5.45C7.2 5.33065 7.24741 5.21619 7.3318 5.1318C7.41619 5.04741 7.53065 5 7.65 5Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

export default SearchPlusIcon;