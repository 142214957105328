import React, { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useMediaQuery } from "@mui/material";
import PropTypes from 'prop-types'; 

import { getScheduleTypeTextWithIntl } from "../../../../utils/integration-utils";
import { calcTableHeight } from "../../../../utils/table";

import { EditIcon, TrashIcon } from "../../../common/icons";
import { CommonDialog, CommonDialogContent, CommonDialogTitle } from "../../../common/dialogs";
import CommonTable from "../../../common/table";
import breakpoints from "../../../common/styles/breakpoints";

import "./style.less";

const getCellValue = (intl, row, column) => {
    switch (column.id) {
        case "typeText":
            return getScheduleTypeTextWithIntl(intl, row.type);

        default:
            return row[column.id];
    }
};

const ScheduleItemTable = ({
    rows,
    onScheduleAction
}) => {
    const intl = useIntl();

    const toolbarActions = useMemo(() => [
        {
            id: "search",
            type: "search",
            position: "end",
            callback: (value) => onScheduleAction("search", value)
        },
        {
            id: "add",
            title: <FormattedMessage id="common.btn-add" />,
            callback: () => onScheduleAction("add"),
            position: "end",
            variant: "contained"
        }
    ], [onScheduleAction]);

    const columns = useMemo(() => [{
        id: "name",
        title: intl.formatMessage({ id: "schedule-maintenance-dialog.columns.name" }),
        type: "text",
        width: "30%"
    },
    {
        id: "startDate",
        title: intl.formatMessage({ id: "schedule-maintenance-dialog.columns.startDate" }),
        type: "datetime",
        width: "20%"
    }, {
        id: "typeText",
        title: intl.formatMessage({ id: "schedule-maintenance-dialog.columns.typeText" }),
        type: "text",
        width: "40%"
    }, {
        id: "actions",
        type: "actions",
        title: "",
        actions: [
            {
                id: "edit",
                IconComponent: EditIcon,
                onClick: (_, row) => onScheduleAction("edit", row)
            },
            {
                id: "delete",
                IconComponent: TrashIcon,
                onClick: (_, row) => onScheduleAction("delete", row)
            }
        ]
    }], [onScheduleAction, intl]);

    const getCellValueInner = useCallback((row, column) => {
        return getCellValue(intl, row, column);
    }, [intl]);

    const matches1920 = useMediaQuery(`(min-width: ${breakpoints.minDesktopBreakpoint})`);

    const tableHeight = calcTableHeight({
        headerHeight: matches1920 ? 29 : 19,
        rowHeight: matches1920 ? 58 : 48,
        rowsNumber: 8
    });

    return (
        <CommonTable
            className="integration-schedule-dialog-table"
            rows={rows || []}
            toolbarActions={toolbarActions}
            columns={columns}
            selectedIds={[]}
            showPagination={false}
            showFiltersActions={false}
            virtualize
            virtualizationProps={{
                style: { height: tableHeight }
            }}
            getCellValue={getCellValueInner}
        />
    )
};

const ScheduleMaintenanceDialog = ({ rows, scheduleType, system, onClose, onScheduleAction, open, ...props }) => {
    const intl = useIntl();

    const innerTitle = intl.formatMessage({ id: "integration-page.schedule-planning-dialog.title" }, { system })

    return (
        <CommonDialog
            className="integration-schedule-dialog"
            open={!!open}
            {...props}
        >
            <CommonDialogTitle
                title={innerTitle}
                onClose={onClose}
            />

            <CommonDialogContent>
                <ScheduleItemTable
                    rows={rows}
                    system={system}
                    scheduleType={scheduleType}
                    onScheduleAction={onScheduleAction}
                />
            </CommonDialogContent>
        </CommonDialog>
    )
};

ScheduleItemTable.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object),
    onScheduleAction: PropTypes.func
}

ScheduleMaintenanceDialog.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object),
    scheduleType: PropTypes.string,
    system: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onScheduleAction: PropTypes.func
}


export default ScheduleMaintenanceDialog;
