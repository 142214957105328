import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { reportsService } from "../../services/reports-service.js";
import { REPORT_LEVELS, REPORT_TYPES, REPORT_VARIANT_TYPES } from "../../utils/sodReports.js";
import { BUSY_TYPES } from "../../utils/busy.js";

const initialState = {
    roles: [],
    groups: [],
    systems: [],
    extendedSystems: [],
    matrixHeaders: [],
    risks: [],
    riskLevels: [],
    parametersOpen: true,

    canFetchResults: false,
    resultsOpen: false,
    reportLevel: REPORT_LEVELS.OPERATIONS,
    reportType: REPORT_TYPES.FINAL,
    rows: [],
    columns: [],
    total: 0,
    selectedIds: [],
    searchString: "",
    pageNumber: 1,
    limitNumber: 10,
    sortData: [],
    filterData: [],
    busy: false,
    busyType: null,
    error: null,
    validationErrors: null,

    variantCreated: false,
    variants: [],
    currentVariant: null,
    standardParameters: {
        roles: [],
        systems: [],
        matrixHeaders: [],
        risks: [],
        riskLevels: [],
        extendedSystems: []
    }
};

export const fetchReportColumns = createAsyncThunk(
    "reports/roleLevel/fetchReportColumns",
    async (_, { getState }) => {
        const state = getState();
        const columns = await reportsService.getRoleLevelReportColumns(
            state.reports.roleLevel.reportLevel,
            state.reports.roleLevel.reportType
        );
        return columns;
    }
);

export const fetchReportResults = createAsyncThunk(
    "reports/roleLevel/fetchReportResults",
    async ({ params }, { getState }) => {
        const state = getState();

        if (!state.reports.roleLevel.canFetchResults) {
            return;
        }

        const newParams = {
            ...params,
            "report_level": state.reports.roleLevel.reportLevel,
            "report_type": state.reports.roleLevel.reportType,
        };

        const response = await reportsService.getRoleLevelReportResults(newParams);
        return response;
    }
);

export const calcReport = createAsyncThunk(
    "reports/roleLevel//calcReport",
    async (_, { getState, rejectWithValue }) => {
        const state = getState();

        const systemsForCalc = state.reports.roleLevel.extendedSystems.filter(item => !item.isGroup).map(item => item.id)
        const groupForCalc = state.reports.roleLevel.extendedSystems.filter(item => item.isGroup).map(item => item.id)

        const form = {
            roles: state.reports.roleLevel.roles,
            systems: systemsForCalc,
            groups: groupForCalc,
            matrix_headers: state.reports.roleLevel.matrixHeaders,
            risks: state.reports.roleLevel.risks,
            risk_levels: state.reports.roleLevel.riskLevels
        };
        
        try{
            await reportsService.calRoleLevelReport(form);
        } catch (error){
            return rejectWithValue(error)
        }
    }
);

export const saveVariant = createAsyncThunk(
    "reports/roleLevel/saveVariant",
    async (variantName, { getState, rejectWithValue }) => {
        const state = getState();

        const isVariantNew = !state.reports.roleLevel.variants.some(
            variant => variant.variantName === variantName
        );

        const newParams = {
            "variant_name": variantName,
            "variant_type": REPORT_VARIANT_TYPES.ROLES,
        };

        const systemsForCalc = state.reports.roleLevel.extendedSystems.filter(item => !item.isGroup).map(item => item.id)
        const groupForCalc = state.reports.roleLevel.extendedSystems.filter(item => item.isGroup).map(item => item.id)

        const variantData = {
            "roles": state.reports.roleLevel.roles,
            "systems": systemsForCalc,
            "matrix_headers": state.reports.roleLevel.matrixHeaders,
            "risks": state.reports.roleLevel.risks,
            "risk_levels": state.reports.roleLevel.riskLevels,
            "groups": groupForCalc
        }

        const form = {
            variant_name: variantName,
            variant_data: variantData,
            variant_type: REPORT_VARIANT_TYPES.ROLES
        }

        try {
            return await reportsService.saveVariant(form, newParams, isVariantNew);
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const deleteVariant = createAsyncThunk(
    "reports/roleLevel/deleteVariant",
    async (variantName, { rejectWithValue }) => {
        const newParams = {
            "variant_name": variantName,
            "variant_type": REPORT_VARIANT_TYPES.ROLES,
        };
        
        try {
            return await reportsService.deleteVariant(newParams);
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const setFavoritesForVariant = createAsyncThunk(
    "reports/roleLevel/setFavoritesForVariant",
    async ({ variantName, inFavorites }, { rejectWithValue }) => {
        const form = {
            variant_name: variantName,
            variant_type: REPORT_VARIANT_TYPES.ROLES,
            in_favorites: inFavorites
        };
        
        try {
            return await reportsService.setFavoritesForVariant(form);
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const fetchVariantsByUser = createAsyncThunk(
    "reports/roleLevel/fetchVariantsByUser",
    async (searchString) => {
        const params = {
            variant_type: REPORT_VARIANT_TYPES.ROLES,
        };

        if (searchString) {
            params.search = searchString;
        }

        const response = await reportsService.getVariantsByUser(params);

        return response;
    }
);

export const exportReport = createAsyncThunk(
    "reports/roleLevel/exportReport",
    async (_, { getState, rejectWithValue }) => {
        const state = getState();

        const params = {
            "report_level": state.reports.roleLevel.reportLevel,
            "report_type": state.reports.roleLevel.reportType,
        };

        try {
            await reportsService.exportRoleLevelReport(params);
        } catch (error) {
            rejectWithValue(error);
        }
    }
);

export const compareReport = createAsyncThunk(
    "reports/roleLevel//compareReport",
    async (_, { getState, rejectWithValue }) => {
        const state = getState();

        const systemsForCalc = state.reports.roleLevel.extendedSystems.filter(item => !item.isGroup).map(item => item.id)
        const groupForCalc = state.reports.roleLevel.extendedSystems.filter(item => item.isGroup).map(item => item.id)

        const form = {
            roles: state.reports.roleLevel.roles,
            systems: systemsForCalc,
            matrix_headers: state.reports.roleLevel.matrixHeaders,
            groups: groupForCalc,
            risks: state.reports.roleLevel.risks,
            risk_levels: state.reports.roleLevel.riskLevels
        };

        try {
            await reportsService.compareRoleLevelReport(form);
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const setReportLevelWithColumns = (reportLevel) => (dispatch) => {
    dispatch(setReportLevel(reportLevel));
    dispatch(fetchReportColumns());
};

export const setReportTypeWithColumns = (reportType) => (dispatch) => {
    dispatch(setReportType(reportType));
    dispatch(fetchReportColumns());
};

export const saveReportColumns = createAsyncThunk(
    "reports/roleLevel/saveReportColumns",
    async ({ columns, reportLevel, reportType }) => {
        await reportsService.saveRoleLevelReportColumns(columns, reportLevel, reportType);
        return { columns, reportLevel, reportType };
    }
);

const roleLevelSlice = createSlice({
    name: "roleLevel",
    initialState: initialState,
    reducers: {
        setRoles(state, action) {
            state.roles = action.payload;
        },

        setSystems(state, action) {
            state.systems = action.payload;
        },

        setSystemsExtended(state, action){
            state.extendedSystems = action.payload
        },

        setMatrixHeaders(state, action) {
            state.matrixHeaders = action.payload;
        },

        setRisks(state, action) {
            state.risks = action.payload;
        },

        setRiskLevels(state, action) {
            state.riskLevels = action.payload;
        },

        setParametersOpen(state, action) {
            state.parametersOpen = action.payload
        },

        setResultsOpen(state, action) {
            state.resultsOpen = action.payload;
        },

        setReportLevel(state, action) {
            state.pageNumber = 1;
            state.searchString = "";
            state.filterData = [];
            state.sortData = [];
            state.reportLevel = action.payload;
        },

        setReportType(state, action) {
            state.pageNumber = 1;
            state.searchString = "";
            state.filterData = [];
            state.sortData = [];
            state.reportType = action.payload;
        },

        setError(state, action) {
            state.error = action.payload;
        },

        setSelectedIds(state, action) {
            state.selectedIds = action.payload;
        },

        setSearchString(state, action) {
            if (state.searchString !== action.payload) {
                state.pageNumber = 1;
                state.searchString = action.payload;
            }
        },

        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },

        setLimitNumber(state, action) {
            state.pageNumber = 1;
            state.limitNumber = action.payload;
        },

        setSortData(state, action) {
            state.pageNumber = 1;
            state.sortData = action.payload;
        },

        setFilterData(state, action) {
            state.pageNumber = 1;
            state.filterData = action.payload;
        },

        setCurrentVariant(state, action) {
            if (!state.currentVariant) {
                state.standardParameters.roles = state.roles;
                state.standardParameters.systems = state.systems;
                state.standardParameters.extendedSystems = state.extendedSystems
                state.standardParameters.matrixHeaders = state.matrixHeaders;
                state.standardParameters.risks = state.risks;
                state.standardParameters.riskLevels = state.riskLevels;
            }

            const variant = action.payload;

            const systemsCurrent = variant ? variant.variantData.systems : state.standardParameters.extendedSystems.filter(item => !item.isGroup).map(item => item.id)
            const groupsCurrent = variant ? variant.variantData.groups : state.standardParameters.extendedSystems.filter(item => item.isGroup).map(item => item.id)

            const systemPart = systemsCurrent.map(system => ({
                id: system,
                isGroup: false
            }))

            const groupPart = groupsCurrent.map(group => ({
                id: group,
                isGroup: true
            }))

            state.currentVariant = variant;

            state.roles = variant
                ? variant.variantData.roles
                : state.standardParameters.roles;

            state.systems = variant
                ? variant.variantData.systems
                : state.standardParameters.systems;

            state.extendedSystems = [...systemPart, ...groupPart]

            state.matrixHeaders = variant
                ? variant.variantData.matrixHeaders
                : state.standardParameters.matrixHeaders;

            state.risks = variant
                ? variant.variantData.risks
                : state.standardParameters.risks;

            state.riskLevels = variant
                ? variant.variantData.riskLevels
                : state.standardParameters.riskLevels;
        },

        clearFilters(state) {
            state.selectedIds = [];
            state.searchString = "";
            state.pageNumber = 1;
            state.sortData = [];
            state.filterData = [];
        }
    },
    extraReducers: {
        [fetchReportResults.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.rows = []
        },

        [fetchReportResults.rejected]: (state, action) => {
            const error = action.payload;

            state.busy = false;
            state.error = error;

            if (error.code === "validation") {
                state.validationErrors = error.errors;
            }
        },

        [fetchReportResults.fulfilled]: (state, action) => {
            state.busy = false;
            state.error = null;
            state.validationErrors = null;

            if (action.payload) {
                state.resultsOpen = true;
                state.rows = action.payload.rows;
                state.total = action.payload.total;
            }
        },


        [calcReport.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.error = null;
            state.validationErrors = null;
        },

        [calcReport.rejected]: (state, action) => {
            state.busy = false;
            const error = action.payload;

            state.busy = false;
            state.error = error;

            if (error && error.code === "validation") {
                state.validationErrors = error.errors;
            }
        },

        [calcReport.fulfilled]: (state) => {
            state.selectedIds = [];
            state.searchString = "";
            state.pageNumber = 1;
            state.sortData = [];
            state.filterData = [];
            state.canFetchResults = true;
        },

        [fetchReportColumns.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchReportColumns.fulfilled]: (state, action) => {
            state.busy = false;
            state.columns = action.payload?.filter(column => column.active);
        },

        [fetchReportColumns.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },

        [saveVariant.pending]: (state) => {
            state.variantCreated = false;
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
        },

        [saveVariant.fulfilled]: (state, action) => {
            state.busy = false;
            state.validationErrors = [];
            state.variantCreated = true;
            
            const savedVariant = action.payload;
            const index = state.variants.findIndex(variant => variant.variantName === savedVariant.variantName);

            if (index === -1) {
                state.variants.push(savedVariant);
            } else {
                state.variants[index] = savedVariant;
            }

            if (state.currentVariant?.variantName === savedVariant.variantName) {
                state.currentVariant = savedVariant;
            }
        },

        [saveVariant.rejected]: (state, action) => {
            const error = action.payload;

            state.busy = false;
            state.error = error;

            if (error && error.code === "validation") {
                state.validationErrors = error.errors;
            }
        },

        [deleteVariant.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
        },

        [deleteVariant.fulfilled]: (state, action) => {
            const variantName = action.meta.arg;

            state.busy = false;
            state.variants = state.variants.filter(variant => variant.variantName !== variantName);

            if (state.currentVariant?.variantName === variantName) {
                state.currentVariant = null;
                state.roles = [];
                state.systems = [];
                state.extendedSystems = []
                state.matrixHeaders = [];
                state.risks = [];
                state.riskLevels = [];
            }
        },

        [deleteVariant.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },

        [setFavoritesForVariant.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
        },

        [setFavoritesForVariant.fulfilled]: (state, action) => {
            const { variantName, inFavorites } = action.meta.arg;

            state.busy = false;

            const variant = state.variants.find(variant => variant.variantName === variantName);

            if (variant) {
                variant.inFavorites = inFavorites;
            }

            if (state.currentVariant?.variantName === variantName) {
                state.currentVariant.inFavorites = inFavorites;
            }
        },

        [setFavoritesForVariant.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },

        [fetchVariantsByUser.fulfilled]: (state, action) => {
            state.variants = action.payload;
        },

        [fetchVariantsByUser.rejected]: (state, action) => {
            state.error = action.error;
        },

        [exportReport.rejected]: (state, action) => {
            state.error = action.error;
        },

        [compareReport.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.error = null;
            state.validationErrors = null;
        },

        [compareReport.rejected]: (state, action) => {
            const error = action.payload;

            state.busy = false;
            state.error = error;

            if (error.code === "validation") {
                state.validationErrors = error.errors;
            }
        },

        [compareReport.fulfilled]: (state) => {
            state.busy = false;
            state.error = null

            state.selectedIds = [];
            state.searchString = "";
            state.pageNumber = 1;
            state.sortData = [];
            state.filterData = [];
        },

        [saveReportColumns.fulfilled]: (state, action) => {
            const { columns, reportLevel, reportType } = action.payload;

            if (state.reportLevel === reportLevel &&
                state.reportType === reportType) {
                state.columns = columns.filter(column => column.active);
            }
        }
    }
});

export const {
    setRoles, setSystems, setMatrixHeaders, setRisks, setSystemsExtended,
    setRiskLevels, setParametersOpen, setResultsOpen,
    setReportLevel, setReportType, setError, setSelectedIds,
    setSearchString, setPageNumber, setLimitNumber, setSortData,
    setFilterData, setCurrentVariant, clearFilters
} = roleLevelSlice.actions;

export default roleLevelSlice.reducer;
