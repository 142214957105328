import React from "react";
import { SvgIcon } from "@mui/material";

const TrashIcon = (props) => (
    <SvgIcon
        viewBox="0 0 30 30"
        {...props}
    >
        <svg
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6 8.95996H24.975M13.5898 13.9102V18.8602M17.3848 13.9102V18.8602M7.89648 8.95996H23.0765L21.5775 23.0378C21.5261 23.5222 21.3052 23.9699 20.9572 24.2951C20.6092 24.6202 20.1585 24.8 19.6913 24.8H11.2816C10.8145 24.8 10.3638 24.6202 10.0158 24.2951C9.66774 23.9699 9.44689 23.5222 9.39551 23.0378L7.89648 8.95996ZM11.071 6.13553C11.2245 5.79593 11.4673 5.50886 11.7712 5.3078C12.0751 5.10675 12.4275 5 12.7873 5H18.1876C18.5475 4.99981 18.9002 5.10647 19.2042 5.30754C19.5083 5.5086 19.7513 5.79578 19.9048 6.13553L21.1799 8.96H9.79492L11.071 6.13553Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </SvgIcon>
);

export default TrashIcon;
