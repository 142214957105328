import { styled, Switch as MuiSwitch } from "@mui/material";

const Switch = styled(MuiSwitch)({
    justifySelf: "center",
    padding: 0,
    width: "40px",
    height: "20px",
    "& .MuiSwitch-switchBase": {
        padding: "3px",

        "&.Mui-checked": {
            "& + .MuiSwitch-track": {
                border: "none",
                background: "rgba(183, 183, 183, 0.65)",
                opacity: 1,
            },
            "& .MuiSwitch-thumb": {
                border: "none",
                background: "#ffffff",
            },
        },
    },
    "& .MuiSwitch-thumb": {
        width: "14px",
        height: "14px",
        boxShadow: "none",
        border: "1px solid rgba(90, 90, 90, 0.42)",
        boxSizing: "border-box",
        background: "#ffffff",
    },
    "& .MuiSwitch-track": {
        borderRadius: "20px",
        border: "1px solid rgba(90, 90, 90, 0.42)",
        background: "none",
        boxSizing: "border-box",
        opacity: 1,
    },
});

export default Switch;
