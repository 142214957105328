import React from "react";
import { Typography, Box } from "@mui/material";
import PropTypes from 'prop-types';

import { CloseIcon } from "../icons";

import "./styles.less"

const Tag = ({ tag, onDelete, onClick, size="medium", selected }) => {
    const classes = ["tag-container", `tag-container-${size}`];

    if (selected){
        classes.push("selected")
    }

    return (
        <Box className={classes.join(" ")}>
            <Typography className="tag-text" onClick={onClick}>
                {tag}
            </Typography>

            <CloseIcon
                className="tag-close-icon"
                onClick={onDelete}
            />
        </Box>
    )
}

Tag.propTypes = {
    tag: PropTypes.string,
    onDelete: PropTypes.func,
    onClick: PropTypes.func,
    size: PropTypes.string,
    selected: PropTypes.bool
}

export default Tag;
