import React from 'react';
import { createSvgIcon } from '@mui/material';

const IntegrationIcon = createSvgIcon(
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M9.6 16.1L11 14.65L8.85 12.5L11 10.35L9.6 8.9L6 12.5L9.6 16.1ZM14.4 16.1L18 12.5L14.4 8.9L13 10.35L15.15 12.5L13 14.65L14.4 16.1ZM5 21.5C4.45 21.5 3.979 21.304 3.587 20.912C3.195 20.52 2.99934 20.0493 3 19.5V5.5C3 4.95 3.196 4.479 3.588 4.087C3.98 3.695 4.45067 3.49933 5 3.5H9.2C9.41667 2.9 9.77934 2.41667 10.288 2.05C10.7967 1.68333 11.3673 1.5 12 1.5C12.6333 1.5 13.2043 1.68333 13.713 2.05C14.2217 2.41667 14.584 2.9 14.8 3.5H19C19.55 3.5 20.021 3.696 20.413 4.088C20.805 4.48 21.0007 4.95067 21 5.5V19.5C21 20.05 20.804 20.521 20.412 20.913C20.02 21.305 19.5493 21.5007 19 21.5H5ZM12 4.75C12.2167 4.75 12.3957 4.679 12.537 4.537C12.6783 4.395 12.7493 4.216 12.75 4C12.75 3.78333 12.679 3.60433 12.537 3.463C12.395 3.32167 12.216 3.25067 12 3.25C11.7833 3.25 11.6043 3.321 11.463 3.463C11.3217 3.605 11.2507 3.784 11.25 4C11.25 4.21667 11.321 4.39567 11.463 4.537C11.605 4.67833 11.784 4.74933 12 4.75Z"
            fill="currentColor"
        />
    </svg>,
    "Integration"
);

export default IntegrationIcon;
