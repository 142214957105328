import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { addGroupRoleChild, removeGroupRoleChild } from "../../../reducers/reports-modeling/roleLevelSlice";

import GroupRoleModelingBox from "../group-role-modeling-box";

const RoleLevelGroupRoleModelingBox = ({ selectedRole }) => {
    const dispatch = useDispatch();

    const handleAddChildRole = (newChildRole) => dispatch(
        addGroupRoleChild({
            id: selectedRole.id,
            newChildRole
        })
    );

    const handleRemoveChildRoles = (roleIdsToRemove) => dispatch(
        removeGroupRoleChild({
            id: selectedRole.id,
            roleIdsToRemove
        })
    );

    return (
        <GroupRoleModelingBox
            role={selectedRole}
            onAddChildRole={handleAddChildRole}
            onRemoveChildRoles={handleRemoveChildRoles}
        />
    )
};

RoleLevelGroupRoleModelingBox.propTypes = {
    selectedRole: PropTypes.object
};

export default RoleLevelGroupRoleModelingBox