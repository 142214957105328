import React from 'react';
import { createSvgIcon } from '@mui/material';

const SortAscArrowIcon = createSvgIcon(
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 11 11"
        fill="none"
    >
        <path
            d="M5.5 0L2.61325 5H8.38675L5.5 0ZM6 11V4.5H5V11H6Z"
            fill="currentColor"
        />
    </svg>,
    "SortAscArrow"
);

export default SortAscArrowIcon;
