import React from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { BackButton } from "../buttons";

import "./style.less";

const EntityFormHeaderTitle = ({ title, onBackClick }) => {
    return (
        <Box className="entity-form-header-start">
            <BackButton onClick={onBackClick} />
            <Typography
                component="h2"
                className="entity-form-header-title"
            >
                {title}
            </Typography>
        </Box>
    );
};

EntityFormHeaderTitle.propTypes = {
    title: PropTypes.node,
    onBackClick: PropTypes.func
};

export default EntityFormHeaderTitle;
