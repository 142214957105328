import React from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types"; 

const TabsListItemLeaf = ({ currentTab, activeTab, handleLeafTabClick }) => {
    const classNames = ["tabs-list-item-leaf"];

    if (currentTab.id === activeTab?.id) {
        classNames.push("tabs-list-item-active");
    }

    return(
        <Box 
            className={classNames.join(" ")}
            onClick={() => handleLeafTabClick(currentTab)}
        >
            <Typography className="tabs-list-item-leaf-title">
                {currentTab.title}
            </Typography>
        </Box>
    );
};

TabsListItemLeaf.propTypes = {
    currentTab: PropTypes.object,
    activeTab: PropTypes.object,
    handleLeafTabClick: PropTypes.func
};

export default TabsListItemLeaf;
