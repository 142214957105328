import { createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit";

import { reportsService } from "../../services/reports-service.js";
import { BUSY_TYPES } from "../../utils/busy.js";
import { SUMMARY_REPORT_VIEWS, REPORT_LEVELS, REPORT_TYPES } from "../../utils/sodReports.js";


export const getTodayDateString = () => {
    const today = new Date()

    return today.toDateString()
}

export const getSummaryReportRowId = (row) =>`${row.employee_id}:${row.risk_id}:${row.system_id}`

export const ALL_USER_KEY = "ALL"

const initialDetailedState = {
    rows: [],
    columns: [],
    reportLevel: REPORT_LEVELS.OPERATIONS,
    reportType: REPORT_TYPES.FINAL,
    total: 0,
    selectedIds: [],
    searchString: "",
    pageNumber: 1,
    limitNumber: 10,
    sortData: [],
    filterData: [],
}

const initialState = {
    calcDate: getTodayDateString(),
    lastCalcDate: null,
    calcStatus: null,
    rows: [],
    columns: [],
    total: 0,
    selectedIds: [],
    searchString: "",
    pageNumber: 1,
    limitNumber: 10,
    sortData: [],
    filterData: [],
    busy: false,
    busyCalc: false,
    busyType: null,
    error: null,
    validationErrors: null,
    currentView: SUMMARY_REPORT_VIEWS.LIST,
    isComparing: false, 
    compareDates: null,

    detailed: {
        ...initialDetailedState,
        currentEmployee: null
    }
};

export const fetchReportColumns = createAsyncThunk(
    "reports/userBusynessLevel/fetchReportColumns",
    async () => {
        const columns = await reportsService.getUserLevelBusynessReportColumns();

        return columns
    }
);


export const fetchDetailedReportColumns = createAsyncThunk(
    "reports/userLevel/fetchReportColumns",
    async (_, { getState }) => {
        const state = getState();

        const reportLevel = state.reports.userBusynessLevel.detailed.reportLevel
        const reportType = state.reports.userBusynessLevel.detailed.reportType

        // const newParams = {
        //     ...params,
        //     "report_level": state.reports.userLevel.reportLevel,
        //     "report_type": state.reports.userLevel.reportType,
        // };

        const columns = await reportsService.getUserLevelReportColumns(reportLevel, reportType);

        return columns;
    }
);

export const fetchReportResults = createAsyncThunk(
    "reports/userBusynessLevel/fetchReportResults",
    async ({ params }, {getState}) => {
        const state = getState()

        const paramsNew = {
            ...params
        }

        if (state.reports.userBusynessLevel.isComparing) {
            paramsNew.viewType = "comparing"
        }

        if (state.reports.userBusynessLevel.compareDates) {
            paramsNew.viewType = "delta"
            paramsNew.earlyDate = state.reports.userBusynessLevel.compareDates.earlyDate
            paramsNew.lateDate = state.reports.userBusynessLevel.compareDates.lateDate

            const filters = []
            const sorters = []

            paramsNew && paramsNew.filters && paramsNew.filters.forEach(filter => {
                if (filter.field === "calc_date") {
                    const filterCopy = {...filter}
                    filterCopy.field = "err_status"

                    filters.push(filterCopy)

                    return
                }

                filters.push(filter)
            })

            paramsNew && paramsNew.sorters && paramsNew.sorters.forEach(sorter => {
                if (sorter.field === "calc_date") {
                    const sorterCopy = {...sorter}
                    sorterCopy.field = "err_status"

                    sorters.push(sorterCopy)

                    return
                }

                sorters.push(sorter)
            })

            paramsNew.filters = filters
            paramsNew.sorters = sorters
        }

        const response = await reportsService.getUserLevelBusynessReportResults(paramsNew);
        return response;
    }
);




export const fetchDetailedReportResults = createAsyncThunk(
    "reports/userBusynessLevel/fetchDetailedReportResults",
    async ({ params }, { getState }) => {
        const state = getState()

        //const calcDate = state.reports.userBusynessLevel.calcDate

        const newParams = {
            ...params,
            "report_level": state.reports.userBusynessLevel.detailed.reportLevel,
            "report_type": state.reports.userBusynessLevel.detailed.reportType,
            //"employee": state.reports.userBusynessLevel.detailed.currentEmployee
        };

        if (state.reports.userBusynessLevel.detailed.currentEmployee !== ALL_USER_KEY){
            newParams.employee =state.reports.userBusynessLevel.detailed.currentEmployee
        }


        const response = await reportsService.getUserLevelBusynessDetailedReportResults({
            ...newParams,
        });
        return response;
    }
);


export const fetchReportInfo = createAsyncThunk(
    "reports/userBusynessLevel/fetchReportInfo",
    async () => {
        const response = await reportsService.getUserLevelBusynessReportInfo();
        return response;
    }
);


export const calcSummaryReport = createAsyncThunk(
    "reports/userBusynessLevel//calcSummaryReport",
    async (_, { rejectWithValue }) => {

        try {
            await reportsService.calUserLevelBusynessReport();

        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const resetCompareDates = createAsyncThunk(
    "reports/userBusynessLevel//calcSummaryReportDelta",
    async (_, { rejectWithValue, dispatch }) => {
        dispatch(setCompareDates(null))


        try {
            const reportInfo = await reportsService.getUserLevelBusynessReportInfo()

            if (reportInfo){
                dispatch(setCalcStatus(reportInfo.current_status))
                dispatch(setCalcDate(reportInfo.last_calc_date))
            }

            dispatch(clearFilters())

        } catch (error) {
            return rejectWithValue(error)
        }
    }
);


export const calcSummaryReportDelta = createAsyncThunk(
    "reports/userBusynessLevel//calcSummaryReportDelta",
    async ({earlyDate, lateDate}, { rejectWithValue, dispatch }) => {
        dispatch(setCompareDates({earlyDate, lateDate}))

        try {
            const deltaInfo = await reportsService.getUserLevelBusynessReportDeltaInfo(earlyDate, lateDate)

            if (deltaInfo && deltaInfo.current_status  === "pending"){
                dispatch(setCalcStatus("pending"))
            }

            await reportsService.calUserLevelBusynessReportDelta(earlyDate, lateDate);

            return {earlyDate, lateDate}
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);


export const scheduleSummaryReport = createAsyncThunk(
    "reports/userBusynessLevel//scheduleSummaryReport",
    async (scheduleItems, { rejectWithValue }) => {

        try {
            await reportsService.scheduleUserLevelBusynessReport(scheduleItems);

        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const fetchSummaryReportScheduleItems = createAsyncThunk(
    "reports/userBusynessLevel//fetchSummaryReportScheduleItems",
    async (_, { rejectWithValue }) => {

        try {
            return await reportsService.getScheduleUserLevelBusynessReportItems();

        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const calcDetailedReport = createAsyncThunk(
    "reports/userBusynessLevel//calcDetailedReport",
    async (linesForCalc, { getState, rejectWithValue }) => {
        const state = getState();

        const calcDate = state.reports.userBusynessLevel.calcDate
        const todayCalcDate = new Date().toDateString()

        if (calcDate !== todayCalcDate) {
            return rejectWithValue(null)
        }

        try {
            await reportsService.calUserLevelBusynessDetailedReport(linesForCalc);

        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const compareBusinessReport = createAsyncThunk(
    "reports/userBusynessLevel//compareBusinessReport",
    async ({users, systems, groups, risks}, { rejectWithValue }) => {

        const form = {
            usernames: users,
            systems: systems,
            groups: groups,
            risks: risks,
            matrix_headers: [],
            risk_levels: []
        };

        try {
            await reportsService.compareUserLevelBusynessReport(form);
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const compareReport = createAsyncThunk(
    "reports/userBusynessLevel//compareReport",
    async (_, { getState, rejectWithValue }) => {
        const state = getState();

        const form = {
            dateFrom: state.reports.userBusynessLevel.dateFrom,
            dateTo: state.reports.userBusynessLevel.dateTo,
        };

        try {
            await reportsService.compareUserLevelBusynessReport(form);
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const exportReport = createAsyncThunk(
    "reports/userBusynessLevel/exportReport",
    async (_, { rejectWithValue }) => {

        const params = {};

        try {
            await reportsService.exportUserLevelReport(params);
        } catch (error) {
            rejectWithValue(error);
        }
    }
);

export const setReportLevelWithColumns = (reportLevel) => (dispatch) => {
    dispatch(setReportLevel(reportLevel));
    dispatch(fetchDetailedReportColumns());
};

export const setReportTypeWithColumns = (reportType) => (dispatch) => {
    dispatch(setReportType(reportType));
    dispatch(fetchDetailedReportColumns());
};

const userBusynessLevelSlice = createSlice({
    name: "userBusynessLevel",
    initialState: initialState,
    reducers: {
        setCurrentView(state, action) {
            state.currentView = action.payload
            state.detailed = initialDetailedState
        },

        setCalcDate(state, action) {
            state.calcDate = action.payload
            state.pageNumber = 1
        },

        setCalcStatus(state, action) {
            state.calcStatus = action.payload
        },

        setLastCalcDate(state, action) {
            state.lastCalcDate = action.payload
        },

        setError(state, action) {
            state.error = action.payload;
        },

        setSelectedIds(state, action) {
            state.selectedIds = action.payload;
        },

        setSearchString(state, action) {
            if (state.searchString !== action.payload) {
                state.pageNumber = 1;
                state.searchString = action.payload;
            }
        },

        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },

        setLimitNumber(state, action) {
            state.pageNumber = 1;
            state.limitNumber = action.payload;
        },

        setSortData(state, action) {
            state.pageNumber = 1;
            state.sortData = action.payload;
        },

        setFilterData(state, action) {
            state.pageNumber = 1;
            state.filterData = action.payload;
        },

        clearFilters(state) {
            state.selectedIds = [];
            state.searchString = "";
            state.pageNumber = 1;
            state.sortData = [];
            state.filterData = [];
            state.calcDate = getTodayDateString()
        },

        setReportLevel(state, action) {
            state.detailed.reportLevel = action.payload;
            state.detailed.pageNumber = 1;
            state.detailed.searchString = "";
            state.detailed.filterData = [];
            state.detailed.sortData = [];
        },

        setReportType(state, action) {
            state.detailed.reportType = action.payload;
            state.detailed.pageNumber = 1;
            state.detailed.searchString = "";
            state.detailed.filterData = [];
            state.detailed.sortData = [];
        },

        setDetailedSelectedIds(state, action) {
            state.detailed.selectedIds = action.payload;
        },

        setDetailedSearchString(state, action) {
            if (state.detailed.searchString !== action.payload) {
                state.detailed.pageNumber = 1;
                state.detailed.searchString = action.payload;
            }
        },

        setDetailedPageNumber(state, action) {
            state.detailed.pageNumber = action.payload;
        },

        setDetailedLimitNumber(state, action) {
            state.detailed.pageNumber = 1;
            state.detailed.limitNumber = action.payload;
        },

        setDetailedSortData(state, action) {
            state.detailed.pageNumber = 1;
            state.detailed.sortData = action.payload;
        },

        setDetailedFilterData(state, action) {
            state.detailed.pageNumber = 1;
            state.detailed.filterData = action.payload;
        },

        setCurrentEmployee(state, action) {
            state.detailed.pageNumber = 1;
            state.detailed.currentEmployee = action.payload;
            state.detailed.filterData = [];
            state.detailed.sortData = [];
        },

        setIsComapring(state, action) {
            state.isComparing = action.payload
        },

        setCompareDates(state, action) {
            state.compareDates = action.payload
        }
    },
    extraReducers: {
        [fetchReportResults.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.rows = [];
        },

        [fetchReportResults.rejected]: (state, action) => {
            const error = action.payload;

            state.busy = false;
            state.error = error;

            if (error && error.code === "validation") {
                state.validationErrors = error.errors;
            }
        },

        [fetchReportResults.fulfilled]: (state, action) => {
            state.busy = false;
            state.error = null;
            state.validationErrors = null;

            if (action.payload) {
                state.rows = action.payload.rows;
                state.total = action.payload.total;
                
                const compareDates = state.compareDates
                
                state.rows.forEach(row => {
                    row.id = getSummaryReportRowId(row)

                    if (compareDates && row.err_status === "in_early") {
                        row.calc_date = compareDates.earlyDate    
                    }

                    if (compareDates && row.err_status === "in_late") {
                        row.calc_date = compareDates.lateDate
                    }
                })
            }
        },



        [fetchDetailedReportResults.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.detailed.rows = [];
        },

        [fetchDetailedReportResults.rejected]: (state, action) => {
            const error = action.payload;

            state.busy = false;
            state.error = error;

            if (error && error.code === "validation") {
                state.validationErrors = error.errors;
            }
        },

        [fetchDetailedReportResults.fulfilled]: (state, action) => {
            state.busy = false;
            state.error = null;
            state.validationErrors = null;

            if (action.payload) {
                state.detailed.rows = action.payload.rows;
                state.detailed.total = action.payload.total;

                state.detailed.rows.forEach(row => row.id = nanoid())
            }
        },


        [compareReport.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.error = null;
            state.validationErrors = null;
        },

        [compareReport.rejected]: (state, action) => {
            const error = action.payload;

            state.busy = false;
            state.error = error;

            if (error.code === "validation") {
                state.validationErrors = error.errors;
            }
        },

        [compareReport.fulfilled]: (state) => {
            state.busy = false;
            state.error = null

            state.selectedIds = [];
            state.searchString = "";
            state.pageNumber = 1;
            state.sortData = [];
            state.filterData = [];
        },

        [compareBusinessReport.pending]: (state) => {
            state.busy = true;
            state.busyCalc = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.error = null;
            state.validationErrors = null;
        },

        [compareBusinessReport.rejected]: (state, action) => {
            const error = action.payload;

            state.busy = false;
            state.busyCalc = false
            state.error = error;

            if (error.code === "validation") {
                state.validationErrors = error.errors;
            }
        },

        [compareBusinessReport.fulfilled]: (state) => {
            state.busy = false;
            state.busyCalc = false
            state.error = null

            state.selectedIds = [];
            state.searchString = "";
            state.pageNumber = 1;
            state.sortData = [];
            state.filterData = [];
        },

        [calcSummaryReport.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.error = null;
            state.validationErrors = null;
        },

        [calcSummaryReport.rejected]: (state, action) => {
            const error = action.payload;

            state.busy = false;
            state.error = error;

            if (error && error.code === "validation") {
                state.validationErrors = error.errors;
            }
        },

        [calcSummaryReport.fulfilled]: (state) => {
            state.busy = false;
            // state.selectedIds = [];
            // state.searchString = "";
            // state.pageNumber = 1;
            // state.sortData = [];
            // state.filterData = [];
        },




        [calcSummaryReportDelta.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.error = null;
            state.validationErrors = null;
        },

        [calcSummaryReportDelta.rejected]: (state) => {

            state.busy = false;
            state.compareDates = null
            //state.error = error;
        },

        [calcSummaryReportDelta.fulfilled]: (state) => {
            state.busy = false;
        },


        [fetchReportColumns.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchReportColumns.fulfilled]: (state, action) => {
            state.busy = false;
            state.columns = action.payload?.filter(column => column.active);
        },

        [fetchReportColumns.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },



        [fetchDetailedReportColumns.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchDetailedReportColumns.fulfilled]: (state, action) => {
            state.busy = false;
            state.detailed.columns = action.payload?.filter(column => column.active);
        },

        [fetchDetailedReportColumns.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },


        [exportReport.rejected]: (state, action) => {
            state.error = action.error;
        },



        [calcDetailedReport.pending]: (state) => {
            state.busy = true;
            state.busyCalc = true
            state.busyType = BUSY_TYPES.LOAD;
            state.error = null;
            state.validationErrors = null;
        },

        [calcDetailedReport.rejected]: (state, action) => {
            const error = action.payload;

            state.busy = false;
            state.busyCalc = false
            state.error = error;

            if (error && error.code === "validation") {
                state.validationErrors = error.errors;
            }
        },

        [calcDetailedReport.fulfilled]: (state) => {
            state.busy = false;
            state.busyCalc = false
            state.currentView = SUMMARY_REPORT_VIEWS.DETAILED
        },




        [fetchReportInfo.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchReportInfo.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload
        },

        [fetchReportInfo.fulfilled]: (state, action) => {
            state.busy = false;
            state.lastCalcDate = action.payload["last_calc_date"]
            state.calcStatus = action.payload["current_status"]
        },



    }
});

export const selectSelectedSummaryReportRows = state => {
    const selectedRowIds = state.reports.userBusynessLevel.selectedIds
    const rows = state.reports.userBusynessLevel.rows

    const selectedRowIdsSet = new Set(selectedRowIds)

    return rows.filter(row => selectedRowIdsSet.has(row.id))
};

export const {
    setError, setSelectedIds,
    setSearchString, setPageNumber, setLimitNumber, setSortData,
    setFilterData, clearFilters, setCalcDate, setCalcStatus, setLastCalcDate, setCurrentView,
    setDetailedSelectedIds,
    setDetailedSearchString, setDetailedPageNumber, setDetailedLimitNumber, setDetailedSortData,
    setDetailedFilterData, setReportLevel, setReportType, setCurrentEmployee, setIsComapring, setCompareDates
} = userBusynessLevelSlice.actions;

export default userBusynessLevelSlice.reducer;
