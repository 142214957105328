import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { criticalRuleService } from "../services/critical-rule-service";
import LockManager from "../utils/lockManager";
import { BUSY_TYPES } from "../utils/busy";

const lockManager = new LockManager("critical-profile");

const initialState = {
    profiles: [],
    columns: [],
    profilesTotal: 0,
    selectedIds: [],
    searchString: "",
    pageNumber: 1,
    limitNumber: 10,
    sortData: [],
    filterData: [],
    busy: false,
    busyType: null,
    error: null,
    submit: false,
    currentProfile: null,
    currentProfileEditable: false,
    currentProfileDeleted: false,
    validationErrors: null,
};

export const fetchProfiles = createAsyncThunk(
    "profiles/fetchProfiles",
    async ({ params }) => {
        const response = await criticalRuleService.getCriticalProfiles(params);
        return response;
    }
);

export const fetchProfileColumns = createAsyncThunk(
    "profiles/fetchProfileColumns",
    async () => {
        const response = await criticalRuleService.getCriticalProfileColumns();
        return response;
    }
);

export const fetchProfileDetailed = createAsyncThunk(
    "profiles/fetchProfileDetailed",
    async ({ name, system }) => {
        const type = "critical_profile"
        const profile = await criticalRuleService.getCriticalProfileDetailed(name, system);

        const [lock, relatedObjsLocks] = await Promise.allSettled([
            lockManager.lockObject({ name, system }),
            criticalRuleService.checkRelatedObjsLock(type, name, system)
        ]);
        
        return { 
            profile, 
            lock: lock.value,
            relatedObjsLocks: relatedObjsLocks.status === "rejected" ? relatedObjsLocks.reason : null
        };
    }
);

export const createProfile = createAsyncThunk(
    "profiles/createProfile",
    async ({profile, warningCheck=true}, { rejectWithValue }) => {
        let response

        try {
            response = await criticalRuleService.createCriticalProfile(profile, warningCheck);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return response;
    }
);

export const editProfile = createAsyncThunk(
    "profiles/editProfile",
    async ({profile, warningCheck=true}, { rejectWithValue }) => {
        let response

        try {
            response = await criticalRuleService.editCriticalProfile(profile, warningCheck);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return response;
    }
);

export const deleteProfile = createAsyncThunk(
    "profiles/deleteProfile",
    async (profile, { rejectWithValue }) => {
        try {
            await criticalRuleService.deleteCriticalProfile(profile);
        } catch (messages) {
            return rejectWithValue(messages)
        }

        return profile;
    }
);

export const clearCurrentProfile = createAsyncThunk(
    "profile/clearCurrentProfile",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        const currentProfile = state.profiles.currentProfile;
        const currentProfileEditable = state.profiles.currentProfileEditable;
        const currentProfileDeleted = state.profiles.currentProfileDeleted;

        if (!currentProfile) return thunkAPI.rejectWithValue(["Current profile is not initialized"]);

        if (currentProfileDeleted || !currentProfileEditable || !currentProfile.name) return;

        const lockResponse = await lockManager.unlockObject({
            name: currentProfile.name,
            system: currentProfile.system
        });

        if (!lockResponse.result) {
            return thunkAPI.rejectWithValue(lockResponse.messages)
        }
    }
);

const profileSlice = createSlice({
    name: "profile",
    initialState: initialState,
    reducers: {
        setSubmit(state, action){
            state.submit = action.payload;
        },

        setError(state, action) {
            state.error = action.payload;
        },

        setValidationErrors(state, action) {
            state.validationErrors = action.payload;
        },

        setInitialCurrentProfile(state) {
            state.currentProfile = {
                name: "",
                description: "",
                active: true,
                system: null,
                riskLevel: null,
                matrices: []
            };

            state.error = null;
            state.submit = false;
            state.currentProfileEditable = true;
            state.currentProfileDeleted = false;
        },

        setSelectedIds(state, action) {
            state.selectedIds = action.payload;
        },

        setSearchString(state, action) {
            if (state.searchString !== action.payload) {
                state.pageNumber = 1;
                state.searchString = action.payload;
            }
        },

        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },

        setLimitNumber(state, action) {
            state.pageNumber = 1;
            state.limitNumber = action.payload;
        },

        setSortData(state, action) {
            state.pageNumber = 1;
            state.sortData = action.payload;
        },

        setFilterData(state, action) {
            state.pageNumber = 1;
            state.filterData = action.payload;
        },
    },

    extraReducers: {
        //get all profiles
        [fetchProfiles.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchProfiles.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },

        [fetchProfiles.fulfilled]: (state, action) => {
            state.busy = false;
            state.profiles = action.payload.profiles;
            state.profilesTotal = action.payload.total;
            state.error = null;
            state.validationErrors = null;
        },

        // clear current profile
        [clearCurrentProfile.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [clearCurrentProfile.fulfilled]: (state) => {
            state.busy = false;
            state.currentProfile = null;
            state.currentProfileEditable = false;
            state.currentProfileDeleted = false;
            state.error = null;
            state.validationErrors = null;
            state.submit = false;
        },

        [clearCurrentProfile.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },


        // delete profile
        [deleteProfile.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
        },

        [deleteProfile.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },

        [deleteProfile.fulfilled]: (state, action) => {
            state.busy = false;
            state.error = null;
            state.validationErrors = null;

            const profile = action.payload;
            state.profiles = state.profiles.filter(item => !(item.name === profile.name && item.system === profile.system));

            if (state.currentProfile?.name === profile.name && state.currentProfile?.system === profile.system) {
                state.submit = true;
                state.currentProfileDeleted = true;
            }
        },

        // create profile
        [createProfile.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
            state.submit = false;
        },

        [createProfile.rejected]: (state, action) => {
            state.busy = false;
            const error = action.payload;

            if (!error) return;

            const validationCodes = new Set(["no_integr_objects", "validation"])

            if (error.code && validationCodes.has(error.code)){
                state.validationErrors = error.errors;
            }

            state.error = error
        },

        [createProfile.fulfilled]: (state, action) => {
            state.busy = false;
            state.submit = true;
            state.currentProfile = action.payload;
            state.error = null;
            state.validationErrors = null;
        },

        // edit profile
        [editProfile.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
            state.submit = false;
        },

        [editProfile.rejected]: (state, action) => {
            state.busy = false;
            const error = action.payload;

            if (!error) return;

            const validationCodes = new Set(["no_integr_objects", "validation"])

            if (error.code && validationCodes.has(error.code)){
                state.validationErrors = error.errors;
            }

            state.error = error
        },

        [editProfile.fulfilled]: (state, action) => {
            state.busy = false;
            state.submit = true;
            state.currentProfile = action.payload;
            state.error = null;
            state.validationErrors = null;
        },

        // fetch profile detailed
        [fetchProfileDetailed.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.currentProfileDeleted = false;
            state.submit = false;
        },

        [fetchProfileDetailed.fulfilled]: (state, action) => {
            const { profile, lock, relatedObjsLocks } = action.payload;

            state.currentProfile = profile;
            state.busy = false;
            state.currentProfileEditable = lock.result;
            state.error = lock.result ? relatedObjsLocks : lock.messages;
        },

        [fetchProfileDetailed.rejected]: (state, action) => {
            state.currentProfile = null;
            state.error = action.error;
            state.busy = false;
            state.currentProfileEditable = false;
        },

        [fetchProfileColumns.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchProfileColumns.fulfilled]: (state, action) => {
            state.busy = false;
            state.columns = action.payload;
        },

        [fetchProfileColumns.rejected]: (state, action) => {
            state.busy = false;
            state.errors = action.error;
        },
    }
})

export const {
    setSubmit, setInitialCurrentProfile, setError, setValidationErrors,
    setSelectedIds, setSearchString, setPageNumber, setLimitNumber,
    setSortData, setFilterData
} = profileSlice.actions;

export default profileSlice.reducer;
