import React, { useState, useMemo, useCallback } from "react";
import { Checkbox } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from 'prop-types';

import { getMassDisabled } from "../../../../utils/formViews";
import { getCriticalProfilesSearchHelp, getSystemsSearchHelp } from "../../../../utils/searchHelps";

import { createRowCellContentInner } from "../../../common/table";
import useLocalTable, { ComplexFilter, Filter } from "../../../common/hooks/useLocalTable";
import { TrashIcon } from "../../../common/icons";
import { SearchHelpInput } from "../../../common/form-controls";
import SearchHelpDialog from "../../../common/search-helps";
import { IconButton } from "../../../common/buttons";
import { EntityFormViewTable } from "../../../common/entity-form";

const getErrorFromValidationErrors = (row, columnId) => {
    return row && row[`error_${columnId}`];
};

const getFilters = (searchString) => {
    const filters = [
        new ComplexFilter(
            [
                new Filter("system", Filter.CO, searchString),
                new Filter("criticalProfile", Filter.CO, searchString),
                new Filter("description", Filter.CO, searchString)
            ],
            ComplexFilter.OR
        )
    ]

    return filters;
};

const CriticalProfilesView = ({
    criticalProfiles,
    addCriticalProfile,
    deleteCriticalProfile,
    deleteCriticalProfileMass,
    editCriticalProfile,
    disabled,
    errorIndex,
    setErrorScroll
}) => {
    const intl = useIntl();

    const [searchString, setSearchString] = useState("")
    const [selectedIds, setSelectedIds] = useState([]);
    const [rowAction, setRowAction] = useState(null)

    const columnTexts = useMemo(() => ({
        system: intl.formatMessage({ id: "matrix-headers-form-content.subtable-column.criticalProfiles.system" }),
        criticalProfile: intl.formatMessage({ id: "matrix-headers-form-content.subtable-column.criticalProfiles.criticalProfile" }),
        description: intl.formatMessage({ id: "matrix-headers-form-content.subtable-column.criticalProfiles.description" }),
        active: intl.formatMessage({ id: "matrix-headers-form-content.subtable-column.active" }),
    }), [intl]);

    const toolbarActions = useMemo(() => {
        const massDisabled = getMassDisabled(disabled, selectedIds);

        return [
            {
                id: "mass-delete",
                title: intl.formatMessage({ id: "common.btn-delete" }),
                callback: () => {
                    deleteCriticalProfileMass(selectedIds);
                    setSelectedIds([]);
                },
                position: "end",
                variant: "contained",
                disabled: massDisabled
            },
            {
                id: "search",
                type: "search",
                position: "end",
                value: searchString,
                callback: value => {
                    setSearchString(value)
                    onFilter(getFilters(value))
                }
            },
            {
                id: "add",
                title: <FormattedMessage id="criticalProfile-view.btn-create-criticalProfile" />,
                callback: addCriticalProfile,
                position: "begin",
                disabled
            }
        ];
    }, [
        onFilter,
        addCriticalProfile,
        searchString,
        disabled,
        selectedIds,
        deleteCriticalProfileMass,
        intl
    ]);

    const createRowCellContent = useCallback((row, column, selected, onSelect) => {
        const error = getErrorFromValidationErrors(row, column.id)

        switch (column.type) {
            case "search-help": {
                return (
                    <SearchHelpInput
                        disabled={disabled}
                        error={error ? (row[column.id] === error.value && error["type"] === "validation") : false}
                        errorMessage={error && error.message}
                        warning={error ? (row[column.id] === error.value && error["type"] === "integration_warning") : false}
                        warningMessage={error && error.message}
                        showFormHelpers={false}
                        showInnerHelpers
                        value={row[column.id]}
                        fullWidth
                        onOpenSearchHelp={() => {
                            setRowAction({
                                type: "search-help",
                                row: row,
                                field: column.id
                            })
                        }}
                        onChange={e => {
                            const value = e.target.value;
                            editCriticalProfile(row, column.id, value);
                        }} />
                )
            }

            case "delete":
                return (
                    <IconButton
                        disabled={disabled}
                        onClick={() => deleteCriticalProfile(row.id)}
                        sx={{
                            border: "1px solid #B7B7B7",
                            borderRadius: "3px",
                            width: "34px",
                            height: "34px",
                        }}
                    >
                        <TrashIcon color="#B7B7B7" width="34px" height="34px" />
                    </IconButton>
                );
            
            case "boolean":
                return (
                    <Checkbox
                        disabled={disabled}
                        checked={row[column.id]}
                        sx={{
                            "&.Mui-checked": {
                                color: "gray"
                            },
                        }}
                        onClick={() => {
                            editCriticalProfile(row, column.id, !row[column.id]);
                        }}
                    />
                );

            default:
                return createRowCellContentInner(row, column, selected, onSelect);
        }
    }, [editCriticalProfile, deleteCriticalProfile, disabled]);


    const columns = useMemo(() => [
        {
            id: "select",
            type: "select",
        },
        {
            id: "system",
            title: columnTexts.system,
            type: "search-help",
        },
        {
            id: "profile",
            title: columnTexts.criticalProfile,
            type: "search-help",
        },
        {
            id: "description",
            title: columnTexts.description,
            type: "text",
            width: "40%"
        },
        {
            id: "active",
            title: columnTexts.active,
            type: "boolean"
        },
        {
            id: "delete",
            type: "delete",
        }
    ], [columnTexts]);

    const getSearchHelpData = useCallback((params, signal) => {
        switch (rowAction?.field) {
            case "system":
                return getSystemsSearchHelp(params, signal);

            case "profile":
                return getCriticalProfilesSearchHelp(params, signal, rowAction?.row?.system);
        }
    }, [rowAction]);

    const searchHelpColumns = {
        "system": [
            { id: "key", title: columnTexts.operation },
            { id: "text", title: columnTexts.description }
        ],
        "profile": [
            { id: "key", title: columnTexts.operation },
            { id: "text", title: columnTexts.description }
        ],
    };

    const {
        rows, onFilter
    } = useLocalTable(criticalProfiles, false);

    const handleSelectRow = useCallback((row, value) => {
        if (value) {
            setSelectedIds(selectedIds => [...selectedIds, row.id]);
            return;
        }

        setSelectedIds(selectedIds => selectedIds.filter(id => id !== row.id));
    }, []);

    const handleSelectAll = useCallback((value) => {
        if (!value) {
            setSelectedIds([]);
            return;
        }

        setSelectedIds(rows.map(risk => risk.id));
    }, [rows]);

    return (
        <>
            <EntityFormViewTable
                rows={rows}
                columns={columns}
                toolbarActions={toolbarActions}
                selectedIds={selectedIds}
                onSelectRow={handleSelectRow}
                onSelectAll={handleSelectAll}
                createRowCellContent={createRowCellContent}
                errorIndex={errorIndex}
                setErrorScroll={setErrorScroll}
            />

            {rowAction?.type === "search-help" && (
                <SearchHelpDialog
                    defaultSearchString={rowAction.row[rowAction.field] || ""}
                    open={rowAction && rowAction.type === "search-help"}
                    readRows={getSearchHelpData}
                    columns={searchHelpColumns[rowAction.field]}
                    selectOneRow={true}
                    onSubmit={(selectedRow) => {
                        editCriticalProfile(rowAction.row, rowAction.field, selectedRow.key)
                        setRowAction(null)
                    }}
                    onCancel={() => {
                        setRowAction(null)
                    }}
                />
            )}
        </>
    )
}

CriticalProfilesView.propTypes = {
    criticalProfiles: PropTypes.arrayOf(PropTypes.object),
    addCriticalProfile: PropTypes.func,
    deleteCriticalProfile: PropTypes.func,
    deleteCriticalProfileMass: PropTypes.func,
    editCriticalProfile: PropTypes.func,
    disabled: PropTypes.bool,
    validationErrors: PropTypes.object,
    errorIndex: PropTypes.number,
    setErrorScroll: PropTypes.func
}

export default CriticalProfilesView