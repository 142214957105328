import React from "react";
import { IconButton } from "@mui/material";
import PropTypes from "prop-types";

import { CloseIcon, EditIcon } from "../../common/icons";

import "./styles.less";

const PfcgViewActionButton = ({ children, onClick }) => {
    return (
        <IconButton
            className="pfcg-view-action-button"
            onClick={onClick}
        >
            {children}
        </IconButton>
    );
};

const PfcgViewEditButton = ({ onClick }) => {
    return (
        <PfcgViewActionButton onClick={onClick}>
            <EditIcon />
        </PfcgViewActionButton>
    );
};

const PfcgViewResetButton = ({ onClick }) => {
    return (
        <PfcgViewActionButton onClick={onClick}>
            <CloseIcon />
        </PfcgViewActionButton>
    );
};

PfcgViewActionButton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func
};

PfcgViewEditButton.propTypes = {
    onClick: PropTypes.func
};

PfcgViewResetButton.propTypes = {
    onClick: PropTypes.func
};

export { PfcgViewEditButton, PfcgViewResetButton };