import React from "react";
import { SvgIcon } from "@mui/material";

const PinIcon = (props) => (
    <SvgIcon
        viewBox="0 0 6 16"
        {...props}
    >
        <svg
            viewBox="0 0 6 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.83827 8.77034C0.368616 8.33288 0.371697 7.5879 0.844951 7.15433L6 2.43166V13.5782C5.99802 13.5764 5.99605 13.5746 5.99407 13.5727L0.83827 8.77034Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

export default PinIcon;
