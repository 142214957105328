import React from "react";
import { Outlet } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import { NavigationTabs } from "../common/tabs"
import breakpoints from "../common/styles/breakpoints.js";

const tabs = [
    {
        id: "critical-roles",
        titleId: "critical-rules-page.tabs.crit-roles.title",
        route: "critical-roles"
    },
    {
        id: "critical-profiles",
        titleId: "critical-rules-page.tabs.crit-profiles.title",
        route: "critical-profiles"
    },
];

const CriticalRulesPage = () => {
    const matches1024 = useMediaQuery(`(min-width:  ${breakpoints.minLaptopBreakpoint})`);

    return(
        <>
            <NavigationTabs
                tabs={tabs}
                oneVisibleTab={!matches1024}
            />
            <Outlet />
        </>
    );
};

export default CriticalRulesPage;
