import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";

import { Button } from "../buttons";
import CommonTable from "../table/table.jsx";

import { CommonDialog, CommonDialogTitle, CommonDialogContent, CommonDialogActions } from "./common-dialog.jsx";

import "./style.less";

const UserLogDialog = ({title, open, logs, onClose, ...props}) => {
    const intl = useIntl();

    const innerTitle = title || intl.formatMessage({ id: "common.user-log-dialog.title" })

    const columns = [
        {
            id: "user",
            title: "Автор изменения",
            type: "text"
        },
        {
            id: "upd_date",
            title: "Дата и время изменения",
            type: "datetime"
        },
        {
            id: "action",
            title: "Операция",
            type: "text"
        },
        {
            id: "fieldname",
            title: "Атрибут",
            type: "text"
        },
        {
            id: "old_value",
            title: "Старое значение",
            type: "text"
        },
        {
            id: "new_value",
            title: "Новое значение",
            type: "text"
        },
        {
            id: "additional_information",
            title: "Дополнительная информация",
            type: "text"
        }

    ];

    return(
        <CommonDialog open={!!open} {...props} className="user-log-dialog">
            <CommonDialogTitle
                title={innerTitle}
                onClose={onClose}
            />
            
            <CommonDialogContent sx={{marginTop: "10px"}}>
                <CommonTable
                    className="user-logs-table"
                    rows={logs}
                    columns={columns}
                    getRowId={row => row.id}
                    selectedIds={[]}
                    toolbarActions={null}
                    showFiltersActions={null}
                    showPagination={false}
                />
            </CommonDialogContent>

            <CommonDialogActions>
                <Button
                    variant="outlined"
                    onClick={onClose}
                    sx={{
                        color: 'var(--font-4)',
                        borderColor: 'var(--font-4)',
                    }}
                >
                    <FormattedMessage id="common.btn-close" />
                </Button>
            </CommonDialogActions>
        </CommonDialog>
    )
};

UserLogDialog.propTypes = {
    title: PropTypes.node,
    open: PropTypes.bool,
    logs: PropTypes.arrayOf(PropTypes.object),
    onClose: PropTypes.func,
}

export default UserLogDialog;
