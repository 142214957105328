import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import PropTypes from "prop-types";

const RequireAction = ({ actionId }) => {
    const user = useSelector(state => state.auth.user);
    const hasAction = user?.actions?.some(action => action.id === actionId);

    if (hasAction) {
        return <Outlet />;  
    } else {
        return <Navigate to="/forbidden" />
    }
};

RequireAction.propTypes = {
    actionId: PropTypes.string
};

export default RequireAction;
