import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

import { PageContentBox } from "../../../../common/page-content-box";
import { SelectInput, TextInput } from "../../../../common/form-controls";
import { ADD_MODE, EntityFormSubtitle } from "../../../../common/entity-form";
import { MenuItem } from "../../../../common/menu";
import { SYSTEM_TYPES } from "./system-form-page.jsx";

const SystemIdInput = ({ id, setId, disabled, error }) => {
    const intl = useIntl();

    return(
        <TextInput
            label={intl.formatMessage({ id: "system-form-page-system.input-system-id" })}
            value={id}
            onChange={(event) => setId(event.target.value)}
            disabled={disabled}
            error={error?.value === id}
            errorMessage={error?.message}
        />
    );
};

const SystemTypeInput = ({ systemTypes, systemTypeId, setSystemTypeId, disabled, error }) => {
    const intl = useIntl();

    return(
        <SelectInput
            label={intl.formatMessage({ id: "system-form-page-system.input-system-type" })}
            value={systemTypeId}
            onChange={(event) => setSystemTypeId(event.target.value)}
            disabled={disabled}
            error={error ? systemTypeId === error.value: false}
            errorMessage={error && error.message}
        >
            {systemTypes.map(systemType => (
                <MenuItem
                    value={systemType.id}
                    key={systemType.id}
                >
                    {systemType.text}
                </MenuItem>
            ))}
        </SelectInput>
    );
};

const SystemDescriptionInput = ({ description, setDescription, disabled, error }) => {
    const intl = useIntl();

    return(
        <TextInput
            multiline
            rows={3}
            label={intl.formatMessage({ id: "system-form-page-system.input-description" })}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            disabled={disabled}
            error={error?.value === description}
            errorMessage={error?.message}
        />
    );
};

const SystemFormPageSystem = ({ form, setForm, disabled, mode, validationErrors }) => {
    const setId = value => setForm({...form, id: value});
    const setDescription = value => setForm({...form, description: value});
    const setSystemTypeId = value => setForm(form => {
        let authTypeId = form.authTypeId;

        if (value === SYSTEM_TYPES.FC ) {
            authTypeId = "HTTP"
        }

        return {...form, authTypeId: authTypeId, systemTypeId: value}
    });
    const idDisabled = mode !== ADD_MODE;

    return(
        <PageContentBox className="system-form-page-system">
            <EntityFormSubtitle>
                <FormattedMessage id="system-form-page-system.title" />
            </EntityFormSubtitle>

            <Box className="system-form-page-system-inputs">
                <SystemIdInput 
                    id={form.id} 
                    setId={setId} 
                    disabled={idDisabled}
                    error={validationErrors && validationErrors["system_id"]} />

                <SystemTypeInput
                    systemTypes={form.systemTypes}
                    systemTypeId={form.systemTypeId}
                    setSystemTypeId={setSystemTypeId}
                    disabled={idDisabled}
                    error={validationErrors && validationErrors["system_type"]}
                />

                <SystemDescriptionInput
                    description={form.description}
                    setDescription={setDescription}
                    disabled={disabled}
                    error={validationErrors && validationErrors["description"]}
                />
            </Box>
        </PageContentBox>
    );
};

SystemIdInput.propTypes = {
    id: PropTypes.string,
    setId: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object,
};

SystemTypeInput.propTypes = {
    systemTypes: PropTypes.arrayOf(PropTypes.object),
    systemTypeId: PropTypes.string,
    setSystemTypeId: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object,
};

SystemDescriptionInput.propTypes = {
    description: PropTypes.string,
    setDescription: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object,
};

SystemFormPageSystem.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func,
    disabled: PropTypes.bool,
    mode: PropTypes.string,
    validationErrors: PropTypes.object,
}

export default SystemFormPageSystem;