import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { Button } from "../../common/buttons";
import ReportParameters from "../../sod-reports/report-parameters";

import "./styles.less";

const ReportModelingParameters = ({
    children,
    title,
    open,
    applyDisabled=false,
    onOpenClick,
    onShowVariantsClick,
    onApplyClick
}) => {
    const intl = useIntl();
    const applyTitle = intl.formatMessage({ id: "common.btn-apply" });

    return (
        <ReportParameters
            title={title}
            open={open}
            onOpenClick={onOpenClick}
            onShowVariantsClick={onShowVariantsClick}
        >
            {children}

            <Button
                className="report-modeling-parameters-apply"
                variant="contained"
                disabled={applyDisabled}
                onClick={onApplyClick}
            >
                {applyTitle}
            </Button>
        </ReportParameters>
    );
};

ReportModelingParameters.propTypes = {
    ...ReportParameters.propTypes,
    onApplyClick: PropTypes.func
};

export default ReportModelingParameters;