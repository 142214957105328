import React from "react";

import {
    AnalyzeButton,
    ReportActionsBox,
    SaveTemplateButton,
    ColumnSettingsButton
} from "../../sod-reports/report-actions";
import PropTypes from "prop-types";

const ReportsModelingActions = ({
    onSubmitClick,
    onSaveClick,
    onColumnSettingsClick,
    isAnalyzeDisabled,
}) => {

    return(
        <ReportActionsBox>
            <AnalyzeButton onClick={onSubmitClick} disabled={isAnalyzeDisabled}/>
            <SaveTemplateButton onClick={onSaveClick} />
            <ColumnSettingsButton onClick={onColumnSettingsClick} />
        </ReportActionsBox>
    );
};

ReportsModelingActions.propTypes = {
    onSubmitClick: AnalyzeButton.propTypes.onClick,
    onSaveClick: SaveTemplateButton.propTypes.onClick,
    onColumnSettingsClick: ColumnSettingsButton.propTypes.onClick,
    isAnalyzeDisabled: PropTypes.bool
};

export default ReportsModelingActions;