import React, { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

import { systemsService } from "../../../../services/systems-service";
import { integrationService } from "../../../../services/integration-service";
import { busynessProcessesService } from "../../../../services/busyness-processes-service.js";

import { SearchHelpInput, TextInput } from "../../../common/form-controls";
import SearchHelpDialog from "../../../common/search-helps";
import { ADD_MODE, EntityFormSubtitle, READ_MODE } from "../../../common/entity-form";

import FunctionFormPageViews from "./function-form-page-views.jsx";
import { getSystemsExtended, getBusynessProcessesSearchHelp } from "../../../../utils/searchHelps";


const FunctionIdInput = ({ id, setId, disabled, error }) => {
    const intl = useIntl();

    return (
        <TextInput
            label={intl.formatMessage({ id: "function-form-page-function.input-function-id" })}
            value={id}
            onChange={(event) => setId(event.target.value)}
            disabled={disabled}
            error={error?.value === id}
            errorMessage={error?.message}
        />
    );
};


const FunctionDescriptionInput = ({ description, setDescription, disabled, error }) => {
    const intl = useIntl();

    return (
        <TextInput
            multiline
            rows={3}
            label={intl.formatMessage({ id: "function-form-page-function.input-description" })}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            disabled={disabled}
            error={error?.value === description}
            errorMessage={error?.message}
        />
    );
};

const SystemInput = ({ systemId, setSystemId, disabled, error }) => {
    const intl = useIntl();

    const title = useMemo(
        () => intl.formatMessage({ id: "function-form-page-function.input-system" }),
        [intl]
    );

    const columns = useMemo(
        () => [
            { id: "key", title: intl.formatMessage({ id: "search-help.columns.system" }) },
            { id: "text", title: intl.formatMessage({ id: "search-help.columns.description" }) }
        ],
        [intl]
    );

    const [open, setOpen] = useState(false);

    return (
        <>
            <SearchHelpInput
                label={title}
                value={systemId}
                error={error ? systemId === error.value: false}
                errorMessage={error && error.message}
                disabled={disabled}
                onChange={(event) => setSystemId(event.target.value)}
                onOpenSearchHelp={() => { setOpen(true) }}
            />

            <SearchHelpDialog
                defaultSearchString={systemId}
                title={title}
                columns={columns}
                open={open}
                readRows={getSystemsExtended}
                onSubmit={(row) => {
                    setSystemId(row.key);
                    setOpen(false);
                }}
                onCancel={() => {
                    setOpen(false)
                }}
                selectOneRow={true}
            />
        </>
    );
};


const BusynessProcessInput = ({ busynessProcessId, setBusynessProcessId, disabled, error }) => {
    const intl = useIntl();

    const title = useMemo(
        () => intl.formatMessage({ id: "function-form-page-function.input-busyness-process" }),
        [intl]
    );

    const columns = useMemo(
        () => [
            { id: "key", title: intl.formatMessage({ id: "search-help.columns.busyness-process" }) },
            { id: "text", title: intl.formatMessage({ id: "search-help.columns.description" }) }
        ],
        [intl]
    );

    const [open, setOpen] = useState(false);

    return (
        <>
            <SearchHelpInput
                label={title}
                value={busynessProcessId}
                error={error ? busynessProcessId === error.value: false}
                errorMessage={error && error.message}
                disabled={disabled}
                onChange={(event) => setBusynessProcessId(event.target.value)}
                onOpenSearchHelp={() => { setOpen(true) }}
            />

            <SearchHelpDialog
                defaultSearchString={busynessProcessId}
                title={title}
                columns={columns}
                open={open}
                readRows={getBusynessProcessesSearchHelp}
                onSubmit={(row) => {
                    setBusynessProcessId(row.key);
                    setOpen(false);
                }}
                onCancel={() => {
                    setOpen(false)
                }}
                selectOneRow={true}
            />
        </>
    );
};

const FunctionFormPageFunction = ({
    form,
    mode,
    setForm,
    validationErrors,
    setValidationErrors,
    errorScroll,
    setErrorScroll
}) => {
    const setId = value => setForm({ ...form, id: value });
    const setDescription = value => setForm({ ...form, description: value });

    const setSystemId = async (value) => {
        let systemError;
        let system;
        let isGroup;

        if (value) {
            try {
                system = await systemsService.getSystemExtendedDetailed(value);
                isGroup = system.is_group
            } catch (e) {
                systemError = e;
            }
        }

        const newSystemId = system?.id ?? value;

        let textMap;

        if (!systemError) {
            try {
                const transactions = form.operations.map(item => item.operation)
                textMap = await integrationService.getTransactionsTextsMass(newSystemId, transactions, isGroup);
            } catch (e) {
                console.error(e);
            }
        }

        setForm(form => {
            const newOperations = form.operations.map(operation => ({
                ...operation,
                description: textMap?.[operation.operation] ?? ""
            }));

            return {
                ...form,
                systemId: newSystemId,
                isGroup: isGroup,
                operations: newOperations
            }
        });

        setValidationErrors(errors => {
            const newErrors = {...errors};

            if (systemError) {
                newErrors.system_id = {
                    value: newSystemId,
                    message: systemError.detail ?? systemError.title
                };
            } else {
                delete newErrors.system_id;
            }

            return newErrors;
        });
    };


    const setBusynessProcessId = async (value) => {
        let processError;
        let process;

        if (value) {
            try {
                process = await busynessProcessesService.getBusynessProcessDetailed(value);
            } catch (e) {
                processError = e;
            }
        }

        const newProcessId = process?.id ?? value;

        setForm(form => {
            return {
                ...form,
                process: newProcessId,
            }
        });

        setValidationErrors(errors => {
            const newErrors = {...errors};

            if (processError) {
                newErrors.business_process_id = {
                    value: newProcessId,
                    message: processError.detail ?? processError.title
                };
            } else {
                delete newErrors.business_process_id;
            }

            return newErrors;
        });
    };

    const formDisabled = mode === READ_MODE;
    const idDisabled = mode !== ADD_MODE;
    const viewsDisabled = formDisabled || !form.systemId || validationErrors?.system_id;

    return (
        <>
            <EntityFormSubtitle className="function-form-page-function-subtitle">
                <FormattedMessage id="function-form-page-function.title" />
            </EntityFormSubtitle>

            <Box className="function-form-page-function-inputs">
                <FunctionIdInput
                    id={form.id}
                    setId={setId}
                    disabled={idDisabled}
                    error={validationErrors && validationErrors["function_id"]}
                />

                <FunctionDescriptionInput
                    description={form.description}
                    setDescription={setDescription}
                    disabled={formDisabled}
                    error={validationErrors && validationErrors["description"]}
                />

                <BusynessProcessInput
                    busynessProcessId={form.process}
                    setBusynessProcessId={setBusynessProcessId}
                    disabled={formDisabled}
                    error={validationErrors && validationErrors["business_process_id"]}
                    />

                <SystemInput
                    systemId={form.systemId}
                    setSystemId={setSystemId}
                    disabled={formDisabled}
                    error={validationErrors && validationErrors["system_id"]}
                />
            </Box>

            <FunctionFormPageViews
                form={form}
                setForm={setForm}
                formDisabled={viewsDisabled}
                validationErrors={validationErrors}
                errorScroll={errorScroll}
                setErrorScroll={setErrorScroll}
            />
        </>
    );
};

FunctionFormPageFunction.propTypes = {
    form: PropTypes.object,
    mode: PropTypes.string,
    setForm: PropTypes.func,
    validationErrors: PropTypes.object,
    setValidationErrors: PropTypes.func,
    errorScroll: PropTypes.object,
    setErrorScroll: PropTypes.func
};

FunctionIdInput.propTypes = {
    id: PropTypes.string,
    setId: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object
};

FunctionDescriptionInput.propTypes = {
    description: PropTypes.string,
    setDescription: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object
};

SystemInput.propTypes = {
    systems: PropTypes.arrayOf(PropTypes.object),
    systemId: PropTypes.string,
    setSystemId: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object
};

BusynessProcessInput.propTypes = {
    busynessProcessId: PropTypes.string,
    setBusynessProcessId: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object
}

export default FunctionFormPageFunction;
