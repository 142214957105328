import React, { useCallback,useEffect, useMemo, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";

import { editSystem, createSystem, deleteSystem, clearCurrentSystem, changeSystemPassword } from "../../../../../reducers/admin/systemsSlice"
import { setSubmit, fetchSystemDetailed, setInitialCurrentSystem } from "../../../../../reducers/admin/systemsSlice";
import { setError } from "../../../../../reducers/admin/systemsSlice";

import { ChangePasswordDialog, DeleteConfirmDialog, DeleteDialog, ProgressDialog } from "../../../../common/dialogs";
import { ErrorsDialog, UserLogDialog } from "../../../../common/dialogs"
import { ADD_MODE, EDIT_MODE, READ_MODE, getCurrentMode } from "../../../../common/entity-form";
import useLogs from "../../../../common/hooks/useLogs";

import SystemFormPageSystem from "./system-form-page-system.jsx";
import SystemFormPageAuth from "./system-form-page-auth.jsx";
import SystemFormPageHeader from "./system-form-page-header.jsx";

import "./style.less";


const AUTH_TYPES = {
    RFC: "RFC",
    HTTP: "HTTP"
}

const authTypes = [
    { id: AUTH_TYPES.RFC, text: <FormattedMessage id="system-form-page.input-integration-type.rfc" /> },
    { id: AUTH_TYPES.HTTP, text: <FormattedMessage id="system-form-page.input-integration-type.http" /> }
];

const SYSTEM_TYPES = {
    SAP: "SAP",
    FC: "1C"
}

const systemTypes = [
    { id: SYSTEM_TYPES.SAP, text: <FormattedMessage id="system-form-page.input-system-type.sap" /> },
    { id: SYSTEM_TYPES.FC, text: <FormattedMessage id="system-form-page.input-system-type.1c" /> }
];

const DIALOG_TYPES = {
    DELETE: "DELETE",
    DELETE_CONFIRM: "DELETE_CONFIRM",
    CHANGE_PASSWORD: "CHANGE_PASSWORD"
};

const SystemFormPage = () => {
    const { systemId } = useParams();
    const dispatch = useDispatch();
    const intl = useIntl();
    const navigate = useNavigate();

    const { logs, getLogs, openLogs, setOpenLogs } = useLogs("system", systemId) 

    const busy = useSelector(state => state.admin.systems.busy);
    const busyType = useSelector(state => state.admin.systems.busyType);
    const error = useSelector(state => state.admin.systems.error);
    const submit = useSelector(state => state.admin.systems.submit);
    const system = useSelector(state => state.admin.systems.currentSystem);
    const systemEditable = useSelector(state => state.admin.systems.currentSystemEditable);
    const systemDeleted = useSelector(state => state.admin.systems.currentSystemDeleted);
    const validationErrors = useSelector(state => state.admin.systems.validationErrors);

    const [form, setForm] = useState(null);
    const [dialogType, setDialogType] = useState(null);

    const mode = getCurrentMode(!systemId, systemEditable);
    const formDisabled = mode === READ_MODE;

    useEffect(() => {
        if (systemId) {
            dispatch(fetchSystemDetailed(systemId));
        } else {
            dispatch(setInitialCurrentSystem());
        }

        return (() => {
            dispatch(clearCurrentSystem());
        });
    }, [systemId, dispatch]);

    useEffect(() => {
        if (!system) {
            return;
        }

        setForm({
            systemTypes,
            authTypes,
            id: system?.id || "",
            description: system?.description || "",
            systemTypeId: system?.systemTypeId || systemTypes[0].id,
            sapSystemId: system?.sapSystemId || "",
            authTypeId: system?.authTypeId || authTypes[0].id,
            login: system?.login || "",
            password: system?.password || "",
            host: system?.host || "",
            mandt: system?.mandt || "",
            instanceNumber: system?.instanceNumber || "",
            sapRouter: system?.sapRouter || "",
            url: system?.url || "",
        });
    }, [system]);

    useEffect(() => {
        if (!submit) return;

        dispatch(setSubmit(false))

        if (mode === ADD_MODE || systemDeleted) {
            handleBackClick();
        }
    }, [submit, dispatch, mode, systemDeleted, handleBackClick]);


    const [formErrors, setFormErrors] = useState(null)

    useEffect(() => {
        if (!validationErrors) return;

        const formErrosTmp = validationErrors.reduce((accumulator, error) => {
            accumulator[error.field] = error;

            return accumulator
        }, {});

        setFormErrors(formErrosTmp)

    }, [validationErrors, form])

    const handleSaveSystem = () => {
        const formSystem = {
            id: form.id?.trim(),
            description: form.description,
            login: form.login,
            password: mode === EDIT_MODE ? null : form.password,
            authTypeId: form.authTypeId,
            systemTypeId: form.systemTypeId,
        };

        if (formSystem.id !== form.id) {
            setForm({
                ...form,
                id: formSystem.id
            });
        }

        formSystem.host = form.host;
        formSystem.mandt = form.mandt;
        formSystem.instanceNumber = form.instanceNumber;
        formSystem.sapRouter = form.sapRouter;
        formSystem.sapSystemId = form.sapSystemId;
        formSystem.url = form.url;

        if (mode === EDIT_MODE) {
            dispatch(editSystem(formSystem));
        } else {
            dispatch(createSystem(formSystem));
        }
    };

    const backPath = useMemo(() => (mode === ADD_MODE
        ? ".."
        : "../.."
    ), [mode]);

    const handleBackClick = useCallback(() => {
        navigate(backPath, { relative: "path" });
    }, [navigate, backPath]);

    const handleDeleteClick = () => {
        setDialogType(DIALOG_TYPES.DELETE);
    };

    const handleDialogCancel = () => {
        setDialogType(null);
    }

    const handleDeleteDialogDelete = () => {
        setDialogType(DIALOG_TYPES.DELETE_CONFIRM);
    };

    const handleDeleteConfirmDialogDelete = () => {
        dispatch(deleteSystem(systemId));
        setDialogType(null);
    };

    const handleEditClick = () => {
        dispatch(fetchSystemDetailed(systemId));
    };

    const handleChangePasswordClick = () => {
        setDialogType(DIALOG_TYPES.CHANGE_PASSWORD);
    };

    const handleChangePasswordConfirm = (newPassword) => {
        dispatch(changeSystemPassword({ systemId, newPassword }));
        setDialogType(null);
    };

    if (!system && error) return <Navigate to={backPath} relative="path" />;

    return (
        <>
            {form && (
                <>
                    <SystemFormPageHeader
                        mode={mode}
                        systemId={systemId}
                        onBackClick={handleBackClick}
                        onDeleteClick={handleDeleteClick}
                        onSaveClick={handleSaveSystem}
                        onEditClick={handleEditClick}
                        onChangePasswordClick={handleChangePasswordClick}
                        onShowLogsClick={getLogs}
                    />

                    <SystemFormPageSystem
                        form={form}
                        setForm={setForm}
                        mode={mode}
                        disabled={formDisabled}
                        validationErrors={formErrors}
                    />

                    <SystemFormPageAuth
                        form={form}
                        setForm={setForm}
                        disabled={formDisabled}
                        validationErrors={formErrors}
                        mode={mode}
                    />
                </>
            )}

            <ProgressDialog
                open={busy}
                busyType={busyType}
            />

            <DeleteDialog
                open={dialogType === DIALOG_TYPES.DELETE}
                title={intl.formatMessage({ id: "delete-system-dialog.title" })}
                text={intl.formatMessage(
                    { id: "delete-system-dialog.text" },
                    { name: systemId }
                )}
                onCancelClick={handleDialogCancel}
                onDeleteClick={handleDeleteDialogDelete}
            />

            <DeleteConfirmDialog
                open={dialogType === DIALOG_TYPES.DELETE_CONFIRM}
                title={intl.formatMessage({ id: "delete-system-dialog.title" })}
                text={intl.formatMessage(
                    { id: "delete-system-dialog.text" },
                    { name: systemId }
                )}
                onCancelClick={handleDialogCancel}
                onDeleteClick={handleDeleteConfirmDialogDelete}
            />

            <ErrorsDialog
                error={error}
                open={error?.type === "error"}
                onClose={() => { dispatch(setError(null)) }}
            />

            <ChangePasswordDialog
                open={dialogType === DIALOG_TYPES.CHANGE_PASSWORD}
                onCancel={handleDialogCancel}
                onConfirm={handleChangePasswordConfirm}
            />

            <UserLogDialog
                open={openLogs}
                logs={logs}
                onClose={() => setOpenLogs(false)}
            />
        </>
    );
};

export default SystemFormPage;
export {
    SYSTEM_TYPES, AUTH_TYPES
}