import React, { forwardRef } from 'react';
import { Box, Drawer, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import TabsListItem from './tabs-list-item.jsx';
import AppDrawerSearch from "./app-drawer-search.jsx";

const AppDrawerMenu = forwardRef(({
    title,
    tabs,
    activeTab,
    onParentTabClick,
    onLeafTabClick,
    open,
    showSearch
}, ref) => {
    return (
        <Drawer
            ref={ref}
            className="app-drawer-menu"
            variant="persistent"
            anchor="left"
            open={open}
        >
            {showSearch && <AppDrawerSearch />}

            <Typography className="app-drawer-menu-title">{title}</Typography>

            <Box className="tabs-list">
                {tabs.map(tab => (
                    <TabsListItem
                        key={tab.id}
                        tab={tab}
                        activeTab={activeTab}
                        onLeafClick={onLeafTabClick}
                        onParentClick={onParentTabClick}
                    />
                ))}
            </Box>
        </Drawer>
    );
});

AppDrawerMenu.propTypes = {
    title: PropTypes.string,
    tabs: PropTypes.arrayOf(PropTypes.object),
    activeTab: PropTypes.object,
    onParentTabClick: PropTypes.func,
    onLeafTabClick: PropTypes.func,
    open: PropTypes.bool,
    showSearch: PropTypes.bool
}

export default AppDrawerMenu;
