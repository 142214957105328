import { useReducer } from "react";

const initialState = {
    reportLevel: "",
    reportType: "",
    columns: [],
    activeAll: false
};

const INIT_COLUMNS = "INIT_COLUMNS";

export const initColumns = (columns, reportLevel, reportType) => ({
    type: INIT_COLUMNS,
    payload: { columns, reportLevel, reportType }
});

const initColumnsReducer = (_, action) => {
    const { columns, reportLevel, reportType } = action.payload;

    return {
        columns,
        reportLevel,
        reportType,
        activeAll: columns.every(column => column.active)
    };
};

const HANDLE_DRAG_END = "HANDLE_DRAG_END";

export const handleDragEnd = (result) => ({
    type: HANDLE_DRAG_END,
    payload: result
});

const handleDragEndReducer = (state, action) => {
    const result = action.payload;

    if (!result.destination) {
        return state;
    }

    const newColumns = Array.from(state.columns);
    const [removed] = newColumns.splice(result.source.index, 1);
    newColumns.splice(result.destination.index, 0, removed);

    return {
        ...state,
        columns: newColumns
    };
};

const SET_COLUMN_ACTIVE = "SET_COLUMN_ACTIVE";

export const setColumnActive = (columnId, active) => ({
    type: SET_COLUMN_ACTIVE,
    payload: { columnId, active }
});

const setColumnActiveReducer = (state, action) => {
    const { columnId, active } = action.payload;

    const newColumns = state.columns.map(column => (
        column.id === columnId
            ? { ...column, active }
            : column
    ));

    const newSelectedAll = newColumns.every(column => column.active);

    return {
        ...state,
        columns: newColumns,
        activeAll: newSelectedAll
    };
};

const SET_ACTIVE_ALL = "SET_ACTIVE_ALL";

export const setActiveAll = (active) => ({
    type: SET_ACTIVE_ALL,
    payload: active
});

const setActiveAllReducer = (state, action) => {
    const active = action.payload;

    const newColumns = state.columns.map(column => ({
        ...column, active 
    }));

    return {
        ...state,
        columns: newColumns,
        activeAll: active
    };
};

const reducer = (state, action) => {
    switch (action.type) {
        case INIT_COLUMNS:
            return initColumnsReducer(state, action);

        case HANDLE_DRAG_END:
            return handleDragEndReducer(state, action);

        case SET_COLUMN_ACTIVE:
            return setColumnActiveReducer(state, action);

        case SET_ACTIVE_ALL:
            return setActiveAllReducer(state, action);

        default:
            return state;
    }
};

const useColumnsReducer = () => {
    return useReducer(reducer, initialState);
};

export default useColumnsReducer;