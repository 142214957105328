import React from "react";
import PropTypes from "prop-types";

import { EntityFormActions, EntityFormHeader } from "../../../common/entity-form";

import FunctionFormHeaderTitle from "./function-form-header-title.jsx";

const FunctionFormPageHeader = ({
    functionId,
    mode,
    onBackClick,
    onDeleteClick,
    onSaveClick,
    onEditClick,
    onShowLogsClick
}) => {
    return (
        <EntityFormHeader>
            <FunctionFormHeaderTitle
                functionId={functionId}
                mode={mode}
                onBackClick={onBackClick}
            />

            <EntityFormActions
                mode={mode}
                onDeleteClick={onDeleteClick}
                onSaveClick={onSaveClick}
                onEditClick={onEditClick}
                onShowLogsClick={onShowLogsClick}
            />
        </EntityFormHeader>
    );
};

FunctionFormPageHeader.propTypes = {
    functionId: PropTypes.string,
    mode: PropTypes.string,
    onBackClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onSaveClick: PropTypes.func,
    onEditClick: PropTypes.func,
    onShowLogsClick: PropTypes.func
}

export default FunctionFormPageHeader;
