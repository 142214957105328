import React from "react";
import { Pagination as MuiPagination } from "@mui/material";
import PropTypes from "prop-types";

import "./styles.less";

const Pagination = ({
    className="",
    count,
    page,
    onChange
}) => {
    const classNameInner = `pagination ${className}`;

    return (
        <MuiPagination
            className={classNameInner}
            count={count}
            page={page}
            shape="rounded"
            onChange={onChange}
        />
    );
};

Pagination.propTypes = {
    className: PropTypes.string,
    count: PropTypes.number,
    page: PropTypes.number,
    onChange: PropTypes.func
};

export default Pagination;