import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

import UserMenu from "./user-menu.jsx";
import AppDrawerSearch from "./app-drawer-search.jsx";
import AboutAppButton from "../buttons/about-app-button.jsx";

const HeaderBar = ({ showSearch, onAboutAppClick }) => {
    return (
        <Box className="header-bar">
            <AboutAppButton onAboutAppClick={onAboutAppClick}/>
            {showSearch && <AppDrawerSearch />}
            <UserMenu />
        </Box>
    );
};

HeaderBar.propTypes = {
    showSearch: PropTypes.bool,
    onAboutAppClick: PropTypes.func
};

export default HeaderBar;
