import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { ADD_MODE, EDIT_MODE, READ_MODE, EntityFormHeaderTitle } from "../../../common/entity-form";

const MatrixHeadersFormHeaderTitle = ({
    matrixHeaderId,
    mode,
    onBackClick
}) => {
    const intl = useIntl();

    const titleValues ={ matrixHeaderId };

    const titleMap = {
        [ADD_MODE]: intl.formatMessage({ id: "matrix-headers-form-header.add-mode-title" }),
        [EDIT_MODE]: intl.formatMessage({ id: "matrix-headers-form-header.edit-mode-title" }, titleValues),
        [READ_MODE]: intl.formatMessage({ id: "matrix-headers-form-header.view-mode-title" }, titleValues),
    };

    return (
        <EntityFormHeaderTitle
            title={titleMap[mode]}
            onBackClick={onBackClick}
        />
    );
};

MatrixHeadersFormHeaderTitle.propTypes = {
    matrixHeaderId: PropTypes.string,
    mode: PropTypes.string,
    onBackClick: PropTypes.func
};

export default MatrixHeadersFormHeaderTitle;
