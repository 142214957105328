import React from "react";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

import { Button } from "../../../common/buttons";

import { EntitiesSelectDialogSearch } from "../../entities-select-dialog";

import "./styles.less";

const UserLevelModelingRolesToolbar = ({
    onSearch,
    onAddRole,
    onCreateNewRole
}) => {
    const intl = useIntl();
    const addTitle = intl.formatMessage({ id: "user-level-modeling.role-search-help-dialog.toolbar.add" });
    const createTitle = intl.formatMessage({ id: "user-level-modeling.role-search-help-dialog.toolbar.create" });

    return (
        <Box className="user-level-modeling-roles-toolbar">
            <EntitiesSelectDialogSearch onSearchClick={onSearch} />
            <Button
                variant="contained"
                onClick={onAddRole}
            >
                {addTitle}
            </Button>
            <Button
                className="user-level-modeling-roles-toolbar-create"
                variant="contained"
                onClick={onCreateNewRole}
            >
                {createTitle}
            </Button>
        </Box>
    );
};

UserLevelModelingRolesToolbar.propTypes = {
    onSearch: PropTypes.func,
    onAddRole: PropTypes.func,
    onCreateNewRole: PropTypes.func,
};

export default UserLevelModelingRolesToolbar;