import { Radio } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

import { RadioButtonIcon } from "../icons";

const CustomRadio = ({ color, ...props }) => {
    return(
        <Radio
            icon={
                <RadioButtonIcon
                    checked={false}
                    sx={{ color: color }}
                />
            }
            checkedIcon={
                <RadioButtonIcon
                    checked={true}
                    sx={{ color: color }}
                />
            }
            {...props}
        />
    )
};

CustomRadio.propTypes = {
    color: PropTypes.string
};

export default CustomRadio;
