import React from 'react'
import { useIntl } from 'react-intl'
import { ListItemIcon as MuiListItemIcon, ListItemText as MuiListItemText, Menu as MuiMenu, MenuItem as MuiMenuItem, styled } from '@mui/material'
import PropTypes from 'prop-types';

import { CloseIcon, PlusIcon, SortDescArrowIcon, SortAscArrowIcon } from '../icons';

const Menu = styled(MuiMenu)({
    ".MuiPopover-paper": {
        borderRadius: "4px",
        background: "#FFF",
        boxShadow: "0px 7px 15px 0px rgba(0, 0, 0, 0.32)",
    },

    ".MuiMenu-list": {
        padding: 0,
    }
});

const MenuItem = styled(MuiMenuItem)({
    "&.MuiMenuItem-root": {
        padding: "4px 12px 4px 8px",
        gap: "10px",
        alignItems: "center",
        minHeight: 0,

        "&.Mui-focusVisible": {
            backgroundColor: "#F5F5F5"
        }
    }
});

const ListItemText = styled(MuiListItemText)({
    ".MuiTypography-root": {
        fontFamily: "Segoe UI",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "20px"
    }
});

const ListItemIcon = styled(MuiListItemIcon)({
    "&.MuiListItemIcon-root": {
        minWidth: 0,

        ".MuiSvgIcon-root": {
            color: "#939494",
            fontSize: "17px"
        }
    }
});

const FilterMenu = ({
    anchorEl,
    open,
    isFilterable,
    isSortable,
    showResetFilters,
    showResetSort,
    sortSign,
    onClose,
    onAdd,
    onReset,
    onSort,
    onResetSort
}) => {
    const intl = useIntl();

    const items = [];

    if (isFilterable) items.push({
        id: "add",
        Icon: PlusIcon,
        text: intl.formatMessage({ id: "common.table.filter-menu.add" }),
        onClick: onAdd
    })

    if (showResetFilters) items.push({
        id: "reset",
        Icon: CloseIcon,
        text: intl.formatMessage({ id: "common.table.filter-menu.reset" }),
        onClick: onReset
    });

    if (isSortable) items.push(sortSign === "desc" ?
        {
            id: "sortAsc",
            Icon: SortAscArrowIcon,
            text: intl.formatMessage({ id: "common.table.filter-menu.sort" }),
            onClick: onSort
        } :
        {
            id: "sortDesc",
            Icon: SortDescArrowIcon,
            text: intl.formatMessage({ id: "common.table.filter-menu.sort" }),
            onClick: onSort
        }
    );

    if (showResetSort) {
        items.push({
            id: "resetSort",
            Icon: CloseIcon,
            text: intl.formatMessage({ id: "common.table.filter-menu.reset-sort" }),
            onClick: onResetSort
        });
    }

    return (
        <Menu
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
        >
            {items.map(item => (
                <MenuItem
                    key={item.id}
                    onClick={item.onClick}
                >
                    <ListItemIcon>
                        <item.Icon />
                    </ListItemIcon>
                    <ListItemText>
                        {item.text}
                    </ListItemText>
                </MenuItem>
            ))}
        </Menu>
    );
};

FilterMenu.propTypes = {
    anchorEl: PropTypes.any,
    open: PropTypes.bool,
    isFilterable: PropTypes.bool,
    isSortable: PropTypes.bool,
    showResetFilters: PropTypes.bool,
    showResetSort: PropTypes.bool,
    sortSign: PropTypes.string,
    onClose: PropTypes.func,
    onAdd: PropTypes.func,
    onReset: PropTypes.func,
    onSort: PropTypes.func,
    onResetSort: PropTypes.func
}

export default FilterMenu;
