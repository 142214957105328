import React, { forwardRef, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Box, ClickAwayListener, Popper, Typography, styled, useMediaQuery } from "@mui/material";
import { Wheel, ShadeSlider, hexToHsva, hsvaToHex } from '@uiw/react-color';
import PropTypes from "prop-types";

import { SelectIcon } from "../icons";
import { Button } from "../buttons";
import breakpoints from "../styles/breakpoints.js";

import InputLabel from "./input-label.jsx";
import FormControl from "./form-control.jsx";
import Input from "./input.jsx";

const ColorBox = styled(Box)({
    border: "1px solid rgba(0, 0, 0, 0.52)",
    borderRadius: "2px",
    boxSizing: "border-box",
    cursor: "pointer",
});

const ColorPickerPopper = styled(Popper)({
    display: "flex",
    flexDirection: "column",
    backgroundColor: "var(--font-3)",
    boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.25)",
    border: "1px solid var(--stroke-1)",
    borderRadius: "8px",
    width: "224px",
    boxSizing: "border-box",
    padding: "0 10px 18px",
    zIndex: 2
});

const DefaultColorPicker = forwardRef(({
    setColor,
    onPickOwnColorClick,
    anchorEl,
    open
}, ref) => {
    const matches1920 = useMediaQuery(`(min-width: ${breakpoints.minDesktopBreakpoint})`);

    const defaultColors = [
        "#000000",
        "#555555",
        "#999999",
        "#AAAAAA",
        "#FFFFFF",
    ];

    return(
        <ColorPickerPopper
            ref={ref}
            anchorEl={anchorEl}
            open={open}
            placement={matches1920 ? "bottom-start" : "bottom-end"}
            sx={{
                padding: "12px 14px 16px"
            }}
        >
            <Typography sx={{
                fontFamily: 'Mulish',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '150%',
                color: 'var(--font-4)'
            }}>
                <FormattedMessage id="color-input.text" />
            </Typography>

            <Box sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "4px",
                marginTop: "6px"
            }}>
                {defaultColors.map(color => (
                    <ColorBox
                        key={color}
                        sx={{
                            width: "16px",
                            height: "16px",
                            backgroundColor: color
                        }}
                        onClick={() => setColor(color)}
                    />
                ))}
            </Box>

            <Button
                variant="contained"
                sx={{
                    width: "100%",
                    fontWeight: 500,
                    fontSize: "12px",
                    marginTop: "24px",
                    backgroundColor: "var(--font-4)",
                    '&:hover': {
                        backgroundColor: "var(--font-4)",
                    }
                }}
                onClick={onPickOwnColorClick}
            >
                <FormattedMessage id="color-input.btn-choose-color" />
            </Button>
        </ColorPickerPopper>
    );
});

const ShadesSliderPointer = ({ prefixCls, top }) => (
    <Box
      className={`${prefixCls}-custom-pointer`}
      sx={{
        width: "8px",
        height: "8px",
        backgroundColor: "#FFF",
        position: "absolute",
        top: top,
        transform: "translateY(-4px) translateX(2px)",
        borderRadius: "4px",
        border: "1px solid rgba(0, 0, 0, 0.52)",
        boxSizing: "border-box"
      }}
    />
);

const NewColorPicker = ({
    color,
    setColor,
    anchorEl,
    open
}) => {
    const matches1920 = useMediaQuery(`(min-width: ${breakpoints.minDesktopBreakpoint})`);

    const [hsva, setHsva] = useState(hexToHsva(color));
    const [newColor, setNewColor] = useState(color);

    useEffect(() => {
        if (newColor.length > 3) {
            setHsva(hexToHsva(newColor));
        }
    }, [newColor]);

    return(
        <ColorPickerPopper
            anchorEl={anchorEl}
            open={open}
            placement={matches1920 ? "right-start" : "bottom-start"}
            sx={{
                padding: "14px 14px 15px 14px"
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between"
                }}
            >
                <Wheel
                    color={hsva}
                    onChange={(newColor) => setNewColor(newColor.hex)}
                    width={174}
                    height={174}
                />

                <ShadeSlider
                    hsva={hsva}
                    onChange={(newShade) => {
                        setNewColor(hsvaToHex({ ...hsva, ...newShade }));
                    }}
                    direction="vertical"
                    width={12}
                    height={174}
                    radius={4}
                    pointer={ShadesSliderPointer}
                />
            </Box>

            <FormControl variant="standard" fullWidth sx={{ marginTop: "25px" }}>
                <InputLabel shrink={false} sx={{ fontSize: "14px" }}>Hex</InputLabel>
                <Input
                    value={newColor?.substring(1)}
                    onChange={event => setNewColor(`#${event.target.value}`)}
                    sx={{ width: "126px" }}
                />
            </FormControl>

            <Button
                variant="contained"
                sx={{
                    width: "100%",
                    fontWeight: 500,
                    fontSize: "12px",
                    marginTop: "12px",
                    backgroundColor: "var(--font-4)",
                    '&:hover': {
                        backgroundColor: "var(--font-4)",
                    }
                }}
                onClick={() => setColor(newColor)}
            >
                <FormattedMessage id="common.btn-save" />
            </Button>
        </ColorPickerPopper>
    );
};

const ColorPickersContainer = ({ anchorEl, open, color, setColor }) => {
    const defaultColors = useRef(null);

    const [showNewColorPicker, setShowNewColorPicker] = useState(false);

    const hadnlePickOwnColorClick = () => {
        setShowNewColorPicker(!showNewColorPicker);
    };

    return (
        <>
            <DefaultColorPicker
                ref={defaultColors}
                setColor={setColor}
                onPickOwnColorClick={hadnlePickOwnColorClick}
                anchorEl={anchorEl}
                open={open}
            />
            {showNewColorPicker && (
                <NewColorPicker
                    color={color}
                    setColor={setColor}
                    anchorEl={defaultColors.current}
                    open={showNewColorPicker}
                    onClose={hadnlePickOwnColorClick}
                />
            )}
        </>
    );
};

const ColorInput = ({ color, setColor, disabled, error, ...props }) => {
    const ref = useRef(null);
    const [showColorPickers, setShowColorPickers] = useState(false);

    const handleColorClick = (pickedColor) => {
        setColor(pickedColor);
        setShowColorPickers(false);
    };

    const handleBoxClick = () => {
        if (disabled) return;

        setShowColorPickers(!showColorPickers);
    };

    const borderColor = error ? "#F35050" : "#ACACAC";

    return(
        <ClickAwayListener onClickAway={() => setShowColorPickers(false)}>
            <Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "5px",
                        padding: "4px 6px",
                        height: "35px",
                        boxSizing: "border-box",
                        backgroundColor: "var(--bg-2)",
                        border: `1px solid ${borderColor}`,
                        borderRadius: "4px",
                        borderBottomLeftRadius: showColorPickers ? 0 : "4px",
                        borderBottomRightRadius: showColorPickers ? 0 : "4px",
                        cursor: "pointer",

                        [`@media (min-width: ${breakpoints.minLaptopBreakpoint})`]: {
                            height: "42px",
                            padding: "7px 6px 8px",
                        }
                    }}
                    ref={ref}
                    onClick={handleBoxClick}
                    {...props}
                >
                    <ColorBox
                        className="color-box"
                        sx={{
                            backgroundColor: color,
                            height: "100%",
                            width: "28px"
                        }}
                    />
                    <SelectIcon
                        sx={{
                            color: "var(--stroke-1)",
                            fontSize: "24px"
                        }}
                    />
                </Box>

                {showColorPickers && (
                    <ColorPickersContainer
                        anchorEl={ref.current}
                        open={showColorPickers}
                        color={color}
                        setColor={handleColorClick}
                        onClose={() => setShowColorPickers(false)}
                    />
                )}
            </Box>
        </ClickAwayListener>
    );
};

DefaultColorPicker.propTypes = {
    setColor: PropTypes.func,
    onPickOwnColorClick: PropTypes.func,
    anchorEl: PropTypes.any,
    open: PropTypes.bool
};

ShadesSliderPointer.propTypes = {
    prefixCls: PropTypes.string,
    top: PropTypes.string,
};

NewColorPicker.propTypes = {
    color: PropTypes.string,
    setColor: PropTypes.func,
    anchorEl: PropTypes.any,
    open: PropTypes.bool
};

ColorPickersContainer.propTypes = {
    anchorEl: PropTypes.any,
    open: PropTypes.bool,
    color: PropTypes.string,
    setColor: PropTypes.func
};

ColorInput.propTypes = {
    color: PropTypes.string,
    setColor: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
};

export default ColorInput;