import React from 'react';
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';

import { selectUserScreenButton } from "../../../reducers/authSlice.js";
import { USER_SCREEN_BUTTONS } from "../../../utils/auth.js";

import Button from './button.jsx';

const DownloadmatrixButton = ({ onClick }) => {
    const downlaodButton = useSelector((state) => selectUserScreenButton(state, USER_SCREEN_BUTTONS.DOWNLOAD_MATRICES));

    if (!downlaodButton){
        return null;
    }

    return (
        <Button
            variant="outlined"
            sx={{
                borderColor: "var(--font-4)",
                color: "var(--font-4)",
            }}
            onClick={onClick}
        >
            {downlaodButton.title}
        </Button>
    );
};

DownloadmatrixButton.propTypes = {
    onClick: PropTypes.func,
};

export default DownloadmatrixButton;