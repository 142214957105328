import React from "react";
import { useSelector } from "react-redux";

import {
    selectModelingOpen,
    setModelingOpen as setModelingOpenAction,
    selectModelingSelectedRole
} from "../../../reducers/reports-modeling/roleLevelSlice";

import useReduxValue from "../../common/hooks/useReduxValue";

import ReportModelingBox from "../report-modeling-box";

import RoleLevelModelingHeader from "./role-level-modeling-header";
import RoleLevelRoleModelingBox from "./role-level-role-modeling-box.jsx";
import RoleLevelGroupRoleModelingBox from "./role-level-group-role-modeling-box.jsx";

const RoleLevelModelingBox = () => {
    const [modelingOpen, setModelingOpen] = useReduxValue(selectModelingOpen, setModelingOpenAction);
    const selectedRole = useSelector(selectModelingSelectedRole);

    const showRoleModelingBox = selectedRole && !selectedRole.isComplex;
    const showGroupRoleModelingBox = selectedRole && selectedRole.isComplex;

    return (
        <ReportModelingBox
            open={modelingOpen}
            onOpenClick={() => setModelingOpen(!modelingOpen)}
        >
            <RoleLevelModelingHeader />

            {showRoleModelingBox && (
                <RoleLevelRoleModelingBox selectedRole={selectedRole} />
            )}

            {showGroupRoleModelingBox && (
                <RoleLevelGroupRoleModelingBox selectedRole={selectedRole} />
            )}
        </ReportModelingBox>
    );
};

export default RoleLevelModelingBox;