import React from "react";
import { SvgIcon } from "@mui/material";

const RightChevronIcon = (props) => (
    <SvgIcon
        viewBox="0 0 8 14"
        {...props}
    >
        <svg
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.00003 6.99633C8.00003 6.71578 7.89739 6.48313 7.67842 6.26416L2.40952 1.11159C2.23161 0.933675 2.01949 0.851562 1.7663 0.851562C1.2531 0.851562 0.835693 1.25528 0.835693 1.76849C0.835693 2.02167 0.945177 2.25432 1.12993 2.43908L5.81036 6.98949L1.12993 11.5536C0.945177 11.7383 0.835693 11.9642 0.835693 12.2242C0.835693 12.7374 1.2531 13.1479 1.7663 13.1479C2.01949 13.1479 2.23161 13.059 2.40952 12.8811L7.67842 7.72851C7.90423 7.50954 8.00003 7.27689 8.00003 6.99633Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

export default RightChevronIcon;
