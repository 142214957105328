import { CircularProgress } from "@mui/material";
import { ErrorIcon, SuccessIcon, WarningIcon} from "../components/common/icons";
import { isValid } from "date-fns";

export const integrationEntityMap = {
    permission_details: "permissionDetail",
    role: "role",
    profile: "profile",
    employee: "employee",
    manual_integration: "manualIntegration",
    common: "common"
};

export const getIntegrationIcon = (status) => {
    switch (status) {
        case "manual":
            return SuccessIcon;

        case "success":
            return SuccessIcon;

        case "error":
        case "connection_lost":
            return ErrorIcon;

        case "pending":
            return CircularProgress;

        default:
            return WarningIcon;
    }
};

export const convertDateToString = (intl, date) => {
    if (!date) {
        return "";
    }

    const dateObj = date instanceof Date
        ? date
        : new Date(date);

    let dateString = isValid(dateObj) ? intl.formatDate(dateObj, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit"
    }) : "";

    return dateString;
};

export const getScheduleTypeTextWithIntl = (intl, type) => {
    return intl.formatMessage({ id: `schedule-maintenance-dialog.schedule-type.${type}` });
};

export const getEntityTextWithIntl = (intl, entity) => {
    switch (entity) {
        case "role":
            return intl.formatMessage({ id: "integration-details-row.roles.text" })
        case "employee":
            return intl.formatMessage({ id: "integration-details-row.users.text" })
        case "profile":
            return intl.formatMessage({ id: "integration-details-row.profiles.text" })
        case "permissionDetail":
            return intl.formatMessage({ id: "integration-system-box.checkbox-permission-details" })
        case "manualIntegration":
            return intl.formatMessage({ id: "integration-details-row.manual-integration.text" })
        default:
            return "";
    }
}

export const getStatusTextWithIntl = (intl, status) => {
    const statusId = `integration-settings-page.status.${status}`;
    return intl.formatMessage({ id: statusId });
}

export const getSystemSyncTitle = (intl, status, lastSyncDate, type) => {
    let titleId = `integration-system-box.sync-status.${type}.`;

    switch (status) {
        case "success":
        case "warning":
        case "error":
            titleId += "true";
            break;
        case "pending":
        case "connection_lost":
            titleId += status;
            break;
        default:
            titleId += "false";
            break;
    }

    const title = intl.formatMessage({ id: titleId });

    if (!lastSyncDate) {
        return title;
    }

    const dateString = convertDateToString(intl, lastSyncDate);

    return dateString ? `${title} ${dateString}` : title;
};

export const getCheckedByEntity = (currentChecked, entity) => {
    const result = {...currentChecked};
    const entityChecked = currentChecked[entity];

    switch (entity) {
        case "profile":
            if (!entityChecked) {
                result.role = false;
                result.employee = false;
            }
            break;

        case "role":
            if (entityChecked) {
                result.profile = true;
            } else {
                result.profile = false;
                result.employee = false;
            }
            break;
        
        case "employee":
            if (entityChecked) {
                result.profile = true;
                result.role = true;
            } else {
                result.profile = false;
                result.role = false;
            }
            break;
    }

    return result;
};
