import React from "react";
import { useIntl } from "react-intl";

import { ErrorPage } from "../../common/pages";

const ForbiddenPage = () => {
    const intl = useIntl();

    const title = intl.formatMessage({ id: "forbidden-page.title" });
    const subtitle = intl.formatMessage({ id: "forbidden-page.subtitle" });
    const description = intl.formatMessage({ id: "forbidden-page.description" });

    return (
        <ErrorPage
            title={title}
            subtitle={subtitle}
            description={description}
        />
    );
};

export default ForbiddenPage;