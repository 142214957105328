import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import {
    fetchBusynessProcesses,
    fetchBusynessProcessColumns,
    setSelectedIds as setSelectedIdsAction,
    setSearchString as setSearchStringAction,
    setPageNumber as setPageNumberAction,
    setLimitNumber as setLimitNumberAction,
    setSortData as setSortDataAction,
    setFilterData as setFilterDataAction,
    setError as setErrorAction,
} from "../../../../../reducers/admin/busynessProcessesSlice";

import useFilters from "../../../../common/hooks/useFilters";
import useReduxTable from "../../../../common/hooks/useReduxTable";
import CommonTable from "../../../../common/table";
import { ProgressDialog, ErrorsDialog } from "../../../../common/dialogs";
import { EmptyTabContent } from "../../../../common/empty-tab-content";

const getCellValue = (row, column) => {
    switch (column.id) {
        case "id":
            return row.id;

        default:
            return row[column.id];
    }
};

const EmptyBusynessProcessPage = ({ handleAddBPClick }) => {
    const intl = useIntl();

    const text = intl.formatMessage({ id: "empty-busyness-processes-page.text" });
    const buttonText = intl.formatMessage({ id: "busyness-processes-list-page.btn-create-busyness-process" });

    return (
        <EmptyTabContent
            text={text}
            buttonText={buttonText}
            onButtonClick={handleAddBPClick}
        />
    );
};

const BusynessProcessesListPage = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    const {
        rows, columns,
        total, busy,
        selectedIds,
        searchString, setSearchString,
        pageNumber, setPageNumber,
        limitNumber, setLimitNumber,
        filterData, setFilterData,
        sortData, setSortData,
        error, setError
    } = useReduxTable(
        (state) => state.admin.busynessProcesses.busynessProcesses, fetchBusynessProcesses,
        (state) => state.admin.busynessProcesses.columns, fetchBusynessProcessColumns,
        (state) => state.admin.busynessProcesses.selectedIds, setSelectedIdsAction,
        (state) => state.admin.busynessProcesses.searchString, setSearchStringAction,
        (state) => state.admin.busynessProcesses.pageNumber, setPageNumberAction,
        (state) => state.admin.busynessProcesses.limitNumber, setLimitNumberAction,
        (state) => state.admin.busynessProcesses.sortData, setSortDataAction,
        (state) => state.admin.busynessProcesses.filterData, setFilterDataAction,
        (state) => state.admin.busynessProcesses.error, setErrorAction,
        (state) => state.admin.busynessProcesses.total,
        (state) => state.admin.busynessProcesses.busy,
    );

    const busyType = useSelector((state) => state.admin.busynessProcesses.busyType);

    const getFilters = useFilters("busynessProcess");

    const handleAddBPClick = () => {
        navigate("form")
    };

    const handleRowClick = (busynessProcess) => {
        navigate(`form/${busynessProcess.id}`)
    };

    const getTableRowId = (row) => row.id;

    const toolbarActions = [
        {
            id: "title",
            title: intl.formatMessage({ id: "busyness-processes-list-page.toolbar.text.title" }),
            type: "text",
            position: "begin"
        },
        {
            id: "add",
            type: "button",
            position: "end",
            title: intl.formatMessage({ id: "busyness-processes-list-page.toolbar.btn-create-busyness-process" }),
            callback: () => {
                handleAddBPClick()
            }
        }
    ];

    if (total === 0 && !searchString && filterData.length === 0 && !busy) {
        return <EmptyBusynessProcessPage handleAddBPClick={handleAddBPClick} />;
    }

    return (
        <>
            <CommonTable
                rows={rows}
                columns={columns}
                filterData={filterData}
                setFilterData={setFilterData}
                sortData={sortData}
                setSortData={setSortData}
                toolbarActions={toolbarActions}
                selectedIds={selectedIds}
                getRowId={getTableRowId}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                limitNumber={limitNumber}
                setLimitNumber={setLimitNumber}
                total={total}
                onRowClick={handleRowClick}
                searchString={searchString}
                setSearchString={setSearchString}
                getCellValue={getCellValue}
                getFilters={getFilters}
            />

            <ProgressDialog
                open={busy}
                busyType={busyType}
            />

            <ErrorsDialog
                error={error}
                open={error?.type === "error"}
                onClose={() => setError(null)}
            />
        </>
    );
};

BusynessProcessesListPage.propTypes = {
    handleAddBPClick: PropTypes.func,
}

EmptyBusynessProcessPage.propTypes = {
    handleAddBPClick: PropTypes.func,
}

export default BusynessProcessesListPage;
