import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import PropTypes from 'prop-types';

import { integrationService } from "../../../../services/integration-service";

import { CloseIcon } from "../../../common/icons";
import { Button } from "../../../common/buttons";
import CommonTable from "../../../common/table";
import useLocalTable from "../../../common/hooks/useLocalTable";

import "./styles.less";

const getDefaultPermissionsForOperation = async (systemId, operation, isGroup=false) => {
    if (!operation.operation) {
        return []
    }

    const filters = [
        { field: "transaction", sign: "EQ", value: operation.operation }
    ];

    if (systemId) {
        filters.push({ field: isGroup ? "groupId": "systemId", sign: "EQ", value: systemId });
    }

    const { rows } = await integrationService.getFunctionDefaultPermissions({
        filters
    });

    if (isGroup){
        return rows.filter(permission => permission.permissionGroup === operation.operation);
    }

    return rows.filter(permission =>
        permission.system === systemId &&
        permission.permissionGroup === operation.operation
    );
};

const OperationsTable = ({ operations, onOperationClick }) => {
    const intl = useIntl();

    const columnTexts = {
        operation: intl.formatMessage({ id: "function-form-page.subtable-column.operation" }),
        description: intl.formatMessage({ id: "function-form-page.subtable-column.description" }),
        active: intl.formatMessage({ id: "function-form-page.subtable-column.active" }),
    };

    const columns = [
        {
            id: "operation",
            title: columnTexts.operation,
            type: "search-help",
        },
        {
            id: "description",
            title: columnTexts.description,
            type: "text",
            width: "60%"
        },
    ];

    const {
        rows, pagination
    } = useLocalTable(operations);

    return (
        <CommonTable
            className="permissions-sync-operations-table"
            rows={rows}
            columns={columns}
            pageNumber={pagination.page}
            setPageNumber={pagination.onSetPage}
            limitNumber={pagination.rowsPerPage}
            setLimitNumber={pagination.onSetRowsPerPage}
            total={pagination.total}
            showFiltersActions={false}
            onRowClick={onOperationClick}
        />
    );
};

const PermissionsTable = ({ permissions, selectedPermissions, onSelectedPermissionsChange }) => {
    useEffect(() => {
        pagination.onSetPage(1);
    }, [permissions]);

    const intl = useIntl();

    const columnTexts = {
        permissionGroup: intl.formatMessage({ id: "function-form-page.subtable-column.permissionGroup" }),
        permission: intl.formatMessage({ id: "function-form-page.subtable-column.permission" }),
        field: intl.formatMessage({ id: "function-form-page.subtable-column.field" }),
        valueFrom: intl.formatMessage({ id: "function-form-page.subtable-column.valueFrom" }),
        valueTo: intl.formatMessage({ id: "function-form-page.subtable-column.valueTo" }),
        active: intl.formatMessage({ id: "function-form-page.subtable-column.active" }),
        description: intl.formatMessage({ id: "function-form-page.subtable-column.description" })
    };

    const columns = [
        {
            id: "select",
            type: "select",
        },
        {
            id: "permissionGroup",
            title: columnTexts.permissionGroup,
            type: "text",
        },
        {
            id: "permission",
            title: columnTexts.permission,
            type: "text"
        },
        {
            id: "field",
            title: columnTexts.field,
            type: "text",
        },
        {
            id: "valueFrom",
            title: columnTexts.valueFrom,
            type: "text"
        },
        {
            id: "valueTo",
            title: columnTexts.valueTo,
            type: "text"
        }
    ];

    const {
        rows, pagination
    } = useLocalTable(permissions);

    const selectedIds = selectedPermissions.map(permission => permission.id);

    const handleSelectAll = (value) => {
        if (!value) {
            onSelectedPermissionsChange([]);
            return;
        }

        const newSelectedPermissions = permissions.filter(
            permission => !selectedIds.includes(permission.id)
        );

        onSelectedPermissionsChange([...selectedPermissions, ...newSelectedPermissions]);
    };

    const handleSelectRow = (selectedPermission, value) => {
        if (value) {
            onSelectedPermissionsChange([...selectedPermissions, selectedPermission]);
        } else {
            onSelectedPermissionsChange(selectedPermissions.filter(
                permission => permission.id !== selectedPermission.id
            ));
        }
    };

    return (
        <CommonTable
            className="permissions-sync-permissions-table"
            rows={rows}
            columns={columns}
            selectedIds={selectedIds}
            onSelectRow={handleSelectRow}
            onSelectAll={handleSelectAll}
            pageNumber={pagination.page}
            setPageNumber={pagination.onSetPage}
            limitNumber={pagination.rowsPerPage}
            setLimitNumber={pagination.onSetRowsPerPage}
            total={pagination.total}
            showFiltersActions={false}
        />
    );
};

const PermissionsSyncDialog = ({
    systemId,
    operations,
    open,
    onCancelClick,
    onSyncClick,
    isGroup
}) => {
    const intl = useIntl();

    const title = intl.formatMessage({ id: "permission-view.sync-dialog.title" });
    const cancelTitle = intl.formatMessage({ id: "common.btn-cancel" });
    const syncTitle = intl.formatMessage({ id: "common.btn-apply" });

    const [selectedOperation, setSelectedOperation] = useState(null);
    const [fetchedPermissions, setFetchedPermissions] = useState({});
    const [selectedPermissions, setSelectedDefaultValues] = useState([]);

    const handleOperationClick = (operation) => {
        const fetchPermissionDefaultValues = async () => {
            const newPermissions = await getDefaultPermissionsForOperation(systemId, operation, isGroup);
    
            setFetchedPermissions((oldFetchedPermissions) => ({
                ...oldFetchedPermissions,
                [operation.operation]: newPermissions
            }));
        };
        
        if (!(operation.operation in fetchedPermissions)) {
            fetchPermissionDefaultValues();
        }

        setSelectedOperation(operation.operation);
    };

    useEffect(() => {
        setFetchedPermissions({});
        setSelectedOperation(null);
        setSelectedDefaultValues([]);
    }, [systemId, operations, open]);

    const handleSyncClick = () => onSyncClick(selectedPermissions);

    const currentOperations = useMemo(() => {
        const operationSet = new Set();
        const operationsDistinct = [];
    
        operations.forEach(operation => {
            const key = operation.operation;

            if (operationSet.has(key)) {
                return;
            }
    
            operationSet.add(key)
            operationsDistinct.push(operation)
        });
    
        return operationsDistinct;
    }, [operations]);

    const currentPermissions = selectedOperation && fetchedPermissions[selectedOperation]
        ? fetchedPermissions[selectedOperation]
        : [];

    return (
        <Dialog
            fullWidth
            maxWidth={false}
            open={open}
            sx={{
                ".MuiDialog-paper": {
                    height: "100%"
                }
            }}
        >
            <DialogTitle>
                {title}
                <IconButton onClick={onCancelClick}>
                    <CloseIcon color="#B7B7B7" />
                </IconButton>
            </DialogTitle>

            <DialogContent
                sx={{
                    display: "flex"
                }}
            >
                <OperationsTable
                    operations={currentOperations}
                    onOperationClick={handleOperationClick}
                />

                <PermissionsTable
                    permissions={currentPermissions}
                    selectedPermissions={selectedPermissions}
                    onSelectedPermissionsChange={setSelectedDefaultValues}
                />
            </DialogContent>

            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={onCancelClick}
                    sx={{
                        color: 'var(--font-4)',
                        borderColor: 'var(--font-4)',
                    }}
                >
                    {cancelTitle}
                </Button>

                <Button
                    variant="contained"
                    onClick={handleSyncClick}
                    sx={{
                        color: 'var(--bg-2)',
                        backgroundColor: 'var(--font-2)',
                        '&:hover': {
                            backgroundColor: 'var(--font-2)',
                        }
                    }}
                >
                    {syncTitle}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

PermissionsSyncDialog.propTypes = {
    systemId: PropTypes.string,
    open: PropTypes.bool,
    onCancelClick: PropTypes.func,
    onSyncClick: PropTypes.func,
    operations: PropTypes.array,
    isGroup: PropTypes.bool
};

PermissionsTable.propTypes = {
    permissions: PropTypes.arrayOf(PropTypes.object),
    selectedPermissions: PropTypes.arrayOf(PropTypes.object),
    onSelectedPermissionsChange: PropTypes.func
};

OperationsTable.propTypes = {
    operations: PropTypes.arrayOf(PropTypes.object),
    onOperationClick: PropTypes.func
};

export default PermissionsSyncDialog;