import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";

import { CommonDialog, CommonDialogActions, CommonDialogContent, CommonDialogTitle } from "../../common/dialogs";

//import "./styles.less";
import { Button } from "../../common/buttons";
import { Input } from "../../common/form-controls";

const GenerateUsersDialog = ({
    open,
    onApply,
    onClose
}) => {
    const intl = useIntl();
    const title = intl.formatMessage({ id: "show-variants-dialog.title" })

    const [template, setTemplate] = useState("")
    const [maxCount, setMaxCount] = useState(0)

    const handleMaxCountEnter = (e) => {
        const value = parseInt(e.target.value)

        if (isNaN(value)){
            setMaxCount(0)
            return
        }

        setMaxCount(value)
    }

    return (
        <CommonDialog
            className="show-variants-dialog"
            open={open}
        >
            <CommonDialogTitle
                title={title}
                onClose={onClose}
            />

            <CommonDialogContent>

                <Input
                    value={template}
                    onChange={e => setTemplate(e.target.value)} />

                <Input
                    value={maxCount}
                    onChange={handleMaxCountEnter} />

            </CommonDialogContent>

            <CommonDialogActions>

                <Button
                    variant="outlined"
                    onClick={onClose}
                    sx={{
                        color: 'var(--font-4)',
                        borderColor: 'var(--font-4)',
                    }}
                >
                    <FormattedMessage id="common.btn-close" />
                </Button>

                <Button
                    variant="outlined"
                    onClick={() => onApply(template, maxCount)}
                    sx={{
                        color: 'var(--font-4)',
                        borderColor: 'var(--font-4)',
                    }}
                >
                    <FormattedMessage id="common.btn-apply" />
                </Button>

            </CommonDialogActions>
        </CommonDialog>
    );
};

GenerateUsersDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onApply: PropTypes.func,
};

export default GenerateUsersDialog;