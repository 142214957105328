import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { getTransactionsSearchHelp } from "../../../utils/searchHelps";

import SearchHelpDialog from "../../common/search-helps";

const AddNodeDialog = ({
    open,
    systemId,
    onClose,
    onAdd
}) => {
    const intl = useIntl();

    const readRows = (params, signal) => getTransactionsSearchHelp(params, signal, systemId);

    const columns = [
        { id: "key", title: intl.formatMessage({ id: "role-menu.add-node-dialog.column-transaction.text" }) },
        { id: "text", title: intl.formatMessage({ id: "role-menu.add-node-dialog.column-description.text" }) }
    ];
    
    const title = intl.formatMessage({ id: "role-menu.add-node-dialog.title" });

    return (
        <SearchHelpDialog
            open={open}
            readRows={readRows}
            columns={columns}
            selectOneRow={true}
            onSubmit={(selectedRow) => onAdd(selectedRow.text, selectedRow.key)}
            onCancel={onClose}
            title={title}
        />
    );
};

AddNodeDialog.propTypes = {
    open: PropTypes.bool,
    systemId: PropTypes.string,
    onClose: PropTypes.func,
    onAdd: PropTypes.func
};

export default AddNodeDialog;