import React, { useId } from "react";
import PropTypes from "prop-types";

import DropdownSearchInput from "./dropdown-search-input.jsx";
import InputWrapper from "./input-wrapper.jsx";
import TagsList from "./tags-list.jsx";

const defaultGetListItemText = (item) => `${item.id} ${item.description}`;
const defaultGetListItemKey = (item) => item.id;
const defaultGetTagText = (key) => key;

const DropdownSearchHelpInput = ({
    className="",
    fullWidth=true,
    shrink=true,
    label,
    ids,
    setIds,
    disabled,
    getData,
    dataKey="rows",
    getTagText=defaultGetTagText,
    getListItemText=defaultGetListItemText,
    getListItemKey=defaultGetListItemKey,
    error=false,
    errorMessage,
    warning=false,
    warningMessage,
    showFormHelpers=true,
    showInnerHelpers=false,
    visibleRowCount,
    icon
}) => {
    const id = useId();

    const handleSelectKey = (key, checked, item) => {
        if (checked) {
            setIds([...ids, key], item);
        } else {
            setIds(ids.filter(id => id !== key), item);
        }
    };

    const handleDeleteKey = (key) => {
        if (disabled) return;
        handleSelectKey(key, false);
    };

    const showSearchInput = !disabled;
    
    if (disabled && ids.length === 0) return null;

    return (
        <InputWrapper
            id={id}
            className={className}
            fullWidth={fullWidth}
            shrink={shrink}
            label={label}
            error={error}
            errorMessage={errorMessage}
            warning={warning}
            warningMessage={warningMessage}
            showFormHelpers={showFormHelpers}
            InputComponent={showSearchInput && (
                <DropdownSearchInput
                    id={id}
                    selectedKeys={ids}
                    onSelectKey={handleSelectKey}
                    getItemKey={getListItemKey}
                    getItemText={getListItemText}
                    dataKey={dataKey}
                    getData={getData}
                    fullWidth={fullWidth}
                    error={error}
                    errorMessage={errorMessage}
                    warning={warning}
                    warningMessage={warningMessage}
                    showInnerHelpers={showInnerHelpers}
                    visibleRowCount={visibleRowCount}
                    icon={icon}
                />
            )}
            EndComponent={
                <TagsList
                    ids={ids}
                    onDelete={handleDeleteKey}
                    getTagText={getTagText}
                />
            }
        />
    );
};

DropdownSearchHelpInput.propTypes = {
    className: PropTypes.string,
    fullWidth: PropTypes.bool,
    shrink: PropTypes.bool,
    ids: PropTypes.arrayOf(PropTypes.string),
    setIds: PropTypes.func,
    disabled: PropTypes.bool,
    getData: PropTypes.func,
    dataKey: PropTypes.string,
    label: PropTypes.any,
    getTagText: PropTypes.func,
    getListItemText: PropTypes.func,
    getListItemKey: PropTypes.func,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    warning: PropTypes.bool,
    warningMessage: PropTypes.string,
    showFormHelpers: PropTypes.bool,
    showInnerHelpers: PropTypes.bool,
    visibleRowCount: PropTypes.number,
    icon: PropTypes.node
};

export default DropdownSearchHelpInput;