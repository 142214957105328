import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import {
    selectModelingSelectedUserId,
    setModelingSelectedRoleByName,
    selectModelingSelectedRole,
} from "../../../../reducers/reports-modeling/userLevelSlice";

import { ReportModelingHeaderSearchHelpInput } from "../../report-modeling-header";

import UserLevelModelingRolesDialog from "../user-level-modeling-roles-dialog";

const UserLevelModelingRoleSearchHelp = () => {
    const [open, setOpen] = useState(false);
    const intl = useIntl();

    const label = intl.formatMessage({ id: "role-level-modeling-role-search-help.label" });

    const dispatch = useDispatch();

    const selectedRole = useSelector(selectModelingSelectedRole);
    const selectedUserId = useSelector(selectModelingSelectedUserId);

    const handleChange = (event) => {
        dispatch(setModelingSelectedRoleByName(
            event.target.value
        ));
    };

    return (
        <>
            <ReportModelingHeaderSearchHelpInput
                disabled={!selectedUserId}
                label={label}
                value={selectedRole ? selectedRole.role : ""}
                onChange={handleChange}
                onOpenSearchHelp={() => setOpen(true)}
            />

            {open && (
                <UserLevelModelingRolesDialog
                    open={open}
                    onClose={() => setOpen(false)}
                />
            )}
        </>
    );
};

export default UserLevelModelingRoleSearchHelp;