import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    fetchIntegrationItems,
    setError as setErrorAction,
    setSearchString as setSearchStringAction,
    setPageNumber as setPageNumberAction,
    setLimitNumber as setLimitNumberAction,
} from "../../../reducers/admin/integrationSlice";

import useReduxValue from "./useReduxValue.js";

const useIntegrationTable = () => {
    const dispatch = useDispatch();

    const rows = useSelector((state) => state.admin.integration.items);
    const total = useSelector((state) => state.admin.integration.total);
    const busy = useSelector((state) => state.admin.integration.busy);

    const [searchString, setSearchString] = useReduxValue(
        (state) => state.admin.integration.searchString,
        setSearchStringAction
    );

    const [pageNumber, setPageNumber] = useReduxValue(
        (state) => state.admin.integration.pageNumber,
        setPageNumberAction
    );

    const [limitNumber, setLimitNumber] = useReduxValue(
        (state) => state.admin.integration.limitNumber,
        setLimitNumberAction
    );

    const [error, setError] = useReduxValue(
        (state) => state.admin.integration.error,
        setErrorAction
    );

    useEffect(() => {
        dispatch(fetchIntegrationItems({
            params: {
                search: searchString,
                page: pageNumber,
                limit: limitNumber
            }
        }));
    }, [dispatch, searchString, pageNumber, limitNumber]);

    return {
        rows, total, busy,
        searchString, setSearchString,
        pageNumber, setPageNumber,
        limitNumber, setLimitNumber,
        error, setError,
    };
};

export default useIntegrationTable;
