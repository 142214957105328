import { Checkbox as MuiCheckbox, styled } from "@mui/material";

const Checkbox = styled(MuiCheckbox)({
    height: "18px",
    borderRadius: "2px",
    "&.Mui-checked": {
        color: "#B7B7B7"
    },
    "&.MuiCheckbox-indeterminate": {
        color: "#B7B7B7"
    }
})

export default Checkbox;