import React from "react";
import { useIntl } from "react-intl";
import { Collapse, Typography, useMediaQuery } from "@mui/material";
import PropTypes from 'prop-types';

import { PageContentBox } from "../../common/page-content-box";
import { Button } from "../../common/buttons";
import breakpoints from "../../common/styles/breakpoints";

import ReportAccordion from "../report-accordion";

import "./style.less";

const ShowVariantsButton = ({ onClick }) => {
    const intl = useIntl();
    const title = intl.formatMessage({ id: "show-variants-dialog.title" });

    return (
        <Button
            className="show-variants-button"
            variant="contained"
            onClick={onClick}
        >
            {title}
        </Button>
    );
};

const ReportParameters = ({
    title,
    open,
    onOpenClick,
    children,
    onShowVariantsClick
}) => {
    const intl = useIntl();
    const matches1024 = useMediaQuery(`(min-width: ${breakpoints.minLaptopBreakpoint})`);

    const accordionTitle = intl.formatMessage({ id: "report-parameters.show-parameters" });

    return (
        <PageContentBox className="report-parameters-box">
            <Typography
                component="h2"
                className="report-page-title"
            >
                {title}
            </Typography>

            {!matches1024 && (
                <ShowVariantsButton
                    onClick={onShowVariantsClick}
                />
            )}

            <ReportAccordion
                open={open}
                onOpenClick={onOpenClick}
                title={accordionTitle}
            />

            <Collapse
                className="report-parameters-box-collapse"
                in={open}
            >
                {children}
            </Collapse>
        </PageContentBox>
    );
};

ShowVariantsButton.propTypes = {
    onClick: PropTypes.func,
};

ReportParameters.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    onOpenClick: PropTypes.func,
    onShowVariantsClick: PropTypes.func,
    children: PropTypes.node
};

export default ReportParameters;
