import React from "react";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

import { CommonDialog, CommonDialogActions, CommonDialogContent, CommonDialogTitle } from "../../common/dialogs";
import { Button } from "../../common/buttons";

import { permissionObjectPropTypes, fieldPropTypes } from "./prop-types";
import ReportModelingEditDialogHeader from "./report-modeling-edit-dialog-header.jsx";

import "./styles.less";

const ReportModelingEditDialog = ({
    children,
    open,
    field,
    permissionObject,
    onClose,
    onApply,
    onScroll
}) => {
    const intl = useIntl();
    const title = intl.formatMessage({ id: `report-modeling-edit-dialog.title.${field.valueType}` });
    const applyTitle = intl.formatMessage({ id: "common.btn-apply" });
    const cancelTitle = intl.formatMessage({ id: "common.btn-cancel" });

    return (
        <CommonDialog
            className="report-modeling-edit-dialog"
            open={open}
        >
            <CommonDialogTitle
                title={title}
                onClose={onClose}
            />
            <CommonDialogContent onScroll={onScroll}>
                <Box className="report-modeling-edit-dialog-content-wrapper">
                    <ReportModelingEditDialogHeader
                        field={field}
                        permissionObject={permissionObject}
                    />

                    {children}
                </Box>
            </CommonDialogContent>
            <CommonDialogActions>
                <Button
                    className="report-modeling-edit-dialog-cancel-button"
                    variant="outlined"
                    onClick={onClose}
                >
                    {cancelTitle}
                </Button>
                <Button
                    className="report-modeling-edit-dialog-apply-button"
                    variant="contained"
                    onClick={onApply}
                >
                    {applyTitle}
                </Button>
            </CommonDialogActions>
        </CommonDialog>
    );
};

ReportModelingEditDialog.propTypes = {
    children: PropTypes.node,
    open: PropTypes.bool,
    field: fieldPropTypes,
    permissionObject: permissionObjectPropTypes,
    onClose: PropTypes.func,
    onApply: PropTypes.func,
    onScroll: PropTypes.func
};

export default ReportModelingEditDialog;