import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { criticalRuleService } from "../services/critical-rule-service";
import LockManager from "../utils/lockManager";
import { BUSY_TYPES } from "../utils/busy";

const lockManager = new LockManager("critical-role");

const initialState = {
    roles: [],
    columns: [],
    rolesTotal: 0,
    selectedIds: [],
    searchString: "",
    pageNumber: 1,
    limitNumber: 10,
    sortData: [],
    filterData: [],
    busy: false,
    busyType: null,
    error: null,
    submit: false,
    currentRole: null,
    currentRoleEditable: false,
    currentRoleDeleted: false,
    validationErrors: null,
}

export const fetchRoles = createAsyncThunk(
    "roles/fetchRoles",
    async ({ params }) => {
        const response = await criticalRuleService.getCriticalRoles(params);
        return response;
    }
);

export const fetchRoleColumns = createAsyncThunk(
    "roles/fetchRoleColumns",
    async () => {
        const response = await criticalRuleService.getCriticalRoleColumns();
        return response;
    }
);

export const fetchRoleDetailed = createAsyncThunk(
    "roles/fetchRoleDetailed",
    async ({ name, system }) => {
        const type = "critical_role"
        const role = await criticalRuleService.getCriticalRoleDetailed(name, system);
        
        const [lock, relatedObjsLocks] = await Promise.allSettled([
            lockManager.lockObject({ name, system }),
            criticalRuleService.checkRelatedObjsLock(type, name, system)
        ]);
        
        return { 
            role, 
            lock: lock.value,
            relatedObjsLocks: relatedObjsLocks.status === "rejected" ? relatedObjsLocks.reason : null
        };
    }
);

export const createRole = createAsyncThunk(
    "roles/createRole",
    async ({role, warningCheck=true}, { rejectWithValue }) => {
        let response

        try {
            response = await criticalRuleService.createCriticalRole(role, warningCheck);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return response;
    }
);

export const editRole = createAsyncThunk(
    "roles/editRole",
    async ({role, warningCheck=true}, { rejectWithValue }) => {
        let response

        try {
            response = await criticalRuleService.editCriticalRole(role, warningCheck);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return response;
    }
);

export const deleteRole = createAsyncThunk(
    "roles/deleteRole",
    async (role, { rejectWithValue }) => {
        try {
            await criticalRuleService.deleteCriticalRole(role);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return role;
    }
);

export const clearCurrentRole = createAsyncThunk(
    "role/clearCurrentRole",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        const currentRole = state.roles.currentRole;
        const currentRoleEditable = state.roles.currentRoleEditable;
        const currentRoleDeleted = state.roles.currentRoleDeleted;

        if (!currentRole) return thunkAPI.rejectWithValue(["Current role is not initialized"]);

        if (currentRoleDeleted || !currentRoleEditable || !currentRole.name) return;

        const lockResponse = await lockManager.unlockObject({
            name: currentRole.name,
            system: currentRole.system
        });

        if (!lockResponse.result) {
            return thunkAPI.rejectWithValue(lockResponse.messages)
        }
    }
);

const roleSlice = createSlice({
    name: "role",
    initialState: initialState,
    reducers: {
        setSubmit(state, action){
            state.submit = action.payload;
        },

        setError(state, action) {
            state.error = action.payload;
        },

        setValidationErrors(state, action) {
            state.validationErrors = action.payload;
        },

        setInitialCurrentRole(state) {
            state.currentRole = {
                name: "",
                description: "",
                active: true,
                system: null,
                riskLevel: null,
                matrices: []
            };

            state.error = null;
            state.validationErrors = null;
            state.submit = false;
            state.currentRoleEditable = true;
            state.currentRoleDeleted = false;
        },

        setSelectedIds(state, action) {
            state.selectedIds = action.payload;
        },

        setSearchString(state, action) {
            if (state.searchString !== action.payload) {
                state.pageNumber = 1;
                state.searchString = action.payload;
            }
        },

        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },

        setLimitNumber(state, action) {
            state.pageNumber = 1;
            state.limitNumber = action.payload;
        },

        setSortData(state, action) {
            state.pageNumber = 1;
            state.sortData = action.payload;
        },

        setFilterData(state, action) {
            state.pageNumber = 1;
            state.filterData = action.payload;
        },
    },
    extraReducers: {
        //get all roles
        [fetchRoles.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchRoles.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },

        [fetchRoles.fulfilled]: (state, action) => {
            state.busy = false;
            state.roles = action.payload.roles;
            state.rolesTotal = action.payload.total;
            state.error = null;
            state.validationErrors = null;
        },

        // delete role
        [deleteRole.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
        },

        [deleteRole.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },

        [deleteRole.fulfilled]: (state, action) => {
            state.busy = false;
            state.error = null;
            state.validationErrors = null;

            const role = action.payload;
            state.roles = state.roles.filter(item => !(item.name === role.name && item.system === role.system));

            if (state.currentRole?.name === role.name && state.currentRole?.system === role.system) {
                state.submit = true;
                state.currentRoleDeleted = true;
            }
        },

        // clear current role
        [clearCurrentRole.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [clearCurrentRole.fulfilled]: (state) => {
            state.busy = false;
            state.currentRole = null;
            state.currentRoleEditable = false;
            state.currentRoleDeleted = false;
            state.error = null;
            state.validationErrors = null;
            state.submit = false;
        },

        [clearCurrentRole.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },

        // create role
        [createRole.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
            state.submit = false;
        },

        [createRole.rejected]: (state, action) => {
            state.busy = false;
            const error = action.payload;

            if (!error) return;

            const validationCodes = new Set(["no_integr_objects", "validation"])

            if (error.code && validationCodes.has(error.code)){
                state.validationErrors = error.errors;
            }

            state.error = error
        },

        [createRole.fulfilled]: (state, action) => {
            state.busy = false;
            state.submit = true;
            state.currentRole = action.payload;
            state.error = null;
            state.validationErrors = null;
        },

        // edit role
        [editRole.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
            state.submit = false;
        },

        [editRole.rejected]: (state, action) => {
            state.busy = false;
            const error = action.payload;

            if (!error) return;

            const validationCodes = new Set(["no_integr_objects", "validation"])

            if (error.code && validationCodes.has(error.code)){
                state.validationErrors = error.errors;
            }

            state.error = error
        },

        [editRole.fulfilled]: (state, action) => {
            state.busy = false;
            state.submit = true;
            state.currentRole = action.payload;
            state.error = null;
            state.validationErrors = null;
        },

        // fetch role detailed
        [fetchRoleDetailed.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.currentRoleDeleted = false;
            state.submit = false;
        },

        [fetchRoleDetailed.fulfilled]: (state, action) => {
            const { role, lock, relatedObjsLocks } = action.payload;

            state.currentRole = role;
            state.busy = false;
            state.currentRoleEditable = lock.result;
            state.error = lock.result ? relatedObjsLocks : lock.messages;
        },

        [fetchRoleDetailed.rejected]: (state, action) => {
            state.currentRole = null;
            state.error = action.error;
            state.busy = false;
            state.currentRoleEditable = false;
        },

        [fetchRoleColumns.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchRoleColumns.fulfilled]: (state, action) => {
            state.busy = false;
            state.columns = action.payload;
        },

        [fetchRoleColumns.rejected]: (state, action) => {
            state.busy = false;
            state.errors = action.error;
        },
    }
})

export const {
    setSubmit, setInitialCurrentRole, setError, setValidationErrors,
    setSelectedIds, setSearchString, setPageNumber, setLimitNumber,
    setSortData, setFilterData
} = roleSlice.actions;

export default roleSlice.reducer;
