import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { matrixService } from "../services/matrix-service";
import LockManager from "../utils/lockManager";
import { BUSY_TYPES } from "../utils/busy";

const lockManager = new LockManager("matrix-header");

const initialState = {
    matrixHeaders: [],
    columns: [],
    total: 0,
    selectedIds: [],
    searchString: "",
    pageNumber: 1,
    limitNumber: 10,
    sortData: [],
    filterData: [],
    busy: false,
    busyType: null,
    error: null,
    submit: false,
    currentMatrixHeader: null,
    currentMatrixHeaderEditable: false,
    currentMatrixHeaderDeleted: false,
    validationErrors: null,
};

export const fetchMatrixHeaders = createAsyncThunk(
    "matrixHeaders/fetchMatrixHeaders",
    async ({ params }) => {
        const response = await matrixService.getMatrixHeaders(params);
        return response;
    }
);

export const fetchMatrixHeaderColumns = createAsyncThunk(
    "matrixHeaders/fetchMatrixHeaderColumns",
    async () => {
        const response = await matrixService.getMatrixHeaderColumns();
        return response;
    }
);

export const fetchMatrixHeaderDetailed = createAsyncThunk(
    "matrixHeaders/fetchMatrixHeaderDetailed",
    async (matrixHeaderId) => {
        const matrixHeader = await matrixService.getMatrixHeaderDetailed(matrixHeaderId);

        const [lock, relatedObjsLocks] = await Promise.allSettled([
            lockManager.lockObject(matrixHeaderId),
            matrixService.checkRelatedObjsLock(matrixHeaderId)
        ]);
        
        return {
            matrixHeader,
            lock: lock.value,
            relatedObjsLocks: relatedObjsLocks.status === "rejected" ? relatedObjsLocks.reason : null
        };
    }
);

export const syncMatrixWithSap = createAsyncThunk(
    "matrixHeaders/syncMatrixWithSap",
    async (matrixHeaderId, {rejectWithValue}) => {
        let response

        try {
            response = await matrixService.syncMatrixWithSap(matrixHeaderId);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return response;
    }
);

export const downloadMatrix = createAsyncThunk(
    "reports/userLevel/downloadMatrix",
    async (matrixHeaderId, { rejectWithValue }) => {
        try {
            await matrixService.downloadMatrix(matrixHeaderId);
        } catch (error) {
            rejectWithValue(error);
        }
    }
);

export const createMatrixHeader = createAsyncThunk(
    "matrixHeaders/createMatrixHeader",
    async (matrixHeader, { rejectWithValue }) => {
        let response

        try {
            response = await matrixService.createMatrixHeader(matrixHeader);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return response;
    }
);

export const editMatrixHeader = createAsyncThunk(
    "matrixHeaders/editMatrixHeader",
    async (matrixHeader, { rejectWithValue }) => {
        let response

        try {
            response = await matrixService.editMatrixHeader(matrixHeader);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return response;
    }
);

export const deleteMatrixHeader = createAsyncThunk(
    "matrixHeaders/deleteMatrixHeader",
    async (matrixHeaderId, { rejectWithValue }) => {
        try {
            await matrixService.deleteMatrixHeader(matrixHeaderId);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return matrixHeaderId;
    }
);

export const clearCurrentMatrixHeader = createAsyncThunk(
    "matrixHeaders/clearCurrentMatrixHeader",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        const currentMatrixHeader = state.matrixHeaders.currentMatrixHeader;
        const currentMatrixHeaderEditable = state.matrixHeaders.currentMatrixHeaderEditable;
        const currentMatrixHeaderDeleted = state.matrixHeaders.currentMatrixHeaderDeleted;

        if (!currentMatrixHeader) return thunkAPI.rejectWithValue(["Current matrixHeader is not initialized"]);

        if (currentMatrixHeaderDeleted || !currentMatrixHeaderEditable || !currentMatrixHeader.id) return;

        const lockResponse = await lockManager.unlockObject(currentMatrixHeader.id);

        if (!lockResponse.result) {
            return thunkAPI.rejectWithValue(lockResponse.messages)
        }
    }
);

const matrixHeadersSlice = createSlice({
    name: "matrixHeaders",

    initialState,

    reducers: {
        setSubmit(state, action){
            state.submit = action.payload;
        },

        setError(state, action) {
            state.error = action.payload;
        },

        setValidationErrors(state, action) {
            state.validationErrors = action.payload;
        },

        setInitialCurrentMatrixHeader(state) {
            state.currentMatrixHeader = {
                id: "",
                description: "",
                risks: [],
                criticalRoles: [],
                criticalProfiles: [],
            }

            state.error = null;
            state.validationErrors = null;
            state.submit = false;
            state.currentMatrixHeaderEditable = true;
            state.currentMatrixHeaderDeleted = false;
        },

        setSelectedIds(state, action) {
            state.selectedIds = action.payload;
        },

        setSearchString(state, action) {
            if (state.searchString !== action.payload) {
                state.pageNumber = 1;
                state.searchString = action.payload;
            }
        },

        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },

        setLimitNumber(state, action) {
            state.pageNumber = 1;
            state.limitNumber = action.payload;
        },

        setSortData(state, action) {
            state.pageNumber = 1;
            state.sortData = action.payload;
        },

        setFilterData(state, action) {
            state.pageNumber = 1;
            state.filterData = action.payload;
        },
    },

    extraReducers: {
        // fetch matrixHeaders
        [fetchMatrixHeaders.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchMatrixHeaders.fulfilled]: (state, action) => {
            state.matrixHeaders = action.payload.matrixHeaders;
            state.total = action.payload.total;
            state.busy = false;
            state.error = null;
            state.validationErrors = null;
        },

        [fetchMatrixHeaders.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },

        // fetch matrixHeader detailed
        [fetchMatrixHeaderDetailed.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.currentMatrixHeaderDeleted = false;
            state.submit = false;
        },

        [fetchMatrixHeaderDetailed.fulfilled]: (state, action) => {
            const { matrixHeader, lock, relatedObjsLocks } = action.payload;

            state.currentMatrixHeader = matrixHeader;
            state.busy = false;
            state.currentMatrixHeaderEditable = lock.result;
            state.error = lock.result ? relatedObjsLocks : lock.messages;
        },

        [fetchMatrixHeaderDetailed.rejected]: (state, action) => {
            state.currentMatrixHeader = null;
            state.error = action.error;
            state.busy = false;
            state.currentMatrixHeaderEditable = false;
        },

        // clear current matrixHeader
        [clearCurrentMatrixHeader.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [clearCurrentMatrixHeader.fulfilled]: (state) => {
            state.busy = false;
            state.currentMatrixHeader = null;
            state.currentMatrixHeaderEditable = false;
            state.currentMatrixHeaderDeleted = false;
            state.error = null;
            state.validationErrors = null;
            state.submit = false;
        },

        [clearCurrentMatrixHeader.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },

        // create matrixHeader
        [createMatrixHeader.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
            state.submit = false;
        },
        
        [createMatrixHeader.fulfilled]: (state, action) => {
            state.currentMatrixHeader = action.payload;
            state.busy = false;
            state.submit = true;
            state.error = null;
            state.validationErrors = null;
        },

        [createMatrixHeader.rejected]: (state, action) => {
            state.busy = false;
            const error = action.payload;

            if (!error) return;

            const validationCodes = new Set(["validation"])

            if (error.code && validationCodes.has(error.code)){
                state.validationErrors = error.errors;
            }

            state.error = error
        },

        // syncMatrixWithSap
        [syncMatrixWithSap.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [syncMatrixWithSap.fulfilled]: (state) => {
            state.busy = false;
            state.error = null;
        },

        [syncMatrixWithSap.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },

        // downloadMatrix
        [downloadMatrix.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [downloadMatrix.fulfilled]: (state) => {
            state.busy = false;
            state.error = null;
        },

        [downloadMatrix.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },

        // edit matrixHeader
        [editMatrixHeader.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
            state.submit = false;
        },

        [editMatrixHeader.fulfilled]: (state, action) => {
            state.currentMatrixHeader = action.payload;
            state.busy = false;
            state.submit = true;
            state.error = null; 
            state.validationErrors = null;
        },

        [editMatrixHeader.rejected]: (state, action) => {
            state.busy = false;
            const error = action.payload;

            if (!error) return;

            const validationCodes = new Set(["validation"])

            if (error.code && validationCodes.has(error.code)){
                state.validationErrors = error.errors;
            }

            state.error = error
        },

        // delete matrixHeader
        [deleteMatrixHeader.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
        },

        [deleteMatrixHeader.fulfilled]: (state, action) => {
            state.busy = false;
            state.error = null;
            state.validationErrors = null;
            state.matrixHeaders = state.matrixHeaders.filter(matrixHeader => matrixHeader.id !== action.payload);

            if (state.currentMatrixHeader?.id === action.payload) {
                state.submit = true;
                state.currentMatrixHeaderDeleted = true;
            }
        },

        [deleteMatrixHeader.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },

        [fetchMatrixHeaderColumns.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchMatrixHeaderColumns.fulfilled]: (state, action) => {
            state.busy = false;
            state.columns = action.payload;
        },

        [fetchMatrixHeaderColumns.rejected]: (state, action) => {
            state.busy = false;
            state.errors = action.error;
        },
    },
});

export const {
    setSubmit, setInitialCurrentMatrixHeader, setError, setValidationErrors, 
    setSelectedIds, setSearchString, setPageNumber, setLimitNumber,
    setSortData, setFilterData
} = matrixHeadersSlice.actions;
export default matrixHeadersSlice.reducer;
