import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

const useReduxValue = (selector, setAction) => {
    const dispatch = useDispatch();

    const value = useSelector(selector);

    const setValue = useCallback(
        (newValue) => dispatch(setAction(newValue)),
        [setAction, dispatch]
    );

    return [value, setValue];
};

export default useReduxValue;
