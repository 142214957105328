import React from "react";
import { Box, IconButton } from "@mui/material";
import PropTypes from "prop-types";

import { AddFolderIcon, AddLeafIcon, RemoveLeafIcon, CopyIcon } from "../../common/icons";  // eslint-disable-line no-unused-vars

import "./styles.less";

const RoleMenuActionButton = ({ children, onClick }) => {
    return (
        <IconButton
            className="role-menu-action-button"
            onClick={onClick}
        >
            {children}
        </IconButton>
    );
};

const RoleMenuActions = ({
    onAddFolder,
    onAddNode,
    onRemoveNode,
    onCopy // eslint-disable-line no-unused-vars
}) => {
    return (
        <Box>
            <RoleMenuActionButton onClick={onAddFolder}>
                <AddFolderIcon />
            </RoleMenuActionButton>

            <RoleMenuActionButton onClick={onAddNode}>
                <AddLeafIcon />
            </RoleMenuActionButton>

            <RoleMenuActionButton onClick={onRemoveNode}>
                <RemoveLeafIcon />
            </RoleMenuActionButton>

            {/* <RoleMenuActionButton onClick={onCopy}>
                <CopyIcon />
            </RoleMenuActionButton> */}
        </Box>
    );
};

RoleMenuActionButton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func
};

RoleMenuActions.propTypes = {
    onAddFolder: PropTypes.func,
    onAddNode: PropTypes.func,
    onRemoveNode: PropTypes.func,
    onCopy: PropTypes.func
};

export default RoleMenuActions;