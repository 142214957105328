import React from "react";
import PropTypes from "prop-types";

import FormControl from "./form-control.jsx";
import InputLabel from "./input-label.jsx";
import FormHelperText from "./form-helper-text.jsx";

const InputWrapper = ({
    InputComponent,
    EndComponent,
    id,
    className="",
    fullWidth=true,
    shrink=true,
    label,
    error=false,
    errorMessage,
    warning=false,
    warningMessage,
    showFormHelpers=true,
}) => {
    return (
        <FormControl
            className={className}
            variant="standard"
            fullWidth={fullWidth}
        >
            {label && (
                <InputLabel
                    shrink={shrink}
                    htmlFor={id}
                >
                    {label}
                </InputLabel>
            )}

            {InputComponent}

            {showFormHelpers && (error && errorMessage || warning && warningMessage) && (
                <FormHelperText
                    error={error}
                    errorMessage={errorMessage}
                    warning={warning}
                    warningMessage={warningMessage}
                />
            )}

            {EndComponent}
        </FormControl>
    );
};

InputWrapper.propTypes = {
    InputComponent: PropTypes.node,
    EndComponent: PropTypes.node,
    id: PropTypes.string,
    className: PropTypes.string,
    fullWidth: PropTypes.bool,
    shrink: PropTypes.bool,
    label: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    warning: PropTypes.bool,
    warningMessage: PropTypes.string,
    showFormHelpers: PropTypes.bool,
};

export default InputWrapper;
