import React from 'react';
import { createSvgIcon } from '@mui/material';

const FilterIcon = createSvgIcon(
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M4 5.16667V6.08022C4.0015 6.1878 4.04468 6.29041 4.12012 6.36571L9.94995 12.3691C10.0254 12.4444 10.0686 12.547 10.0701 12.6546V18.2746C10.0699 18.351 10.0909 18.426 10.1305 18.4909C10.1702 18.5558 10.2269 18.608 10.2943 18.6416L13.4575 19.7917C13.5186 19.8222 13.5863 19.8364 13.6542 19.8328C13.7222 19.8292 13.7881 19.8081 13.8458 19.7713C13.9035 19.7346 13.9511 19.6835 13.984 19.6228C14.017 19.5622 14.0342 19.494 14.034 19.4247V12.5812C14.0451 12.4709 14.0966 12.369 14.1782 12.2957L19.8799 6.38202C19.9553 6.30672 19.9985 6.20411 20 6.09654V5.16667H4Z"
            fill="currentColor"
        />
    </svg>,
    "Filter"
);

export default FilterIcon;
