import React from "react";
import { useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";

import breakpoints from "../../../common/styles/breakpoints";

import RolesTable from "./roles-table.jsx";
import RolesList from "./roles-list.jsx";

import "./styles.less";

const RolesWrapper = ({
    selectedRoleId,
    onSelectRole,
    onDeleteRole,
    onRowClick,
    roles
}) => {
    const matchesLaptop = useMediaQuery(`(min-width: ${breakpoints.minLaptopBreakpoint})`);
    
    const RolesComponent = matchesLaptop ? RolesTable : RolesList;

    return (
        <RolesComponent
            rows={roles}
            selectedRoleId={selectedRoleId}
            onSelectRole={onSelectRole}
            onDeleteRole={onDeleteRole}
            onRowClick={onRowClick}
        />
    );
};

RolesWrapper.propTypes = {
    roles:PropTypes.arrayOf(PropTypes.object),
    selectedRoleId: PropTypes.string,
    onSelectRole: PropTypes.func,
    onDeleteRole: PropTypes.func,
    onRowClick: PropTypes.func
};

export default RolesWrapper;