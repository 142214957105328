import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchRiskLevels } from "../../../reducers/appSlice";

const useRiskLevels = () => {
    const riskLevels = useSelector(state => state.app.riskLevels);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchRiskLevels());
    }, [dispatch]);

    return riskLevels;
};

export default useRiskLevels;