import SodService from "./sod-service.js";

class AdminService extends SodService {
    async generateRules() {
        const currentInstance = this.getCurrentInstance(true);
        return currentInstance.get("/sod/reports/shm");
    }

    async clearMatrices() {
        return this.delete("/sod/clear-matrices");
    }

    async getSodAppVersion() {
        return this.get("/sod/version", null, true, false)
    }

    async getCoreAppVersion() {
        return this.get("/core/version")
    }
}

const adminService = new AdminService();

export { adminService };
export default AdminService;
