import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

import {
    fetchRisks,
    fetchRiskColumns,
    setSelectedIds as setSelectedIdsAction,
    setSearchString as setSearchStringAction,
    setPageNumber as setPageNumberAction,
    setLimitNumber as setLimitNumberAction,
    setSortData as setSortDataAction,
    setFilterData as setFilterDataAction,
    setError as setErrorAction,
} from "../../../../reducers/riskSlice";

import useFilters from "../../../common/hooks/useFilters";
import useReduxTable from "../../../common/hooks/useReduxTable";
import useRiskLevels from "../../../common/hooks/useRiskLevels";
import CommonTable from "../../../common/table";
import { ProgressDialog, ErrorsDialog } from "../../../common/dialogs";

import "./styles.less";


const getCellValue = (row, column, intl, riskLevels) => {
    switch (column.id) {
        case "riskId":
            return row.id;

        case "riskLevelId": {
            const riskLevel = riskLevels.find(riskLevel => riskLevel.id === row.riskLevel);
            return riskLevel?.description || row.riskLevel;
        }
        case "updTime":
            return row.updatedTime;

        case "updUserName":
            return row.user;

        case "matrices":
            return row.matrixHeaders.join(", ");

        case "functions":
            return row.functions.join(", ");

        case "active":
            return row.active ?
                intl.formatMessage({ id: "common.table.active.true" }) :
                intl.formatMessage({ id: "common.table.active.false" });

        default:
            return row[column.id];
    }
};

const RiskListPage = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    const riskLevels = useRiskLevels();

    const {
        rows, columns,
        total, busy,
        selectedIds,
        searchString, setSearchString,
        pageNumber, setPageNumber,
        limitNumber, setLimitNumber,
        filterData, setFilterData,
        sortData, setSortData,
        error, setError
    } = useReduxTable(
        (state) => state.risks.risks, fetchRisks,
        (state) => state.risks.columns, fetchRiskColumns,
        (state) => state.risks.selectedIds, setSelectedIdsAction,
        (state) => state.risks.searchString, setSearchStringAction,
        (state) => state.risks.pageNumber, setPageNumberAction,
        (state) => state.risks.limitNumber, setLimitNumberAction,
        (state) => state.risks.sortData, setSortDataAction,
        (state) => state.risks.filterData, setFilterDataAction,
        (state) => state.risks.error, setErrorAction,
        (state) => state.risks.risksTotal,
        (state) => state.risks.busy,
    );

    const busyType = useSelector(state => state.risks.busyType);

    const getFilters = useFilters("risk");

    const handleAddRiskClick = () => {
        navigate("form")
    };

    const handleRowClick = (risk) => {
        navigate(`form/${risk.id}`)
    };

    const getTableRowId = (row) => row.id;

    const toolbarActions = [
        {
            id: "title",
            title: intl.formatMessage({ id: "risk-list-page.toolbar.text.title" }),
            type: "text",
            position: "begin"
        },
        {
            id: "add",
            title: intl.formatMessage({ id: "risk-list-page.toolbar.btn-create-risk" }),
            callback: handleAddRiskClick,
            position: "end"
        }
    ];

    const getCellValueInner = (row, column) => getCellValue(row, column, intl, riskLevels);

    return (
        <>
            <CommonTable
                rows={rows}
                columns={columns}
                filterData={filterData}
                setFilterData={setFilterData}
                sortData={sortData}
                setSortData={setSortData}
                toolbarActions={toolbarActions}
                selectedIds={selectedIds}
                getRowId={getTableRowId}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                limitNumber={limitNumber}
                setLimitNumber={setLimitNumber}
                total={total}
                onRowClick={handleRowClick}
                searchString={searchString}
                setSearchString={setSearchString}
                getCellValue={getCellValueInner}
                getFilters={getFilters}
            />

            <ProgressDialog
                open={busy}
                busyType={busyType}
            />

            <ErrorsDialog
                error={error}
                open={error?.type === "error"}
                onClose={() => setError(null)}
            />
        </>
    )
};

export default RiskListPage;
