import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from 'prop-types';

import { CommonDialog, CommonDialogActions, CommonDialogContent, CommonDialogTitle } from "../../common/dialogs";

import "./style.less";
import { Box } from "@mui/material";
import { DatePicker } from "../../common/form-controls";
import { Button } from "../../common/buttons";



const getToday = () => {
    const date = new Date()

    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)

    return date
}

const getYesterday = () => {
    const date = getToday()

    date.setDate(date.getDate() - 1)

    return date
}

const getTommorow = () => {
    const date = getToday()

    date.setDate(date.getDate() + 1)

    return date
}

const CompareSummaryReportsDialog = ({ onClose, onApply, open, validationErrors, setCalcReportDeltaValErrors, ...props }) => {
    const intl = useIntl();

    const innerTitle = intl.formatMessage({ id: "summary-report.dialog.compare-reports.title" })
    const cancelTitle = intl.formatMessage({ id: "common.btn-cancel" });
    const changeTitle = intl.formatMessage({ id: "common.btn-apply" });
    const incorrectDateTitle = intl.formatMessage({ id: "summary-report.dialog.compare-reports.error.date" });

    const [earlyDate, setEarlyDate] = useState(getYesterday())
    const [lateDate, setLateDate] = useState(getToday())

    const [earlyError, setEarlyError] = useState("")
    const [lateError, setLateError] = useState("")

    useEffect(() => {
        return () => {
            setEarlyError("")
            setLateError("")
            setCalcReportDeltaValErrors()
        }
    }, [setCalcReportDeltaValErrors])

    useEffect(() => {
        if (!validationErrors) {
            setEarlyError("")
            setLateError("")
            return
        }

        if (validationErrors.early_date) {
            setEarlyError(validationErrors.early_date.message)
        }

        if (validationErrors.late_date){
            setLateError(validationErrors.late_date.message)
        }
    }, [validationErrors])

    const checkDates = (earlyDate, lateDate) => {
        const tommorrow = getTommorow()

        if (earlyDate.getTime() >= lateDate.getTime()) {
            setEarlyError(incorrectDateTitle)
        } else {
            setEarlyError("")
        }

        if (lateDate.getTime() >= tommorrow.getTime()){
            setLateError(incorrectDateTitle)
        } else {
            setLateError("")
        }
    }

    const onApplyCompare = () => {
        if (earlyError || lateError) {
            return
        }

        onApply({
            lateDate, earlyDate
        })
    }

    const onChangeEarlyDate = (val) => {
        if (isNaN(val.getTime())){
            setEarlyError(incorrectDateTitle)
            return
        }

        checkDates(val, lateDate)

        setEarlyDate(val)
    }

    const onChangeLateDate = (val) => {
        if (isNaN(val.getTime())){
            setLateError(incorrectDateTitle)
            return
        }

        checkDates(earlyDate, val)

        setLateDate(val)
    }

    return (
        <CommonDialog
            className="integration-schedule-dialog"
            open={!!open}
            {...props}
        >
            <CommonDialogTitle
                title={innerTitle}
                onClose={onClose}
            />

            <CommonDialogContent>

                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    width: "100%",
                    gap: "20px",
                    height: "200px",
                    paddingTop: "30px"
                }}>
                    <DatePicker
                        value={earlyDate}
                        error={!!earlyError}
                        errorMessage={earlyError}
                        onChange={onChangeEarlyDate}
                    />


                    <DatePicker
                        value={lateDate}
                        error={!!lateError}
                        errorMessage={lateError}
                        onChange={onChangeLateDate}
                    />
                </Box>

            </CommonDialogContent>

            <CommonDialogActions>
                <Button
                    variant="outlined"
                    onClick={onClose}
                    sx={{
                        color: 'var(--font-4)',
                        borderColor: 'var(--font-4)',
                    }}
                >
                    {cancelTitle}
                </Button>

                <Button
                    variant="contained"
                    onClick={onApplyCompare}
                    sx={{
                        color: 'var(--bg-2)',
                        backgroundColor: 'var(--font-2)',
                        '&:hover': {
                            backgroundColor: 'var(--font-2)',
                        }
                    }}
                >
                    {changeTitle}
                </Button>
            </CommonDialogActions>
        </CommonDialog>
    )
};


CompareSummaryReportsDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onApply: PropTypes.func,
    setError: PropTypes.func,
    validationErrors: PropTypes.any,
    setCalcReportDeltaValErrors: PropTypes.func,
}

export default CompareSummaryReportsDialog;
