import React from 'react';
import { createSvgIcon } from '@mui/material';

const CalendarIcon = createSvgIcon(
    <path
        d="M9.375 10H10.6875V11.3333H9.375V10ZM13.3125 18H14.625V19.3333H13.3125V18ZM17.25 10H18.5625V11.3333H17.25V10ZM13.3125 10H14.625V11.3333H13.3125V10ZM9.375 12.6667H10.6875V14H9.375V12.6667ZM5.4375 12.6667H6.75V14H5.4375V12.6667ZM17.25 12.6667H18.5625V14H17.25V12.6667ZM13.3125 12.6667H14.625V14H13.3125V12.6667ZM9.375 15.3333H10.6875V16.6667H9.375V15.3333ZM5.4375 15.3333H6.75V16.6667H5.4375V15.3333ZM17.25 15.3333H18.5625V16.6667H17.25V15.3333ZM13.3125 15.3333H14.625V16.6667H13.3125V15.3333ZM9.375 18H10.6875V19.3333H9.375V18ZM5.4375 18H6.75V19.3333H5.4375V18ZM22.5 3.33333V22H1.5V3.33333H5.4375V2H6.75V3.33333H17.25V2H18.5625V3.33333H22.5ZM2.8125 4.66667V7.33333H21.1875V4.66667H18.5625V6H17.25V4.66667H6.75V6H5.4375V4.66667H2.8125ZM21.1875 20.6667V8.66667H2.8125V20.6667H21.1875Z"
        fill="currentColor"
    />,
    "Calendar"
);

export default CalendarIcon;
