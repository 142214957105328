import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

import {
    fetchMatrixHeaders,
    fetchMatrixHeaderColumns,
    setSelectedIds as setSelectedIdsAction,
    setSearchString as setSearchStringAction,
    setPageNumber as setPageNumberAction,
    setLimitNumber as setLimitNumberAction,
    setSortData as setSortDataAction,
    setFilterData as setFilterDataAction,
    setError as setErrorAction,
} from "../../../../reducers/matrixHeadersSlice";

import useFilters from "../../../common/hooks/useFilters";
import useReduxTable from "../../../common/hooks/useReduxTable";
import CommonTable from "../../../common/table";
import { ProgressDialog, ErrorsDialog } from "../../../common/dialogs";


const getCellValue = (row, column) => {
    switch (column.id) {
        case "matrixId":
            return row.id;

        case "updTime":
            return row.updatedTime;

        case "updUserName":
            return row.updatedUser;

        default:
            return row[column.id];
    }
};

const MatrixHeadersListPage = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    const {
        rows, columns,
        total, busy,
        selectedIds,
        searchString, setSearchString,
        pageNumber, setPageNumber,
        limitNumber, setLimitNumber,
        filterData, setFilterData,
        sortData, setSortData,
        error, setError
    } = useReduxTable(
        (state) => state.matrixHeaders.matrixHeaders, fetchMatrixHeaders,
        (state) => state.matrixHeaders.columns, fetchMatrixHeaderColumns,
        (state) => state.matrixHeaders.selectedIds, setSelectedIdsAction,
        (state) => state.matrixHeaders.searchString, setSearchStringAction,
        (state) => state.matrixHeaders.pageNumber, setPageNumberAction,
        (state) => state.matrixHeaders.limitNumber, setLimitNumberAction,
        (state) => state.matrixHeaders.sortData, setSortDataAction,
        (state) => state.matrixHeaders.filterData, setFilterDataAction,
        (state) => state.matrixHeaders.error, setErrorAction,
        (state) => state.matrixHeaders.total,
        (state) => state.matrixHeaders.busy,
    );

    const busyType = useSelector((state) => state.matrixHeaders.busyType);

    const getFilters = useFilters("matrix-header");

    const handleAddClick = () => {
        navigate(`form`)
    };

    const handleRowClick = (matrixHeader) => {
        navigate(`form/${matrixHeader.id}`)
    };

    const getTableRowId = (row) => row.id;

    const toolbarActions = [
        {
            id: "title",
            title: intl.formatMessage({ id: "matrix-headers-list-page.toolbarActions.title" }),
            type: "text",
            position: "begin"
        },
        {
            id: "add",
            title: intl.formatMessage({ id: "matrix-headers-list-page.toolbarActions.add" }),
            callback: handleAddClick,
            position: "end"
        }
    ];

    return (
        <>
            <CommonTable
                rows={rows}
                columns={columns}
                filterData={filterData}
                setFilterData={setFilterData}
                sortData={sortData}
                setSortData={setSortData}
                toolbarActions={toolbarActions}
                selectedIds={selectedIds}
                getRowId={getTableRowId}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                limitNumber={limitNumber}
                setLimitNumber={setLimitNumber}
                total={total}
                onRowClick={handleRowClick}
                searchString={searchString}
                setSearchString={setSearchString}
                getCellValue={getCellValue}
                getFilters={getFilters}
            />

            <ProgressDialog
                open={busy}
                busyType={busyType}
            />

            <ErrorsDialog
                error={error}
                open={error?.type === "error"}
                onClose={() => setError(null)}
            />
        </>
    );
};

export default MatrixHeadersListPage;
