import React from "react";
import { useIntl } from "react-intl";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";

import { ADD_MODE, EDIT_MODE, READ_MODE, EntityFormHeaderTitle } from "../../../common/entity-form";

const UserFormHeaderTitle = ({
    fullName,
    mode,
    onBackClick
}) => {
    const intl = useIntl();

    const fullNameComponent = fullName &&
        <Typography
            component="span"
            className="user-form-page-header-title-user"
        >
            {fullName}
        </Typography>;

    const titleMap = {
        [ADD_MODE]: intl.formatMessage({ id: "user-form-page-header.new.title" }),
        [EDIT_MODE]: intl.formatMessage({ id: "user-form-page-header.edit.title" }),
        [READ_MODE]: intl.formatMessage({ id: "user-form-page-header.read.title" }),
    };

    return (
        <EntityFormHeaderTitle
            title={
                <>
                    {`${titleMap[mode]} `}
                    {fullNameComponent}
                </>
            }
            onBackClick={onBackClick}
        />
    );
};

UserFormHeaderTitle.propTypes = {
    fullName: PropTypes.string,
    mode: PropTypes.string,
    onBackClick: PropTypes.func
};

export default UserFormHeaderTitle;
