import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { systemsService } from "../../services/systems-service";
import LockManager from "../../utils/lockManager";
import { BUSY_TYPES } from "../../utils/busy";

const lockManager = new LockManager("system");

export const CHECK_STATUS_NONE = "none";
export const CHECK_STATUS_PENDING = "pending";
export const CHECK_STATUS_ERROR = "error";
export const CHECK_STATUS_SUCCESS = "success";

const initialState = {
    systems: [],
    columns: [],
    total: 0,
    selectedIds: [],
    searchString: "",
    pageNumber: 1,
    limitNumber: 10,
    sortData: [],
    filterData: [],
    busy: false,
    busyType: null,
    error: null,
    submit: false,
    currentSystem: null,
    currentSystemEditable: false,
    currentSystemDeleted: true,
    validationErrors: null,
    testConnectionStatus: CHECK_STATUS_NONE
};

export const fetchSystems = createAsyncThunk(
    "admin/fetchSystems",
    async ({ params }) => {
        const response = await systemsService.getSystems(params);
        return response;
    }
);

export const fetchSystemColumns = createAsyncThunk(
    "admin/fetchSystemColumns",
    async () => {
        const response = await systemsService.getSystemColumns();
        return response;
    }
);

export const fetchSystemDetailed = createAsyncThunk(
    "admin/fetchSystemDetailed",
    async (systemId) => {
        const system = await systemsService.getSystemDetailed(systemId);
        const lock = await lockManager.lockObject(systemId);

        return { system, lock };
    }
);

export const createSystem = createAsyncThunk(
    "admin/saveSystem",
    async (system, { rejectWithValue }) => {
        let response

        try {
            response = await systemsService.createSystem(system);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return response;
    }
);

export const editSystem = createAsyncThunk(
    "admin/editSystem",
    async (system, {rejectWithValue}) => {
        let response

        try {
            response = await systemsService.editSystem(system);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return response;
    }
);

export const testSystemConnection = createAsyncThunk(
    "admin/testSystemConnection",
    async (system, {rejectWithValue}) => {
        let response

        try {
            response = await systemsService.testConnectionWithSAP(system);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return response;
    }
);

export const deleteSystem = createAsyncThunk(
    "admin/deleteSystem",
    async (systemId, {rejectWithValue}) => {
        try {
            await systemsService.deleteSystem(systemId);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return systemId;
    }
);

export const clearCurrentSystem = createAsyncThunk(
    "admin/clearCurrentSystem",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        const currentSystem = state.admin.systems.currentSystem;
        const currentSystemEditable = state.admin.systems.currentSystemEditable;
        const currentSystemDeleted = state.admin.systems.currentSystemDeleted;

        if (!currentSystem) return thunkAPI.rejectWithValue(["Current system is not initialized"]);

        if (currentSystemDeleted || !currentSystemEditable || !currentSystem.id) return;

        const lockResponse = await lockManager.unlockObject(currentSystem.id);

        if (!lockResponse.result) {
            return thunkAPI.rejectWithValue(lockResponse.messages);
        }
    }
);

export const changeSystemPassword = createAsyncThunk(
    "admin/changeSystemPassword",
    async ({ systemId, newPassword }, {rejectWithValue}) => {
        let response

        try {
            response = await systemsService.changeSystemPassword(systemId, newPassword);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return response;
    }
);

const systemsSlice = createSlice({
    name: "systems",
    initialState: initialState,
    reducers: {
        setSubmit(state, action){
            state.submit = action.payload;
        },

        setBusy(state, action){
            state.busy = action.payload;
        },

        setInitialCurrentSystem(state) {
            state.currentSystem = {
                id: "",
                description: "",
                login: "",
                password: "",
                host: "",
                mandt: "",
                instanceNumber: "",
                sapRouter: "",
                sapSystemId: ""
            };

            state.error = null;
            state.validationErrors = null;
            state.submit = false;
            state.currentSystemEditable = true;
            state.currentSystemDeleted = false;
            state.testConnectionStatus = CHECK_STATUS_NONE;
        },

        setError(state, action) {
            state.error = action.payload;
        },

        setValidationErrors(state, action) {
            state.validationErrors = action.payload;
        },

        setSelectedIds(state, action) {
            state.selectedIds = action.payload;
        },

        setSearchString(state, action) {
            if (state.searchString !== action.payload) {
                state.pageNumber = 1;
                state.searchString = action.payload;
            }
        },

        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },

        setLimitNumber(state, action) {
            state.pageNumber = 1;
            state.limitNumber = action.payload;
        },

        setSortData(state, action) {
            state.pageNumber = 1;
            state.sortData = action.payload;
        },

        setFilterData(state, action) {
            state.pageNumber = 1;
            state.filterData = action.payload;
        },

        resetTestConnectionStatus(state) {
            state.testConnectionStatus = CHECK_STATUS_NONE;
            state.validationErrors = [];
            state.error = [];
        }
    },
    extraReducers: {
        //get all systems
        [fetchSystems.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchSystems.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },

        [fetchSystems.fulfilled]: (state, action) => {
            state.busy = false;
            state.systems = action.payload.systems;
            state.total = action.payload.total;
            state.error = null;
            state.validationErrors = null;
        },

        // fetch system detailed
        [fetchSystemDetailed.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.currentSystemDeleted = false;
            state.submit = false;
            state.testConnectionStatus = CHECK_STATUS_NONE;
        },

        [fetchSystemDetailed.fulfilled]: (state, action) => {
            const { system, lock } = action.payload;

            state.currentSystem = system;
            state.busy = false;
            state.currentSystemEditable = lock.result;
            state.error = lock.result ? null : lock.messages;
        },

        [fetchSystemDetailed.rejected]: (state, action) => {
            state.currentSystem = null;
            state.error = action.error;
            state.busy = false;
            state.currentSystemEditable = false;
        },

        // clear current system
        [clearCurrentSystem.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [clearCurrentSystem.fulfilled]: (state) => {
            state.busy = false;
            state.currentSystem = null;
            state.currentSystemEditable = false;
            state.currentSystemDeleted = false;
            state.error = null;
            state.validationErrors = null;
            state.submit = false;
        },

        [clearCurrentSystem.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },

        //create systems
        [createSystem.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
            state.submit = false;
        },

        [createSystem.fulfilled]: (state, action) => {
            state.currentSystem = action.payload;
            state.busy = false;
            state.submit = true;
            state.error = null;   
            state.validationErrors = null;  
        },

        [createSystem.rejected]: (state, action) => {
            state.busy = false;
            const error = action.payload;

            if (!error) return;

            const validationCodes = new Set(["validation"])

            if (error.code && validationCodes.has(error.code)){
                state.validationErrors = error.errors;
            }

            state.error = error
        },

        //edit system
        [editSystem.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
            state.submit = false;
        },

        [editSystem.fulfilled]: (state, action) => {
            state.busy = false;
            state.submit = true;
            state.error = null;
            state.validationErrors = null;
            state.currentSystem = action.payload;
        },

        [editSystem.rejected]: (state, action) => {
            state.busy = false;
            const error = action.payload;

            if (!error) return;

            const validationCodes = new Set(["validation"])

            if (error.code && validationCodes.has(error.code)){
                state.validationErrors = error.errors;
            }

            state.error = error
        },

        //test system connection
        [testSystemConnection.pending]: (state) => {
            state.testConnectionStatus = CHECK_STATUS_PENDING;
        },

        [testSystemConnection.fulfilled]: (state) => {
            state.testConnectionStatus = CHECK_STATUS_SUCCESS;
        },

        [testSystemConnection.rejected]: (state, action) => {
            state.testConnectionStatus = CHECK_STATUS_ERROR;
            const error = action.payload;

            if (!error) return;

            const validationCodes = new Set(["validation"])

            if (error.code && validationCodes.has(error.code)){
                state.validationErrors = error.errors;
            }

            state.error = error
        },

        //delete system

        [deleteSystem.fulfilled]: (state, action) => {
            state.busy = false;
            state.error = null;
            state.validationErrors = null;
            state.systems = state.systems.filter(system => system.id !== action.payload);

            if (state.currentSystem?.id === action.payload) {
                state.submit = true;
                state.currentSystemDeleted = true;
            }
        },

        [deleteSystem.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },

        [deleteSystem.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
        },

        [fetchSystemColumns.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchSystemColumns.fulfilled]: (state, action) => {
            state.busy = false;
            state.columns = action.payload;
        },

        [fetchSystemColumns.rejected]: (state, action) => {
            state.busy = false;
            state.errors = action.error;
        },

        // change password
        [changeSystemPassword.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
        },

        [changeSystemPassword.fulfilled]: (state) => {
            state.busy = false;
            state.error = null;   
            state.validationErrors = null;  
        },

        [changeSystemPassword.rejected]: (state, action) => {
            state.busy = false;
            const error = action.payload;

            if (!error) return;

            const validationCodes = new Set(["validation"])

            if (error.code && validationCodes.has(error.code)){
                state.validationErrors = error.errors;
            }

            state.error = error
        },
    }
});


export const {
    setSubmit, setBusy, setInitialCurrentSystem, setError, setValidationErrors,
    setSelectedIds, setSearchString, setPageNumber, setLimitNumber,
    setSortData, setFilterData, resetTestConnectionStatus
} = systemsSlice.actions;

export default systemsSlice.reducer;
