import { Box, Typography } from "@mui/material";
import React, { forwardRef } from "react";
import { Outlet } from "react-router-dom";
import PropTypes from 'prop-types';

import { TabTitleChevronIcon } from "../icons";

const TabContent = forwardRef(({ activeTab, menuTitle }, ref) => {
    const titles = [menuTitle];

    if (activeTab?.parent) {
        titles.push(activeTab?.parent.title);
    }

    if (activeTab) {
        titles.push(activeTab.title);
    }

    return(
        <Box className="tab-content" ref={ref}>
            <Box className="tab-content-header">
                {titles.map((title, index) => (
                    <React.Fragment key={title}>
                        <Typography
                            component="span"
                            className="tab-content-header-title"
                        >
                            {title}
                        </Typography>

                        {index !== (titles.length - 1) && (
                            <TabTitleChevronIcon
                                sx={{
                                    color: "var(--font-4)",
                                    width: "3px",
                                    height: "6px"
                                }}
                            />
                        )}
                    </React.Fragment>
                ))}
            </Box>
            
            <Outlet />
        </Box>
    );
});

TabContent.propTypes = {
    activeTab: PropTypes.object,
    menuTitle: PropTypes.string
}

export default TabContent;
