import React from "react";
import PropTypes from "prop-types";

import { CommonDialog } from "../../common/dialogs";

import "./styles.less";

const EntitiesSelectDialog = ({
    className="",
    ...props
}) => (
    <CommonDialog
        className={`${className} entities-select-dialog`}
        {...props}
    />
);

EntitiesSelectDialog.propTypes = {
    className: PropTypes.string
};

export default EntitiesSelectDialog;