import React from "react";
import { createSvgIcon } from '@mui/material';

const PlusIcon = createSvgIcon(
    <>
        <rect x="10.3375" y="5.22773" width="3.32308" height="14.4" fill="currentColor"/>
        <rect x="19.2" y="10.7652" width="3.32308" height="14.4" transform="rotate(90 19.2 10.7652)" fill="currentColor"/>
    </>,
    "Plus"
);

export default PlusIcon;
 