import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";

import { Button } from "../buttons";

import { CommonDialog, CommonDialogTitle, CommonDialogContent, CommonDialogActions } from "./common-dialog.jsx";

import "./style.less";

const AppVersionDialog = ({onCancelClick, coreVersion, sodVersion, ...props }) => {
    const intl = useIntl();
    const title = intl.formatMessage({ id: "common.about-app-title" });

    return(
        <CommonDialog {...props}>
            <CommonDialogTitle
                title={title}
                onClose={onCancelClick}
            />

            <CommonDialogContent>
                {coreVersion && <Typography component="p" className="delete-dialog-text app-version-dialog-text">
                    {intl.formatMessage({id: "commom.app-version-dialog.core-version-text"}, {coreVersion: coreVersion})}
                </Typography>
                }

                {sodVersion && <Typography component="p" className="delete-dialog-text app-version-dialog-text">
                    {intl.formatMessage({id: "commom.app-version-dialog.sod-version-text"}, {sodVersion: sodVersion})}
                </Typography>
                }
            </CommonDialogContent>

            <CommonDialogActions>
                <Button
                    variant="outlined"
                    onClick={onCancelClick}
                    sx={{
                        color: 'var(--font-4)',
                        borderColor: 'var(--font-4)',
                    }}
                >
                    <FormattedMessage id="common.btn-close" />
                </Button>
            </CommonDialogActions>
        </CommonDialog>
    )
};

AppVersionDialog.propTypes = {
    sodVersion: PropTypes.string,
    coreVersion: PropTypes.string,
    onCancelClick: PropTypes.func,
}

export default AppVersionDialog;
