import React from "react";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";

const EntityFormSubtitle = ({ className="", ...props }) => {
    return (
        <Typography
            component="h3"
            {...props}
            className={`entity-form-subtitle ${className}`}
        />
    )
};

EntityFormSubtitle.propTypes = {
    className: PropTypes.string
};

export default EntityFormSubtitle;
