import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { Collapse } from "@mui/material";
import PropTypes from "prop-types";

import { REPORT_LEVELS } from "../../../utils/sodReports.js";

import { PageContentBox } from "../../common/page-content-box";

import ReportResultsHeader from "./report-results-header.jsx";
import ReportResultsTabs from "./report-results-tabs.jsx";
import ReportResultsTable from "./report-results-table.jsx";

import "./style.less";

const ReportResults = ({
    open, setOpen,
    reportLevel, setReportLevel,
    reportType, setReportType,
    rows, columns,
    total, busy,
    selectedIds,
    searchString, setSearchString,
    pageNumber, setPageNumber,
    limitNumber, setLimitNumber,
    filterData, setFilterData,
    sortData, setSortData,
    onExportClick, filterId,
    collapseChildren,
    conflictLevel
}) => {
    const intl = useIntl();

    const tabs = useMemo(() => [
        {
            id: REPORT_LEVELS.OPERATIONS,
            label: intl.formatMessage({ id: "report-results.tabs.operations-level"})
        },
        {
            id: REPORT_LEVELS.PERMISSIONS,
            label: intl.formatMessage({ id: "report-results.tabs.permissions-level"})
        },
        {
            id: REPORT_LEVELS.CRITICAL_OPERATIONS,
            label: intl.formatMessage({ id: "report-results.tabs.critical-operations-level"})
        },
        {
            id: REPORT_LEVELS.CRITICAL_PERMISSIONS,
            label: intl.formatMessage({ id: "report-results.tabs.critical-permissions-level"})
        },
        {
            id: REPORT_LEVELS.CRITICAL_OBJECTS,
            label: intl.formatMessage({ id: "report-results.tabs.critical-objects-level"})
        }
    ], [intl]);

    const handleOpenClick = (event) => {
        event.stopPropagation();
        setOpen(!open);
    };

    return (
        <PageContentBox className="report-results-box">
            <ReportResultsHeader
                open={open}
                onOpenClick={handleOpenClick}
                onExportClick={onExportClick}
            />

            <Collapse in={open}>
                {collapseChildren}

                <ReportResultsTabs
                    tabs={tabs}
                    activeTabId={reportLevel}
                    onActiveTabIdChange={setReportLevel}
                />

                <ReportResultsTable
                    reportType={reportType}
                    reportLevel={reportLevel}
                    setReportType={setReportType}
                    rows={rows}
                    columns={columns}
                    total={total}
                    busy={busy}
                    selectedIds={selectedIds}
                    searchString={searchString}
                    setSearchString={setSearchString}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    limitNumber={limitNumber}
                    setLimitNumber={setLimitNumber}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    sortData={sortData}
                    setSortData={setSortData}
                    filterId={filterId}
                    conflictLevel={conflictLevel}
                />
            </Collapse>
        </PageContentBox>
    );
};

ReportResults.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    reportLevel: PropTypes.string,
    setReportLevel: PropTypes.func,
    reportType: PropTypes.string,
    setReportType: PropTypes.func,
    rows: PropTypes.arrayOf(PropTypes.object),
    columns: PropTypes.arrayOf(PropTypes.object),
    total: PropTypes.number,
    busy: PropTypes.bool,
    selectedIds: PropTypes.arrayOf(PropTypes.string),
    searchString: PropTypes.string,
    setSearchString: PropTypes.func,
    pageNumber: PropTypes.number,
    setPageNumber: PropTypes.func,
    limitNumber: PropTypes.number,
    setLimitNumber: PropTypes.func,
    filterData: PropTypes.arrayOf(PropTypes.object),
    setFilterData: PropTypes.func,
    sortData: PropTypes.arrayOf(PropTypes.object),
    setSortData: PropTypes.func,
    onExportClick: PropTypes.func,
    filterId: PropTypes.string,
    collapseChildren: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node)
    ]),
    conflictLevel: PropTypes.string
};

export default ReportResults;
