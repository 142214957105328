import React from 'react';
import PropTypes from "prop-types";

import { EDIT_MODE, EntityFormActions, EntityFormHeader } from "../../../common/entity-form";

import RiskFormHeaderTitle from "./risk-form-header-title.jsx";
import SyncWithSapButton from '../../../common/buttons/sync-with-sap-button.jsx';

const RiskFormPageHeader = ({
    mode,
    riskId,
    onSaveClick,
    onBackClick,
    onEditClick,
    onDeleteClick,
    onShowLogsClick,
    onSyncRiskClick
}) => {
    return (
        <EntityFormHeader>
            <RiskFormHeaderTitle
                riskId={riskId}
                mode={mode}
                onBackClick={onBackClick}
            />

            <EntityFormActions
                mode={mode}
                onDeleteClick={onDeleteClick}
                onSaveClick={onSaveClick}
                onEditClick={onEditClick}
                onShowLogsClick={onShowLogsClick}
                customActions={mode === EDIT_MODE && (
                    <SyncWithSapButton
                        onClick={onSyncRiskClick}
                    />
                )}
            />
        </EntityFormHeader>
    );
};

RiskFormPageHeader.propTypes = {
    riskId: PropTypes.string,
    mode: PropTypes.string,
    onBackClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onSaveClick: PropTypes.func,
    onEditClick: PropTypes.func,
    onShowLogsClick: PropTypes.func,
    onSyncRiskClick: PropTypes.func
}

export default RiskFormPageHeader;
