import React from "react";
import { useIntl } from "react-intl";
import { IconButton } from "@mui/material";
import PropTypes from "prop-types";

import { createRowCellContentInner, getCellClassNameInner, TableRowCellText } from "../../common/table";
import TreeTable from "../../common/tree-table";
import { Checkbox } from "../../common/buttons";
import { RightScrollButtonIcon } from "../../common/icons";

import "./styles.less";

const columns = [
    {
        id: "node",
        type: "node",
    },
    {
        id: "checked",
        type: "checkbox",
    },
    {
        id: "id",
        type: "text"
    }
];

const getCellClassName = (row, column, selected) => {
    let className = getCellClassNameInner(row, column, selected);

    className += " select-permissions-table-cell";

    if (row.type) {
        className += ` select-permissions-table-cell-row-${row.type}`;
    }

    if (column.id) {
        className += ` select-permissions-table-cell-column-${column.id}`;
    }

    return className;
};

const SelectPermissionsDialogTable = ({ tree, onItemFieldChange }) => {
    const intl = useIntl();

    const toolbarActions = [
        {
            id: "title",
            title: intl.formatMessage({ id: "select-permissions-dialog.table.title" }),
            type: "text",
            position: "begin"
        }
    ];

    const handleOpenRowChange = (row) => onItemFieldChange(row.path, "open", !row.open);

    const createRowCellContent = (row, column, selected, onSelect, getCellValue) => {
        switch (column.type) {
            case "node":
                if (row.leaf) return;

                return (
                    <IconButton>
                        <RightScrollButtonIcon />
                    </IconButton>
                );

            case "checkbox": {
                return (
                    <>
                        {!row.leaf && (
                            <Checkbox
                                checked={row.checked}
                                indeterminate={row.indeterminate}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => onItemFieldChange(row.path, "checked", e.target.checked)}
                            />
                        )}

                        <TableRowCellText>
                            {row.description}
                        </TableRowCellText>
                    </>
                    
                )
            }

            default:
                return createRowCellContentInner(row, column, selected, onSelect, getCellValue);
        }
    };

    return (
        <TreeTable
            className="select-permissions-table"
            tree={tree}
            columns={columns}
            showFiltersActions={false}
            showPagination={false}
            toolbarActions={toolbarActions}
            getCellClassName={getCellClassName}
            createRowCellContent={createRowCellContent}
            onRowClick={handleOpenRowChange}
            virtualize
        />
    );
};

SelectPermissionsDialogTable.propTypes = {
    tree: PropTypes.arrayOf(PropTypes.object),
    onItemFieldChange: PropTypes.func
};

export default SelectPermissionsDialogTable;