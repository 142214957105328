import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { IconButton } from "@mui/material";
import PropTypes from "prop-types";

import { SearchIcon } from "../icons";

import Input from "./input.jsx";

import "./styles.less";

const SearchInput = forwardRef(({ value="", onSearchClick, delay=900, iconColor="#363636", className="", ...props }, ref) => {
    const intl = useIntl();
    const placeholder = intl.formatMessage({ id: "common.search-input.title" });

    const [valueInner, setValueInner] = useState("");
    const [changeEvent, setChangeEvent] = useState(null);
    const [timeoutId, setTimeoutId] = useState(null);

    const search = useCallback(() => {
        if (!changeEvent || !onSearchClick) return;

        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        onSearchClick(changeEvent);
    }, [timeoutId, changeEvent, onSearchClick]);

    useEffect(() => {
        setValueInner(value);
    }, [value]);

    useEffect(() => {
        if (!changeEvent || !onSearchClick) return;

        const timeoutIdInner = setTimeout(() => {
            onSearchClick(changeEvent)
        }, delay);

        setTimeoutId(timeoutIdInner);

        return () => clearTimeout(timeoutIdInner);
    }, [changeEvent, delay, onSearchClick]);

    const handleSearchKeyDown = (event) => {
        if (event.key === 'Enter') {
            search();
        }
    };

    return (
        <Input
            className={`search-input ${className}`}
            ref={ref}
            placeholder={placeholder}
            value={valueInner}
            onChange={(e) => {
                const value = e.target.value;
                setValueInner(value);
                setChangeEvent(e);
            }}
            onKeyDown={handleSearchKeyDown}
            endAdornment={
                <IconButton
                    onClick={search}
                    sx={{ padding: 0 }}
                >
                    <SearchIcon
                        sx={{
                            color: iconColor,
                            fontSize: "34px"
                        }}
                    />
                </IconButton>
            }
            {...props}
        />
    )
});

SearchInput.propTypes = {
    value: PropTypes.string,
    onSearchClick: PropTypes.func,
    delay: PropTypes.number,
    iconColor: PropTypes.string,
    className: PropTypes.string
};

export default SearchInput;
