import React from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from 'prop-types';

const TabsListItemParent = ({ tab, onClick, activeTab }) => {
    const active = tab.id === activeTab?.id && !tab.children?.length;
    const classNames = ["tabs-list-item-parent"];

    if (active) {
        classNames.push("tabs-list-item-active");
    }

    const Icon = tab.icon;
    const iconColor = (tab.open || active) ? "var(--font-1)" : "var(--font-3)";

    return (
        <Box
            className={classNames.join(" ")}
            onClick={() => onClick(tab)}
        >
            {Icon && (
                <Icon
                    sx={{
                        width: "16px",
                        height: "16px",
                        color: iconColor
                    }}
                />
            )}

            <Typography className="tabs-list-item-parent-title">
                {tab.title}
            </Typography>
        </Box>
    );
};

TabsListItemParent.propTypes = {
    tab: PropTypes.object,
    onClick: PropTypes.func,
    activeTab: PropTypes.object
};

export default TabsListItemParent;
