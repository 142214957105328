import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";

import { Button } from "../buttons";
import { TextInput } from "../form-controls";

import { CommonDialog, CommonDialogTitle, CommonDialogContent, CommonDialogActions } from "./common-dialog.jsx";

import "./style.less";

const DeleteConfirmDialog = ({
    className="",
    title,
    open,
    onDeleteClick,
    onCancelClick,
    ...props
}) => {
    const intl = useIntl();

    const inputLabel = intl.formatMessage({ id: "common.delete-confirm-dialog.input-label" });

    const [inputValue, setInputValue] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if (open) {
            setInputValue("");
        }
    }, [open]);

    const handleDeleteClick = () => {
        const correctInputValue = intl.formatMessage({
            id: "common.delete-confirm-dialog.correct-input-value"
        });

        if (inputValue === correctInputValue) {
            onDeleteClick();
            return;
        }

        setErrorMessage(intl.formatMessage({
            id: "common.delete-confirm-dialog.input-error"
        }));
    };

    return(
        <CommonDialog
            className={`delete-confirm-dialog ${className}`}
            open={open}
            {...props}
        >
            <CommonDialogTitle
                title={title}
                onClose={onCancelClick}
            />

            <CommonDialogContent>
                <TextInput
                    label={inputLabel}
                    value={inputValue}
                    error={Boolean(errorMessage)}
                    errorMessage={errorMessage}
                    onChange={(event) => setInputValue(event.target.value)}
                />
            </CommonDialogContent>

            <CommonDialogActions>
                <Button
                    variant="outlined"
                    onClick={onCancelClick}
                    sx={{
                        color: 'var(--font-4)',
                        borderColor: 'var(--font-4)',
                    }}
                >
                    <FormattedMessage id="common.btn-cancel" />
                </Button>
                <Button
                    variant="contained"
                    onClick={handleDeleteClick}
                    sx={{
                        color: 'var(--bg-2)',
                        backgroundColor: 'var(--font-2)',
                        '&:hover': {
                            backgroundColor: 'var(--font-2)',
                        }
                    }}
                >
                    <FormattedMessage id="common.delete-dialog.btn-delete" />
                </Button>
            </CommonDialogActions>
        </CommonDialog>
    )
};

DeleteConfirmDialog.propTypes = {
    className: PropTypes.string,
    title: PropTypes.node,
    open: PropTypes.bool,
    onDeleteClick: PropTypes.func,
    onCancelClick: PropTypes.func,
};

export default DeleteConfirmDialog;
