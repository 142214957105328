import { nanoid } from "@reduxjs/toolkit";

import { CHANGE_STATUS } from "../components/common/const";
import { Filter } from "../components/common/hooks/useLocalTable";

export const getUpdatedFormForEntity = (
    form, entity, field, value, entitiesKey, entityKeyFields
) => {
    const entityIndex = form[entitiesKey].findIndex(
        formCriticalProfile => formCriticalProfile.id === entity.id
    );

    if (entityIndex === -1) return {
        newForm: form,
        item: null
    };

    const entityCopy = {
        ...form[entitiesKey][entityIndex],
        [field]: value
    };

    const newForm = {
        ...form,
        [entitiesKey]: [...form[entitiesKey]]
    };

    if (!entityCopy.changeStatus) {
        if (entityKeyFields.includes(field)) {
            const entityForDelete = {
                ...entity,
                changeStatus: CHANGE_STATUS.DELETE,
                id: nanoid()
            };

            entityCopy.changeStatus = CHANGE_STATUS.ADD;

            newForm[entitiesKey].push(entityForDelete);
        } else {
            entityCopy.changeStatus = CHANGE_STATUS.EDIT;
        }
    }

    newForm[entitiesKey][entityIndex] = entityCopy;

    return {
        newForm,
        item: entityCopy
    };
};

export const getUpdatedFormForMassEditEntity = (
    form, entityIds, field, value, allIfIdsEmpty, entitiesKey, entityKeyFields
) => {
    const isKeyField = entityKeyFields.includes(field);

    const newEntities = [];

    const editAll = allIfIdsEmpty && entityIds?.length === 0;

    form[entitiesKey].forEach(entity => {
        if (entity.changeStatus === CHANGE_STATUS.DELETE ||
            entity[field] === value ||
            !editAll && !entityIds.includes(entity.id)) {
            newEntities.push(entity);
            return;
        }

        const newEntity = {
            ...entity,
            [field]: value
        };

        if (entity.changeStatus === CHANGE_STATUS.ADD) {
            newEntities.push(newEntity);
            return;
        }

        if (isKeyField) {
            const entityForDelete = {
                ...entity,
                changeStatus: CHANGE_STATUS.DELETE,
                id: nanoid()
            };

            newEntity.changeStatus = CHANGE_STATUS.ADD;

            newEntities.push(entityForDelete);
        } else {
            newEntity.changeStatus = CHANGE_STATUS.EDIT;
        }

        newEntities.push(newEntity);
    });

    const newForm = {
        ...form,
        [entitiesKey]: newEntities
    };

    return newForm
}

export const getUpdatedFormForCopyEntity = (form, entityId, entitiesKey) => {
    const entity = form[entitiesKey].find(entity => entity.id === entityId);

    if (!entity) {
        return form;
    }

    const entityCopy = {
        ...entity,
        id: nanoid(),
        changeStatus: CHANGE_STATUS.ADD
    };

    const newEntities = [
        ...form[entitiesKey],
        entityCopy
    ];

    const newForm = {
        ...form,
        [entitiesKey]: newEntities
    };

    return newForm
}

export const getUpdatedFormForMassCopyEntity = (form, entityIds, entitiesKey) => {
    const entitiesCopies = [];

    form[entitiesKey].forEach(entity => {
        if (entity.changeStatus === CHANGE_STATUS.DELETE ||
            !entityIds.includes(entity.id)) {
            return;
        }

        const entityCopy = {
            ...entity,
            id: nanoid(),
            changeStatus: CHANGE_STATUS.ADD
        };

        entitiesCopies.push(entityCopy);
    });

    const newEntities = [
        ...form[entitiesKey],
        ...entitiesCopies
    ];

    const newForm = {
        ...form,
        [entitiesKey]: newEntities
    };

    return newForm;
}

export const getUpdatedFormForMassDeleteEntity = (form, entityIds, entitiesKey) => {
    const idsSet = new Set(entityIds)

    const newEntities = []

    form[entitiesKey].forEach(entity => {
        if (!idsSet.has(entity.id)){
            newEntities.push(entity)
            return
        }

        if (entity.changeStatus === CHANGE_STATUS.ADD) return

        const entityCopy = {...entity, changeStatus: CHANGE_STATUS.DELETE}

        newEntities.push(entityCopy)
    })

    const newForm = {
        ...form,
        [entitiesKey]: newEntities
    };

    return newForm
}

export const getUpdatedFormForDeleteEntity = (form, entityId, entitiesKey) => {
    const entityIndex = form[entitiesKey].findIndex(entity => entity.id === entityId);

    if (entityIndex === -1) return {
        newForm: form,
        item: null
    };

    const oldEntity = form[entitiesKey][entityIndex];

    const entityCopy = {
        ...oldEntity,
        changeStatus: CHANGE_STATUS.DELETE
    };

    let newEntities = [];

    if (oldEntity.changeStatus === CHANGE_STATUS.ADD) {
        newEntities = form[entitiesKey].filter(item => item.id !== entityId);
    } else {
        newEntities = [...form[entitiesKey]];
        newEntities[entityIndex] = entityCopy;
    }

    const newForm = {
        ...form,
        [entitiesKey]: newEntities
    };

    return {
        newForm,
        item: entityCopy
    };
};

export const getVisibleEntities = (entities) => (
    entities.filter(entity => entity.changeStatus !== CHANGE_STATUS.DELETE)
);

export const isEntityChanged = (entity) => Boolean(entity.changeStatus);

export const prepareEntitiesForSave = (entities, entityCallback) => {
    const allEntities = [];
    const deltaEntities = [];

    entities.forEach(entity => {
        if (isEntityChanged(entity)) {
            const preparedEntity = entityCallback(entity);
            allEntities.push(preparedEntity);
            deltaEntities.push(preparedEntity);
        } else {
            allEntities.push(entity);
        }
    });

    return {
        allEntities,
        deltaEntities
    };
};

export const getEntitiesDeltaForServer = (entities) => (
    entities.filter(entity => isEntityChanged(entity))
);

export const getMassDisabled = (formDisabled, selectedIds) => {
    return formDisabled || selectedIds.length === 0;
};


export const createFiltersFromFilterData = (filterData) => filterData.map(filter => {
    const filterSign = Array.isArray(filter.value) ? Filter.IN : Filter.EQ;
    return new Filter(filter.field, filterSign, filter.value);
});