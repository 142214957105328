import React, { useState, useContext, useCallback, useMemo } from "react";
import { TableContainer, Table, TableRow, TableCell, Paper, Typography, Box, Popover, Checkbox as MuiCheckbox } from "@mui/material";
import { useIntl } from "react-intl";
import isValid from "date-fns/isValid";
import PropTypes from 'prop-types';

import { Checkbox, IconButton } from "../buttons";
import { FilterIcon, SortDescArrowIcon, SortAscArrowIcon } from "../icons";

import { KeyboardNavigationContext } from "./keyboard-navigation-provider.jsx";

import "./styles.less";
import { ClickAwayListenerWrapper } from "../click-away-listener";

export const DateTimeCell = ({ value }) => {
    const intl = useIntl()
    const date = new Date(value);

    const dateString = isValid(date) && intl.formatDate(date, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit"
    });

    return dateString && (
        <Typography className="table-row-cell-text">
            {dateString}
        </Typography>
    );
};

export const DateCell = ({ value }) => {
    const intl = useIntl()
    const date = new Date(value);

    const dateString = isValid(date) && intl.formatDate(date, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });

    return dateString && (
        <Typography className="table-row-cell-text">
            {dateString}
        </Typography>
    );
};

export const TableRowCellText = ({ children }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleTextClick = (event) => {
        const node = event.currentTarget;

        if (node.clientHeight < node.scrollHeight) {
            event.stopPropagation();
            setAnchorEl(node);
        }
    };

    const handleClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    return (
        <>
            <Typography
                className="table-row-cell-text"
                onClick={handleTextClick}
            >
                {children}
            </Typography>

            {open && (
                <Popover
                    className="table-row-cell-popover"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    sx={{
                        ".MuiPopover-paper": {
                            width: anchorEl.clientWidth
                        }
                    }}
                >
                    <Typography
                        className="table-row-cell-popover-text"
                    >
                        {children}
                    </Typography>
                </Popover>
            )}
        </>
    );
};

export const getCellClassNameInner = (_, column, selected) => {
    let className = "table-cell";

    if (selected)
        className += " table-cell-selected";

    if (column.type === "actions")
        className += " table-cell-actions";

    return className;
};

export const createRowCellContentInner = (
    row,
    column,
    selected,
    onSelect,
    getCellValue = (row, column) => row[column.id]
) => {
    const value = getCellValue(row, column);

    switch (column.type) {
        case "select":
            return (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        width: "fit-content"
                    }}>
                    <MuiCheckbox
                        checked={selected}
                        sx={{
                            "&.Mui-checked": {
                                color: "gray"
                            },
                        }}
                        onClick={() => {
                            onSelect(row, !selected)
                        }} />

                    {value && (
                        <Typography className="table-row-cell-text">
                            {value}
                        </Typography>
                    )}
                </Box>
            );

        case "datetime":
            return <DateTimeCell value={value} />

        case "date":
            return <DateCell value={value} />

        case "actions": {
            return (
                <Box className="table-row-cell-actions">
                    {column.actions.map(action => (
                        <IconButton
                            className="table-row-cell-actions-item"
                            key={action.id}
                            disabled={action.disabled}
                            onClick={(event) => action.onClick(event, row)}
                        >
                            <action.IconComponent />
                        </IconButton>
                    ))}
                </Box>
            )
        }

        default:
            return value && (
                <TableRowCellText>
                    {value.toString()}
                </TableRowCellText>
            )
    }
};

export const CommonTableRow = (props) => (
    <TableRow
        className="table-row"
        {...props}
    />
);

export const TableBodyCell = React.memo(({
    row,
    column,
    columnIndex,
    rowId,
    selectedIds,
    onSelectRow,
    rowIndex,
    getCellValue,
    createRowCellContent=createRowCellContentInner,
    getCellClassName=getCellClassNameInner,
}) => {
    const selected = selectedIds.includes(rowId);

    const keyboardNavigationContext = useContext(KeyboardNavigationContext);
    
    const className = getCellClassName(row, column, selected);

    const { handleClick, handleCellRef } = useMemo(() => {
        const keyboardNavigationRowIndex = rowIndex + 1;

        const handleClick = keyboardNavigationContext
            ? (e) => keyboardNavigationContext.handleCellClick(e, keyboardNavigationRowIndex, columnIndex)
            : undefined;

        const handleCellRef = keyboardNavigationContext
            ? (node) => keyboardNavigationContext.handleCellRef(node, keyboardNavigationRowIndex, columnIndex)
            : undefined;

        return { handleClick, handleCellRef };
    }, [keyboardNavigationContext, columnIndex, rowIndex]);

    return (
        <TableCell
            ref={handleCellRef}
            className={className}
            onClick={handleClick}
            tabIndex={-1}
        >
            <Box className="table-cell-content">
                {createRowCellContent(
                    row,
                    column,
                    selected,
                    onSelectRow,
                    getCellValue
                )}
            </Box>
        </TableCell>
    )
});

export const createBodyRowContent = ({
    rowIndex,
    row,
    getRowId,
    selectedIds,
    columns,
    onSelectRow,
    getCellValue,
    createRowCellContent,
    getCellClassName
}) => {
    const id = getRowId(row);

    return (
        <>
            {columns.map((column, columnIndex) => (
                <TableBodyCell
                    key={column.id}
                    row={row}
                    column={column}
                    columnIndex={columnIndex}
                    rowId={id}
                    selectedIds={selectedIds}
                    onSelectRow={onSelectRow}
                    rowIndex={rowIndex}
                    getCellValue={getCellValue}
                    createRowCellContent={createRowCellContent}
                    getCellClassName={getCellClassName}
                />
            ))}
        </>
    )
};

export const SelectColumn = ({ column, onSelect, checked }) => {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: "100%"
        }}>
            <Checkbox
                checked={checked}
                onClick={() => {
                    onSelect(!checked)
                }}
            />

            {column.title && (
                <Typography className="table-column-title">
                    {column.title}
                </Typography>
            )}
        </Box>
    )
};

export const Column = ({
    columnIndex,
    column,
    onSelect,
    checked,
    onFilterClick,
    filterData,
    sortData
}) => {
    const keyboardNavigationContext = useContext(KeyboardNavigationContext);

    const handleClick = (event) => {
        if (keyboardNavigationContext) {
            keyboardNavigationContext.handleCellClick(event, 0, columnIndex);
        }

        if (onFilterClick) {
            onFilterClick(event, column);
        }
    };

    const handleCellRef = useMemo(() => {
        if (!keyboardNavigationContext) {
            return;
        }

        return (node) => keyboardNavigationContext.handleCellRef(node, 0, columnIndex);
    }, [keyboardNavigationContext, columnIndex]);

    const hasFilters = filterData?.some(filter => filter.field === column.id);
    const sort = sortData?.find(sort => sort.field === column.id);

    const SortIcon = sort?.sign === "desc" ?
        SortDescArrowIcon : sort?.sign === "asc" ?
            SortAscArrowIcon : null;
        
    const className = column.type === "select"
        ? "table-column-cell table-column-cell-select"
        : "table-column-cell";

    return (
        <TableCell
            ref={handleCellRef}
            className={className}
            onClick={handleClick}
            tabIndex={-1}
            sx={{
                "&.MuiTableCell-root": {
                    width: column && column.width,
                }
            }}
        >
            {column.type === "select" ? (
                <SelectColumn
                    checked={checked}
                    column={column}
                    onSelect={onSelect}
                />
            ) : (
                <Typography className="table-column-title">
                    {column.title}
                </Typography>
            )}

            <Box className="table-column-icons">
                {hasFilters && <FilterIcon sx={{ color: "#9D9D9D", fontSize: "18px" }} />}
                {SortIcon && <SortIcon sx={{ color: "#9D9D9D", fontSize: "11px" }} />}
            </Box>
        </TableCell>
    )
};

export const CommonTableContainer = React.forwardRef((props, ref) => {
    const keyboardNavigationContext = useContext(KeyboardNavigationContext);

    const handleContainerRef = useCallback((node) => {
        if (ref) {
            if (typeof ref === "function") {
                ref(node);
            } else {
                ref.current = node;
            }
        }

        if (keyboardNavigationContext) {
            keyboardNavigationContext.handleContainerRef(node);
        }
    }, [keyboardNavigationContext, ref]);

    return (
        <ClickAwayListenerWrapper onClickAway={keyboardNavigationContext?.handleContainerClickAway}>
            <TableContainer
                component={Paper}
                className="table-container"
                {...props}
                ref={handleContainerRef}
                tabIndex={-1}
                onKeyDown={keyboardNavigationContext?.handleKeyDown}
            />
        </ClickAwayListenerWrapper>
    )
});

export const CommonTableComponent = (props) => <Table {...props} className="table-table" />;

export const CommonTableColumnRow = (props) => <TableRow {...props} className="table-column" />;

TableBodyCell.propTypes = {
    row: PropTypes.object,
    column: PropTypes.object,
    columnIndex: PropTypes.number,
    rowId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    selectedIds: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
    ),
    onSelectRow: PropTypes.func,
    rowIndex: PropTypes.number,
    getCellValue: PropTypes.func,
    createRowCellContent: PropTypes.func,
    getCellClassName: PropTypes.func,
};

TableRowCellText.propTypes = {
    children: PropTypes.node
};

DateTimeCell.propTypes = {
    value: PropTypes.any,
};

DateCell.propTypes = {
    value: PropTypes.any,
};

Column.propTypes = {
    columnIndex: PropTypes.number,
    column: PropTypes.object,
    onSelect: PropTypes.func,
    checked: PropTypes.bool,
    onFilterClick: PropTypes.func,
    filterData: PropTypes.arrayOf(PropTypes.object),
    sortData: PropTypes.arrayOf(PropTypes.object)
};

SelectColumn.propTypes = {
    column: PropTypes.object,
    onSelect: PropTypes.func,
    checked: PropTypes.bool
};
