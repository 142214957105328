import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import ScrollableTabs from "./scrollable-tabs.jsx";

const Tabs = ({ tabs, oneVisibleTab }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const defaultTab = tabs.find(tab => location.pathname.endsWith(tab.route)) || tabs[0];

    const [activeTabId, setActiveTabId] = useState(defaultTab.id);

    useEffect(() => {
        const activeTab = tabs.find(tab => tab.id === activeTabId);

        if (activeTab?.route) {
            navigate(activeTab.route);
        }
    }, [activeTabId, tabs, navigate]);

    return (
        <ScrollableTabs
            tabs={tabs}
            value={activeTabId}
            onChange={setActiveTabId}
            oneVisibleTab={oneVisibleTab}
        />
    );
};

Tabs.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.object),
    oneVisibleTab: PropTypes.bool
};

export default Tabs;
