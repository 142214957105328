import React, { useCallback } from 'react';
import { useIntl } from "react-intl";
import PropTypes from 'prop-types';

import SearchHelpDialog from '../search-helps';

const getDialogTitleId = (column) => {
    switch (column.id) {
        case "active":
            return "search-help.filters.active.title";

        case "critProfileName":
            return "search-help.filters.crit-profile-name.title";

        case "critRoleName":
            return "search-help.filters.crit-role-name.title";

        case "description":
            return "search-help.filters.description.title";

        case "email":
            return "search-help.filters.email.title";

        case "firstname":
        case "firstName":
            return "search-help.filters.firstname.title";

        case "functionId":
        case "functions":
            return "search-help.filters.funcrion-id.title";

        case "groupId":
            return "search-help.filters.system-group-id.title";

        case "lastname":
        case "lastName":
            return "search-help.filters.lastname.title";

        case "matrixId":
        case "matrices":
            return "search-help.filters.matrix-id.title";

        case "middlename":
        case "middleName":
            return "search-help.filters.middlename.title";

        case "phone":
            return "search-help.filters.phone.title";

        case "riskId":
            return "search-help.filters.risk-id.title";

        case "riskLevelId":
            return "search-help.filters.risk-level-id.title";

        case "systemId":
            return "search-help.filters.system-id.title";
        
        case "username":
        case "updUserName":
            return "search-help.filters.username.title"

        default:
            return "search-help.filters.default.title"
    }
};

const getTagText = (id) => {
    switch(id){
        case "active":
            return (key, text) => text
        case "riskLevelId":
            return (key, text) => text
        default:
            return key => key
    }
}

const filtersDialogColumns = [{ id: "text" }];

const FilterSelectDialog = ({ open, column, currentFilters, getFilters, onClose, onSubmit }) => {
    const intl = useIntl();

    const title = intl.formatMessage({ id: getDialogTitleId(column) });
    //const columns = getDialogColumns(column);

    const currentIds = [];
    
    currentFilters.forEach(filter => {
        if (Array.isArray(filter.value)) {
            currentIds.push(...filter.value);
        } else {
            currentIds.push(filter.value);
        }
    });

    const readRows = useCallback((params) => {
        return getFilters({
            ...params,
            field: column.id
        });
    }, [column.id, getFilters]);

    return(
        <SearchHelpDialog
            title={title}
            open={open}
            currentIds={currentIds}
            onCancel={onClose}
            onSubmit={onSubmit}
            readRows={readRows}
            showTableHeader={false}
            columns={filtersDialogColumns}
            showResetButton={true}
            getTagText={getTagText(column.id)}
        />
    );
};

FilterSelectDialog.propTypes = {
    open: PropTypes.bool, 
    column: PropTypes.object, 
    currentFilters: PropTypes.arrayOf(PropTypes.object), 
    getFilters: PropTypes.func, 
    onClose: PropTypes.func, 
    onSubmit: PropTypes.func
}

export default FilterSelectDialog;
