import React from "react";
import PropTypes from "prop-types";

import TreeTable from "../../common/tree-table";
import { createRowCellContentInner, getCellClassNameInner } from "../../common/table";

import RoleMenuNodeCellContent from "./role-menu-node-cell-content.jsx";
import RoleMenuActions from "./role-menu-actions.jsx";

import "./styles.less";

const columns = [
    {
        id: "node",
        title: "",
        type: "node",
    }
];

const getCellClassName = (row, column, selected, activeNodePath) => {
    let className = getCellClassNameInner(row, column, selected);

    className += " role-menu-table-cell";

    if (row.isRoot) {
        className += " role-menu-table-cell-row-menu";
    }

    if (row.isFolder) {
        if (row.level % 2 === 0) {
            className += " role-menu-table-cell-row-subfolder";
        } else {
            className += " role-menu-table-cell-row-folder";
        }
    }

    if (row.leaf) {
        className += " role-menu-table-cell-row-leaf";
    }

    if (row.path === activeNodePath) {
        className += ` role-menu-table-cell-active`;
    }

    return className;
};

const getNodeText = (row) => {
    return row.isFolder ? row.nodeText : `${row.reportName} - ${row.nodeText}`
}

const RoleMenuTable = ({
    tree,
    activeNodePath,
    onOpenNode,
    onAddFolder,
    onAddNode,
    onRemoveNode,
    onCopy,
    onRowClick
}) => {
    const createRowCellContent = (row, column, selected, onSelect, getCellValue) => {
        switch (column.type) {
            case "node":
                return (
                    <>
                        <RoleMenuNodeCellContent
                            open={row.open}
                            title={getNodeText(row)}
                            leaf={row.leaf}
                            level={row.level}
                            onClick={() => onOpenNode(row.path)}
                        />

                        {row.isRoot && (
                            <RoleMenuActions
                                onAddFolder={onAddFolder}
                                onAddNode={onAddNode}
                                onRemoveNode={onRemoveNode}
                                onCopy={onCopy}
                            />
                        )}
                    </>
                    
                );
    
            default:
                return createRowCellContentInner(row, column, selected, onSelect, getCellValue);
        }
    };

    return (
        <TreeTable
            className="role-menu"
            tree={tree}
            columns={columns}
            showFiltersActions={false}
            showPagination={false}
            getCellClassName={(row, column, selected) => getCellClassName(row, column, selected, activeNodePath)}
            createRowCellContent={createRowCellContent}
            onRowClick={onRowClick}
        />
    );
};

RoleMenuTable.propTypes = {
    tree: PropTypes.arrayOf(PropTypes.object),
    activeNodePath: PropTypes.arrayOf(PropTypes.string),
    onOpenNode: PropTypes.func,
    onAddFolder: PropTypes.func,
    onAddNode: PropTypes.func,
    onRemoveNode: PropTypes.func,
    onCopy: PropTypes.func,
    onRowClick: PropTypes.func,
};

export default RoleMenuTable;