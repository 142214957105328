import React from "react";
import { useIntl } from "react-intl";
import { CircularProgress, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { BUSY_TYPES } from "../../../utils/busy.js";

import { CommonDialog } from "./common-dialog.jsx";

import "./style.less";

const getDialogTitle = (title, busyType, intl) => {
    if (title) {
        return title;
    }

    if (!busyType) {
        return null;
    }

    return intl.formatMessage({
        id: `common.progress-dialog.${busyType}.title`
    });
};

const ProgressDialog = ({
    title,
    progress,
    busyType,
    ...props
}) => {
    const intl = useIntl();
    const titleInner = getDialogTitle(title, busyType, intl);

    return(
        <CommonDialog className="progress-dialog" {...props}>
            <CircularProgress
                sx={{ color: "var(--font-4)" }}
                size="24px"
            />

            <Typography component="p" className="progress-dialog-title">{titleInner}</Typography>

            {
                progress &&
                <Typography component="p" className="progress-dialog-progress">{progress}</Typography>
            }
        </CommonDialog>
    )
}

ProgressDialog.propTypes = {
    title: PropTypes.node,
    progress: PropTypes.number,
    busyType: PropTypes.oneOf(Object.values(BUSY_TYPES))
};

export default ProgressDialog;
