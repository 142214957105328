import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { selectModelingSelectedRoleId, setModelingSelectedRoleById } from "../../../../reducers/reports-modeling/roleLevelSlice";

import { CommonDialogTitle } from "../../../common/dialogs";

import { EntitiesSelectDialog, EntitiesSelectDialogContent, EntitiesSelectDialogSearch } from "../../entities-select-dialog";

import RolesTable from "./roles-table.jsx";
import RoleLevelModelingRolesDialogActions from "./role-level-modeling-roles-dialog-actions.jsx";

const RoleLevelModelingRolesDialog = ({ open, onClose }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const savedSelectedRoleId = useSelector(selectModelingSelectedRoleId);
    const [selectedRoleId, setSelectedRoleId] = useState("");
    const [searchString, setSearchString] = useState("");

    const title = intl.formatMessage({ id: "role-level-modeling-role-search-help.dialog.title" });

    useEffect(() => {
        setSelectedRoleId(savedSelectedRoleId);
    }, [savedSelectedRoleId]);
    
    const handleSubmit = () => {
        dispatch(setModelingSelectedRoleById(
            selectedRoleId
        ));
        onClose();
    };

    return (
        <EntitiesSelectDialog open={open}>
            <CommonDialogTitle
                title={title}
                onClose={onClose}
            />

            <EntitiesSelectDialogContent>
                <EntitiesSelectDialogSearch
                    value={searchString}
                    onSearchClick={(event) => setSearchString(event.target.value)}
                />
                <RolesTable
                    searchString={searchString}
                    selectedId={selectedRoleId}
                    onSelectRow={(e) => setSelectedRoleId(e.target.value)}
                    onRowClick={(row) => setSelectedRoleId(row.id)}
                />
            </EntitiesSelectDialogContent>

            <RoleLevelModelingRolesDialogActions
                selectedRoleId={selectedRoleId}
                onSubmitClick={handleSubmit}
                onUnselectRole={() => setSelectedRoleId("")}
                onCancelClick={onClose}
            />
        </EntitiesSelectDialog>
    );
};

RoleLevelModelingRolesDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
};

export default RoleLevelModelingRolesDialog;