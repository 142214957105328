import React from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { Tag } from "../../common/form-controls";

import "./styles.less";

const SelectedEntityTag = ({
    title,
    tag,
    onDelete
}) => {
    return (
        <Box className="selected-entity-tag-box">
            <Typography className="selected-entity-tag-title">
                {title}
            </Typography>
            <Tag
                tag={tag}
                onDelete={onDelete}
            />
        </Box>
    );
};

SelectedEntityTag.propTypes = {
    title: PropTypes.string,
    tag: PropTypes.string,
    onDelete: PropTypes.func
};

export default SelectedEntityTag;