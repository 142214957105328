import React from "react";
import { useIntl } from "react-intl";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { CommonDialog, CommonDialogTitle, CommonDialogContent, CommonDialogActions } from "../../common/dialogs"
import { Button } from "../../common/buttons";

import "./styles.less";

const PermissionWithoutClassErrorDialog = ({
    error,
    open,
    onClose,
    onRemoveUnvalid
}) => {
    const intl = useIntl();
    const text = intl.formatMessage({ id: "permission-without-class-error-dialog.text" });
    const cancelTitle = intl.formatMessage({ id: "common.btn-cancel" });
    const removeUnvalidTitle = intl.formatMessage({ id: "permission-without-class-error-dialog.remove-unvalid" });

    const {
        title,
        detail: permissions
    } = error;

    return (
        <CommonDialog open={open}>
            <CommonDialogTitle
                title={title}
                onClose={onClose}
            />

            <CommonDialogContent>
                <Typography
                    className="permission-without-class-error-dialog-text"
                >
                    {text}
                </Typography>
                <List>
                    {permissions?.map(permission => (
                        <ListItem
                            key={permission}
                        >
                            <ListItemText
                                className="permission-without-class-error-dialog-item-text"
                                primary={permission}
                            />
                        </ListItem>
                    ))}
                </List>
            </CommonDialogContent>

            <CommonDialogActions>
                <Button
                    className="permission-without-class-error-dialog-cancel"
                    variant="outlined"
                    onClick={onClose}
                >
                    {cancelTitle}
                </Button>

                <Button
                    className="permission-without-class-error-dialog-remove"
                    variant="contained"
                    onClick={onRemoveUnvalid}
                >
                    {removeUnvalidTitle}
                </Button>
            </CommonDialogActions>
        </CommonDialog>
    );
};

PermissionWithoutClassErrorDialog.propTypes = {
    error: PropTypes.shape({
        title: PropTypes.string,
        detail: PropTypes.arrayOf(PropTypes.string)
    }),
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onRemoveUnvalid: PropTypes.func
};

export default PermissionWithoutClassErrorDialog;