import React, { useEffect } from "react";
import { Container } from "@mui/material";
import { SignInForm } from "../sign-in-form";
import "./styles.less";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


const SignInPage = () => {
    const signedIn = useSelector(state => state.auth.signedIn);
    const navigate = useNavigate();

    useEffect(() => {
        if (signedIn) {
            navigate("/");
        }
    }, [signedIn, navigate]);

    return(
        <Container className="sign-in-page">
            <SignInForm />
        </Container>
    );
};

export default SignInPage;
