import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "@mui/material";

import { ScrollableTabs } from "../../common/tabs";
import breakpoints from "../../common/styles/breakpoints";

import "./style.less";

const ReportResultsTabs = ({ tabs, activeTabId, onActiveTabIdChange }) => {
    const matches1024 = useMediaQuery(`(min-width: ${breakpoints.minLaptopBreakpoint})`);

    return (
        <ScrollableTabs
            className="report-results-tabs"
            tabs={tabs}
            value={activeTabId}
            oneVisibleTab={!matches1024}
            onChange={onActiveTabIdChange}
        />
    );
};

ReportResultsTabs.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.object),
    activeTabId: PropTypes.string,
    onActiveTabIdChange: PropTypes.func
};

export default ReportResultsTabs;
