import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useMediaQuery } from "@mui/material";
import { Download } from "@mui/icons-material";
import PropTypes from "prop-types";

import { getEntityTextWithIntl, getStatusTextWithIntl } from "../../../../utils/integration-utils";
import { calcTableHeight } from "../../../../utils/table";

import { CommonDialog, CommonDialogContent, CommonDialogTitle } from "../../../common/dialogs";
import CommonTable from "../../../common/table";
import breakpoints from "../../../common/styles/breakpoints";

import "./style.less";

const getCellValue = (intl, row, column) => {
    switch (column.id) {
        case "entityText":
            return getEntityTextWithIntl(intl, row.entity);

        case "statusText":
            return getStatusTextWithIntl(intl, row.status);


        default:
            return row[column.id];
    }
};

const ItemTable = ({
    rows,
    showDownload,
    onRowAction
}) => {
    const intl = useIntl();

    const matches1920 = useMediaQuery(`(min-width: ${breakpoints.minDesktopBreakpoint})`);
    
    const toolbarActions = useMemo(() => [
        {
            id: "search",
            type: "search",
            position: "end",
            callback: (value) => onRowAction("search", value)
        },
    ], [onRowAction]);

    const getCellValueInner = useCallback((row, column) => {
        return getCellValue(intl, row, column);
    }, [intl]);

    const columns = useMemo(() => {
        const columns = [
            {
                id: "entityText",
                title: intl.formatMessage({ id: "integration-history-dialog.columns.entity-text" }),
                type: "text",
                width: "0.75fr"
            },
            {
                id: "statusText",
                title: intl.formatMessage({ id: "integration-history-dialog.columns.status-text" }),
                type: "text",
                width: "0.75fr"
            },
            {
                id: "startDate",
                title: intl.formatMessage({ id: "integration-history-dialog.columns.start-date" }),
                type: "datetime",
                width: "0.5fr"
            }
        ];

        if (showDownload) {
            columns.push({
                id: "actions",
                type: "actions",
                title: "",
                actions: [
                    {
                        id: "download",
                        IconComponent: Download,
                        onClick: (_, row) => onRowAction("download", row)
                    },
                ]
            });
        }

        return columns;
    }, [onRowAction, intl, showDownload]);

    const tableHeight = calcTableHeight({
        headerHeight: matches1920 ? 29 : 19,
        rowHeight: matches1920 ? 58 : 48,
        rowsNumber: 8
    });

    return (
        <CommonTable
            className="integration-history-dialog-table"
            rows={rows || []}
            getCellValue={getCellValueInner}
            toolbarActions={toolbarActions}
            columns={columns}
            selectedIds={[]}
            showPagination={false}
            showFiltersActions={false}
            virtualize
            virtualizationProps={{
                style: { height: tableHeight }
            }}
        />
    );
};

const IntegrationHistoryDialog = ({
    rows,
    system,
    showDownload,
    onClose,
    onRowAction,
    open,
    ...props
}) => {
    const intl = useIntl();

    const innerTitle = intl.formatMessage({ id: "integration-page.history-dialog.title" }, { system })

    return (
        <CommonDialog
            open={!!open}
            {...props}
            className="integration-history-dialog"
        >
            <CommonDialogTitle
                title={innerTitle}
                onClose={onClose}
            />

            <CommonDialogContent>
                <ItemTable
                    rows={rows}
                    showDownload={showDownload}
                    onRowAction={onRowAction}
                />
            </CommonDialogContent>
        </CommonDialog>
    )
};

IntegrationHistoryDialog.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object),
    system: PropTypes.string,
    showDownload: PropTypes.bool,
    onClose: PropTypes.func,
    onRowAction: PropTypes.func,
    open: PropTypes.bool
};

ItemTable.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object),
    showDownload: PropTypes.bool,
    onRowAction: PropTypes.func
};

export default IntegrationHistoryDialog;
