import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

import "./styles.less";

const ReportModelingHeader = ({
    className="",
    children,
    ...props
}) => {
    const childrenIsArray = Array.isArray(children);

    const [firstChild, ...restChildren] = childrenIsArray
        ? children || [null, null]
        : [children];

    const childrenNumber = childrenIsArray && children.length || 1;

    return (
        <Box
            className={`report-modeling-header ${className}`}
            sx={{
                "--report-modeling-header-columns": childrenNumber 
            }}
            {...props}
        >
            <Box className="report-modeling-header-first-child-wrapper">
                {firstChild}
                {/* <ReportModelingHeaderSaveButton
                    disabled={saveDisabled}
                    onClick={onSaveClick}
                /> */}
            </Box>
            
            {restChildren}
        </Box>
    ); 
};

ReportModelingHeader.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node)
    ]),
};

export default ReportModelingHeader;