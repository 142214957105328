import React from "react";
import { useIntl } from "react-intl";
import PropTypes from 'prop-types';

import { reportsService } from "../../../services/reports-service";

import { DropdownSearchHelpInput } from "../../common/form-controls";

const getSystemsDefault = (params, signal) => {
    return reportsService.getSystemsSearchHelp(params, signal);
};

const SystemIdsInput = ({ ids, setIds, getSystems }) => {
    const intl = useIntl();
    const label = intl.formatMessage({ id: "report-parameters.systems-label" });

    return (
        <DropdownSearchHelpInput
            ids={ids}
            setIds={setIds}
            getData={getSystems}
            label={label}
        />
    );
};

SystemIdsInput.propTypes = {
    ids: PropTypes.arrayOf(PropTypes.string),
    setIds: PropTypes.func,
    getSystems: PropTypes.func
};

SystemIdsInput.defaultProps = {
    getSystems: getSystemsDefault
}

export default SystemIdsInput;
