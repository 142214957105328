import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { CommonDialogActions } from "../../common/dialogs";
import { Button } from "../../common/buttons";

import "./styles.less";

const ReportColumnsDialogActions = ({
    resetDisabled,
    applyDisabled,
    onResetClick,
    onApplyClick
}) => {
    const intl = useIntl();

    const applyTitle = intl.formatMessage({ id: "common.btn-save" });
    const resetTitle = intl.formatMessage({ id: "report-columns-dialog.actions.reset" });

    return (
        <CommonDialogActions className="report-column-dialog-actions">
            <Button
                disabled={applyDisabled}
                variant="contained"
                className="report-column-dialog-actions-save"
                onClick={onApplyClick}
            >
                {applyTitle}
            </Button>

            <Button
                disabled={resetDisabled}
                variant="outlined"
                className="report-column-dialog-actions-reset"
                onClick={onResetClick}
            >
                {resetTitle}
            </Button>
        </CommonDialogActions>
    );
};

ReportColumnsDialogActions.propTypes = {
    resetDisabled: PropTypes.bool,
    applyDisabled: PropTypes.bool,
    onResetClick: PropTypes.func,
    onApplyClick: PropTypes.func
};

export default ReportColumnsDialogActions;