import React from "react";
import { FormattedMessage } from "react-intl";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";

import { Button } from "../buttons";

import { CommonDialog, CommonDialogTitle, CommonDialogContent, CommonDialogActions } from "./common-dialog.jsx";

import "./style.less";

const DeleteDialog = ({ title, text, onDeleteClick, onCancelClick, ...props }) => {
    return(
        <CommonDialog {...props}>
            <CommonDialogTitle
                title={title}
                onClose={onCancelClick}
            />

            <CommonDialogContent>
                <Typography component="p" className="delete-dialog-text">
                    {text}
                </Typography>
            </CommonDialogContent>

            <CommonDialogActions>
                <Button
                    variant="outlined"
                    onClick={onCancelClick}
                    sx={{
                        color: 'var(--font-4)',
                        borderColor: 'var(--font-4)',
                    }}
                >
                    <FormattedMessage id="common.btn-cancel" />
                </Button>
                <Button
                    variant="contained"
                    onClick={onDeleteClick}
                    sx={{
                        color: 'var(--bg-2)',
                        backgroundColor: 'var(--font-2)',
                        '&:hover': {
                            backgroundColor: 'var(--font-2)',
                        }
                    }}
                >
                    <FormattedMessage id="common.delete-dialog.btn-delete" />
                </Button>
            </CommonDialogActions>
        </CommonDialog>
    )
};

DeleteDialog.propTypes = {
    title: PropTypes.node,
    text: PropTypes.string,
    onDeleteClick: PropTypes.func,
    onCancelClick: PropTypes.func,
}

export default DeleteDialog;
