import React, { useCallback, useEffect, useState } from "react";
import { nanoid } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

import { fetchRiskLevels, updateRiskLevels } from "../../../../reducers/admin/riskLevelsSlice";
import { clearRiskLevels } from "../../../../reducers/admin/riskLevelsSlice";
import { setError } from "../../../../reducers/admin/riskLevelsSlice";

import { READ_MODE, getCurrentMode } from "../../../common/entity-form";
import { ProgressDialog, ErrorsDialog } from "../../../common/dialogs";
import { EmptyTabContent } from "../../../common/empty-tab-content";

import RiskLevelInput from "./risk-level-input.jsx";
import RiskLevelsActions from "./risk-levels-actions.jsx";

import "./style.less";


const EmptyRiskLevelsTab = ({ onAddRiskLevelClick }) => {
    const intl = useIntl();
    const text = intl.formatMessage({ id: "risk-levels-tab.empty-risk-levels.text" });
    const buttonText = intl.formatMessage({ id: "risk-levels-tab.toolbar.btn-create-risk-level" });

    return (
        <EmptyTabContent
            text={text}
            buttonText={buttonText}
            onButtonClick={onAddRiskLevelClick}
        />
    );
};

const getValidationErrorForField = (validationErrors, field, value) => {
    return validationErrors?.find(error =>
        error.field === field && error.value === value
    );
};

const getFormRiskLevelsWithErrors = (formRiskLevels, validationErrors) => {
    const newFormRiskLevels = formRiskLevels.map(riskLevel => {
        const descriptionError = getValidationErrorForField(
            validationErrors, "description", riskLevel.description
        );

        const colorError = getValidationErrorForField(
            validationErrors, "color", riskLevel.color
        );

        return {
            ...riskLevel,
            descriptionError,
            colorError
        };
    });

    return newFormRiskLevels;
};

const RiskLevelsTab = () => {
    const dispatch = useDispatch();

    const riskLevels = useSelector(state => state.admin.riskLevels.riskLevels);
    const error = useSelector(state => state.admin.riskLevels.error);
    const validationErrors = useSelector(state => state.admin.riskLevels.validationErrors);
    const busy = useSelector(state => state.admin.riskLevels.busy);
    const busyType = useSelector(state => state.admin.riskLevels.busyType);
    const riskLevelsEditable = useSelector(state => state.admin.riskLevels.riskLevelsEditable);

    const [formRiskLevels, setFormRiskLevels] = useState([]);
    const [emptyClicked, setEmptyClicked] = useState(false);

    const mode = getCurrentMode(false, riskLevelsEditable);

    useEffect(() => {
        dispatch(fetchRiskLevels());

        return (() => {
            dispatch(clearRiskLevels());
        });
    }, [dispatch]);

    useEffect(() => {
        if (riskLevelsEditable && emptyClicked) return;
        initForm();
    }, [initForm, riskLevelsEditable, emptyClicked]);

    useEffect(() => {
        setFormRiskLevels(formRiskLevels => {
            return getFormRiskLevelsWithErrors(formRiskLevels, validationErrors);
        });
    }, [validationErrors]);

    const initForm = useCallback(() => {
        setFormRiskLevels(riskLevels.map((riskLevel) => ({
            ...riskLevel,
            localId: nanoid(),
        })));
    }, [riskLevels]);

    const getNewRiskLevel = () => {
        return {
            localId: nanoid(),
            description: "",
            color: "#ffffff",
        };
    };

    const updateRiskLevel = (index, updatedRiskLevel) => {
        const newRiskLevels = formRiskLevels.slice();
        newRiskLevels[index] = updatedRiskLevel;
        setFormRiskLevels(newRiskLevels);
    };

    const handleAddRiskLevelClick = () => {
        const newRiskLevel = getNewRiskLevel();
        setFormRiskLevels([...formRiskLevels, newRiskLevel]);
    };

    const handleEmptyAddRiskLevelClick = () => {
        if (mode === READ_MODE) {
            handleEditClick();
        }

        setEmptyClicked(true);
        handleAddRiskLevelClick();
    };

    const deleteRiskLevel = (localId) => {
        setFormRiskLevels(formRiskLevels.filter(riskLevel => riskLevel.localId !== localId));
    };

    const handleEditClick = () => {
        dispatch(fetchRiskLevels());
    };

    const handleSaveClick = () => {
        dispatch(updateRiskLevels(formRiskLevels));
    };

    const riskLevelsEmpty = riskLevels.length === 0 && formRiskLevels.length === 0;

    return (
        <>
            {!busy && riskLevelsEmpty ? (
                <EmptyRiskLevelsTab onAddRiskLevelClick={handleEmptyAddRiskLevelClick} />
            ) : (
                <Box className="risk-levels-tab">
                    <Box sx={{ display: "flex", flexDirection: "column", rowGap: "12px", marginTop: "24px" }}>
                        {formRiskLevels.map(((riskLevel, index) => (
                            <RiskLevelInput
                                key={riskLevel.localId}
                                showLabel={index === 0}
                                riskLevel={riskLevel}
                                setRiskLevel={(updatedRiskLevel) => updateRiskLevel(index, updatedRiskLevel)}
                                onDeleteClick={() => deleteRiskLevel(riskLevel.localId)}
                                disabled={mode === READ_MODE}
                            />
                        )))}
                    </Box>

                    <RiskLevelsActions
                        mode={mode}
                        onAddClick={handleAddRiskLevelClick}
                        onEditClick={handleEditClick}
                        onSaveClick={handleSaveClick}
                        onResetClick={initForm}
                    />
                </Box>
            )}

            <ProgressDialog
                open={busy}
                busyType={busyType}
            />

            <ErrorsDialog
                error={error}
                open={error?.type === "error"}
                onClose={() => { dispatch(setError(null)) }}
            />
        </>
    );
};

EmptyRiskLevelsTab.propTypes = {
    onAddRiskLevelClick: PropTypes.func,
};

export default RiskLevelsTab;
