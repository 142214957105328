import { IconButton as MuiIconButton, styled } from "@mui/material";

const IconButton = styled(MuiIconButton)({
    padding: 0,
    border: "1px solid #ACACAC",
    borderRadius: 4,
    width: 42,
    height: 42,

    ".MuiSvgIcon-root": {
        color: "#B7B7B7",
        width: "100%",
        height: "100%"
    }
});

export default IconButton;
