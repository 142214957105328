import React from "react";
import { Box, List, ListItemButton, ListItemText } from "@mui/material";
import PropTypes from 'prop-types'; 

import { FavoriteIcon, TrashIcon } from "../../common/icons";
import { IconButton } from "../../common/buttons";

import "./styles.less";

const VariantsListItemIconButton = ({
    children,
    active,
    onClick
}) => {
    let className = "variants-list-item-icon-button";

    if (active) {
        className += " variants-list-item-icon-button-active"
    }

    return (
        <IconButton
            className={className}
            onClick={onClick}
        >
            {children}
        </IconButton>
    );
};

const VariantListItem = ({
    variant,
    onClick,
    onFavoriteClick,
    onDeleteClick
}) => {
    return (
        <ListItemButton
            className="variants-list-item"
            onClick={onClick}
        >
            <ListItemText className="variant-list-item-text">
                {variant.variantName}
            </ListItemText>

            <VariantsListItemIconButton
                onClick={onFavoriteClick}
                active={variant.inFavorites}
            >
                <FavoriteIcon />
            </VariantsListItemIconButton>

            <VariantsListItemIconButton
                onClick={onDeleteClick}
            >
                <TrashIcon />
            </VariantsListItemIconButton>
        </ListItemButton>
    )
};

const VariantsList = ({
    variants,
    onDeleteClick,
    onFavoriteClick,
    onItemClick,
}) => {
    const handleFavoriteClick = (event, variant) => {
        event.stopPropagation();
        onFavoriteClick(variant);
    };

    const handleDeleteClick = (event, variant) => {
        event.stopPropagation();
        onDeleteClick(variant);
    };

    return (
        <Box className="variants-list-wrapper">
            <List className="variants-list">
                {variants?.map(variant => (
                    <VariantListItem
                        key={variant.variantName}
                        variant={variant}
                        onClick={() => onItemClick(variant)}
                        onFavoriteClick={(event) => handleFavoriteClick(event, variant)}
                        onDeleteClick={(event) => handleDeleteClick(event, variant)}
                    />
                ))}
            </List>
        </Box>
    );
};

VariantsListItemIconButton.propTypes = {
    children: PropTypes.node,
    active: PropTypes.bool,
    onClick: PropTypes.func
};

VariantListItem.propTypes = {
    variant: PropTypes.shape({
        variantName: PropTypes.string,
        inFavorites: PropTypes.bool
    }),
    onClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onFavoriteClick: PropTypes.func,
};

VariantsList.propTypes = {
    onDeleteClick: PropTypes.func,
    onFavoriteClick: PropTypes.func,
    onItemClick: PropTypes.func,
    variants: PropTypes.arrayOf(PropTypes.shape({
        variantName: PropTypes.string,
        inFavorites: PropTypes.bool
    }))
};

export default VariantsList;
