import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { SubmitDialog } from "../../common/dialogs";

const FavoritesDialog = ({ open, variant, onFavoritesClick, onCancelClick }) => {
    const intl = useIntl();

    const titleId = variant.inFavorites ?
        "user-level-report-page.favorites-dialog.remove.title" :
        "user-level-report-page.favorites-dialog.add.title";
    
    const textId = variant.inFavorites ?
        "user-level-report-page.favorites-dialog.remove.text" :
        "user-level-report-page.favorites-dialog.add.text";
    
    const confirmTitleId = variant.inFavorites ?
        "user-level-report-page.favorites-dialog.remove.btn-remove" :
        "user-level-report-page.favorites-dialog.add.btn-add";

    const title= intl.formatMessage({ id: titleId });

    const text = intl.formatMessage(
        { id: textId },
        { name: variant.variantName }
    );

    const confirmTitle = intl.formatMessage({ id: confirmTitleId });
    const cancelTitle = intl.formatMessage({ id: "common.btn-cancel" });

    return(
        <SubmitDialog
            open={open}
            title={title}
            message={text}
            onCancelClick={onCancelClick}
            buttons={[
                {
                    text: cancelTitle,
                    type: "close",
                    onClick: onCancelClick
                },
                {
                    text: confirmTitle,
                    type: "apply",
                    onClick: onFavoritesClick
                }
            ]}
        />
    );
};

FavoritesDialog.propTypes = {
    open: PropTypes.bool,
    variant: PropTypes.object,
    onFavoritesClick: PropTypes.func,
    onCancelClick: PropTypes.func
};

export default FavoritesDialog;
