import React from "react";
import { Box, Typography, styled } from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import PropTypes from "prop-types";

import { SearchHelpErrorIcon, WarningIcon } from "../icons";

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#EAEAEA',
        color: '#363636',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        borderRadius: "8px",
        padding: "8px 16px",
    },
}));

const ErrorIcon = React.forwardRef((props, ref) => {
    return (<SearchHelpErrorIcon {...props} innerRef={ref} />)
})

const WarningIconWrapped = React.forwardRef((props, ref) => {
    return (<WarningIcon {...props} ref={ref} />)
})

const InputEndAdornment = ({
    error=false,
    errorMessage,
    warning=false,
    warningMessage,
    showInnerHelpers=false,
    endAdornment,
}) => {
    if (!showInnerHelpers) {
        return endAdornment;
    }

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
                position: "absolute",
                top: "50%",
                right: "10px",
                transform: "translateY(-50%)",
            }}
        >
            <HtmlTooltip
                title={
                    <React.Fragment>
                        <Typography color="inherit">{errorMessage || ""}</Typography>
                    </React.Fragment>
                }
                placement="top"
                style={{
                    cursor: "pointer",
                    display: error ? "initial" : "none"
                }}
            >
                <ErrorIcon sx={{ color: "red" }} />
            </HtmlTooltip>

            <HtmlTooltip
                title={
                    <React.Fragment>
                        <Typography color="inherit">{warningMessage || ""}</Typography>
                    </React.Fragment>
                }
                placement="top"
                style={{
                    cursor: "pointer",
                    display: warning ? "initial" : "none"
                }}
            >
                <WarningIconWrapped color="orange" />
            </HtmlTooltip>

            {endAdornment}
        </Box>
    );
};

InputEndAdornment.propTypes = {
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    warning: PropTypes.bool,
    warningMessage: PropTypes.string,
    showInnerHelpers: PropTypes.bool,
    endAdornment: PropTypes.node
};

export default InputEndAdornment;