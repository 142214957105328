import SodService from "./sod-service";

class SystemGroupsService extends SodService {
    async getGroups(params) {
        const config = this.generateConfigForGetEntities(params);
        const data = await this.get("/core/systems/system-groups/list", config);

        const groups = data?.rows.map(item => this.parseSystemGroup(item));
        const total = data?.total;

        return { groups, total };
    }

    async getGroupColumns() {
        return await this.getColumns("/core/systems/system-groups/columns");
    }

    async getGroupChangeHistory(key) {
        const data = await this.get(`/core/systems/system-groups/detailed/change-history/${key}`)
        return data && data.map(item => this.parseUserActionLog(item))
    }

    async getGroupsFilters(params) {
        return this.getFilters("/core/systems/system-groups/filters", params);
    }

    async getGroupDetailed(groupId) {
        const data = await this.get(`/core/systems/system-groups/detailed/${groupId}`);

        const groupDetailed = this.parseSystemGroup(data);

        return groupDetailed;
    }

    async createGroup(group) {
        const data = await this.post(`/core/systems/system-groups/detailed/${group.id}`, this.parseSystemGroupToServer(group));

        return this.parseSystemGroup(data);
    }

    async editGroup(group) {
        const data = await this.put(`/core/systems/system-groups/detailed/${group.id}`, this.parseSystemGroupToServer(group));

        return this.parseSystemGroup(data);
    }

    async deleteGroup(groupId) {
        return this.delete(`/core/systems/system-groups/detailed/${groupId}`);
    }

    parseSystemGroupToServer(item) {
        return {
            group_id: item.id,
            description: item.description,
            systems: item.systems.map(system => this.parseGroupSystemForServer(system))
        }
    }

    parseGroupSystemForServer(item) {
        return {
            system_id: item.id
        }
    }

    parseSystemGroup(item) {
        return {
            id: item.group_id,
            description: item.description,
            systems: item.systems.map(system => ({ id: system.system_id }))
        }
    }
}

const systemGroupsService = new SystemGroupsService();

export { systemGroupsService };
export default SystemGroupsService;