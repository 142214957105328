import React from 'react';
import { createSvgIcon } from '@mui/material';

const FavoriteIcon = createSvgIcon(
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-4 -4 24 24"
        fill="none"
    >
        <path
            d="M9.93653 3.41102C9.51432 3.70224 9.11903 4.11047 8.83991 4.54244C8.65569 4.82753 8.33943 4.99971 8 4.99971C7.66057 4.99971 7.34432 4.82753 7.1601 4.54244C6.88426 4.11557 6.48706 3.71093 6.06036 3.41945C5.61966 3.11839 5.24108 3.00021 5 3.00021C3.48929 3.00021 2 4.32024 2 6.33338C2 8.44468 3.3291 10.3629 4.91579 11.8275C5.69319 12.5452 6.49165 13.115 7.12915 13.5013C7.44837 13.6947 7.71641 13.8355 7.9116 13.9241C7.94444 13.939 7.97389 13.9519 8.00001 13.9628C8.02612 13.9519 8.05557 13.939 8.08841 13.9241C8.2836 13.8355 8.55164 13.6947 8.87085 13.5013C9.50835 13.115 10.3068 12.5452 11.0842 11.8275C12.6709 10.3629 14 8.44468 14 6.33338C14 4.33373 12.5204 3.023 10.9851 3.00008C10.7441 2.99649 10.3723 3.11042 9.93653 3.41102ZM7.85629 14.0153C7.85642 14.0152 7.86013 14.0141 7.86703 14.0125C7.85962 14.0147 7.85616 14.0155 7.85629 14.0153ZM8.13297 14.0125C8.13989 14.0141 8.1436 14.0152 8.14371 14.0153C8.14382 14.0155 8.14037 14.0147 8.13297 14.0125ZM8.80097 1.76466C9.42765 1.33241 10.2059 0.988229 11.0149 1.00031C13.6796 1.04009 16 3.27768 16 6.33338C16 9.2775 14.1791 11.6925 12.4408 13.2971C11.5557 14.1142 10.6479 14.763 9.90728 15.2118C9.53743 15.4359 9.19843 15.6167 8.91511 15.7453C8.7743 15.8092 8.63513 15.8658 8.50497 15.9084C8.40088 15.9424 8.20987 15.9997 8 15.9997C7.79013 15.9997 7.59913 15.9424 7.49503 15.9084C7.36488 15.8658 7.22571 15.8092 7.08489 15.7453C6.80157 15.6167 6.46258 15.4359 6.09273 15.2118C5.3521 14.763 4.44432 14.1142 3.55921 13.2971C1.8209 11.6925 0 9.2775 0 6.33338C0 3.29117 2.31071 1.00021 5 1.00021C5.79076 1.00021 6.56217 1.34013 7.18851 1.768C7.47165 1.96142 7.74433 2.18567 7.99712 2.43241C8.24764 2.18517 8.51841 1.95956 8.80097 1.76466Z"
            fill="currentColor"
        />
    </svg>,
    "FavoriteIcon"
);

export default FavoriteIcon;


