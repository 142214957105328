import { nanoid } from "@reduxjs/toolkit";

// TODO: при необходимости добавить дельту
export function addGroupRoleChild(newRoleChild, children) {
    const alreadyInChildren = children?.some(child => (
        child.role === newRoleChild.role &&
        child.systemId === newRoleChild.systemId
    ));

    if (children && !alreadyInChildren) {
        children.push({
            ...newRoleChild,
            id: nanoid()
        });
    }
}

// TODO: при необходимости добавить дельту
export function removeGroupRoleChildren(roleIdsToRemove, children) {
    return children?.filter(child => !roleIdsToRemove?.includes(child.id));
}