import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { systemGroupsService } from "../../services/system-groups-service";
import LockManager from "../../utils/lockManager";
import { systemsService } from "../../services/systems-service";
import { BUSY_TYPES } from "../../utils/busy";

const lockManager = new LockManager("systemGroup");

const initialState = {
    groups: [],
    columns: [],
    total: 0,
    selectedIds: [],
    searchString: "",
    pageNumber: 1,
    limitNumber: 10,
    sortData: [],
    filterData: [],
    busy: false,
    busyType: null,
    error: null,
    submit: false,
    currentGroup: null,
    currentGroupEditable: false,
    currentGroupDeleted: false,
    validationErrors: null,
    canCreateFirstGroup: false,
}

export const fetchGroups = createAsyncThunk(
    "admin/fetchGroups",
    async ({ params }) => {
        const response = await systemGroupsService.getGroups(params);
        return response;
    }
);

export const fetchGroupColumns = createAsyncThunk(
    "admin/fetchGroupColumns",
    async () => {
        const response = await systemGroupsService.getGroupColumns();
        return response;
    }
);

export const fetchGroupDetailed = createAsyncThunk(
    "admin/fetchGroupDetailed",
    async (groupId) => {
        const group = await systemGroupsService.getGroupDetailed(groupId);
        const lock = await lockManager.lockObject(groupId);

        return { group, lock };
    }
);

export const createGroup = createAsyncThunk(
    "admin/saveGroup",
    async (group, { rejectWithValue }) => {
        let response

        try {
            response = await systemGroupsService.createGroup(group);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return response;
    }
);

export const editGroup= createAsyncThunk(
    "admin/editGroup",
    async (group, { rejectWithValue }) => {
        let response

        try {
            response = await systemGroupsService.editGroup(group);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return response;
    }
);

export const deleteGroup = createAsyncThunk(
    "admin/deleteGroup",
    async (groupId, { rejectWithValue }) => {
        try {
            await systemGroupsService.deleteGroup(groupId);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return groupId
    }
);

export const clearCurrentGroup = createAsyncThunk(
    "admin/clearCurrentGroup",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        const currentGroup = state.admin.systemGroups.currentGroup;
        const currentGroupEditable = state.admin.systemGroups.currentGroupEditable;
        const currentGroupDeleted = state.admin.systemGroups.currentGroupDeleted;

        if (!currentGroup) return thunkAPI.rejectWithValue(["Current group is not initialized"]);

        if (currentGroupDeleted || !currentGroupEditable || !currentGroup.id) return;

        const lockResponse = await lockManager.unlockObject(currentGroup.id);

        if (!lockResponse.result) {
            return thunkAPI.rejectWithValue(lockResponse.messages);
        }
    }
);

export const checkCanCreateFirstGroup = createAsyncThunk(
    "admin/checkCanCreateFirstGroup",
    async (_, { rejectWithValue }) => {
        let response;

        try {
            response = await systemsService.getSystems({
                limit: 1,
                page: 1
            });
        } catch (messages){
            return rejectWithValue(messages)
        }

        return response.total > 0;
    }
);

const groupsSlice = createSlice({
    name: "groups",
    initialState: initialState,
    reducers: {
        setSubmit(state, action){
            state.submit = action.payload;
        },

        setBusy(state, action){
            state.busy = action.payload;
        },

        setError(state, action){
            state.error = action.payload;
        },

        setInitialCurrentGroup(state) {
            state.currentGroup = {
                id: "",
                descripption: "",
                systems: []
            };

            state.error = null;
            state.submit = false;
            state.currentGroupEditable = true;
            state.currentGroupDeleted = false;
        },

        setSelectedIds(state, action) {
            state.selectedIds = action.payload;
        },

        setSearchString(state, action) {
            if (state.searchString !== action.payload) {
                state.pageNumber = 1;
                state.searchString = action.payload;
            }
        },

        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },

        setLimitNumber(state, action) {
            state.pageNumber = 1;
            state.limitNumber = action.payload;
        },

        setSortData(state, action) {
            state.pageNumber = 1;
            state.sortData = action.payload;
        },

        setFilterData(state, action) {
            state.pageNumber = 1;
            state.filterData = action.payload;
        },
    },
    extraReducers: {
        //get all groups
        [fetchGroups.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchGroups.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },

        [fetchGroups.fulfilled]: (state, action) => {
            state.busy = false;
            state.groups = action.payload.groups;
            state.total = action.payload.total;
            state.error = null;
            state.validationErrors = null;
        },

        // fetch group detailed
        [fetchGroupDetailed.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.currentGroupDeleted = false;
            state.submit = false;
        },

        [fetchGroupDetailed.fulfilled]: (state, action) => {
            const { group, lock } = action.payload;

            state.currentGroup = group;
            state.busy = false;
            state.currentGroupEditable = lock.result;
            state.error = lock.result ? null : lock.messages;
        },

        [fetchGroupDetailed.rejected]: (state, action) => {
            state.currentGroup = null;
            state.error = action.error;
            state.busy = false;
            state.currentGroupEditable = false;
        },

        // clear current group
        [clearCurrentGroup.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [clearCurrentGroup.fulfilled]: (state) => {
            state.busy = false;
            state.currentGroup = null;
            state.currentGroupEditable = false;
            state.currentGroupDeleted = false;
            state.error = null;
            state.validationErrors = null;
            state.submit = false;
        },

        [clearCurrentGroup.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },

        // create group
        [createGroup.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
            state.submit = false;
        },

        [createGroup.rejected]: (state, action) => {
            state.busy = false;
            const error = action.payload;

            if (!error) return;

            const validationCodes = new Set(["validation"])

            if (error.code && validationCodes.has(error.code)){
                state.validationErrors = error.errors;
            }

            state.error = error
        },

        [createGroup.fulfilled]: (state, action) => {
            state.busy = false;
            state.submit = true
            state.error = null;
            state.validationErrors = null;
            state.currentGroup = action.payload;
        },

        // edit group
        [editGroup.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
            state.submit = false;
        },

        [editGroup.rejected]: (state, action) => {
            state.busy = false;
            const error = action.payload;

            if (!error) return;

            const validationCodes = new Set(["validation"])

            if (error.code && validationCodes.has(error.code)){
                state.validationErrors = error.errors;
            }

            state.error = error
        },

        [editGroup.fulfilled]: (state, action) => {
            state.busy = false;
            state.submit = true
            state.error = null;
            state.validationErrors = null;
            state.currentGroup = action.payload;
        },

        // delete group
        [deleteGroup.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
        },

        [deleteGroup.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },

        [deleteGroup.fulfilled]: (state, action) => {
            state.busy = false;
            state.error = null;
            state.validationErrors = null;
            state.groups = state.groups.filter(group => group.id !== action.payload);

            if (state.currentGroup?.id === action.payload) {
                state.submit = true;
                state.currentGroupDeleted = true;
            }
        },

        [fetchGroupColumns.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchGroupColumns.fulfilled]: (state, action) => {
            state.busy = false;
            state.columns = action.payload;
        },

        [fetchGroupColumns.rejected]: (state, action) => {
            state.busy = false;
            state.errors = action.error;
        },

        [checkCanCreateFirstGroup.rejected]: (state, action) => {
            state.error = action.error;
        },

        [checkCanCreateFirstGroup.fulfilled]: (state, action) => {
            state.canCreateFirstGroup = action.payload;
        },
    }
});

export const {
    setSubmit, setBusy, setError, setInitialCurrentGroup,
    setSelectedIds, setSearchString, setPageNumber, setLimitNumber,
    setSortData, setFilterData
} = groupsSlice.actions;

export default groupsSlice.reducer;
