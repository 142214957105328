import { nanoid } from "@reduxjs/toolkit";

import downloadFile, { getFilenameFromResponse } from "../utils/downloadFile";

import SodService from "./sod-service";

class MatrixService extends SodService {
    async getMatrixHeaders(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/sod/matrix-headers/list", config);

        const matrixHeaders = data?.rows.map((matrixHeader) => this.parseMatrixHeader(matrixHeader));
        const total = data?.total;

        return {
            matrixHeaders,
            total,
        };
    }

    async getMatrixHeadersFilters(params) {
        return this.getFilters("/sod/matrix-headers/filters", params);
    }

    async getMatrixHeaderColumns() {
        return await this.getColumns("/sod/matrix-headers/columns");
    }

    async getMatrixHeaderChangeHistory(key) {
        const data = await this.get(`/sod/matrix-headers/detailed/change-history/${key}`)
        return data && data.map(item => this.parseUserActionLog(item))
    }

    async getMatrixHeaderDetailed(matrixHeaderId) {
        const data = await this.get(`/sod/matrix-headers/detailed/${matrixHeaderId}`);

        const matrixHeaderDetailed = this.parseMatrixHeader(data);

        return matrixHeaderDetailed;
    }

    async syncMatrixWithSap(matrixHeaderId) {
        return await this.post(`/sod/matrix-headers/detailed/sync-sap/${matrixHeaderId}`);
    }

    async checkRelatedObjsLock(matrixHeaderId) {
        return await this.get(`/sod/matrix-headers/detailed/check-related-objs-lock/${matrixHeaderId}`);
    }

    async createMatrixHeader(matrixHeader) {
        const data = await this.post(
            `/sod/matrix-headers/detailed/${matrixHeader.id}`,
            this.parseMatrixHeaderToServer(matrixHeader)
        );

        return this.parseMatrixHeader(data);
    }

    async editMatrixHeader(matrixHeader) {
        const data = await this.put(
            `/sod/matrix-headers/detailed/${matrixHeader.id}`,
            this.parseMatrixHeaderToServer(matrixHeader)
        );

        return this.parseMatrixHeader(data);
    }

    async deleteMatrixHeader(matrixHeaderId) {
        return this.delete(`/sod/matrix-headers/detailed/${matrixHeaderId}`);
    }

    async downloadMatrix(matrixHeaderId){
        return await this.downloadFileReq(`/sod/matrix-downloading/${matrixHeaderId}`)
    }

    async downloadFileReq(endpoint) {
        let response;
        const currentInstance = this.getCurrentInstance(true);

        try {
            response = await currentInstance.get(endpoint, {
                responseType: "blob"
            });
        } catch (error) {
            this.handleError(error);
        }

        const data = response.data || null;
        const filename = getFilenameFromResponse(response);

        downloadFile(data, filename);
    }

    parseMatrixHeader(matrixHeader) {
        const risks = matrixHeader.risks ? (
            matrixHeader.risks.map(risk => ({
                id: nanoid(),
                riskId: risk.risk_id,
                description: risk.description,
                active: risk.active
            }))
        ) : [];

        const criticalRoles = matrixHeader.critical_roles ? (
            matrixHeader.critical_roles.map(role => ({
                id: nanoid(),
                role: role.crit_role_name,
                system: role.system_id,
                description: role.description,
                active: role.active
            }))
        ) : [];

        const criticalProfiles = matrixHeader.critical_profiles ? (
            matrixHeader.critical_profiles.map(profile => ({
                id: nanoid(),
                profile: profile.crit_profile_name,
                system: profile.system_id,
                description: profile.description,
                active: profile.active
            }))
        ) : [];

        return {
            id: matrixHeader.matrix_id,
            description: matrixHeader.description,
            updatedTime: matrixHeader.upd_time,
            updatedUser: matrixHeader.upd_user,
            risks,
            criticalRoles,
            criticalProfiles,
        };
    }

    parseMatrixHeaderToServer(matrixHeader) {
        return {
            matrix_id: matrixHeader.id,
            description: matrixHeader.description,

            risks: matrixHeader.risks.map(risk => ({
                risk_id: risk.riskId,
                active: risk.active,
                change_status: risk.changeStatus,
            })),

            critical_roles: matrixHeader.criticalRoles.map(role => ({
                system_id: role.system,
                crit_role_name: role.role,
                active: role.active,
                change_status: role.changeStatus
            })),

            critical_profiles: matrixHeader.criticalProfiles.map(profile => ({
                system_id: profile.system,
                crit_profile_name: profile.profile,
                active: profile.active,
                change_status: profile.changeStatus
            })),
        };
    }
}

const matrixService = new MatrixService();

export { matrixService };
export default MatrixService;