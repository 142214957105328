import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { createMatrixHeader, deleteMatrixHeader, setSubmit, clearCurrentMatrixHeader } from '../../../../reducers/matrixHeadersSlice';
import { editMatrixHeader, fetchMatrixHeaderDetailed, setInitialCurrentMatrixHeader } from '../../../../reducers/matrixHeadersSlice';
import { syncMatrixWithSap, downloadMatrix } from '../../../../reducers/matrixHeadersSlice';

import { getEntitiesDeltaForServer } from "../../../../utils/formViews";

import { ProgressDialog } from '../../../common/dialogs';
import { ADD_MODE, EDIT_MODE, EntityFormPageContent, getCurrentMode } from '../../../common/entity-form';

import DeleteMatrixHeaderDialog from '../delete-matrix-header-dialog';
import MatrixHeadersFormHeader from './matrix-headers-form-header.jsx';
import MatrixHeadersFormContent from './matrix-headers-form-content.jsx';
import MatrixHeadersFormViews from './matrix-headers-form-views.jsx';

import { ErrorsDialog, UserLogDialog } from '../../../common/dialogs';
import { setError, setValidationErrors } from '../../../../reducers/matrixHeadersSlice';
import useLogs from '../../../common/hooks/useLogs';
import { READ_MODE } from '../../../common/entity-form';
import checkIfRowHasMatchWithKey from '../../../../utils/checkRowHasMatchWithKey';

import "./style.less";

const pasteErrorsToRows = ({ form, setForm, validationErrors, setValidationErrors, dispatch }) => {
    const formCopy = JSON.parse(JSON.stringify(form))

    let tab = null, errorIndex = null

    validationErrors.forEach(error => {
        if (!error.additional_info?.key) return;

        if (error.field === "risks") {
            const fieldMapping = { risk_id: "riskId" }

            formCopy.risks.forEach((action, index) => {

                const rowMatched = checkIfRowHasMatchWithKey(
                    action, error.additional_info.key, fieldMapping
                )

                if (rowMatched) {
                    action[`error_${fieldMapping[error.additional_info.field]}`] = error

                    if (tab === null) tab = "risks"
                    if (errorIndex === null) errorIndex = index
                }
            })
        }

        if (error.field === "critical_roles") {
            const fieldMapping = {
                system_id: "system",
                crit_role_name: "role",
            }

            formCopy.criticalRoles.forEach((action, index) => {

                const rowMatched = checkIfRowHasMatchWithKey(
                    action, error.additional_info.key, fieldMapping
                )

                if (rowMatched) {
                    action[`error_${fieldMapping[error.additional_info.field]}`] = error

                    if (tab === null) tab = "critical-roles"
                    if (errorIndex === null) errorIndex = index
                }
            })
        }


        if (error.field === "critical_profiles") {
            const fieldMapping = {
                system_id: "system",
                crit_profile_name: "profile",
            }

            formCopy.criticalProfiles.forEach((action, index) => {

                const rowMatched = checkIfRowHasMatchWithKey(
                    action, error.additional_info.key, fieldMapping
                )

                if (rowMatched) {
                    action[`error_${fieldMapping[error.additional_info.field]}`] = error

                    if (tab === null) tab = "critical-profiles"
                    if (errorIndex === null) errorIndex = index
                }
            })
        }

    });

    setForm(formCopy)

    dispatch(setValidationErrors(null))

    return {errorTab: tab, errorIndex}
}

const MatrixHeadersFormPage = () => {
    const { matrixHeaderId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const busy = useSelector(state => state.matrixHeaders.busy);
    const busyType = useSelector(state => state.matrixHeaders.busyType);
    const error = useSelector(state => state.matrixHeaders.error);
    const submit = useSelector(state => state.matrixHeaders.submit);
    const matrixHeader = useSelector(state => state.matrixHeaders.currentMatrixHeader);
    const matrixHeaderEditable = useSelector(state => state.matrixHeaders.currentMatrixHeaderEditable);
    const matrixHeaderDeleted = useSelector(state => state.matrixHeaders.currentMatrixHeaderDeleted);
    const validationErrors = useSelector(state => state.matrixHeaders.validationErrors);

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [form, setForm] = useState(null);

    const mode = getCurrentMode(!matrixHeaderId, matrixHeaderEditable);

    const { logs, getLogs, openLogs, setOpenLogs } = useLogs("matrix", matrixHeaderId)

    const [formErrors, setFormErrors] = useState(null)
    const [errorScroll, setErrorScroll] = useState()

    useEffect(() => {
        if (!validationErrors) return;

        const errorScrollData = pasteErrorsToRows({
            form, setForm, validationErrors, setValidationErrors, dispatch
        });

        const formErrosTmp = validationErrors.reduce((accumulator, error) => {
            accumulator[error.field] = error;

            return accumulator
        }, {});

        setFormErrors(formErrosTmp)
        setErrorScroll(errorScrollData)

    }, [validationErrors, form, dispatch])

    useEffect(() => {
        if (matrixHeaderId) {
            dispatch(fetchMatrixHeaderDetailed(matrixHeaderId));
        } else {
            dispatch(setInitialCurrentMatrixHeader());
        }

        return (() => {
            dispatch(clearCurrentMatrixHeader());
        });
    }, [matrixHeaderId, dispatch]);

    useEffect(() => {
        if (!matrixHeader) {
            return;
        }

        const criticalRoles = matrixHeader?.criticalRoles || []

        const criticalProfiles = matrixHeader?.criticalProfiles || []

        setForm({
            ...matrixHeader,
            criticalRoles,
            criticalProfiles,
        });
    }, [matrixHeader]);

    useEffect(() => {
        if (!submit) return;

        dispatch(setSubmit(false));

        if (mode === ADD_MODE || matrixHeaderDeleted) {
            handleBackClick();
        }
    }, [submit, dispatch, handleBackClick, matrixHeaderDeleted, mode]);

    const handleSaveClick = () => {
        const risksDelta = getEntitiesDeltaForServer(form.risks);
        const criticalRolesDelta = getEntitiesDeltaForServer(form.criticalRoles);
        const criticalProfilesDelta = getEntitiesDeltaForServer(form.criticalProfiles);

        const matrixHeaderToSave = {
            ...form,
            id: form.id?.trim(),
            risks: risksDelta,
            criticalRoles: criticalRolesDelta,
            criticalProfiles: criticalProfilesDelta,
        };

        if (matrixHeaderToSave.id !== form.id) {
            setForm({
                ...form,
                id: matrixHeaderToSave.id
            });
        }

        if (mode === EDIT_MODE) {
            dispatch(editMatrixHeader(matrixHeaderToSave));
        } else {
            dispatch(createMatrixHeader(matrixHeaderToSave));
        }
    };

    const backPath = useMemo(() => (mode === ADD_MODE
        ? ".."
        : "../.."
    ), [mode]);

    const handleBackClick = useCallback(() => {
        navigate(backPath, { relative: "path" });
    }, [navigate, backPath]);

    const handleEditClick = async () => {
        dispatch(fetchMatrixHeaderDetailed(matrixHeaderId));
    };

    const handleDeleteClick = () => {
        setShowDeleteDialog(true);
    };

    const handleCancelDeleteClick = () => {
        setShowDeleteDialog(false);
    };

    const handleConfirmDeleteClick = () => {
        dispatch(deleteMatrixHeader(matrixHeaderId));
    };

    const handleSyncClick = async () => {
        dispatch(syncMatrixWithSap(matrixHeaderId));
    };

    const handleDownloadMatrixClick = async () => {
        dispatch(downloadMatrix(matrixHeaderId))
    }

    if (!matrixHeader && error) return <Navigate to={backPath} relative="path" />;

    const formDisabled = mode === READ_MODE;

    return (
        <>
            {form && (
                <EntityFormPageContent>
                    <MatrixHeadersFormHeader
                        mode={mode}
                        matrixHeaderId={matrixHeaderId}
                        onSaveClick={handleSaveClick}
                        onBackClick={handleBackClick}
                        onEditClick={handleEditClick}
                        onDeleteClick={handleDeleteClick}
                        onShowLogsClick={getLogs}
                        onSyncMatrixClick={handleSyncClick}
                        onDownloadMatrixClick={handleDownloadMatrixClick}
                    />

                    <MatrixHeadersFormContent
                        form={form}
                        setForm={setForm}
                        mode={mode}
                        validationErrors={formErrors}
                    />

                    <MatrixHeadersFormViews
                        form={form}
                        setForm={setForm}
                        validationErrors={formErrors}
                        formDisabled={formDisabled}
                        errorScroll={errorScroll}
                        setErrorScroll={setErrorScroll}
                    />
                </EntityFormPageContent>
            )}

            <ProgressDialog
                open={busy}
                busyType={busyType}
            />

            <ErrorsDialog
                error={error}
                open={error?.type === "error" || error?.type === "warning"}
                onClose={() => { dispatch(setError(null)) }}
                disableRestoreFocus
            />

            {showDeleteDialog && (
                <DeleteMatrixHeaderDialog
                    open={showDeleteDialog}
                    matrixHeaderId={matrixHeaderId}
                    onCancelClick={handleCancelDeleteClick}
                    onDeleteClick={handleConfirmDeleteClick}
                />
            )}

            <UserLogDialog
                open={openLogs}
                logs={logs}
                onClose={() => setOpenLogs(false)}
                disableRestoreFocus
            />
        </>
    );
};

export default MatrixHeadersFormPage;
