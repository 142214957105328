import React from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import { TextInput } from '../../../common/form-controls';
import { ADD_MODE, EntityFormSubtitle, READ_MODE } from "../../../common/entity-form";

import "./style.less";

const IdInput = ({ id, setId, disabled, error }) => {
    const intl = useIntl();

    return(
        <TextInput
            label={intl.formatMessage({ id: "matrix-headers-form-content.id-input-label" })}
            value={id}
            onChange={(event) => setId(event.target.value)}
            disabled={disabled}
            error={error?.value === id}
            errorMessage={error?.message}
        />
    );
};

const DescriptionInput = ({ description, setDescription, disabled, error }) => {
    const intl = useIntl();

    return(
        <TextInput
            label={intl.formatMessage({ id: "matrix-headers-form-content.description-input-label" })}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            disabled={disabled}
            error={error?.value === description}
            errorMessage={error?.message}
        />
    );
};

const MatrixHeadersFormContent = ({ form, setForm, mode, validationErrors }) => {
    const setId = (value) => setForm({ ...form, id: value });
    const setDescription = (value) => setForm({ ...form, description: value });

    const formDisabled = mode === READ_MODE;
    const idDisabled = mode !== ADD_MODE;
    
    return (
        <Box className="matrix-headers-form-content-inputs">
            <EntityFormSubtitle className="matrix-headers-form-content-subtitle">
                <FormattedMessage id="matrix-headers-form-content.title" />
            </EntityFormSubtitle>

            <IdInput
                id={form.id}
                setId={setId}
                disabled={idDisabled}
                error={validationErrors && validationErrors["matrix_id"]}
            />

            <DescriptionInput
                description={form.description}
                setDescription={setDescription}
                disabled={formDisabled}
                error={validationErrors && validationErrors["description"]}
            />
        </Box>
    );
};

MatrixHeadersFormContent.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func,
    mode: PropTypes.string,
    validationErrors: PropTypes.object,
}

IdInput.propTypes = {
    id: PropTypes.string,
    setId: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object,
}

DescriptionInput.propTypes = {
    description: PropTypes.string,
    setDescription: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object,
}

export default MatrixHeadersFormContent;
