import React from "react";
import PropTypes from "prop-types";

import { EntityFormHeader } from "../../../../common/entity-form";

import SystemFormHeaderTitle from "./system-form-header-title.jsx";
import SystemFormActions from "./system-form-actions.jsx";

const SystemFormPageHeader = ({
    systemId,
    mode,
    onBackClick,
    onDeleteClick,
    onSaveClick,
    onEditClick,
    onChangePasswordClick,
    onShowLogsClick
}) => {    
    return (
        <EntityFormHeader>
            <SystemFormHeaderTitle
                systemId={systemId}
                mode={mode}
                onBackClick={onBackClick}
            />

            <SystemFormActions
                mode={mode}
                onBackClick={onBackClick}
                onDeleteClick={onDeleteClick}
                onSaveClick={onSaveClick}
                onEditClick={onEditClick}
                onShowLogsClick={onShowLogsClick}
                onChangePasswordClick={onChangePasswordClick}
            />
        </EntityFormHeader>
    );
};

SystemFormPageHeader.propTypes = {
    systemId: PropTypes.string,
    mode: PropTypes.string,
    onBackClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onSaveClick: PropTypes.func,
    onEditClick: PropTypes.func,
    onChangePasswordClick: PropTypes.func,
    onShowLogsClick: PropTypes.func,
};

export default SystemFormPageHeader;