import React from "react";
import PropTypes from "prop-types";

import { EntityFormActions } from "../../../../common/entity-form";

const BusynessProcessFormActions = ({
    mode,
    onDeleteClick,
    onSaveClick,
    onEditClick,
    onShowLogsClick,
}) => {
    return (
        <EntityFormActions
            mode={mode}
            onDeleteClick={onDeleteClick}
            onSaveClick={onSaveClick}
            onEditClick={onEditClick}
            onShowLogsClick={onShowLogsClick}
        />
    );
};

BusynessProcessFormActions.propTypes = {
    mode: PropTypes.string,
    onDeleteClick: PropTypes.func,
    onSaveClick: PropTypes.func,
    onEditClick: PropTypes.func,
    onShowLogsClick: PropTypes.func,
    onChangePasswordClick: PropTypes.func
};

export default BusynessProcessFormActions;
