import React from 'react';
import { createSvgIcon } from '@mui/material';

const SortDescArrowIcon = createSvgIcon(
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 11 11"
        fill="none"
    >
        <path
            d="M5.5 11L8.38675 6H2.61325L5.5 11ZM5 0V6.5H6V0H5Z"
            fill="currentColor"
        />
    </svg>,
    "SortDescArrow"
);

export default SortDescArrowIcon;
