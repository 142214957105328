import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { REPORT_MODELING_VIEWS } from "../../../utils/reports-modeling-utils";

import { ButtonsSwitch } from "../../common/buttons";

import "./styles.less";

const views = Object.values(REPORT_MODELING_VIEWS);

const ReportModelingViewsSwitch = ({ value, onChange }) => {
    const intl = useIntl();

    const buttonsList = views.map(view => ({
        id: view,
        title: intl.formatMessage({ id: `report-modeling-views-switch.${view}` })
    }));

    return (
        <ButtonsSwitch
            className="report-modeling-views-switch"
            buttonsList={buttonsList}
            value={value}
            onChange={onChange}
        />
    );
};

ReportModelingViewsSwitch.propTypes = {
    value: PropTypes.oneOf(views),
    onChange: PropTypes.func
};

export default ReportModelingViewsSwitch;