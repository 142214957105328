import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";

import { adminService } from "../../../services/admin-service";
import { selectUserScreenButton } from "../../../reducers/authSlice";
import { USER_SCREEN_BUTTONS } from "../../../utils/auth";

import { Button } from "../../common/buttons";

import "./styles.less";

const AdminActionButton = ({
    children,
    onClick,
    disabled
}) => {
    return (
        <Button
            className="admin-action-button"
            variant="outlined"
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </Button>
    )
};

const AdminPage = () => {
    const genRulesButton = useSelector((state) => selectUserScreenButton(state, USER_SCREEN_BUTTONS.GEN_RULES));
    const clearMatricesButton = useSelector((state) => selectUserScreenButton(state, USER_SCREEN_BUTTONS.CLEAR_MATRICES));

    const [busy, setBusy] = useState(false);

    const handleGenRulesClick = async () => {
        setBusy(true);

        try {
            await adminService.generateRules();
        } catch (error) {
            console.error(error);
        }

        setBusy(false);
    };

    const handleDeleteMatrixClick = async () => {
        setBusy(true);

        try {
            await adminService.clearMatrices();
        } catch (error) {
            console.error(error);
        }

        setBusy(false);
    };

    return (
        <Box>
            {genRulesButton && (
                <AdminActionButton
                    onClick={handleGenRulesClick}
                    disabled={busy}
                >
                    {genRulesButton.title}
                </AdminActionButton>
            )}

            {clearMatricesButton && (
                <AdminActionButton
                    onClick={handleDeleteMatrixClick}
                    disabled={busy}
                >
                    {clearMatricesButton.title}
                </AdminActionButton>
            )}

            {busy && (
                <CircularProgress size="34px" />
            )}
        </Box>
    );
};

AdminActionButton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
    disabled: PropTypes.bool
};

export default AdminPage;
