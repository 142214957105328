import { Box, styled } from "@mui/material";

const PageContentBox = styled(Box)({
    backgroundColor: "var(--bg-3)",
    borderRadius: "8px",
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "12px 14px",
});

export default PageContentBox;
