import React from "react";
import { SvgIcon } from "@mui/material";

const ExpandDownIcon = (props) => (
    <SvgIcon
        viewBox="0 0 13 13"
        {...props}
    >
        <svg
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.1521 7.16434C6.43265 7.16434 6.66531 7.0617 6.88428 6.84273L12.0369 1.57383C12.2148 1.39592 12.2969 1.18379 12.2969 0.930611C12.2969 0.417407 11.8932 -1.76472e-08 11.3799 -4.00801e-08C11.1268 -5.1147e-08 10.8941 0.109484 10.7094 0.294237L6.15895 4.97466L1.59484 0.294237C1.41009 0.109483 1.18428 -4.85747e-07 0.924257 -4.97113e-07C0.411053 -5.19546e-07 0.000488263 0.417406 0.000488241 0.930611C0.00048823 1.18379 0.0894441 1.39592 0.267355 1.57383L5.41993 6.84273C5.6389 7.06854 5.87155 7.16434 6.1521 7.16434Z"
                fill="currentColor"
            />
            <path
                d="M6.1521 12.3284C6.43265 12.3284 6.66531 12.2258 6.88428 12.0068L12.0369 6.73789C12.2148 6.55998 12.2969 6.34785 12.2969 6.09467C12.2969 5.58147 11.8932 5.16406 11.3799 5.16406C11.1268 5.16406 10.8941 5.27355 10.7094 5.4583L6.15895 10.1387L1.59484 5.4583C1.41009 5.27355 1.18428 5.16406 0.924257 5.16406C0.411053 5.16406 0.000488263 5.58147 0.000488241 6.09467C0.00048823 6.34785 0.0894441 6.55998 0.267355 6.73789L5.41993 12.0068C5.6389 12.2326 5.87155 12.3284 6.1521 12.3284Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

export default ExpandDownIcon;
