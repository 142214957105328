import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { Button } from "../buttons";
import { TextInput } from "../form-controls";

import { CommonDialog, CommonDialogTitle, CommonDialogContent, CommonDialogActions } from "./common-dialog.jsx";

const PasswordFormControl = ({ value, setValue, label, errorMessage }) => {
    return(
        <TextInput
            type="password"
            label={label}
            value={value}
            onChange={(event) => setValue(event.target.value)}
            error={!!errorMessage}
            errorMessage={errorMessage}
        />
    );
};

const ChangePasswordDialog = ({ onCancel, open, onConfirm }) => {
    const intl = useIntl();

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [newPasswordError, setNewPasswordError] = useState(null);
    const [confirmPasswordError, setConfirmPasswordError] = useState(null);

    const title = intl.formatMessage({ id: "user-form-page.change-password-dialog.title" });
    const newPasswordLabel = intl.formatMessage({ id: "user-form-page.change-password-dialog.new-password-label" });
    const confirmPasswordLabel = intl.formatMessage({ id: "user-form-page.change-password-dialog.confirm-password-label" });
    const cancelTitle = intl.formatMessage({ id: "common.btn-cancel" });
    const changeTitle = intl.formatMessage({ id: "common.btn-change" });

    const handleConfirmClick = () => {
        let currentNewPasswordError = null;
        let currentConfirmPasswordError = null;

        if (!newPassword) {
            currentNewPasswordError = intl.formatMessage({ id: "user-form-page.change-password-dialog.new-password-error" });
        }

        if (newPassword !== confirmPassword) {
            currentConfirmPasswordError = intl.formatMessage({ id: "user-form-page.change-password-dialog.confirm-password-error" });
        }

        setNewPasswordError(currentNewPasswordError);
        setConfirmPasswordError(currentConfirmPasswordError);

        if (!currentNewPasswordError && !currentConfirmPasswordError) {
            onConfirm(newPassword);
        }
    };

    return (
        <CommonDialog
            open={open}
            className="change-password-dialog"
        >
            <CommonDialogTitle
                title={title}
                onClose={onCancel}
            />

            <CommonDialogContent className="change-password-dialog-content">
                <PasswordFormControl
                    value={newPassword}
                    setValue={setNewPassword}
                    label={newPasswordLabel}
                    errorMessage={newPasswordError}
                />

                <PasswordFormControl
                    value={confirmPassword}
                    setValue={setConfirmPassword}
                    label={confirmPasswordLabel}
                    errorMessage={confirmPasswordError}
                />
            </CommonDialogContent>

            <CommonDialogActions>
                <Button
                    variant="outlined"
                    onClick={onCancel}
                    sx={{
                        color: 'var(--font-4)',
                        borderColor: 'var(--font-4)',
                    }}
                >
                    {cancelTitle}
                </Button>

                <Button
                    variant="contained"
                    onClick={handleConfirmClick}
                    sx={{
                        color: 'var(--bg-2)',
                        backgroundColor: 'var(--font-2)',
                        '&:hover': {
                            backgroundColor: 'var(--font-2)',
                        }
                    }}
                >
                    {changeTitle}
                </Button>
            </CommonDialogActions>
        </CommonDialog>
    );
};

PasswordFormControl.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.func,
    label: PropTypes.string,
    errorMessage: PropTypes.string,
};

ChangePasswordDialog.propTypes = {
    onCancel: PropTypes.func,
    open: PropTypes.bool,
    onConfirm: PropTypes.func,
};

export default ChangePasswordDialog