import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from 'prop-types';

import { CommonDialog, CommonDialogActions, CommonDialogContent, CommonDialogTitle } from "../../common/dialogs";

import "./style.less";
import { Box, Typography } from "@mui/material";
import { SelectInput, TimePicker } from "../../common/form-controls";
import { MenuItem } from "../../common/menu";
import { Button } from "../../common/buttons";
import { PlusIcon, MinusMuiIcon } from "../../common/icons";
import { useDispatch } from "react-redux";
import { fetchSummaryReportScheduleItems } from "../../../reducers/reports/userBusynessLevelSlice";
import LockManager from "../../../utils/lockManager"


const lockManager = new LockManager("summary-report-schedule")

const PERIODS = {
    EVERY_DAY: "every-day",
    IN_2_DAYS: "in-2-days",
    IN_3_DAYS: "in-3-days",
    IN_1_WEEK: "in-1-week"
}

const PeriodField = ({ value, setValue, disabled }) => {
    const intl = useIntl();

    const periodicValues = useMemo(() => [{
        key: PERIODS.EVERY_DAY,
        text: intl.formatMessage({ id: "summary-report.dialog.schedule.time.every-day.text" })
    }, {
        key: PERIODS.IN_2_DAYS,
        text: intl.formatMessage({ id: "summary-report.dialog.schedule.time.in-2-days.text" })
    }, {
        key: PERIODS.IN_3_DAYS,
        text: intl.formatMessage({ id: "summary-report.dialog.schedule.time.in-3-days.text" })
    }, {
        key: PERIODS.IN_1_WEEK,
        text: intl.formatMessage({ id: "summary-report.dialog.schedule.time.in-1-week.text" })
    }], [intl])

    return (
        <SelectInput
            label=""
            value={value}
            onChange={(event) => setValue(event.target.value)}
            disabled={disabled}
            className=""
        >
            {periodicValues.map(item => (
                <MenuItem
                    value={item.key}
                    key={item.key}
                    sx={{
                        minHeight: "initial",
                        height: "36px"
                    }}
                >
                    {item.text}
                </MenuItem>
            ))}
        </SelectInput>
    );
};


const ScheduleTimePicker = ({ value, setValue, onRemoveItem, disabled }) => {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px",
            gap: "15px"
        }}>
            {/* <IconButton onClick={onRemoveItem}>
                 <MinusIcon color="#B7B7B7" width="34px" height="34px" sx={{
                    borderRadius: "50%"
                }} /> *

                <MinusMuiIcon sx={{
                    width: "18px",
                    height: "18px"
                }}
                
                onClick={onRemoveItem}/>
            </IconButton> */}

            <MinusMuiIcon sx={{
                width: "18px",
                height: "18px",
                cursor: "pointer",
                color: "#363636",
            }}
                onClick={onRemoveItem}
            />

            <Typography className="summary-report-header-box-time-picker-descr">
                <FormattedMessage id="summary-report.dialog.schedule.time-picker.descr" />
            </Typography>

            <TimePicker
                className="summary-report-header-box-time-picker"
                fullWidth={false}
                value={value}
                disabled={disabled}
                onChange={setValue}
            />
        </Box>
    )
}


const PeriodSchedulingItem = ({ disabled, period, setPeriod, timeItems, setTimeItems }) => {

    const onAddTimeItem = () => {
        setTimeItems(timeItems => {
            const timeItemsCopy = [...timeItems]

            if (timeItemsCopy.length >= 1) {
                return timeItemsCopy
            }

            const today = new Date()
            today.setHours(12)
            today.setMinutes(0)

            timeItemsCopy.push(today)

            return timeItemsCopy
        })
    }

    const addItemIsVisible = timeItems.length < 1

    return (
        <Box className="busyness-dialog-schedule-period-item">

            <Box className="busyness-dialog-schedule-period-wrapper">
                <PeriodField
                    value={period}
                    setValue={setPeriod}
                    disabled={disabled} />

                {
                    timeItems.map((timeItem, index) => {
                        const onChangeTime = (value) => {
                            setTimeItems(timeItems => {
                                const timeItemsCopy = [...timeItems]

                                timeItemsCopy.splice(index, 1, value)

                                return timeItemsCopy
                            })
                        }

                        const onRemoveItem = () => {
                            if (disabled) {
                                return
                            }

                            setTimeItems(timeItems => {
                                const timeItemsCopy = [...timeItems]

                                timeItemsCopy.splice(index, 1)

                                return timeItemsCopy
                            })
                        }

                        return (
                            <ScheduleTimePicker
                                disabled={disabled}
                                key={index}
                                value={timeItem}
                                setValue={onChangeTime}
                                onRemoveItem={onRemoveItem}
                            />
                        )
                    })
                }

                {addItemIsVisible && <Button
                    onClick={onAddTimeItem}
                    disabled={disabled}
                    className="compare-button"
                    sx={{
                        marginTop: "20px",
                        //marginBottom: "15px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "start",
                        gap: "10px"
                    }}
                    startIcon={
                        <PlusIcon sx={{
                            borderRadius: "50%",
                            width: "18px",
                            height: "18px",
                        }} />
                    }
                >


                    <Typography className="summary-report-header-box-time-picker-descr" sx={{
                        marginLeft: "10px"
                    }}>
                        <FormattedMessage id="summary-report.dialog.schedule.add-time-item.title" />
                    </Typography>
                </Button>

                }

            </Box>

        </Box>
    )
}

const BusynessReportScheduleDialog = ({ onClose, onApply, setError, open, ...props }) => {
    const intl = useIntl();

    const innerTitle = intl.formatMessage({ id: "summary-report.dialog.schedule.title" })

    const [period, setPeriod] = useState(PERIODS.EVERY_DAY)
    const [timeItems, setTimeItems] = useState([new Date()])

    const cancelTitle = intl.formatMessage({ id: "common.btn-cancel" });
    const changeTitle = intl.formatMessage({ id: "common.btn-apply" });

    const [isEditable, setIsEditalbe] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        return async () => {
            if (isEditable) {
                lockManager.unlockObject()
            }
        }
    }, [isEditable])

    useEffect(() => {
        (async () => {
            const items = await dispatch(fetchSummaryReportScheduleItems()).unwrap()
            const lock = await lockManager.lockObject()

            setIsEditalbe(lock.result)

            if (!lock.result) {
                setError(lock.messages)
            }

            let periodWasUpdated = false
            const newtimeItems = []

            items.forEach(item => {
                if (!periodWasUpdated) {
                    setPeriod(item.period)
                    periodWasUpdated = true
                }

                const today = new Date()

                today.setMinutes(item.minutes)
                today.setHours(item.hours)

                newtimeItems.push(today)
            })

            setTimeItems(newtimeItems)
        })()
    }, [dispatch, setError])

    return (
        <CommonDialog
            className="integration-schedule-dialog"
            open={!!open}
            {...props}
        >
            <CommonDialogTitle
                title={innerTitle}
                onClose={onClose}
            />

            <CommonDialogContent>

                <PeriodSchedulingItem
                    disabled={!isEditable}
                    period={period}
                    setPeriod={setPeriod}
                    timeItems={timeItems}
                    setTimeItems={setTimeItems} />

            </CommonDialogContent>

            <CommonDialogActions>
                <Button
                    variant="outlined"
                    onClick={onClose}
                    sx={{
                        color: 'var(--font-4)',
                        borderColor: 'var(--font-4)',
                    }}
                >
                    {cancelTitle}
                </Button>

                <Button
                    variant="contained"
                    onClick={() => onApply(period, timeItems)}
                    sx={{
                        color: 'var(--bg-2)',
                        backgroundColor: 'var(--font-2)',
                        '&:hover': {
                            backgroundColor: 'var(--font-2)',
                        }
                    }}
                    disabled={!isEditable}
                >
                    {changeTitle}
                </Button>
            </CommonDialogActions>
        </CommonDialog>
    )
};


BusynessReportScheduleDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onApply: PropTypes.func,
    setError: PropTypes.func
}

PeriodSchedulingItem.propTypes = {
    disabled: PropTypes.bool,
    period: PropTypes.string,
    setPeriod: PropTypes.func,
    timeItems: PropTypes.arrayOf(PropTypes.object),
    setTimeItems: PropTypes.func
}

ScheduleTimePicker.propTypes = {
    value: PropTypes.object,
    setValue: PropTypes.func,
    onRemoveItem: PropTypes.func,
    disabled: PropTypes.bool
}

PeriodField.propTypes = {
    value: PropTypes.object,
    setValue: PropTypes.func,
    disabled: PropTypes.bool
}

export default BusynessReportScheduleDialog;
