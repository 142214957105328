import React from 'react'
import { FormattedMessage } from 'react-intl';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import { Button } from '../../../common/buttons';
import { EditIcon, PlusIcon } from '../../../common/icons';
import { EDIT_MODE, READ_MODE } from '../../../common/entity-form';

const EditButton = ({ onClick }) => (
    <Button
        variant='outlined'
        startIcon={<EditIcon />}
        onClick={onClick}
    >
        <FormattedMessage id="common.btn-edit" />
    </Button>
);

const SaveButton = ({ onClick }) => (
    <Button
        variant="outlined"
        onClick={onClick}
    >
        <FormattedMessage id="common.btn-save" />
    </Button>
);

const AddRiskLevelButton = ({ onClick }) => (
    <Button
        variant="contained"
        startIcon={<PlusIcon />}
        onClick={onClick}
    >
        <FormattedMessage id="risk-levels-tab.btn-create-risk-level" />
    </Button>
);

const ResetRiskLevelsButton = ({ onClick }) => (
    <Button
        variant='outlined'
        onClick={onClick}
    >
        <FormattedMessage id="risk-levels-tab.btn-reset" />
    </Button>
);

const RiskLevelsActions = ({
    mode,
    onEditClick,
    onSaveClick,
    onAddClick,
    onResetClick
}) => {
    return (
        <Box className="risk-levels-actions">
            {mode === READ_MODE && <EditButton onClick={onEditClick} />}
            {mode === EDIT_MODE && (
                <>
                    <SaveButton onClick={onSaveClick} />
                    <ResetRiskLevelsButton onClick={onResetClick} />
                    <AddRiskLevelButton onClick={onAddClick} />
                </>
            )}
        </Box>
  );
};

EditButton.propTypes = {
    onClick: PropTypes.func,
};

SaveButton.propTypes = {
    onClick: PropTypes.func,
};

AddRiskLevelButton.propTypes = {
    onClick: PropTypes.func,
};

ResetRiskLevelsButton.propTypes = {
    onClick: PropTypes.func,
};

RiskLevelsActions.propTypes = {
    mode: PropTypes.string,
    onEditClick: PropTypes.func,
    onSaveClick: PropTypes.func,
    onAddClick: PropTypes.func,
    onResetClick: PropTypes.func
}

export default RiskLevelsActions;
