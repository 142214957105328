import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

import Button from "./button.jsx";

import "./styles.less";

const ButtonsSwitchItem = ({ active, onClick, title }) => {
    let className = "buttons-switch-item";

    if (active) {
        className += " buttons-switch-item-active";
    }

    return (
        <Button
            className={className}
            onClick={onClick}
        >
            {title}
        </Button>
    )
};

const ButtonsSwitch = ({ className, buttonsList, value, onChange }) => {
    let classNameInner = "buttons-switch";

    if (className) {
        classNameInner += ` ${className}`
    }

    return (
        <Box className={classNameInner}>
            {buttonsList.map(viewButton => (
                <ButtonsSwitchItem
                    key={viewButton.id}
                    active={viewButton.id === value}
                    title={viewButton.title}
                    onClick={() => onChange(viewButton.id)}
                />
            ))}
        </Box>
    );
};

ButtonsSwitchItem.propTypes = {
    active: PropTypes.bool,
    onClick: PropTypes.func,
    title: PropTypes.string
};

const buttonIdType = PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
]);

ButtonsSwitch.propTypes = {
    className: PropTypes.string,
    buttonsList: PropTypes.arrayOf(PropTypes.shape({
        id: buttonIdType,
        title: PropTypes.string
    })),
    value: buttonIdType,
    onChange: PropTypes.func
};

export default ButtonsSwitch;