import { useCallback, useState } from "react";

import useLocalTable from "./useLocalTable";

const useEntityFormViewTable = (data, getFilters) => {
    const {
        rows, onFilter
    } = useLocalTable(data, false);

    const [filterData, setFilterData] = useState([]);
    const [searchString, setSearchString] = useState("");

    const setFilterDataCallback = useCallback((filterData) => {
        setFilterData(filterData);
        onFilter(getFilters(searchString, filterData));
    }, [searchString, onFilter, getFilters]);

    const setSearchStringCallback = useCallback((searchString) => {
        setSearchString(searchString);
        onFilter(getFilters(searchString, filterData));
    }, [filterData, onFilter, getFilters]);

    return {
        rows,
        searchString,
        setSearchString: setSearchStringCallback,
        filterData,
        setFilterData: setFilterDataCallback
    };
};

export default useEntityFormViewTable;
