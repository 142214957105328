import { useCallback } from "react";

import { createFiltersFromFilterData } from "../../../../utils/formViews";

import { Filter, ComplexFilter } from "../../../common/hooks/useLocalTable";
import useEntityFormViewTable from "../../../common/hooks/useEntityFormViewTable";

const getFilters = (searchString, filterData) => {
    const filters = [];

    if (searchString) {
        filters.push(new ComplexFilter(
            [
                new Filter("description", Filter.CO, searchString),
                new Filter("operation", Filter.CO, searchString)
            ],
            ComplexFilter.OR
        ));
    }

    if (filterData) {
        const createdFilters = createFiltersFromFilterData(filterData);
        filters.push(...createdFilters);
    }

    return filters;
};

const useOperationsTable = (operations) => {
    const getDialogFilters = useCallback(async ({ search="" }) => {
        const lowerSearch = search.toLowerCase();

        const keys = operations
            .filter(({ operation }) => (
                operation?.toLowerCase().includes(lowerSearch)
            ))
            .map(({ operation }) => operation);

        const uniqueKeys = [...new Set(keys)];
        const rows = uniqueKeys.map((key) => ({ key, text: key }));
        return { rows, total: rows?.length };
    }, [operations]);

    const viewTable = useEntityFormViewTable(operations, getFilters);

    return {
        ...viewTable,
        getDialogFilters
    };
};

export default useOperationsTable;
