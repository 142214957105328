import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

import "./styles.less";

const TabsDots = ({ className="", tabs, value, onChange }) => {
    const getTabDotClassName = (tab) => {
        return tab.id === value
            ? "tabs-dot tabs-dot-active"
            : "tabs-dot"
    };

    return (
        <Box className={`tabs-dots-container ${className}`}>
            {tabs.map(tab => (
                <Box
                    key={tab.id}
                    className={getTabDotClassName(tab)}
                    onClick={() => onChange(tab.id)}
                />
            ))}
        </Box>
    );
};

TabsDots.propTypes = {
    className: PropTypes.string,
    tabs: PropTypes.arrayOf(PropTypes.object),
    value: PropTypes.string,
    onChange: PropTypes.func,
};

export default TabsDots;
