import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { isValid } from 'date-fns';
import { IconButton, } from '@mui/material';
import PropTypes from "prop-types"

import { FormControl, Input } from "../form-controls";
import { CalendarIcon } from '../icons';

const shortDateRegexp = /(\d{4})(\d{2})(\d{2})/;
const yearFirstDateRegexp = /(\d{4})\D(\d{1,2})\D(\d{1,2})/;
const yearLastDateRegexp = /(\d{1,2})\D(\d{1,2})\D(\d{4})/;
const datesRegexp = /\d{4}\d{2}\d{2}|(\d{4})\D(\d{1,2})\D(\d{1,2})|(\d{1,2})\D(\d{1,2})\D(\d{4})/g;

const dateFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
};

const getStringFromDate = (date, intl) => {
    if (!date) return "";

    return intl.formatDate(date, dateFormatOptions);
};

const getDateFromString = (dateString, isMonthFirst) => {
    if (!dateString) return null;

    let year, month, day;
    
    if (shortDateRegexp.test(dateString)) {
        [, year, month, day] = dateString.match(shortDateRegexp);
    } else if (yearFirstDateRegexp.test(dateString)) {
        [, year, month, day] = dateString.match(yearFirstDateRegexp);
    } else if (yearLastDateRegexp.test(dateString)) {
        [, month, day, year] = dateString.match(yearLastDateRegexp);
    } else {
        return null;
    }

    year = Number(year);
    month = Number(month);
    day = Number(day);

    if (!isMonthFirst && day <= 12) {
        [month, day] = [day, month];
    }

    month--;

    const convertedDate = new Date(year, month, day);

    if (isValid(convertedDate)) {
        return convertedDate;
    } else {
        return null;
    }
};

const DateRangeInput = ({ onCalendarClick, onChange, startDate, endDate }) => {
    const intl = useIntl();

    const placeholder = intl.formatMessage({ id: "common.date-range-input.title" });

    const formatParts = useMemo(
        () => intl.formatDateToParts(new Date(), dateFormatOptions),
        [intl]
    ) ;

    const isMonthFirst = useMemo(
        () => formatParts.findIndex(part => part.type === "month") < formatParts.findIndex(part => part.type === "day"),
        [formatParts]
    );

    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        const startDateString = getStringFromDate(startDate, intl);
        const endDateString = getStringFromDate(endDate, intl);
        const devider = (startDateString || endDateString) ? " - " : "";
        setInputValue(`${startDateString}${devider}${endDateString}`);
    }, [startDate, endDate, intl]);

    const setNewDates = () => {
        let newStartDate, newEndDate

        const dates = inputValue.match(datesRegexp);

        if (dates) {
            newStartDate = getDateFromString(dates[0], isMonthFirst);
            newEndDate = getDateFromString(dates[1], isMonthFirst);
        } else {
            newStartDate = null;
            newEndDate = null;
        }

        onChange(newStartDate, newEndDate);
    };

    const handleKeyDown = (event) => {
        if (event.key !== 'Enter') {
            return;
        }

        setNewDates();
    };

    const handleChange = (event) => setInputValue(event.target.value);

    return(
        <FormControl sx={{ fontSize: "16px" }}>
            <Input
                placeholder={placeholder}
                value={inputValue}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onBlur={setNewDates}
                endAdornment={
                    <IconButton
                        sx={{ padding: 0 }}
                        onClick={onCalendarClick}
                    >
                        <CalendarIcon />
                    </IconButton>
                }
            />
        </FormControl>
    );
};

DateRangeInput.propTypes = {
    onCalendarClick: PropTypes.func,
    onChange: PropTypes.func,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date)
}

export default DateRangeInput;
