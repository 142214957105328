import React from "react";
import { useIntl } from "react-intl";

import ErrorPage from "./error-page.jsx";

const NotFoundPage = () => {
    const intl = useIntl();

    const title = intl.formatMessage({ id: "not-found-page.title" });
    const subtitle = intl.formatMessage({ id: "not-found-page.subtitle" });

    return (
        <ErrorPage
            title={title}
            subtitle={subtitle}
        />
    );
}

export default NotFoundPage;