import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { SearchInput } from "../form-controls";
import { CloseIcon } from "../icons";

import "./style.less";

const DialogSearchTitle = ({
    title,
    onSearch,
    onClose
}) => {
    return (
        <Box className="dialog-search-title-wrapper">
            <Typography className="dialog-search-title-text">
                {title}
            </Typography>
            <SearchInput onSearchClick={onSearch} />
            <IconButton
                className="dialog-search-title-close-button"
                onClick={onClose}
            >
                <CloseIcon />
            </IconButton>
        </Box>
    );
};

DialogSearchTitle.propTypes = {
    title: PropTypes.string,
    onSearch: SearchInput.propTypes.onSearchClick,
    onClose: PropTypes.func
};

export default DialogSearchTitle;