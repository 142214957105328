import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

import { clearUploadError } from "../../../../reducers/admin/integrationSlice";

import { CommonDialog, CommonDialogContent, CommonDialogTitle } from "../../../common/dialogs";
import { CommonDialogActions } from "../../../common/dialogs/common-dialog.jsx";
import { Button } from "../../../common/buttons";
import FileUploadField from "../../../common/form-controls/file-upload-input.jsx";

import "./style.less";

const UploadMatrixDialog = ({ system, onClose, onApply, open, ...props }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const innerTitle = intl.formatMessage({ id: "integration-page.matrix-upload-dialog.title" }, { system })

    const uploadErrors = useSelector((state) => state.admin.integration.uploadErrors)

    const [functionFile, setFunctionFile] = useState();
    const [actionFile, setActionFile] = useState();
    const [permissionFile, setPermissionFile] = useState();
    const [riskFile, setRiskFile] = useState();
    const [riskDescriptionFile, setRiskDescription] = useState();
    const [matrixFile, setMatrixFile] = useState();
    const [riskToMatrixFile, setRiskToMatrix] = useState();

    const [busynessProcessesFile, setBusynessProcesses] = useState();
    const [funcToBusynessProcessesFile, setFuncToBusynessProcesses] = useState();

    const onSubmit = async () => {
        const formData = new FormData()
        formData.append("functions", functionFile)
        formData.append("actions", actionFile)
        formData.append("permissions", permissionFile)
        formData.append("risks", riskFile)
        formData.append("risk_texts", riskDescriptionFile)
        formData.append("matrix", matrixFile)
        formData.append("matrix_risks", riskToMatrixFile)
        formData.append("business_processes", busynessProcessesFile)
        formData.append("function_business_process", funcToBusynessProcessesFile)

        onApply(formData)
    }

    const cancelTitle = intl.formatMessage({ id: "common.btn-cancel" });
    const changeTitle = intl.formatMessage({ id: "common.btn-apply" });

    useEffect(() => {
        return () => {
            setFunctionFile(null)
            setActionFile(null)
            setPermissionFile(null)
            setRiskFile(null)
            setRiskDescription(null)
            setMatrixFile(null)
            setRiskToMatrix(null)
            setBusynessProcesses(null)
            setFuncToBusynessProcesses(null)
        }
    }, [])

    const onCancel = () => {
        onClose();
    }

    return (
        <CommonDialog
            className="integration-schedule-dialog"
            open={!!open}
            {...props}
        >
            <CommonDialogTitle
                title={innerTitle}
                onClose={onCancel}
            />

            <CommonDialogContent>
                <Box className="integration-upload-function-step-form">

                    <FileUploadField
                        title={intl.formatMessage({ id: "integration-page.matrix-upload-dialog.field.functions" })}
                        id="functions"
                        file={functionFile}
                        setFile={(file) => {
                            setFunctionFile(file);
                            dispatch(clearUploadError("functions"))
                        }}
                        errorMessage={uploadErrors && uploadErrors["functions"] && uploadErrors["functions"]["message"]}
                        error={uploadErrors && uploadErrors["functions"]} />

                    <FileUploadField
                        title={intl.formatMessage({ id: "integration-page.matrix-upload-dialog.field.actions" })}
                        id="actions"
                        file={actionFile}
                        setFile={(file) => {
                            setActionFile(file);
                            dispatch(clearUploadError("actions"))
                        }}
                        errorMessage={uploadErrors && uploadErrors["actions"] && uploadErrors["actions"]["message"]}
                        error={uploadErrors && uploadErrors["actions"]} />

                    <FileUploadField
                        title={intl.formatMessage({ id: "integration-page.matrix-upload-dialog.field.permissions" })}
                        id="permissions"
                        file={permissionFile}
                        setFile={(file) => {
                            setPermissionFile(file);
                            dispatch(clearUploadError("permissions"))
                        }}
                        errorMessage={uploadErrors && uploadErrors["permissions"] && uploadErrors["permissions"]["message"]}
                        error={uploadErrors && uploadErrors["permissions"]} />

                    <FileUploadField
                        title={intl.formatMessage({ id: "integration-page.matrix-upload-dialog.field.risks" })}
                        id="risks"
                        file={riskFile}
                        setFile={(file) => {
                            setRiskFile(file);
                            dispatch(clearUploadError("risks"))
                        }}
                        errorMessage={uploadErrors && uploadErrors["risks"] && uploadErrors["risks"]["message"]}
                        error={uploadErrors && uploadErrors["risks"]} />

                    <FileUploadField
                        title={intl.formatMessage({ id: "integration-page.matrix-upload-dialog.field.riskDescriptions" })}
                        id="risk_description"
                        file={riskDescriptionFile}
                        setFile={(file) => {
                            setRiskDescription(file);
                            dispatch(clearUploadError("risk_texts"))
                        }}
                        errorMessage={uploadErrors && uploadErrors["risk_texts"] && uploadErrors["risk_texts"]["message"]}
                        error={uploadErrors && uploadErrors["risk_texts"]} />

                    <FileUploadField
                        title={intl.formatMessage({ id: "integration-page.matrix-upload-dialog.field.matrices" })}
                        file={matrixFile}
                        id="matrices"
                        setFile={(file) => {
                            setMatrixFile(file);
                            dispatch(clearUploadError("matrix"))
                        }}
                        errorMessage={uploadErrors && uploadErrors["matrix"] && uploadErrors["matrix"]["message"]}
                        error={uploadErrors && uploadErrors["matrix"]} />

                    <FileUploadField
                        title={intl.formatMessage({ id: "integration-page.matrix-upload-dialog.field.riskToMatrices" })}
                        id="risk_to_matrix"
                        file={riskToMatrixFile}
                        setFile={(file) => {
                            setRiskToMatrix(file);
                            dispatch(clearUploadError("matrix_risks"))
                        }}
                        errorMessage={uploadErrors && uploadErrors["matrix_risks"] && uploadErrors["matrix_risks"]["message"]}
                        error={uploadErrors && uploadErrors["matrix_risks"]} />


                    <FileUploadField
                        title={intl.formatMessage({ id: "integration-page.matrix-upload-dialog.field.busynessProcesses" })}
                        id="business_processes"
                        file={busynessProcessesFile}
                        setFile={(file) => {
                        setBusynessProcesses(file);
                            dispatch(clearUploadError("business_processes"))
                        }}
                        errorMessage={uploadErrors && uploadErrors["business_processes"] && uploadErrors["business_processes"]["message"]}
                        error={uploadErrors && uploadErrors["business_processes"]} />



                    <FileUploadField
                        title={intl.formatMessage({ id: "integration-page.matrix-upload-dialog.field.functionsTobusynessProcesses" })}
                        id="function_business_process"
                        file={funcToBusynessProcessesFile}
                        setFile={(file) => {
                            setFuncToBusynessProcesses(file);
                            dispatch(clearUploadError("function_business_process"))
                        }}
                        errorMessage={uploadErrors && uploadErrors["function_business_process"] && uploadErrors["function_business_process"]["message"]}
                        error={uploadErrors && uploadErrors["function_business_process"]} />

                </Box>
            </CommonDialogContent>

            <CommonDialogActions>
                <Button
                    variant="outlined"
                    onClick={onCancel}
                    sx={{
                        color: 'var(--font-4)',
                        borderColor: 'var(--font-4)',
                    }}
                >
                    {cancelTitle}
                </Button>

                <Button
                    variant="contained"
                    onClick={onSubmit}
                    sx={{
                        color: 'var(--bg-2)',
                        backgroundColor: 'var(--font-2)',
                        '&:hover': {
                            backgroundColor: 'var(--font-2)',
                        }
                    }}
                >
                    {changeTitle}
                </Button>
            </CommonDialogActions>
        </CommonDialog>
    )
};

UploadMatrixDialog.propTypes = {
    system: PropTypes.string,
    onClose: PropTypes.func,
    onApply: PropTypes.func,
    open: PropTypes.bool
};

export default UploadMatrixDialog;
