import React from "react";
import { IconButton } from "@mui/material";
import PropTypes from "prop-types";

import { LeftChevronIcon } from "../icons";
import breakpoints from "../styles/breakpoints.js";

const BackButton = ({ sx, ...props }) => (
    <IconButton
        variant="outlined"
        {...props}
        sx={{
            border: "0.625px solid var(--stroke-1)",
            backgroundColor: "var(--bg-2)",
            borderRadius: "50%",
            width: "30px",
            height: "30px",

            [`@media (min-width: ${breakpoints.minLaptopBreakpoint})`]: {
                width: "42px",
                height: "42px",
                borderWidth: "0.875px"
            },

            ...sx
        }}
    >
        <LeftChevronIcon
            sx={{
                color: "#7C8673",
                fontSize: "15px",

                [`@media (min-width: ${breakpoints.minLaptopBreakpoint})`]: {
                    fontSize: "21px"
                }
            }}
        />
    </IconButton>
);

BackButton.propTypes = {
    sx: PropTypes.object
};

export default BackButton;
