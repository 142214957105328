import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

import {
    fetchUsers,
    fetchUsersColumns,
    setSelectedIds as setSelectedIdsAction,
    setSearchString as setSearchStringAction,
    setPageNumber as setPageNumberAction,
    setLimitNumber as setLimitNumberAction,
    setSortData as setSortDataAction,
    setFilterData as setFilterDataAction,
    setError as setErrorAction,
} from "../../../../reducers/admin/usersSlice";

import useFilters from "../../../common/hooks/useFilters";
import useUserRoles from "../../../common/hooks/useUserRoles";
import useReduxTable from "../../../common/hooks/useReduxTable";
import CommonTable from "../../../common/table";
import { ProgressDialog, ErrorsDialog } from "../../../common/dialogs";


const getCellValue = (row, column, userRolesText) => {
    switch (column.id) {
        case "role":
            return userRolesText[row[column.id]];

        default:
            return row[column.id];
    }
};

const UsersListPage = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    const { userRolesText } = useUserRoles();

    const {
        rows, columns,
        total, busy,
        selectedIds,
        searchString, setSearchString,
        pageNumber, setPageNumber,
        limitNumber, setLimitNumber,
        filterData, setFilterData,
        sortData, setSortData,
        error, setError
    } = useReduxTable(
        (state) => state.admin.users.users, fetchUsers,
        (state) => state.admin.users.columns, fetchUsersColumns,
        (state) => state.admin.users.selectedIds, setSelectedIdsAction,
        (state) => state.admin.users.searchString, setSearchStringAction,
        (state) => state.admin.users.pageNumber, setPageNumberAction,
        (state) => state.admin.users.limitNumber, setLimitNumberAction,
        (state) => state.admin.users.sortData, setSortDataAction,
        (state) => state.admin.users.filterData, setFilterDataAction,
        (state) => state.admin.users.error, setErrorAction,
        (state) => state.admin.users.total,
        (state) => state.admin.users.busy,
    );

    const busyType = useSelector((state) => state.admin.users.busyType);

    const getFilters = useFilters("user");

    const handleAddUserClick = () => {
        navigate("form")
    };

    const handleRowClick = (user) => {
        navigate(`form/${user.username}`)
    };

    const getTableRowId = (row) => row.username;

    const toolbarActions = [
        {
            id: "title",
            title: intl.formatMessage({ id: "users-list-page.toolbar.text.title" }),
            type: "text",
            position: "begin"
        },
        {
            id: "add",
            type: "button",
            position: "end",
            title: intl.formatMessage({ id: "users-list-page.toolbar.add-user-btn" }),
            callback: () => {
                handleAddUserClick()
            }
        }
    ];

    const getCellValueInner = (row, column) => getCellValue(row, column, userRolesText);

    return (
        <>
            <CommonTable
                rows={rows}
                columns={columns}
                filterData={filterData}
                setFilterData={setFilterData}
                sortData={sortData}
                setSortData={setSortData}
                toolbarActions={toolbarActions}
                selectedIds={selectedIds}
                getRowId={getTableRowId}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                limitNumber={limitNumber}
                setLimitNumber={setLimitNumber}
                total={total}
                onRowClick={handleRowClick}
                searchString={searchString}
                setSearchString={setSearchString}
                getCellValue={getCellValueInner}
                getFilters={getFilters}
            />

            <ProgressDialog
                open={busy}
                busyType={busyType}
            />

            <ErrorsDialog
                error={error}
                open={error?.type === "error"}
                onClose={() => setError(null)}
            />
        </>
    );
};

export default UsersListPage;
