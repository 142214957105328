import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import useReduxValue from "./useReduxValue";

const useReduxTable = (
    rowsSelector,
    fetchRowsAction,
    columnsSelector,
    fetchColumnsAction,
    selectedIdsSelector,
    setSelectedIdsAction,
    searchStringSelector,
    setSearchStringAction,
    pageNumberSelector,
    setPageNumberAction,
    limitNumberSelector,
    setLimitNumberAction,
    sortDataSelector,
    setSortDataAction,
    filterDataSelector,
    setFilterDataAction,
    errorSelector,
    setErrorAction,
    totalSelector,
    busySelector,
) => {
    const dispatch = useDispatch();

    const rows = useSelector(rowsSelector);
    const columns = useSelector(columnsSelector);
    const total = useSelector(totalSelector);
    const busy = useSelector(busySelector);

    const [selectedIds, setSelectedIds] = useReduxValue(selectedIdsSelector, setSelectedIdsAction);
    const [searchString, setSearchString] = useReduxValue(searchStringSelector, setSearchStringAction);
    const [pageNumber, setPageNumber] = useReduxValue(pageNumberSelector, setPageNumberAction);
    const [limitNumber, setLimitNumber] = useReduxValue(limitNumberSelector, setLimitNumberAction);
    const [sortData, setSortData] = useReduxValue(sortDataSelector, setSortDataAction);
    const [filterData, setFilterData] = useReduxValue(filterDataSelector, setFilterDataAction);
    const [error, setError] = useReduxValue(errorSelector, setErrorAction);

    useEffect(() => {
        if (columns.length > 0) return;
        dispatch(fetchColumnsAction());
    }, [columns.length, fetchColumnsAction, dispatch]);

    useEffect(() => {
        dispatch(fetchRowsAction({
            params: {
                search: searchString,
                filters: filterData,
                page: pageNumber,
                limit: limitNumber,
                sorters: sortData
            }
        }));
    }, [dispatch, fetchRowsAction, searchString, filterData, pageNumber, limitNumber, sortData]);

    return {
        rows, columns,
        total, busy,
        selectedIds, setSelectedIds,
        searchString, setSearchString,
        pageNumber, setPageNumber,
        limitNumber, setLimitNumber,
        sortData, setSortData,
        filterData, setFilterData,
        error, setError,
    }
};

export default useReduxTable;
