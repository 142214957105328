import React from "react";
import { FormControl as MuiFormControl } from "@mui/material";
import PropTypes from "prop-types";

import "./styles.less";

export const formControlClassName = "form-control";

const FormControl = ({
    className="",
    ...props
}) => {
    return (
        <MuiFormControl
            className={`${formControlClassName} ${className}`}
            {...props}
        />
    )
};

FormControl.propTypes = {
    className: PropTypes.string
};

export default FormControl;
