import React, { createContext, useCallback, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";

const getTableSize = (rows, columns) => {
    const columnsLength = columns?.length ?? 0;

    let rowsLength = rows?.length ?? 0;

    if (columns) {
        rowsLength += 1;
    }

    return {
        columnsLength,
        rowsLength
    };
};

const getKeyboardFocusableElements = (element = document) => {
    return [
        ...element.querySelectorAll(
            'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])',
        ),
    ].filter(
        el => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden'),
    );
};

const SCROLL_DIRECTIONS = {
    DOWN: "DOWN",
    UP: "UP"
};

const scrollContainerIfNeccessary = (containerNode, cellNode, direction) => {
    if (!containerNode || !cellNode) {
        return;
    }

    const containerRect = containerNode.getBoundingClientRect();
    const cellRect = cellNode.getBoundingClientRect();
    const bottomDelta = Math.abs(containerRect.bottom - cellRect.bottom);
    const topDelta = Math.abs(containerRect.top - cellRect.top);

    let scrollBy;

    switch (direction) {
        case SCROLL_DIRECTIONS.DOWN:
            if (bottomDelta <= cellRect.height) {
                scrollBy = topDelta;
            }
            break;
        
        case SCROLL_DIRECTIONS.UP:
            if (topDelta <= cellRect.height) {
                scrollBy = -bottomDelta;
            }
            break;
    }

    if (scrollBy) {
        containerNode.scrollBy({
            top: scrollBy,
            behavior: "instant"
        });
    }
};

const itemHasFocus = (item) => Boolean(
    item &&
    document.activeElement === item
);

const itemIsTextInput = (item) => Boolean(
    item &&
    item.tagName === "INPUT" &&
    item.type === "text"
);

export const KeyboardNavigationContext = createContext();

export const KeyboardNavigationProvider = ({
    children,
    rowsLength,
    columnsLength,
}) => {
    const containerRef = useRef(null);
    const itemRefs = useRef({});
    const activeElement = useRef({
        activeRowIndex: -1,
        activeColumnIndex: -1,
        activeItemIndex: -1,
        activeNode: null
    });

    const handleContainerRef = useCallback((node) => {
        containerRef.current = node;
    }, []);

    const getCellRef = useCallback((rowIndex, columnIndex) => {
        return itemRefs.current[rowIndex]?.[columnIndex];
    }, []);

    const setActiveNode = useCallback(({
        rowIndex,
        columnIndex,
        itemIndex,
        focus=true
    }) => {
        const currentActiveElement = activeElement.current;
        currentActiveElement.activeRowIndex = rowIndex;
        currentActiveElement.activeColumnIndex = columnIndex;
        currentActiveElement.activeItemIndex = itemIndex;

        const cell = getCellRef(rowIndex, columnIndex);
        const item = cell?.items[itemIndex];

        const newActiveNode = item || cell?.node;

        if (focus && newActiveNode) {
            newActiveNode.focus();
            currentActiveElement.activeNode = newActiveNode;

        } else {
            currentActiveElement.activeNode?.blur();
            currentActiveElement.activeNode = null;
        }
    }, [getCellRef]);

    const handleCellRef = useCallback((node, rowIndex, columnIndex) => {
        const {
            activeRowIndex,
            activeColumnIndex,
            activeItemIndex
        } = activeElement.current;

        if (!(rowIndex in itemRefs.current)) {
            itemRefs.current[rowIndex] = {};
        }

        const row = itemRefs.current[rowIndex];

        if (!node && (columnIndex in row)) {
            delete row[columnIndex];

            if (Object.keys(row).length === 0) {
                delete itemRefs.current[rowIndex];
            }
        } else {
            const focusableElements = getKeyboardFocusableElements(node);

            row[columnIndex] = {
                items: focusableElements,
                node
            };

            if (rowIndex === activeRowIndex &&
                columnIndex === activeColumnIndex) {
                setActiveNode({
                    rowIndex,
                    columnIndex,
                    itemIndex: activeItemIndex
                });
            }
        }
    }, [setActiveNode]);

    const handleContainerClickAway = useCallback((e) => {
        if (e.target.localName === "body") {
            return;
         }

        setActiveNode({
            rowIndex: -1,
            columnIndex: -1,
            itemIndex: -1,
            focus: false
        });
    }, [setActiveNode]);

    const handleArrowLeft = useCallback((e) => {
        if (e.defaultPrevented) {
            return;
        }

        const {
            activeRowIndex,
            activeColumnIndex,
            activeItemIndex,
            activeNode
        } = activeElement.current;

        if (activeItemIndex > -1 &&
            activeNode &&
            itemHasFocus(activeNode) &&
            itemIsTextInput(activeNode) &&
            activeNode.selectionStart !== 0) {
            return;
        }

        e.preventDefault();

        if (e.repeat) {
            return;
        }

        if (activeItemIndex > 0) {
            setActiveNode({
                rowIndex: activeRowIndex,
                columnIndex: activeColumnIndex,
                itemIndex: activeItemIndex - 1,
            });
        } else if (activeColumnIndex > 0) {
            const nextColumnIndex = activeColumnIndex - 1;
            const nextCell = getCellRef(activeRowIndex, nextColumnIndex);
            const nextItemsLength = nextCell?.items.length || 0;
            const nextItemIndex = nextItemsLength - 1;

            setActiveNode({
                rowIndex: activeRowIndex,
                columnIndex: nextColumnIndex,
                itemIndex: nextItemIndex
            });
        }
    }, [getCellRef, setActiveNode]);

    const handleArrowRight = useCallback((e) => {
        if (e.defaultPrevented) {
            return;
        }

        const {
            activeRowIndex,
            activeColumnIndex,
            activeItemIndex,
            activeNode
        } = activeElement.current;

        if (activeItemIndex > -1 &&
            activeNode &&
            itemHasFocus(activeNode) &&
            itemIsTextInput(activeNode) &&
            activeNode.selectionStart !== activeNode.value.length) {
            return;
        }

        e.preventDefault();

        if (e.repeat) {
            return;
        }

        const cell = getCellRef(activeRowIndex, activeColumnIndex);

        if (activeItemIndex >= 0 &&
            activeItemIndex < cell?.items.length - 1) {
            setActiveNode({
                rowIndex: activeRowIndex,
                columnIndex: activeColumnIndex, 
                itemIndex: activeItemIndex + 1
            });
        } else if (activeColumnIndex < columnsLength - 1) {
            const nextColumnIndex = activeColumnIndex + 1;
            const nextCell = getCellRef(activeRowIndex, nextColumnIndex);
            const nextItemIndex = nextCell?.items.length ? 0 : -1;
            
            setActiveNode({
                rowIndex: activeRowIndex,
                columnIndex: nextColumnIndex,
                itemIndex: nextItemIndex
            });
        }
    }, [columnsLength, getCellRef, setActiveNode]);

    const handleArrowUp = useCallback((e) => {
        if (e.defaultPrevented) {
            return;
        }

        const {
            activeRowIndex,
            activeColumnIndex,
            activeItemIndex
        } = activeElement.current;

        e.preventDefault();

        if (e.repeat || activeRowIndex <= 0) {
            return;
        }

        const currentCell = getCellRef(activeRowIndex, activeColumnIndex);
        scrollContainerIfNeccessary(containerRef.current, currentCell?.node, SCROLL_DIRECTIONS.UP);

        const nextRowIndex = activeRowIndex - 1;
        const nextCell = getCellRef(nextRowIndex, activeColumnIndex);
        const nextItemsLength = nextCell?.items.length || 0;
        
        let nextItemIndex;

        if (activeItemIndex >= 0) {
            nextItemIndex = activeItemIndex < nextItemsLength ? activeItemIndex : 0;
        } else {
            nextItemIndex = nextItemsLength ? 0 : -1;
        }

        setActiveNode({
            rowIndex: nextRowIndex,
            columnIndex: activeColumnIndex,
            itemIndex: nextItemIndex
        });
    }, [getCellRef, setActiveNode]);

    const handleArrowDown = useCallback((e) => {
        if (e.defaultPrevented) {
            return;
        }

        const {
            activeRowIndex,
            activeColumnIndex,
            activeItemIndex
        } = activeElement.current;

        e.preventDefault();
        
        if (e.repeat || activeRowIndex >= rowsLength - 1) {
            return;
        }

        const currentCell = getCellRef(activeRowIndex, activeColumnIndex);
        scrollContainerIfNeccessary(containerRef.current, currentCell?.node, SCROLL_DIRECTIONS.DOWN);

        const nextRowIndex = activeRowIndex + 1;
        const nextCell = getCellRef(nextRowIndex, activeColumnIndex);
        const nextItemsLength = nextCell?.items.length || 0;
        
        let nextItemIndex;

        if (activeItemIndex >= 0) {
            nextItemIndex = activeItemIndex < nextItemsLength ? activeItemIndex : 0;
        } else {
            nextItemIndex = nextItemsLength ? 0 : -1;
        }
    
        setActiveNode({
            rowIndex: nextRowIndex,
            columnIndex: activeColumnIndex,
            itemIndex: nextItemIndex
        });
    }, [getCellRef, rowsLength, setActiveNode]);

    const handleEnter = useCallback((e) => {
        const isButton = e.target.tagName === "BUTTON";
    
        if (e.defaultPrevented || isButton) {
            return;
        }

        e.target.click();
    }, []);

    const handleEscape = useCallback((e) => {
        if (e.defaultPrevented) {
            return;
        }

        setActiveNode({
            rowIndex: -1,
            columnIndex: -1,
            itemIndex: -1,
            focus: false
        });
    }, [setActiveNode]);

    const handleKeyDown = useCallback((e) => {
        const { key } = e;

        switch (key) {
            case "ArrowUp":
                handleArrowUp(e);
                break;

            case "ArrowDown":
                handleArrowDown(e);
                break;

            case "ArrowLeft":
                handleArrowLeft(e);
                break;

            case "ArrowRight":
                handleArrowRight(e);
                break;

            case "Enter":
                handleEnter(e);
                break;

            case "Escape":
                handleEscape(e);
                break;
        }
    }, [
        handleArrowUp,
        handleArrowDown,
        handleArrowLeft,
        handleArrowRight,
        handleEnter,
        handleEscape
    ]);

    const handleCellClick = useCallback((e, rowIndex, columnIndex) => {
        const { activeNode } = activeElement.current;

        if (activeNode === e.target) {
            return;
        }

        const cell = getCellRef(rowIndex, columnIndex);

        if (!cell) {
            return;
        }

        let itemIndex = cell.items.indexOf(e.target);

        if (cell.items.length && itemIndex === -1) {
            itemIndex = 0;
        }

        setActiveNode({
            rowIndex,
            columnIndex,
            itemIndex
        });
    }, [getCellRef, setActiveNode]);

    useEffect(() => {
        if (rowsLength === 0 || columnsLength === 0) {
            setActiveNode({
                rowIndex: -1,
                columnIndex: -1,
                itemIndex: -1,
                focus: false
            });
        }
    }, [rowsLength, columnsLength, setActiveNode]);

    const providerValue = useMemo(() => ({
        handleCellRef,
        handleContainerRef,
        handleKeyDown,
        handleCellClick,
        handleContainerClickAway
    }), [
        handleCellRef,
        handleContainerRef,
        handleKeyDown,
        handleCellClick,
        handleContainerClickAway
    ]);

    return (
        <KeyboardNavigationContext.Provider value={providerValue}>
            {children}
        </KeyboardNavigationContext.Provider>
    );
};

export const KeyboardNavigationWrapper = ({
    children,
    useKeyboardNavigation,
    rows,
    columns
}) => {
    if (!useKeyboardNavigation) {
        return children;
    }

    const { columnsLength, rowsLength } = getTableSize(rows, columns);

    return (
        <KeyboardNavigationProvider
            rowsLength={rowsLength}
            columnsLength={columnsLength}
        >
            {children}
        </KeyboardNavigationProvider>
    );
};

KeyboardNavigationProvider.propTypes = {
    children: PropTypes.node,
    rowsLength: PropTypes.number,
    columnsLength: PropTypes.number,
};

KeyboardNavigationWrapper.propTypes = {
    children: PropTypes.node,
    rows: PropTypes.arrayOf(PropTypes.object),
    columns: PropTypes.arrayOf(PropTypes.object),
    useKeyboardNavigation: PropTypes.bool
};