import React from "react";
import { useIntl } from "react-intl";
import { Checkbox, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PropTypes from "prop-types";

import { CheckboxInput } from "../../common/form-controls";

import { handleDragEnd, setActiveAll, setColumnActive } from "./use-columns-reducer";

import "./styles.less";

const ColumnListItem = ({ disabled, column, index, onCheckboxChange }) => {
    return (
        <Draggable
            key={column.id}
            draggableId={column.id}
            index={index}
            isDragDisabled={disabled}
        >
            {(provided) => (
                <ListItem
                    ref={provided.innerRef}
                    className="report-columns-dialog-list-item"
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <ListItemIcon>
                        <DragIndicatorIcon />
                    </ListItemIcon>
                    <ListItemIcon>
                        <Checkbox
                            disabled={disabled}
                            checked={column.active}
                            onChange={onCheckboxChange}
                        />
                    </ListItemIcon>
                    <ListItemText primary={column.title} />
                </ListItem>
            )}
        </Draggable>
    )
};

const ActiveAllCheckbox = ({ disabled, checked, onChange }) => {
    const intl = useIntl();

    return (
        <CheckboxInput
            className="report-columns-dialog-checked-all-checkbox"
            label={intl.formatMessage({id: "report-columns-dialog-list.checked-all-checkbox.label"})}
            disabled={disabled}
            checked={checked}
            onChange={onChange}
        />
    )
};

const ColumnsList = ({
    disabled,
    columnsState,
    columnsStateDispatch
}) => {
    const handleDragEndInner = (result) => {
        columnsStateDispatch(
            handleDragEnd(result)
        );
    };

    const handleCheckboxChange = (event, columnId) => {
        columnsStateDispatch(
            setColumnActive(columnId, event.target.checked)
        );
    };

    const handleActiveAllChange = (event) => {
        columnsStateDispatch(
            setActiveAll(event.target.checked)
        );
    };

    const { columns, activeAll } = columnsState;

    return (
        <>
            <ActiveAllCheckbox
                disabled={disabled}
                checked={activeAll}
                onChange={handleActiveAllChange}
            />
            <DragDropContext onDragEnd={handleDragEndInner}>
                <Droppable
                    droppableId="droppable"
                    isDropDisabled={disabled}
                >
                    {(provided) => (
                        <List
                            ref={provided.innerRef}
                            className="report-columns-dialog-list"
                            {...provided.droppableProps}
                        >
                            {columns.map((column, index) => (
                                <ColumnListItem
                                    key={column.id}
                                    column={column}
                                    index={index}
                                    disabled={disabled}
                                    onCheckboxChange={(event) => handleCheckboxChange(event, column.id)}
                                />
                            ))}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
};

const columnShape = PropTypes.shape({
    id: PropTypes.string,
    title:PropTypes.string,
    active: PropTypes.bool
});

ColumnListItem.propTypes = {
    disabled: PropTypes.bool,
    column: columnShape,
    index: PropTypes.number,
    onCheckboxChange: PropTypes.func
};

ActiveAllCheckbox.propTypes = {
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    onChange: PropTypes.func
};

ColumnsList.propTypes = {
    disabled: PropTypes.bool,
    columnsState: PropTypes.shape({
        columns: PropTypes.arrayOf(columnShape),
        activeAll: PropTypes.bool
    }),
    columnsStateDispatch: PropTypes.func
};

export default ColumnsList;