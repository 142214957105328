import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { getRolesSearchHelp } from "../../../utils/searchHelps";

import SearchHelpDialog from "../../common/search-helps";

const SelectRoleDialog = ({
    open,
    systemId,
    onClose,
    onSelect
}) => {
    const intl = useIntl();

    const readRows = (params, signal) => {
        const paramsInner = {
            ...params,
            filters: [
                { field: "systemId", sign: "EQ", value: systemId }
            ]
        };

        return getRolesSearchHelp(paramsInner, signal);
    };

    const columns = [
        { id: "key", title: intl.formatMessage({ id: "role-menu.select-role-dialog.title" }) },
        { id: "text", title: intl.formatMessage({ id: "role-menu.select-role-dialog.title" }) }
    ];

    const title = intl.formatMessage({ id: "role-menu.select-role-dialog.title" });

    return (
        <SearchHelpDialog
            open={open}
            readRows={readRows}
            columns={columns}
            selectOneRow={true}
            onSubmit={(selectedRow) => onSelect(selectedRow.key)}
            onCancel={onClose}
            title={title}
        />
    );
};

SelectRoleDialog.propTypes = {
    open: PropTypes.bool,
    systemId: PropTypes.string,
    onClose: PropTypes.func,
    onSelect: PropTypes.func
};

export default SelectRoleDialog;