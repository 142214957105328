import React, { useId } from "react";
import { Select } from "@mui/material";
import PropTypes from "prop-types";

import { SelectIcon } from "../icons";
import { menuClassName } from "../menu";

import InputWrapper from "./input-wrapper.jsx";
import { getInputClassName } from "./input.jsx";

import "./styles.less";

const SelectInput = ({
    children,
    className="",
    fullWidth=true,
    shrink=true,
    label,
    value,
    error=false,
    errorMessage,
    warning=false,
    warningMessage,
    disabled,
    IconComponent=SelectIcon,
    onChange
}) => {
    const id = useId();
    const inputClassName = getInputClassName({
        className,
        error,
        warning
    });

    return (
        <InputWrapper
            id={id}
            className={className}
            fullWidth={fullWidth}
            shrink={shrink}
            label={label}
            error={error}
            errorMessage={errorMessage}
            warning={warning}
            warningMessage={warningMessage}
            InputComponent={
                <Select
                    id={id}
                    className={inputClassName}
                    value={value}
                    disabled={disabled}
                    error={error}
                    onChange={onChange}
                    IconComponent={IconComponent}
                    MenuProps={{
                        className: menuClassName
                    }}
                >
                    {children}
                </Select>
            }
        />
    );
};

SelectInput.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    fullWidth: PropTypes.bool,
    shrink: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    warning: PropTypes.bool,
    warningMessage: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    IconComponent: PropTypes.elementType
};

export default SelectInput;
