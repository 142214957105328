import React from "react";
import { createSvgIcon } from '@mui/material';

const MinusMuiIcon = createSvgIcon(
    <>
        <rect fill="currentColor" />
        <path d="M4.45016 9.27187C4.45016 8.97388 4.70725 8.72263 4.99355 8.72263L9.55106 8.72264L13.0043 8.72264C13.2964 8.72264 13.5476 8.97389 13.5476 9.27188C13.5506 9.57279 13.2964 9.82112 13.0043 9.82112L8.44967 9.82403L4.99063 9.82403C4.71017 9.82403 4.45308 9.57279 4.45016 9.27187Z" fill="currentColor" />
        </>
    ,
    "MinusMui"
);

export default MinusMuiIcon;
