import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import * as locales from "react-date-range/dist/locale";
import { set } from 'date-fns';
import { Popover } from '@mui/material';
import PropTypes from 'prop-types';

import { DateRange } from '../calendars';
import { DateRangeInput } from '../form-controls';

const DateFilterPopover = ({ anchorEl, open, currentFilters, onClose }) => {
    const intl = useIntl();

    const [openCalendar, setOpenCalendar] = useState(false);

    const [range, setRange] = useState({
        startDate: null,
        endDate: null,
        key: 'selection'
    });

    useEffect(() => {
        const startDate = currentFilters?.find(filter => filter.sign === "GE")?.value;
        const endDate = currentFilters?.find(filter => filter.sign === "LE")?.value;

        setRange(range => ({
            ...range,
            startDate: startDate || null,
            endDate: endDate || null,
        }));
    }, [currentFilters]);

    const currentLocale = locales[intl.locale.split("-")[0]];

    const handleClose = () => {
        const newEndDate = range.endDate && set(range.endDate, {
            hours: 23,
            minutes: 59,
            seconds: 59,
            milliseconds: 999
        });

        onClose(range.startDate, newEndDate);
    };

    const handleDateRangeInputChange = (newStartDate, newEndDate) => {
        setRange({
            ...range,
            startDate: newStartDate,
            endDate: newEndDate,
        });
    };

    return (
        <Popover
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            sx={{
                ".MuiPopover-paper": {
                    display: "flex",
                    flexDirection: "column",
                    gap: "2px",
                    padding: "8px",
                    width: "269px"
                }
            }}
        >
            <DateRangeInput
                startDate={range.startDate}
                endDate={range.endDate}
                onChange={handleDateRangeInputChange}
                onCalendarClick={() => setOpenCalendar(!openCalendar)}
            />

            {openCalendar && (
                <DateRange
                    onChange={item => setRange(item.selection)}
                    ranges={[range]}
                    locale={currentLocale}
                    rangeColors={["#DEDEDE"]}
                    date={range.startDate}
                    displayMode="date"
                />
            )}
        </Popover>
    );
};

DateFilterPopover.propTypes = {
    anchorEl: PropTypes.any,
    open: PropTypes.bool,
    currentFilters: PropTypes.arrayOf(PropTypes.object),
    onClose: PropTypes.func
}

export default DateFilterPopover;
