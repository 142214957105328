import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { SelectInput } from "../form-controls";
import { MenuItem } from "../menu";

const defaultLimits = [10, 20, 100];

const PaginationLimit = ({
    className="",
    value,
    limits=defaultLimits,
    onChange
}) => {
    const intl = useIntl();
    const label = intl.formatMessage({ id: "common.pagination.limit" });

    const classNameInner = `pagination-limit ${className}`;

    return (
        <SelectInput
            className={classNameInner}
            shrink={false}
            label={label}
            value={value}
            onChange={onChange}
        >
            {limits.map(limit => (
                <MenuItem
                    key={limit}
                    value={limit}
                >
                    {limit}
                </MenuItem>
            ))}
        </SelectInput>
    );
};

PaginationLimit.propTypes = {
    className: PropTypes.string,
    value: PropTypes.number,
    limits: PropTypes.arrayOf(PropTypes.number),
    onChange: PropTypes.func
};

export default PaginationLimit;