import React from 'react';
import { SvgIcon } from '@mui/material';

const TabTitleChevronIcon = (props) => (
    <SvgIcon
        viewBox="0 0 12 24"
        {...props}
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 12 24"
            fill="none"
        >
            <path
                d="M1 0C1.28125 0 1.51562 0.102273 1.70312 0.306818L11.7031 11.2159C11.901 11.4318 12 11.6875 12 11.983C12 12.2898 11.901 12.5511 11.7031 12.767L1.70312 23.6761C1.50521 23.892 1.27083 24 1 24C0.729167 24 0.494792 23.892 0.296875 23.6761C0.0989583 23.4489 0 23.1875 0 22.892C0 22.5966 0.0989583 22.3409 0.296875 22.125L9.59375 12L0.296875 1.85795C0.0989583 1.64205 0 1.38068 0 1.07386C0 0.778409 0.0989583 0.528409 0.296875 0.323864C0.494792 0.107955 0.729167 0 1 0Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

export default TabTitleChevronIcon;
