import React from 'react';
import { createSvgIcon } from '@mui/material';

const UserIcon = createSvgIcon(
    <path
        d="M12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7C17 8.32608 16.4732 9.59785 15.5355 10.5355C14.5979 11.4732 13.3261 12 12 12C10.6739 12 9.40215 11.4732 8.46447 10.5355C7.52678 9.59785 7 8.32608 7 7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2ZM12 14.5C17.525 14.5 22 16.7375 22 19.5V22H2V19.5C2 16.7375 6.475 14.5 12 14.5Z"
        fill="currentColor"
    />,
    "User"
);

export default UserIcon;
