import React from "react";
import { Box } from "@mui/material";
import PropTypes from 'prop-types';

import { Pagination, PaginationLimit } from "../pagination";

import "./styles.less";

const PaginationBar = ({
    pageNumber,
    limitNumber,
    setPageNumber,
    setLimitNumber,
    total
}) => {
    return (
        <Box className="table-pagination-wrapper">
            <Pagination
                count={Math.ceil(total / limitNumber) || 1}
                page={pageNumber}
                onChange={(_, value) => { setPageNumber(value) }}
            />

            <PaginationLimit
                value={limitNumber}
                onChange={(event) => {setLimitNumber(event.target.value)}}
            />
        </Box>
    )
}

PaginationBar.propTypes = {
    pageNumber: PropTypes.number,
    limitNumber: PropTypes.number,
    setPageNumber: PropTypes.func,
    setLimitNumber: PropTypes.func,
    total: PropTypes.number
}


export default PaginationBar