import React, { useMemo, useRef } from "react";
import { Radio } from "@mui/material";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { selectModelingUsersBySearch } from "../../../../reducers/reports-modeling/userLevelSlice";

import { createRowCellContentInner } from "../../../common/table";

import { EntitiesSelectTable } from "../../entities-select-dialog";

import { REPORT_MODELING_DELTA_STATUSES } from "../../../../utils/reports-modeling-utils";

const getCellValue = (row, column) => {
    switch (column.id) {
        case "description": {
            const fullName = [];

            if (row.lastName) {
                fullName.push(row.lastName);
            }

            if (row.firstName) {
                fullName.push(row.firstName);
            }

            return fullName.join(" ");
        }

        default:
            return row[column.id];
    }
};

const UsersTable = ({
    searchString,
    selectedId,
    onSelectRow,
    onRowClick
}) => {
    const users = useSelector((state) => selectModelingUsersBySearch(state, searchString));
    const intl = useIntl();

    const columns = [
        {
            id: "radio",
            type: "radio"
        },
        {
            id: "employee",
            title: intl.formatMessage({ id: "user-level-modeling-user-search-help.dialog.columns.user" })
        },
        {
            id: "systemId",
            title: intl.formatMessage({ id: "user-level-modeling-user-search-help.dialog.columns.system-id" })
        },
        {
            id: "description",
            title: intl.formatMessage({ id: "user-level-modeling-user-search-help.dialog.columns.description" })
        },
    ];

    const createRowCellContent = (row, column, selected, onSelect, getCellValue) => {
        switch (column.type) {
            case "radio": {
                return (
                    <Radio
                        checked={row.id === selectedId}
                        value={row.id}
                        onChange={onSelectRow}
                    />
                );
            }

            default:
                return createRowCellContentInner(row, column, selected, onSelect, getCellValue);
        }
    };

    const tableRef = useRef();

    const changedUsers = useMemo(() => {
        return users.map(user => {
            const userCopy = {...user}

            if (user.roles.some(role => role.status || role.changed)){
                userCopy.status = REPORT_MODELING_DELTA_STATUSES.CHANGED
            }

            return userCopy
        })
    }, [users])

    return (
        <EntitiesSelectTable
            containerRef={tableRef}
            rows={changedUsers}
            columns={columns}
            createRowCellContent={createRowCellContent}
            getCellValue={getCellValue}
            onRowClick={onRowClick}
        />
    )
};

UsersTable.propTypes = {
    searchString: PropTypes.string,
    selectedId: PropTypes.string,
    onSelectRow: PropTypes.func,
    onRowClick: PropTypes.func
};

export default UsersTable;