import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useIntl } from "react-intl";

import { setInitialCurrentRole, deleteRole, clearCurrentRole } from "../../../../reducers/roleSlice";
import { setSubmit, createRole, editRole, fetchRoleDetailed } from "../../../../reducers/roleSlice";

import { DeleteDialog, ProgressDialog, SubmitDialog } from "../../../common/dialogs";
import { ADD_MODE, EDIT_MODE, getCurrentMode } from "../../../common/entity-form";
import { PageContentBox } from "../../../common/page-content-box";
import useLogs from "../../../common/hooks/useLogs";

import RoleFormPageHeader from "./role-form-page-header.jsx";
import RoleFormPageRole from "./role-form-page-role.jsx";

import { ErrorsDialog, UserLogDialog } from "../../../common/dialogs";
import { setError } from "../../../../reducers/roleSlice";

import "./style.less";


const RoleFormPage = () => {
    const { name: encodedName, system } = useParams();
    const name = encodedName ? decodeURIComponent(encodedName) : undefined;

    const dispatch = useDispatch();
    const intl = useIntl();
    const navigate = useNavigate();

    const busy = useSelector(state => state.roles.busy);
    const busyType = useSelector(state => state.roles.busyType);
    const error = useSelector(state => state.roles.error);
    const submit = useSelector(state => state.roles.submit);
    const role = useSelector(state => state.roles.currentRole);
    const roleEditable = useSelector(state => state.roles.currentRoleEditable);
    const roleDeleted = useSelector(state => state.roles.currentRoleDeleted);
    const validationErrors = useSelector(state => state.roles.validationErrors);

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [form, setForm] = useState(null);

    const mode = getCurrentMode(!name, roleEditable);

    const { logs, getLogs, openLogs, setOpenLogs } = useLogs("critical_role", `${system},${name}`)

    const [formErrors, setFormErrors] = useState(null)

    useEffect(() => {
        if (!validationErrors) return;

        const formErrosTmp = validationErrors.reduce((accumulator, error) => {
            accumulator[error.field] = error;

            return accumulator
        }, {});

        setFormErrors(formErrosTmp)

    }, [validationErrors, form])


    useEffect(() => {
        if (name) {
            dispatch(fetchRoleDetailed({ name, system }));
        } else {
            dispatch(setInitialCurrentRole());
        }

        return (() => {
            dispatch(clearCurrentRole());
        });
    }, [name, system, dispatch]);

    useEffect(() => {
        if (role) {
            setForm({
                name: role?.name || "",
                description: role?.description || "",
                active: role?.active === undefined ? true : role?.active,
                system: role?.system || null,
                riskLevel: role?.riskLevel || null,
                matrices: role?.matrices || []
            });
        }
    }, [role, setForm]);

    useEffect(() => {
        if (!submit) return;

        dispatch(setSubmit(false));

        if (mode === ADD_MODE || roleDeleted) {
            handleBackClick();
        }
    }, [submit, mode, roleDeleted, dispatch, handleBackClick]);

    const handleSaveRole = () => {
        const formRole = {
            name: form.name?.trim(),
            description: form.description,
            active: form.active,
            system: form.system,
            riskLevel: form.riskLevel,
            matrices: form.matrices
        };

        if (formRole.name !== form.name) {
            setForm({
                ...form,
                name: formRole.name
            });
        }

        if (mode === EDIT_MODE) {
            dispatch(editRole({ role: formRole }));
        } else {
            dispatch(createRole({ role: formRole }));
        }
    };

    const backPath = useMemo(() => (mode === ADD_MODE
        ? ".."
        : "../../.."
    ), [mode]);

    const handleBackClick = useCallback(() => {
        navigate(backPath, { relative: "path" });
    }, [navigate, backPath]);

    const handleDeleteClick = () => {
        setShowDeleteDialog(true);
    };

    const handleDeleteCancelClick = () => {
        setShowDeleteDialog(false);
    };

    const handleDeleteConfirmClick = () => {
        dispatch(deleteRole(role));
    };

    const handleEditClick = () => {
        dispatch(fetchRoleDetailed({ name, system }));
    };

    const handleSubmitSaveRole = () => {
        const formRole = {
            name: form.name,
            description: form.description,
            active: form.active,
            system: form.system,
            riskLevel: form.riskLevel,
            matrices: form.matrices
        };

        if (mode === EDIT_MODE) {
            dispatch(editRole({ role: formRole, warningCheck: false }));
        } else {
            dispatch(createRole({ role: formRole, warningCheck: false }));
        }
    }

    if (!role && error) return <Navigate to={backPath} relative="path" />;

    return (
        <>
            {form && (
                <PageContentBox className="role-form-page-role">
                    <RoleFormPageHeader
                        mode={mode}
                        name={name}
                        onBackClick={handleBackClick}
                        onDeleteClick={handleDeleteClick}
                        onSaveClick={handleSaveRole}
                        onEditClick={handleEditClick}
                        onShowLogsClick={getLogs}
                    />

                    <RoleFormPageRole
                        form={form}
                        setForm={setForm}
                        mode={mode}
                        validationErrors={formErrors}
                    />
                </PageContentBox>
            )}

            <DeleteDialog
                open={showDeleteDialog}
                title={intl.formatMessage({ id: "role-form-page.delete-dialog.title" })}
                text={intl.formatMessage(
                    { id: "role-form-page.delete-role.text" },
                    { name: role?.name || "" }
                )}
                onCancelClick={handleDeleteCancelClick}
                onDeleteClick={handleDeleteConfirmClick}
            />

            <ProgressDialog
                open={busy}
                busyType={busyType}
            />

            <SubmitDialog
                open={error && error.type === "warning" && error.code === "no_integr_objects"}
                title={error && error.title}
                message={error && error.detail}
                onCancelClick={() => { dispatch(setError(null)) }}
                buttons={[{
                    text: intl.formatMessage({ id: "common.submit-dialog.btn-close" }),
                    onClick: () => { dispatch(setError(null)) }
                }, {
                    text: intl.formatMessage({ id: "common.submit-dialog.btn-apply" }),
                    type: "apply",
                    onClick: () => {
                        handleSubmitSaveRole();
                        dispatch(setError(null))
                    }
                }]}
            />


            <ErrorsDialog
                error={error}
                open={error?.type === "error" || error?.type === "warning"}
                onClose={() => { dispatch(setError(null)) }}
            />

            <UserLogDialog
                open={openLogs}
                logs={logs}
                onClose={() => setOpenLogs(false)}
            />
        </>
    );
};

export default RoleFormPage;
