import React, { useMemo } from "react";
import PropTypes from 'prop-types';

import { REPORT_TYPES } from "../../../utils/sodReports.js";
import useFilters from "../../common/hooks/useFilters.js";

import CommonTable from "../../common/table";
import { getCellClassNameInner } from "../../common/table";

import "./style.less";

const getCellClassName = (row, column, selected) => {
    let className = getCellClassNameInner(row, column, selected);

    if (row["err_status"] === "in_sap"){
        className += " table-cell-in-sap";
    }

    if (row["err_status"] === "in_sod"){
        className += " table-cell-in-sod";
    }

    return className;
};

const ReportResultsTable = ({
    reportType, setReportType,
    rows, columns,
    total, selectedIds,
    searchString, setSearchString,
    pageNumber, setPageNumber,
    limitNumber, setLimitNumber,
    filterData, setFilterData,
    sortData, setSortData, reportLevel, filterId,
    conflictLevel
}) => {
    const toolbarActions = useMemo(() => [
        {
            id: "select",
            type: "select",
            position: "end",
            options: [
                { value: REPORT_TYPES.FINAL, title: "Итоговый отчет" },
                { value: REPORT_TYPES.DETAILED, title: "Подробная информация" },
            ],
            value: reportType,
            callback: setReportType,
        },
    ], [reportType, setReportType]);

    const getFilters = useFilters(filterId, {report_level: reportLevel, report_type: reportType, conflict_level: conflictLevel});

    return (
        <CommonTable
            className="report-results-table"
            toolbarActions={toolbarActions}
            rows={rows}
            columns={columns}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            limitNumber={limitNumber}
            setLimitNumber={setLimitNumber}
            total={total}
            showFiltersActions={false}
            searchString={searchString}
            setSearchString={setSearchString}
            filterData={filterData}
            setFilterData={setFilterData}
            sortData={sortData}
            setSortData={setSortData}
            selectedIds={selectedIds}
            getFilters={getFilters}
            getCellClassName={getCellClassName}
            handleFilterBeforeApply={(filter) => {
                if (filter.field === "risk_level_text"){
                    return {
                        ...filter,
                        field: "risk_level_id"
                    }
                }

                return filter
            }}
        />
    );
};

ReportResultsTable.propTypes = {
    reportType: PropTypes.string,
    setReportType: PropTypes.func,
    rows: PropTypes.arrayOf(PropTypes.object),
    columns: PropTypes.arrayOf(PropTypes.object),
    total: PropTypes.number,
    selectedIds: PropTypes.arrayOf(PropTypes.number),
    searchString: PropTypes.string,
    setSearchString: PropTypes.func,
    pageNumber: PropTypes.number,
    limitNumber: PropTypes.number,
    setPageNumber: PropTypes.func,
    setLimitNumber: PropTypes.func,
    filterData: PropTypes.arrayOf(PropTypes.object),
    setFilterData: PropTypes.func,
    sortData: PropTypes.arrayOf(PropTypes.object),
    setSortData: PropTypes.func,
    reportLevel: PropTypes.string,
    filterId: PropTypes.string,
    conflictLevel: PropTypes.string
};

export default ReportResultsTable;
