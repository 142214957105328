import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReportResultsTabs from "../report-results/report-results-tabs.jsx";
import PropTypes from 'prop-types';

import {
    setDetailedSelectedIds as setDetailedSelectedIdsAction,
    setDetailedSearchString as setDetailedSearchStringAction,
    setDetailedPageNumber as setDetailedPageNumberAction,
    setDetailedLimitNumber as setDetailedLimitNumberAction,
    setDetailedSortData as setDetailedSortDataAction,
    setDetailedFilterData as setDetailedFilterDataAction,
    setError as setErrorAction,
    setCurrentEmployee,
    fetchDetailedReportColumns,
    fetchDetailedReportResults,
    setReportLevelWithColumns,
    setReportTypeWithColumns,
    getSummaryReportRowId,
    selectSelectedSummaryReportRows,
    setSelectedIds,
    ALL_USER_KEY
} from "../../../reducers/reports/userBusynessLevelSlice";

import useReportBusynessDetailedReduxTable from "../../common/hooks/useReportBusynessDetailedReduxTable";

import CommonTable from "../../common/table/table.jsx";
import { getCellClassNameInner } from "../../common/table/table-components.jsx";
import useFilters from "../../common/hooks/useFilters.js";
import { TableToolbar } from "../../common/table/action-toolbar.jsx";
import { useParams } from "react-router-dom";
import { REPORT_LEVELS, REPORT_TYPES } from "../../../utils/sodReports.js";
import { useIntl } from "react-intl";
import SelectIcon from "../../common/icons/SelectIcon.jsx";
import Button from "../../common/buttons/button.jsx";
import TagsListDetailed from "../../common/form-controls/tag-list-detailed.jsx";


const getCellClassName = (row, column, selected) => {
    let className = getCellClassNameInner(row, column, selected);

    if (row["err_status"] === "in_sap") {
        className += " table-cell-in-sap";
    }

    if (row["err_status"] === "in_sod") {
        className += " table-cell-in-sod";
    }

    return className;
};

const UserLevelBusynessReportDetailedResults = ({ disabled, onBackClick }) => {
    const intl = useIntl();
    const dispatch = useDispatch()

    const { rowsForCalc: rowsForCalcRaw } = useParams()
    const [rowsForCalc, setRowsForCalc] = useState([])

    useEffect(() => {
        if (!rowsForCalcRaw) {
            return
        }

        const rows = JSON.parse(rowsForCalcRaw)

        setRowsForCalc(rows)

        dispatch(setCurrentEmployee(ALL_USER_KEY))
    }, [rowsForCalcRaw, dispatch])

    const tabs = useMemo(() => [
        {
            id: REPORT_LEVELS.OPERATIONS,
            label: intl.formatMessage({ id: "report-results.tabs.operations-level" })
        },
        {
            id: REPORT_LEVELS.PERMISSIONS,
            label: intl.formatMessage({ id: "report-results.tabs.permissions-level" })
        },
        {
            id: REPORT_LEVELS.CRITICAL_OPERATIONS,
            label: intl.formatMessage({ id: "report-results.tabs.critical-operations-level" })
        },
        {
            id: REPORT_LEVELS.CRITICAL_PERMISSIONS,
            label: intl.formatMessage({ id: "report-results.tabs.critical-permissions-level" })
        },
        {
            id: REPORT_LEVELS.CRITICAL_OBJECTS,
            label: intl.formatMessage({ id: "report-results.tabs.critical-objects-level" })
        }
    ], [intl]);

    const {
        rows, columns,
        total,
        selectedIds,
        searchString, setSearchString,
        pageNumber, setPageNumber,
        limitNumber, setLimitNumber,
        filterData, setFilterData,
        sortData, setSortData,
        reportLevel, setReportLevel,
        reportType, setReportType,
    } = useReportBusynessDetailedReduxTable(
        (state) => state.reports.userBusynessLevel.detailed.rows, fetchDetailedReportResults,
        (state) => state.reports.userBusynessLevel.detailed.columns, fetchDetailedReportColumns,
        (state) => state.reports.userBusynessLevel.detailed.selectedIds, setDetailedSelectedIdsAction,
        (state) => state.reports.userBusynessLevel.detailed.searchString, setDetailedSearchStringAction,
        (state) => state.reports.userBusynessLevel.detailed.pageNumber, setDetailedPageNumberAction,
        (state) => state.reports.userBusynessLevel.detailed.limitNumber, setDetailedLimitNumberAction,
        (state) => state.reports.userBusynessLevel.detailed.sortData, setDetailedSortDataAction,
        (state) => state.reports.userBusynessLevel.detailed.filterData, setDetailedFilterDataAction,
        (state) => state.reports.userBusynessLevel.error, setErrorAction,
        (state) => state.reports.userBusynessLevel.detailed.total,
        (state) => state.reports.userBusynessLevel.busy,
        (state) => state.reports.userBusynessLevel.detailed.reportLevel, setReportLevelWithColumns,
        (state) => state.reports.userBusynessLevel.detailed.reportType, setReportTypeWithColumns,
    );

    //const tst = useSelector((state) => state.reports.userBusynessLevel.detailed.rows)

    //console.log(rows)


    const calcDateStr = useSelector(state => state.reports.userBusynessLevel.calcDate);
    const currentEmployee = useSelector(state => state.reports.userBusynessLevel.detailed.currentEmployee)

    const filterParams = {
        calcDate: calcDateStr,
        report_level: reportLevel,
        report_type: reportType,
    }

    if (currentEmployee !== ALL_USER_KEY) {
        filterParams["employee"] = currentEmployee
    }

    const getFilters = useFilters("user-busyness-detailed-report", filterParams);


    const toolbarActions = useMemo(() => [
        {
            id: "select",
            type: "select",
            position: "end",
            options: [
                { value: REPORT_TYPES.FINAL, title: "Итоговый отчет" },
                { value: REPORT_TYPES.DETAILED, title: "Подробная информация" },
            ],
            value: reportType,
            callback: setReportType,
        },
    ], [reportType, setReportType]);

    const backBtnTitle = intl.formatMessage({ id: "summary-report.detailed.button.back.title" })

    const [employeeTags, setEmployeeTags] = useState([])

    useEffect(() => {
        const result = []
        const handledEmployees = new Set()

        result.push({
            key: ALL_USER_KEY,
            text: intl.formatMessage({ id: "summary-report.detailed.tags.all-tag.text" })
        })

        rowsForCalc.forEach(row => {
            const employee = row.employee

            if (handledEmployees.has(employee)) {
                return
            }

            result.push({
                key: employee,
                text: employee
            })

            handledEmployees.add(employee)
        })

        setEmployeeTags(result)

    }, [rowsForCalc, intl])

    const onClickEmployeeReport = useCallback((employee) => {
        dispatch(setCurrentEmployee(employee))
        dispatch(fetchDetailedReportResults({
            params: {
                page: 1,
                limit: limitNumber
            }
        }))

    }, [dispatch, limitNumber])

    const summaryRows = useSelector(selectSelectedSummaryReportRows)

    const onDeleteEmployeeTab = useCallback((employee) => {
        if (employee === ALL_USER_KEY) {
            dispatch(setSelectedIds([]))
            onBackClick()
            return

        }

        const newEmployees = employeeTags.filter(item => item.key !== employee)

        const newSelectedIds = summaryRows.filter(row => row.employee_id !== employee).map(row => getSummaryReportRowId(row))
        dispatch(setSelectedIds(newSelectedIds))

        if (newEmployees.length === 1) {
            onBackClick()
            return
        }

        if (employee === currentEmployee && newEmployees.length > 0) {
            const newCurrentEmployee = newEmployees[newEmployees.length - 1]
            dispatch(setCurrentEmployee(newCurrentEmployee.key))
        }

        setEmployeeTags(newEmployees)
    }, [dispatch, currentEmployee, employeeTags, onBackClick, summaryRows])

    return (
        <>
            <TableToolbar className="user-busyness-report-toolbar">
                <Button
                    onClick={onBackClick}
                    disabled={disabled}
                    variant="outlined"
                    sx={{
                        marginRight: "auto",
                        width: "250px",
                        border: "none",
                        color: '#363636',
                        borderColor: '#363636',
                        padding: "7px 16px 0px !important",
                        whiteSpace: "nowrap",
                        "&:hover": {
                            border: "none",
                            textDecoration: "underline"
                        }

                    }}
                    startIcon={<SelectIcon style={{ transform: "rotate(90deg)" }} />}
                >
                    {backBtnTitle}
                </Button>


                <TagsListDetailed
                    tags={employeeTags}
                    onClick={onClickEmployeeReport}
                    onDelete={onDeleteEmployeeTab}
                    getTagText={tag => tag.text}
                    selectedTagKey={currentEmployee} />


            </TableToolbar>

            <ReportResultsTabs
                tabs={tabs}
                activeTabId={reportLevel}
                onActiveTabIdChange={setReportLevel}
            />

            <CommonTable
                className="report-results-table"
                toolbarActions={toolbarActions}
                rows={rows}
                columns={columns}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                limitNumber={limitNumber}
                setLimitNumber={setLimitNumber}
                total={total}
                showFiltersActions={false}
                searchString={searchString}
                setSearchString={setSearchString}
                filterData={filterData}
                setFilterData={setFilterData}
                sortData={sortData}
                setSortData={setSortData}
                selectedIds={selectedIds}
                getFilters={getFilters}
                getCellClassName={getCellClassName}
                handleFilterBeforeApply={(filter) => {
                    if (filter.field === "risk_level_text") {
                        return {
                            ...filter,
                            field: "risk_level_id"
                        }
                    }

                    return filter
                }}
            />
        </>

    );
};

UserLevelBusynessReportDetailedResults.propTypes = {
    disabled: PropTypes.bool,
    onBackClick: PropTypes.func
}

export default UserLevelBusynessReportDetailedResults;
