import React, { useCallback, useEffect, useRef, useState } from "react";
import { Checkbox } from "@mui/material";
import PropTypes from "prop-types";

import { SearchIcon } from "../icons";
import { Input } from "../form-controls";
import { Menu, MenuItem } from "../menu";

import "./styles.less";
import useScrollPagination from "../hooks/useScrollPagination";


//getSearchHelpData must be memoized!!

const defaultGetItemKey = (item) => item.key;
const defaultGetItemText = (item) => item.text;

const DropdownSearchInput = ({
    selectedKeys,
    onSelectKey,
    getItemKey=defaultGetItemKey,
    getItemText=defaultGetItemText,
    dataKey="rows",
    getData,
    error=false,
    errorMessage,
    warning=false,
    warningMessage,
    showInnerHelpers=false,
    visibleRowCount=6,
    delay=500,
    icon=<SearchIcon />,
    ...props
}) => {
    const inputRef = useRef();

    const [value, setValue] = useState("")
    const [valueInner, setValueInner] = useState("")
    const [open, setOpen] = useState(false);

    const getDataInner = useCallback(async (page, signal) => {
        const { [dataKey]: rows, total } = await getData({
            search: value,
            page: page,
            limit: visibleRowCount + 1,
        }, signal);

        return { rows, total };
    }, [getData, value, visibleRowCount, dataKey]);

    const { items, handleScroll } = useScrollPagination({
        open: open,
        getData: getDataInner
    });

    const keySet = new Set(selectedKeys);

    // set timeout for inner value for search delay
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setValue(valueInner)
        }, delay)

        return () => {
            clearTimeout(timeoutId)
        }
    }, [valueInner, delay])

    const listItemHeight = 30;
    const listMaxHeight = listItemHeight * visibleRowCount;

    return (
        <>
            <Input
                ref={inputRef}
                error={error}
                errorMessage={errorMessage}
                warning={warning}
                warningMessage={warningMessage}
                showInnerHelpers={showInnerHelpers}
                onChange={(e) => setValueInner(e.target.value)}
                onFocus={() => setOpen(true)}
                endAdornment={icon}
                {...props}
            />

            <Menu
                open={open}
                anchorEl={inputRef.current}
                onClose={() => setOpen(false)}
                disableRestoreFocus
                autoFocus={false}
                disableAutoFocus
                disableAutoFocusItem
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                MenuListProps={{
                    onScroll: handleScroll,
                    sx: {
                        width: inputRef.current?.offsetWidth,
                        maxHeight: `${listMaxHeight}px`,
                        minHeight: `${listItemHeight}px`
                    }
                }}
            >
                {items?.map((item) => {
                    const key = getItemKey(item);
                    const text = getItemText(item);
                    const checked = keySet.has(key);

                    return (
                        <DropdownItem
                            key={key}
                            checked={checked}
                            text={text}
                            onClick={() => onSelectKey(key, !checked, item)}
                            listItemHeight={listItemHeight}
                        />
                    )
                })}
            </Menu>
        </>
    );
}

const DropdownItem = ({
    checked,
    text,
    listItemHeight,
    onClick
}) => {
    const handleMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();
        onClick();
    };

    return (
        <MenuItem
            className="dropdown-search-input-menu-item"
            onMouseDown={handleMouseDown}
            sx={{
                height: `${listItemHeight}px`
            }}
        >
            <Checkbox
                className="dropdown-search-input-menu-item-checkbox"
                checked={checked}
            />

            <span className="dropdown-search-input-menu-item-text">
                {text}
            </span>
        </MenuItem>
    );
};

DropdownSearchInput.propTypes = {
    selectedKeys: PropTypes.array,
    onSelectKey: PropTypes.func,
    getItemKey: PropTypes.func,
    getItemText: PropTypes.func,
    dataKey: PropTypes.string,
    getData: PropTypes.func,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    warning: PropTypes.bool,
    warningMessage: PropTypes.string,
    showInnerHelpers: PropTypes.bool,
    visibleRowCount: PropTypes.number,
    delay: PropTypes.number,
    icon: PropTypes.node
};

DropdownItem.propTypes = {
    checked: PropTypes.bool,
    text: PropTypes.string,
    listItemHeight: PropTypes.number,
    onClick: PropTypes.func
};

export default DropdownSearchInput;
