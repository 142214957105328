import React from "react";
import PropTypes from "prop-types";

import { EDIT_MODE, EntityFormActions } from "../../../common/entity-form";
import { ChangePasswordButton } from "../../../common/buttons";

const UserFormActions = ({
    mode,
    onDeleteClick,
    onSaveClick,
    onEditClick,
    onShowLogsClick,
    onChangePasswordClick
}) => {
    return (
        <EntityFormActions
            mode={mode}
            onDeleteClick={onDeleteClick}
            onSaveClick={onSaveClick}
            onEditClick={onEditClick}
            onShowLogsClick={onShowLogsClick}
            customActions={mode === EDIT_MODE && (
                <ChangePasswordButton
                    onClick={onChangePasswordClick}
                />
            )}
        />
    );
};

UserFormActions.propTypes = {
    mode: PropTypes.string,
    onDeleteClick: PropTypes.func,
    onSaveClick: PropTypes.func,
    onEditClick: PropTypes.func,
    onShowLogsClick: PropTypes.func,
    onChangePasswordClick: PropTypes.func
};

export default UserFormActions;
