import React, { useRef } from "react";
import { Radio } from "@mui/material";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { selectModelingRolesBySearch } from "../../../../reducers/reports-modeling/roleLevelSlice";

import { createRowCellContentInner, getCellClassNameInner } from "../../../common/table";

import { EntitiesSelectTable } from "../../entities-select-dialog";

import "./styles.less";

export const getEntityCellClassName = (row, column, selected) => {
    let className = getCellClassNameInner(row, column, selected);

    const status = row.changed;

    if (status) {
        className += ` entities-select-table-cell-changed`;
    }

    return className;
};

const RolesTable = ({
    searchString,
    selectedId,
    onSelectRow,
    onRowClick
}) => {
    const roles = useSelector((state) => selectModelingRolesBySearch(state, searchString));
    const intl = useIntl();

    const columns = [
        {
            id: "radio",
            type: "radio"
        },
        {
            id: "role",
            title: intl.formatMessage({ id: "role-level-modeling-role-search-help.dialog.columns.role" })
        },
        {
            id: "systemId",
            title: intl.formatMessage({ id: "role-level-modeling-role-search-help.dialog.columns.system-id" })
        },
        {
            id: "description",
            title: intl.formatMessage({ id: "role-level-modeling-role-search-help.dialog.columns.description" })
        },
    ];

    const createRowCellContent = (row, column, selected, onSelect, getCellValue) => {
        switch (column.type) {
            case "radio": {
                return (
                    <Radio
                        checked={row.id === selectedId}
                        value={row.id}
                        onChange={onSelectRow}
                    />
                );
            }

            default:
                return createRowCellContentInner(row, column, selected, onSelect, getCellValue);
        }
    };

    const tableRef = useRef();

    return (
        <EntitiesSelectTable
            containerRef={tableRef}
            rows={roles}
            columns={columns}
            createRowCellContent={createRowCellContent}
            onRowClick={onRowClick}
            getCellClassName={getEntityCellClassName}
        />
    )
};

RolesTable.propTypes = {
    searchString: PropTypes.string,
    selectedId: PropTypes.string,
    onSelectRow: PropTypes.func,
    onRowClick: PropTypes.func
};

export default RolesTable;