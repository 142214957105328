import React from "react";
import { IconButton } from "@mui/material";
import PropTypes from "prop-types";

import { createRowCellContentInner, getCellClassNameInner, TableRowCellText } from "../../common/table";
import TreeTable from "../../common/tree-table";
import { Checkbox } from "../../common/buttons";
import { RightScrollButtonIcon } from "../../common/icons";

import "./styles.less";

const columns = [
    {
        id: "node",
        type: "node",
    },
    {
        id: "checked",
        type: "checkbox",
    },
    {
        id: "reportName",
        type: "text"
    }
];

const getCellClassName = (row, column, selected) => {
    let className = getCellClassNameInner(row, column, selected);

    className += " copy-menu-table-cell";

    if (row.leaf && column.id === "node") {
        className += " copy-menu-table-cell-leaf"
    }

    return className;
};

const nodeStep = 16;

const CopyMenuTable = ({ tree, onItemFieldChange }) => {
    const handleOpenRowChange = (row) => onItemFieldChange(row.path, "open", !row.open);

    const createRowCellContent = (row, column, selected, onSelect, getCellValue) => {
        switch (column.type) {
            case "node": {
                if (row.leaf) return;

                const marginLeftNumber = row.level * nodeStep;
                let className = "copy-menu-table-open-node-button";

                if (row.open) {
                    className += " copy-menu-table-open-node-button-open";
                }

                return (
                    <IconButton
                        className={className}
                        sx={{ marginLeft: `${marginLeftNumber}px` }}
                    >
                        <RightScrollButtonIcon />
                    </IconButton>
                );
            }

            case "checkbox": {
                return (
                    <>
                        <Checkbox
                            checked={row.checked}
                            indeterminate={row.indeterminate}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => onItemFieldChange(row.path, "checked", e.target.checked)}
                        />

                        <TableRowCellText>
                            {row.nodeText}
                        </TableRowCellText>
                    </>
                    
                )
            }

            default:
                return createRowCellContentInner(row, column, selected, onSelect, getCellValue);
        }
    };

    return (
        <TreeTable
            className="copy-menu-table"
            tree={tree}
            columns={columns}
            showFiltersActions={false}
            showPagination={false}
            getCellClassName={getCellClassName}
            createRowCellContent={createRowCellContent}
            onRowClick={handleOpenRowChange}
        />
    );
};

CopyMenuTable.propTypes = {
    tree: PropTypes.arrayOf(PropTypes.object),
    onItemFieldChange: PropTypes.func
};

export default CopyMenuTable;