import React from "react";
import { SvgIcon } from "@mui/material";

const SmallPlusIcon = (props) => (
    <SvgIcon
        viewBox="0 0 8 8"
        {...props}
    >
        <svg
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.07692 0.923077C3.07692 0.413275 3.4902 0 4 0C4.5098 0 4.92308 0.413276 4.92308 0.923077V7.07692C4.92308 7.58672 4.5098 8 4 8C3.4902 8 3.07692 7.58672 3.07692 7.07692V0.923077Z"
                fill="currentColor"
            />
            <path
                d="M7.07692 3.07692C7.58672 3.07692 8 3.4902 8 4C8 4.5098 7.58672 4.92308 7.07692 4.92308H0.923077C0.413276 4.92308 1.80649e-08 4.5098 4.03491e-08 4C6.26333e-08 3.4902 0.413276 3.07692 0.923077 3.07692L7.07692 3.07692Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

export default SmallPlusIcon;
