import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
    calcReport,
    deleteVariant,
    fetchVariantsByUser,
    saveVariant,
    setFavoritesForVariant,
    setError as setErrorAction,
    setVariantWithFetchModeling,
    clearFilters,
    saveReportColumns
} from "../../../reducers/reports-modeling/roleLevelSlice";

import { DIALOG_TYPES } from "../../../utils/sodReports";

import { reportsModelingService } from "../../../services/reports-modeling-service";

import { ProgressDialog, ErrorsDialog } from "../../common/dialogs";
import useReduxValue from "../../common/hooks/useReduxValue";

import {
    FavoritesDialog,
    SaveVariantDialog,
    DeleteVariantDialog,
    VariantsListBox,
    VariantsListDialog
} from "../../sod-reports/report-variants";

import ReportColumnsDialog from "../../sod-reports/report-columns-dialog";

import { ReportHeader } from "../../sod-reports/report-header";

import ReportsModelingActions from "../reports-modeling-actions";

import RoleLevelModelingBox from "./role-level-modeling-box.jsx";
import RoleLevelModelingParameters from "./role-level-modeling-parameters.jsx";
import RoleLevelModelingResults from "./role-level-modeling-results.jsx";

const getColumns = (reportLevel, reportType, defaultColumns) => reportsModelingService.getRoleLevelModelingColumns(reportLevel, reportType, defaultColumns);

const RoleLevelModelingPage = () => {
    const { variantName: encodedVariantName } = useParams();
    const variantName = encodedVariantName ? decodeURIComponent(encodedVariantName) : undefined;

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [currentVariant, setCurrentVariant] = useReduxValue(state => state.reportsModeling.roleLevel.currentVariant, setVariantWithFetchModeling);
    const [error, setError] = useReduxValue(state => state.reportsModeling.roleLevel.error, setErrorAction);

    const [variantDialogType, setVariantDialogType] = useState(null);
    const [dialogVariant, setDialogVariant] = useState(null);

    const busy = useSelector(state => state.reportsModeling.roleLevel.busy);
    const busyType = useSelector(state => state.reportsModeling.roleLevel.busyType);
    const variantCreated = useSelector(state => state.reportsModeling.roleLevel.variantCreated);
    const variants = useSelector(state => state.reportsModeling.roleLevel.variants);
    const parametersOpen = useSelector(state => state.reportsModeling.roleLevel.parametersOpen);
    const validationErrors = useSelector(state => state.reportsModeling.roleLevel.validationErrors);

    const systemsExtended = useSelector(state => state.reportsModeling.roleLevel.extendedSystems)

    const roles = useSelector(state => state.reportsModeling.roleLevel.roles)

    const isAnalyzeDisabled = !(roles?.length && systemsExtended.length === 1)

    useEffect(() => {
        dispatch(fetchVariantsByUser());

        return(() => {
            dispatch(clearFilters());
        });
    }, [dispatch]);

    useEffect(() => {
        if (!currentVariant && !variantName) {
            return;
        }

        const newCurrentVariant = variants.find(
            variant => variant.variantName === variantName
        );

        if (!newCurrentVariant) {
            setCurrentVariant(null);
            navigate("/reports-modeling/role-level");
            return;
        }

        setCurrentVariant(newCurrentVariant);
    }, [variantName, variants, currentVariant, setCurrentVariant, navigate]);

    useEffect(() => {
        if (variantCreated) {
            hideVariantDialog();
        }
    }, [variantCreated]);

    const hideVariantDialog = () => {
        setDialogVariant(null);
        setVariantDialogType(null);
    };

    // Variants list dialog
    const handleShowVariantsClick = () => {
        setVariantDialogType(DIALOG_TYPES.VARIANTS);
    };

    // Delete dialog
    const handleDeleteDialogConfirmClick = () => {
        dispatch(deleteVariant(dialogVariant.variantName))
        hideVariantDialog();
    };

    const handleDeleteClick = (variant) => {
        setVariantDialogType(DIALOG_TYPES.DELETE);
        setDialogVariant(variant);
    };

    // Favorites dialog
    const handleFavoritesDialogConfirmClick = () => {
        dispatch(setFavoritesForVariant({
            variantName: dialogVariant.variantName,
            inFavorites: !dialogVariant.inFavorites
        }));

        hideVariantDialog();
    };

    const handleFavoriteClick = (variant) => {
        setVariantDialogType(DIALOG_TYPES.FAVORITES);
        setDialogVariant(variant);
    };

    // Save dialog
    const handleSaveDialogConfirmClick = async (variantName) => {
        await dispatch(saveVariant(variantName)).unwrap();
        navigate(`/reports-modeling/role-level/${encodeURIComponent(variantName)}`);
    };

    const handleSaveClick = () => {
        setVariantDialogType(DIALOG_TYPES.SAVE);
    };

    // Columns settings dialog
    const handleColumnSettingsClick = () => {
        setVariantDialogType(DIALOG_TYPES.COLUMN_SETTINGS);
    };

    const handleSubmitClick = () => {
        dispatch(calcReport())
    };

    const handleSearchVariant = useCallback((event) => {
        dispatch(fetchVariantsByUser(event.target.value));
    }, [dispatch]);

    const handleVariantClick = (variant) => {
        if (variant.variantName === variantName) {
            setCurrentVariant(variant);
        } else {
            const encodedVariant = encodeURIComponent(variant.variantName);

            if (variantName) {
                navigate(`../${encodedVariant}`, { relative: "path" });
            } else {
                navigate(encodedVariant);
            }
        }

        hideVariantDialog();
    };

    const handleSaveColumns = (columns, reportLevel, reportType) => {
        dispatch(saveReportColumns({
            columns, reportLevel, reportType
        }));
    };

    const variantsListDialogOpen = variantDialogType === DIALOG_TYPES.VARIANTS;
    const saveDialogOpen = variantDialogType === DIALOG_TYPES.SAVE;
    const deleteDialogOpen = dialogVariant && variantDialogType === DIALOG_TYPES.DELETE;
    const favoritesDialogOpen = dialogVariant && variantDialogType === DIALOG_TYPES.FAVORITES;
    const columnSettingsDialogOpen = variantDialogType === DIALOG_TYPES.COLUMN_SETTINGS;

    return (
        <>
            <ReportHeader
                parameters={
                    <RoleLevelModelingParameters
                        onShowVariantsClick={handleShowVariantsClick}
                    />
                }
                variants={
                    <VariantsListBox
                        variants={variants}
                        onDeleteClick={handleDeleteClick}
                        onFavoriteClick={handleFavoriteClick}
                        onItemClick={handleVariantClick}
                        onSearch={handleSearchVariant}
                    />
                }
                parametersOpen={parametersOpen}
            />

            <RoleLevelModelingBox />

            <ReportsModelingActions
                onSubmitClick={handleSubmitClick}
                onSaveClick={handleSaveClick}
                onColumnSettingsClick={handleColumnSettingsClick}
                isAnalyzeDisabled={isAnalyzeDisabled}
            />

            <RoleLevelModelingResults />

            {saveDialogOpen && (
                <SaveVariantDialog
                    defaultName={currentVariant?.variantName}
                    open={saveDialogOpen}
                    validationErrors={validationErrors}
                    onSaveClick={handleSaveDialogConfirmClick}
                    onCancelClick={hideVariantDialog}
                />
            )}

            {deleteDialogOpen && (
                <DeleteVariantDialog
                    open={deleteDialogOpen}
                    variant={dialogVariant}
                    onDeleteClick={handleDeleteDialogConfirmClick}
                    onCancelClick={hideVariantDialog}
                />
            )}

            {favoritesDialogOpen && (
                <FavoritesDialog
                    open={favoritesDialogOpen}
                    variant={dialogVariant}
                    onFavoritesClick={handleFavoritesDialogConfirmClick}
                    onCancelClick={hideVariantDialog}
                />
            )}

            {variantsListDialogOpen && (
                <VariantsListDialog
                    open={variantsListDialogOpen}
                    variants={variants}
                    onDeleteClick={handleDeleteClick}
                    onFavoriteClick={handleFavoriteClick}
                    onItemClick={handleVariantClick}
                    onSearch={handleSearchVariant}
                    onClose={hideVariantDialog}
                />
            )}

            {columnSettingsDialogOpen && (
                <ReportColumnsDialog
                    open={columnSettingsDialogOpen}
                    onClose={hideVariantDialog}
                    getColumns={getColumns}
                    saveColumns={handleSaveColumns}
                />
            )}

            <ProgressDialog
                open={busy}
                busyType={busyType}
            />

            <ErrorsDialog
                error={error}
                open={error?.type === "error"}
                onClose={() => setError(null)}
            />
        </>
    );
};

export default RoleLevelModelingPage;