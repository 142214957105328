import React from 'react'
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { Menu, MenuItem } from "../menu";

import useUserRoles from '../hooks/useUserRoles';

const UserRolesPopover = ({ anchorEl, open, currentFilters, onClose, onSelect }) => {
    const intl = useIntl();

    const { userRoles, userRolesText } = useUserRoles();

    const items = [
        {
            id: "all",
            text: intl.formatMessage({ id: "common.user-roles.all" }),
            value: ""
        }
    ];

    userRoles.forEach(userRole => {
        items.push({
            id: userRole.id,
            text: userRolesText[userRole.name],
            value: userRole.name
        })
    });

    const selectedValue = currentFilters?.[0]?.value || "";

    return (
        <Menu
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
        >
            {items.map(item => (
                <MenuItem
                    onClick={() => onSelect(item.value)}
                    key={item.id}
                    selected={item.value === selectedValue}
                >
                    {item.text}
                </MenuItem>
            ))}
        </Menu>
    );
};


UserRolesPopover.propTypes = {
    anchorEl: PropTypes.any, 
    open: PropTypes.bool, 
    currentFilters: PropTypes.arrayOf(PropTypes.object), 
    onClose: PropTypes.func, 
    onSelect: PropTypes.func
}

export default UserRolesPopover;
