import {configureStore} from "@reduxjs/toolkit"
import reducer from "./reducers"


const store = configureStore({
    reducer: reducer,
    //middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
})

export default store
