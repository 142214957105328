import React from "react";
import PropTypes from "prop-types";

import CommonTable from "../../common/table";

import "./styles.less";

const columns = [
    {
        id: "select",
        type: "select",
    },
    {
        id: "role",
        title: "Название роли",
        type: "text"
    },
    {
        id: "systemId",
        title: "Система",
        type: "text"
    },
    {
        id: "roleType",
        title: "Тип роли",
        type: "text"
    },
    {
        id: "description",
        title: "Описание",
        type: "text"
    }
];

const getCellValue = (row, column) => {
    switch (column.id) {
        case "roleType":
            return row.isComplex ? "Групповая роль" : "Одиночная роль";

        default:
            return row[column.id];
    }
};

const GroupRoleTable = ({
    rows,
    selectedIds,
    setSelectedIds
}) => {
    const handleSelectRow = (row, value) => {
        if (value) {
            setSelectedIds([...selectedIds, row.id]);
            return;
        }

        setSelectedIds(selectedIds.filter(id => id !== row.id));
    };

    const handleSelectAll = (value) => {
        if (!value) {
            setSelectedIds([]);
            return;
        }

        setSelectedIds(rows.map(operation => operation.id));
    };

    return (
        <CommonTable
            className="group-role-table"
            rows={rows}
            columns={columns}
            selectedIds={selectedIds}
            onSelectRow={handleSelectRow}
            onSelectAll={handleSelectAll}
            showPagination={false}
            showFiltersActions={false}
            getCellValue={getCellValue}
        />
    );
};

GroupRoleTable.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        role: PropTypes.string,
        systemId: PropTypes.string,
        isComplex: PropTypes.bool,
        description: PropTypes.string
    })),
    selectedIds: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
    setSelectedIds: PropTypes.func
};

export default GroupRoleTable;