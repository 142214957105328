import React from "react";
import { SvgIcon } from "@mui/material";

const CurvedArrowIcon = (props) => (
    <SvgIcon
        viewBox="0 0 18 18"
        {...props}
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
            fill="none"
        >
            <path
                d="M11.5 -2.84124e-07L4 -6.11959e-07L4 2L11.5 2C14 2 16 4 16 6.5C16 9 14 11 11.5 11L5.83 11L8.91 7.91L7.5 6.5L2 12L7.5 17.5L8.92 16.09L5.83 13L11.5 13C13.2239 13 14.8772 12.3152 16.0962 11.0962C17.3152 9.87721 18 8.22391 18 6.5C18 4.77609 17.3152 3.12279 16.0962 1.90381C14.8772 0.68482 13.2239 -2.0877e-07 11.5 -2.84124e-07Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

export default CurvedArrowIcon;
