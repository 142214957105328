import { FormControlLabel } from "@mui/material";
import React from "react";
import { CustomRadio } from "../buttons";

const RadioInput = (props) => {
    return(
        <FormControlLabel
            {...props}
            control={<CustomRadio color="var(--font-4)" />}
            sx={{
                margin: 0,
                alignItems: "center",
                columnGap: "5px",
    
                "& .MuiButtonBase-root.MuiRadio-root": {
                    margin: 0,
                    padding: 0,
                },

                "& .MuiFormControlLabel-label": {
                    fontFamily: 'Mulish',
                    fontWeight: 500,
                    fontSize: 18,
                    lineHeight: '150%',
                }
            }}
        />
    );
};

export default RadioInput;

