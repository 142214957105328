import React, { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { useMediaQuery } from "@mui/material";

import {
    addModelingSelectedUserRole,
    //selectModelingNewRoles,
    selectModelingSelectedUserSystemId
} from "../../../../reducers/reports-modeling/userLevelSlice";

import { CommonDialog, CommonDialogContent, CommonDialogTitle, ErrorsDialog } from "../../../common/dialogs";
import { ScrollableTabs } from "../../../common/tabs";
import breakpoints from "../../../common/styles/breakpoints";
import SearchHelpTable from "../../../common/search-helps/search-help-table.jsx";
import { SearchInput } from "../../../common/form-controls";

import PersistedRolesTable from "../../persisted-roles-table";
import {integrationService} from "../../../../services/integration-service.js";

import "./styles.less";
import useScrollPagination from "../../../common/hooks/useScrollPagination.js";

const DIALOG_TABS_IDS = {
    PERSISTED_ROLES: "persistedRoles",
    NEW_ROLES: "newRoles"
};

const dialogTabs = [
    {
        id: DIALOG_TABS_IDS.PERSISTED_ROLES,
        titleId: "add-role-dialog.tabs.persisted-roles"
    },
    {
        id: DIALOG_TABS_IDS.NEW_ROLES,
        titleId: "add-role-dialog.tabs.new-roles"
    }
];

const getRowId = row => row.role;

const NewRolesTable = ({
    columns,
    systemId,
    searchString,
    onRowClick
}) => {
    //const roles = useSelector(state => selectModelingNewRoles(state, systemId, searchString));
    const tableRef = useRef();

    const getData = useCallback(async (page, signal) => {
        const params = {
            page,
            limit: 100,
            search: searchString,
            filters: [
                { field: "systemId", sign: "EQ", value: systemId },
                { field: "manual", sign: "EQ", value: "true"}
            ]
        };

        return integrationService.getModelingRoles(params, signal);
    }, [systemId, searchString]);

    const {
        items,
        busy,
        handleScroll
    } = useScrollPagination({ open, getData });

    return (
        <SearchHelpTable
            rows={items}
            onScroll={handleScroll}
            columns={columns}
            onRowClick={onRowClick}
            selectOneRow
            listContainerRef={tableRef}
            getRowId={getRowId}
            busy={busy}
        />
    )
};

const AddRoleDialog = ({
    open,
    onClose
}) => {
    const dispatch = useDispatch();

    const systemId = useSelector(selectModelingSelectedUserSystemId);
    const [error, setError] = useState(null);
    const [tab, setTab] = useState(DIALOG_TABS_IDS.PERSISTED_ROLES);
    const [searchString, setSearchString] = useState("");
    const matchesLaptop = useMediaQuery(`(min-width: ${breakpoints.minLaptopBreakpoint})`);

    const intl = useIntl();
    const title = intl.formatMessage({ id: "add-role-dialog.title" });

    const columns = [
        {
            id: "role",
            title: intl.formatMessage({ id: "add-role-dialog.columns.key" })
        },
        {
            id: "description",
            title: intl.formatMessage({ id: "add-role-dialog.columns.text" })
        }
    ];

    const handleRowClick = async (role) => {
        try {
            await dispatch(addModelingSelectedUserRole(role)).unwrap();
            onClose();
        } catch (e) {
            const message = e.useIntl
                ? intl.formatMessage({ id: e.errorMessageId })
                : e.message;
    
            setError({ detail: message });
        }
    };

    const errorDialogOpen = Boolean(error);

    return (
        <>
            <CommonDialog
                className="add-role-dialog"
                open={open}
            >
                <CommonDialogTitle
                    title={title}
                    onClose={onClose}
                />

                <CommonDialogContent>
                    <ScrollableTabs
                        tabs={dialogTabs}
                        value={tab}
                        oneVisibleTab={!matchesLaptop}
                        onChange={(newTab) => setTab(newTab)}
                    />

                    <SearchInput
                        value={searchString}
                        onSearchClick={(event) => setSearchString(event.target.value)}
                    />

                    {tab === DIALOG_TABS_IDS.PERSISTED_ROLES && (
                        <PersistedRolesTable
                            columns={columns}
                            systemId={systemId}
                            searchString={searchString}
                            onRowClick={handleRowClick}
                            showManual={false}
                        />
                    )}

                    {tab === DIALOG_TABS_IDS.NEW_ROLES && (
                        <NewRolesTable
                            columns={columns}
                            systemId={systemId}
                            searchString={searchString}
                            onRowClick={handleRowClick}
                        />
                    )}
                </CommonDialogContent>
            </CommonDialog>

            {errorDialogOpen && (
                <ErrorsDialog
                    error={error}
                    open={errorDialogOpen}
                    onClose={() => setError(null)}
                />
            )}
        </>
    );
};

PersistedRolesTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    systemId: PropTypes.string,
    searchString: PropTypes.string,
    onRowClick: PropTypes.func
};

NewRolesTable.propTypes = PersistedRolesTable.propTypes;

AddRoleDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

export default AddRoleDialog;