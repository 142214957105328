import React from "react"
import { Menu as MuiMenu, MenuItem as MuiMenuItem } from "@mui/material"
import PropTypes from "prop-types";

import "./styles.less";

export const menuClassName = "menu";
export const menuItemClassName = "menu-item";

export const Menu = ({
    className="",
    ...props
}) => {
    return (
        <MuiMenu
            className={`${menuClassName} ${className}`}
            {...props}
        />
    );
};

export const MenuItem = ({
    className="",
    ...props
}) => {
    return (
        <MuiMenuItem
            className={`${menuItemClassName} ${className}`}
            {...props}
        />
    );
};

Menu.propTypes = {
    className: PropTypes.string
};

MenuItem.propTypes = {
    className: PropTypes.string
};