import React from 'react';
import { createSvgIcon } from '@mui/material';

const SodReportsIcon = createSvgIcon(
    <svg 
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M19 3.5H5C3.9 3.5 3 4.4 3 5.5V19.5C3 20.6 3.9 21.5 5 21.5H19C20.1 21.5 21 20.6 21 19.5V5.5C21 4.4 20.1 3.5 19 3.5ZM9 17.5H7V10.5H9V17.5ZM13 17.5H11V7.5H13V17.5ZM17 17.5H15V13.5H17V17.5Z"
            fill="currentColor"
        />
    </svg>,
    "SodReportsIcon"
);

export default SodReportsIcon;
