import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import {
    setUsers as setUsersAction,
    //setSystems as setSystemsAction,
    setSystemsExtended as setSystemsExtendedAction,
    setMatrixHeaders as setMatrixHeadersAction,
    setRisks as setRisksAction,
    setRiskLevels as setRiskLevelsAction,
    setParametersOpen as setParametersOpenAction,
    setVariantWithFetchModeling,
} from "../../../reducers/reports-modeling/userLevelSlice";

import { getFieldValidationError } from "../../../utils/sodReports";

import useReduxValue from "../../common/hooks/useReduxValue";

import {
    MatrixIdsInput, RiskIdsInput, RiskLevelInput,
    UsersInput, SystemIdsInput
} from "../../sod-reports/report-parameters";

import ReportModelingParameters from "../report-modeling-parameters";
import {reportsService} from "../../../services/reports-service";
import { useNavigate } from "react-router-dom";

const getSystems = (params, signal) => {
    return reportsService.getSystemsOnlySearchHelp(params, signal);
};


const UserLevelModelingParameters = ({ onShowVariantsClick }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentVariant = useSelector(state => state.reportsModeling.userLevel.currentVariant);
    const validationErrors = useSelector(state => state.reportsModeling.userLevel.validationErrors);

    const [users, setUsers] = useReduxValue(state => state.reportsModeling.userLevel.users, setUsersAction);
    //const [systems, setSystems] = useReduxValue(state => state.reportsModeling.userLevel.systems, setSystemsAction);
    const [systemsExtended, setSystemsExtended] = useReduxValue(state => state.reportsModeling.userLevel.extendedSystems, setSystemsExtendedAction);
    const [matrixHeaders, setMatrixHeaders] = useReduxValue(state => state.reportsModeling.userLevel.matrixHeaders, setMatrixHeadersAction);
    const [risks, setRisks] = useReduxValue(state => state.reportsModeling.userLevel.risks, setRisksAction);
    const [riskLevels, setRiskLevels] = useReduxValue(state => state.reportsModeling.userLevel.riskLevels, setRiskLevelsAction);
    const [open, setOpen] = useReduxValue(state => state.reportsModeling.userLevel.parametersOpen, setParametersOpenAction);

    const titles = [intl.formatMessage({ id: "user-level-report-page.title" })];

    const onChangeSystems = useCallback((ids, item) => {
        if (!item){
            const idsSet = new Set(ids)
            const systemsExtendedNew = systemsExtended.filter(system => idsSet.has(system.id))

            setSystemsExtended(systemsExtendedNew)

            return
        }

        const systemExtended = systemsExtended.find(system => system.id === item.id && system.isGroup === item.isGroup)

        if (systemExtended) {
            setSystemsExtended(systemsExtended.filter(system => !(system.id === item.id && system.isGroup === item.isGroup)))
        } else {
            setSystemsExtended([...systemsExtended, item])
        }
   }, [systemsExtended, setSystemsExtended])

    if (currentVariant) {
        titles.push(intl.formatMessage(
            { id: "user-level-report-page.variant-selected.title" },
            { name: currentVariant.variantName }
        ));
    }

    const handleOpenClick = (event) => {
        event.stopPropagation();
        setOpen(!open);
    };

    const handleApply = () => {
        navigate("/reports-modeling/user-level");
        
        dispatch(setVariantWithFetchModeling(null));
    };

    const applyBtnDisabled = users?.length && systemsExtended.length == 1

    return (
        <ReportModelingParameters
            title={titles.join(" ")}
            open={open}
            applyDisabled={!applyBtnDisabled}
            onOpenClick={handleOpenClick}
            onShowVariantsClick={onShowVariantsClick}
            onApplyClick={handleApply}
        >
            <UsersInput
                ids={users}
                setIds={setUsers}
                error={getFieldValidationError(validationErrors, "usernames")}
            />

            <SystemIdsInput
                ids={systemsExtended.map(system => system.id)}
                setIds={onChangeSystems}
                getSystems={getSystems}
            />

            <MatrixIdsInput
                ids={matrixHeaders}
                setIds={setMatrixHeaders}
            />

            <RiskIdsInput
                ids={risks}
                setIds={setRisks}
            />

            <RiskLevelInput
                ids={riskLevels}
                setIds={setRiskLevels}
            />
        </ReportModelingParameters>
    );
};

UserLevelModelingParameters.propTypes = {
    onShowVariantsClick: PropTypes.func
};

export default UserLevelModelingParameters;
