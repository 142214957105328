import React from "react";
import ReportModelingHeader from "../../report-modeling-header";

import RoleLevelModelingRoleSearchHelp from "./role-level-modeling-role-search-help.jsx";

const RoleLevelModelingHeader = () => {
    return (
        <ReportModelingHeader>
            <RoleLevelModelingRoleSearchHelp />
        </ReportModelingHeader>
    );
};

export default RoleLevelModelingHeader;