import React from "react";
import { Box, IconButton, List, ListItem, Radio, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { getEntityDeleteIcon } from "../../../../utils/reports-modeling-utils";

import "./styles.less";

const RolesListItem = ({
    id,
    role,
    description,
    selected,
    status,
    onSelectRole,
    onDeleteRole,
    onClick
}) => {
    const baseClassName = "user-level-modeling-roles-list-item";

    let className = baseClassName;

    if (status) {
        className += ` ${baseClassName}-${status}`;
    }

    const DeleteIcon = getEntityDeleteIcon(status);

    return (
        <ListItem className={className} onClick={onClick}>
            <Box className={`${baseClassName}-header`}>
                <Radio
                    className={`${baseClassName}-radio`}
                    checked={selected}
                    value={id}
                    onChange={onSelectRole}
                />

                <IconButton
                    className={`${baseClassName}-close`}
                    onClick={onDeleteRole}
                >
                    <DeleteIcon />
                </IconButton>
            </Box>

            <Typography className={`${baseClassName}-title`}>
                {role}
            </Typography>

            {description && (
                <Typography className={`${baseClassName}-description`}>
                    {description}
                </Typography>
            )}
        </ListItem>
    )
}

const RolesList = ({
    rows,
    selectedRoleId,
    onSelectRole,
    onDeleteRole,
    onRowClick
}) => {
    return (
        <Box className="user-level-modeling-roles-list-wrapper">
            <List className="user-level-modeling-roles-list">
                {rows.map((row) => (
                    <RolesListItem
                        key={row.id}
                        id={row.id}
                        role={row.role}
                        description={row.description}
                        selected={row.id === selectedRoleId}
                        status={row.status}
                        onSelectRole={onSelectRole}
                        onDeleteRole={(e) => {
                            e.stopPropagation();
                            onDeleteRole(row.id)
                        }}
                        onClick={() => onRowClick(row)}
                    />
                ))}
            </List>
        </Box>
    );
};

RolesListItem.propTypes = {
    id: PropTypes.string,
    role: PropTypes.string,
    description: PropTypes.string,
    selected: PropTypes.bool,
    status: PropTypes.string,
    onSelectRole: PropTypes.func,
    onDeleteRole: PropTypes.func,
    onClick: PropTypes.func
};

RolesList.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object),
    selectedRoleId: PropTypes.string,
    onSelectRole: PropTypes.func,
    onDeleteRole: PropTypes.func,
    onRowClick: PropTypes.func
};

export default RolesList;