import React from "react";
import PropTypes from "prop-types";

import { Button } from "../../common/buttons";
import { ReportModelingNodeSelectIcon, ReportModelingNodeFolderIcon } from "../../common/icons";

import "./styles.less";

const PfcgViewNodeButton = ({ sx, open, endContent, onClick }) => {
    let className = "pfcg-view-node-button";

    if (!open) {
        className += " pfcg-view-node-button-closed";
    }

    return (
        <Button
            variant="contained"
            className={className}
            sx={sx}
            onClick={onClick}
        >
            <ReportModelingNodeSelectIcon className="pfcg-view-node-button-select-icon" />
            <ReportModelingNodeFolderIcon className="pfcg-view-node-button-folder-icon" />
            {endContent}
        </Button>
    );
};

PfcgViewNodeButton.propTypes = {
    open: PropTypes.bool,
    onClick: PropTypes.func,
    sx: PropTypes.object,
    endContent: PropTypes.node
};

export default PfcgViewNodeButton;