import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import {
    fetchGroups,
    fetchGroupColumns,
    setSelectedIds as setSelectedIdsAction,
    setSearchString as setSearchStringAction,
    setPageNumber as setPageNumberAction,
    setLimitNumber as setLimitNumberAction,
    setSortData as setSortDataAction,
    setFilterData as setFilterDataAction,
    setError as setErrorAction,
    checkCanCreateFirstGroup,
} from "../../../../../reducers/admin/systemGroupReducer";

import useFilters from "../../../../common/hooks/useFilters";
import useReduxTable from "../../../../common/hooks/useReduxTable";
import CommonTable from "../../../../common/table";
import { ProgressDialog, ErrorsDialog } from "../../../../common/dialogs";

import { EmptyTabContent } from "../../../../common/empty-tab-content";

const getCellValue = (row, column) => {
    switch (column.id) {
        case "groupId":
            return row.id;

        case "systems":
            return row.systems
                .map(system => system.id)
                .join(", ")

        default:
            return row[column.id];
    }
};

const EmptySystemGroupsListPage = ({ onButtonClick }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const canCreateFirstGroup = useSelector(state => state.admin.systemGroups.canCreateFirstGroup);

    const text = canCreateFirstGroup
        ? intl.formatMessage({ id: "system-groups-tab.empty-system-groups.can-create-text" })
        : intl.formatMessage({ id: "system-groups-tab.empty-system-groups.can-not-create-text" });

    const buttonText = intl.formatMessage({ id: "system-groups-tab.empty-system-groups.btn-create-system-group" });

    useEffect(() => {
        dispatch(checkCanCreateFirstGroup());
    }, [dispatch]);

    return (
        <EmptyTabContent
            text={text}
            buttonText={buttonText}
            buttonDisabled={!canCreateFirstGroup}
            onButtonClick={onButtonClick}
        />
    );
};

const SystemGroupsListPage = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    const {
        rows, columns,
        total, busy,
        selectedIds,
        searchString, setSearchString,
        pageNumber, setPageNumber,
        limitNumber, setLimitNumber,
        filterData, setFilterData,
        sortData, setSortData,
        error, setError
    } = useReduxTable(
        (state) => state.admin.systemGroups.groups, fetchGroups,
        (state) => state.admin.systemGroups.columns, fetchGroupColumns,
        (state) => state.admin.systemGroups.selectedIds, setSelectedIdsAction,
        (state) => state.admin.systemGroups.searchString, setSearchStringAction,
        (state) => state.admin.systemGroups.pageNumber, setPageNumberAction,
        (state) => state.admin.systemGroups.limitNumber, setLimitNumberAction,
        (state) => state.admin.systemGroups.sortData, setSortDataAction,
        (state) => state.admin.systemGroups.filterData, setFilterDataAction,
        (state) => state.admin.systemGroups.error, setErrorAction,
        (state) => state.admin.systemGroups.total,
        (state) => state.admin.systemGroups.busy,
    );

    const busyType = useSelector((state) => state.admin.systemGroups.busyType);

    const getFilters = useFilters("system-group");

    const handleAddGroupClick = () => {
        navigate("form")
    };

    const handleRowClick = (group) => {
        navigate(`form/${group.id}`)
    };

    const toolbarActions = [
        {
            id: "title",
            title: intl.formatMessage({ id: "system-groups-tab.toolbar.text.title" }),
            type: "text",
            position: "begin"
        },
        {
            id: "add",
            type: "button",
            position: "end",
            title: intl.formatMessage({ id: "system-groups-tab.toolbar.btn-create-system-group" }),
            callback: () => {
                handleAddGroupClick()
            }
        }
    ];

    const showEmpty = total === 0 && !searchString && filterData.length === 0;

    return (
        <>
            {showEmpty ? (
                <EmptySystemGroupsListPage
                    onButtonClick={handleAddGroupClick}
                />
            ) : (
                <CommonTable
                    rows={rows}
                    columns={columns}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    sortData={sortData}
                    setSortData={setSortData}
                    toolbarActions={toolbarActions}
                    selectedIds={selectedIds}
                    getRowId={row => row.id}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    limitNumber={limitNumber}
                    setLimitNumber={setLimitNumber}
                    total={total}
                    onRowClick={handleRowClick}
                    searchString={searchString}
                    setSearchString={setSearchString}
                    getCellValue={getCellValue}
                    getFilters={getFilters}
                />
            )}

            <ProgressDialog
                open={busy}
                busyType={busyType}
            />

            <ErrorsDialog
                error={error}
                open={error?.type === "error"}
                onClose={() => setError(null)}
            />
        </>
    )
};

EmptySystemGroupsListPage.propTypes = {
    onButtonClick: PropTypes.func,
};

export default SystemGroupsListPage;
