import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types'; 

import Button from './button.jsx';
import "../app-drawer/style.less"

const AboutAppButton = ({ onAboutAppClick }) => {
    const intl = useIntl();
    const title = intl.formatMessage({ id: "common.about-app-title" });

    return (
        <Button
            variant="text"
            onClick={onAboutAppClick}
            sx={{color: "#ffffff"}}
        >
            {title}
        </Button>
    );
};

AboutAppButton.propTypes = {
    onAboutAppClick: PropTypes.func,
    title: PropTypes.string
}

export default AboutAppButton;
