import React from 'react';
import { createSvgIcon } from '@mui/material';

const SodMatrixIcon = createSvgIcon(
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M3 3.5V21.5H21V19.7H19.2V17.9H21V16.1H19.2V14.3H21V12.5H17.4V10.7H15.6V12.5H13.8V10.7H12V8.9H8.4V12.5H12V16.1H10.2V17.9H12V19.7H10.2V17.9H8.4V16.1H6.6V14.3H4.8V12.5H6.6V7.1H4.8V3.5H3ZM6.6 7.1H8.4V3.5H6.6V7.1ZM8.4 16.1H10.2V14.3H8.4V16.1ZM17.4 10.7H21V8.9H17.4V10.7ZM17.4 8.9V5.3H15.6V7.1H13.8V8.9H17.4ZM15.6 5.3V3.5H13.8V5.3H15.6ZM17.4 5.3H19.2V3.5H17.4V5.3ZM19.2 5.3V7.1H21V5.3H19.2ZM10.2 3.5V7.1H12V3.5H10.2ZM13.8 14.3H15.6V16.1H17.4V19.7H13.8V14.3ZM4.8 17.9H6.6V19.7H4.8V17.9Z"
            fill="currentColor"
        />
    </svg>,
    "SodMatrixIcon"
);

export default SodMatrixIcon;
