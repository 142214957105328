import React from "react";
import { SvgIcon } from "@mui/material";

const DropdownListIcon = (props) => (
    <SvgIcon
        viewBox="0 0 18 19"
        {...props}
    >
        <svg
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.9999 11.9H10.9999C11.2208 11.9 11.3999 12.0791 11.3999 12.3C11.3999 12.4964 11.2584 12.6597 11.0718 12.6936L10.9999 12.7H6.9999C6.77899 12.7 6.5999 12.5209 6.5999 12.3C6.5999 12.1036 6.7414 11.9403 6.928 11.9064L6.9999 11.9H10.9999H6.9999ZM5.3999 8.7H12.5999C12.8208 8.7 12.9999 8.87909 12.9999 9.1C12.9999 9.29637 12.8584 9.45969 12.6718 9.49356L12.5999 9.5H5.3999C5.17899 9.5 4.9999 9.32091 4.9999 9.1C4.9999 8.90363 5.1414 8.74031 5.328 8.70644L5.3999 8.7H12.5999H5.3999ZM3.7999 5.5H14.1999C14.4208 5.5 14.5999 5.67909 14.5999 5.9C14.5999 6.09637 14.4584 6.25969 14.2718 6.29356L14.1999 6.3H3.7999C3.57899 6.3 3.3999 6.12091 3.3999 5.9C3.3999 5.70363 3.5414 5.54031 3.728 5.50644L3.7999 5.5H14.1999H3.7999Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
)

export default DropdownListIcon;