const checkIfRowHasMatchWithKey = (row, key, fieldMapping) => {
    const checkRowArr = []

    for (let field in key) {
        const rowField = fieldMapping[field];

        if (!rowField) {
            checkRowArr.push(false)
            return;
        }

        checkRowArr.push(row[rowField] == key[field])
    }

    return checkRowArr.every(item => item)
}

export default checkIfRowHasMatchWithKey