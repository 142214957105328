import React from "react";
import { Box, TabScrollButton } from "@mui/material";
import PropTypes from "prop-types";

import { RightScrollButtonIcon, LeftScrollButtonIcon } from "../icons";
import useScrollableTabs from "../hooks/useScrollableTabs";

import CommonTabs from "./common-tabs.jsx";

import "./styles.less";

const ScrollButton = ({ direction, orientation="horizontal", className="", ...props }) => {
    return (
        <TabScrollButton
            className={`${className} scroll-button`}
            direction={direction}
            orientation={orientation}
            {...props}
            slots={{
                StartScrollButtonIcon: LeftScrollButtonIcon,
                EndScrollButtonIcon: RightScrollButtonIcon
            }}
        />
    )
};

const ScrollableTabs = ({ tabs, value, onChange, oneVisibleTab, className="" }) => {
    const {
        visibleTabs,
        handleLeftScrollClick,
        handleRightScrollClick
    } = useScrollableTabs(tabs, value, oneVisibleTab, onChange);

    const tabsProps = oneVisibleTab
        ? {
            centered: true
        }
        : {
            variant: "scrollable",
            scrollButtons: "auto",
            allowScrollButtonsMobile: true,
            ScrollButtonComponent: ScrollButton
        };

    return (
        <Box className={`scrollable-tabs-wrapper ${className}`}>
            {oneVisibleTab && (
                <ScrollButton
                    className="scrollable-tabs-scroll scrollable-tabs-scroll-left"
                    direction="left"
                    onClick={handleLeftScrollClick}
                />
            )}

            <CommonTabs
                tabs={visibleTabs}
                value={value}
                onChange={onChange}
                {...tabsProps}
            />

            {oneVisibleTab && (
                <ScrollButton
                    className="scrollable-tabs-scroll scrollable-tabs-scroll-right"
                    direction="right"
                    onClick={handleRightScrollClick}
                />
            )}
        </Box>
    );
};

ScrollButton.propTypes = {
    direction: PropTypes.oneOf(["right", "left"]),
    orientation: PropTypes.oneOf(["horizontal", "vertical"]),
    className: PropTypes.string
};

ScrollableTabs.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.object),
    value: PropTypes.string,
    onChange: PropTypes.func,
    oneVisibleTab: PropTypes.bool,
    className: PropTypes.string
};

export default ScrollableTabs;
