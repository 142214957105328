import React from "react";
import { SvgIcon } from "@mui/material";

const ReportModelingSearchHelpIcon = (props) => (
    <SvgIcon
        viewBox="0 0 28 28"
        {...props}
    >
        <svg
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                y="28"
                width="28"
                height="28"
                rx="4"
                transform="rotate(-90 0 28)"
                fill="currentColor"
            />

            <path
                d="M9.29412 21C8.93824 21 8.63347 20.863 8.37982 20.5891C8.12661 20.3147 8 19.985 8 19.6V9.8H9.29412V19.6H16.4118V21H9.29412ZM11.8824 18.2C11.5265 18.2 11.2219 18.063 10.9687 17.7891C10.7151 17.5147 10.5882 17.185 10.5882 16.8V8.4C10.5882 8.015 10.7151 7.6853 10.9687 7.4109C11.2219 7.13697 11.5265 7 11.8824 7H17.7059C18.0618 7 18.3665 7.13697 18.6202 7.4109C18.8734 7.6853 19 8.015 19 8.4V16.8C19 17.185 18.8734 17.5147 18.6202 17.7891C18.3665 18.063 18.0618 18.2 17.7059 18.2H11.8824Z"
                fill="white"
            />
        </svg>
    </SvgIcon>
);

export default ReportModelingSearchHelpIcon