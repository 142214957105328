import React from "react";
import PropTypes from "prop-types";

import { EntityFormActions, EntityFormHeader } from "../../../common/entity-form";

import RoleFormHeaderTitle from "./role-form-header-title.jsx";

const RoleFormPageHeader = ({
    name,
    mode,
    onBackClick,
    onDeleteClick,
    onSaveClick,
    onEditClick,
    onShowLogsClick
}) => {
    return (
        <EntityFormHeader>
            <RoleFormHeaderTitle
                name={name}
                mode={mode}
                onBackClick={onBackClick}
            />

            <EntityFormActions
                mode={mode}
                onDeleteClick={onDeleteClick}
                onSaveClick={onSaveClick}
                onEditClick={onEditClick}
                onShowLogsClick={onShowLogsClick}
            />
        </EntityFormHeader>
    );
};

RoleFormPageHeader.propTypes = {
    name: PropTypes.string,
    mode: PropTypes.string,
    onCancelClick: PropTypes.func,
    onBackClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onSaveClick: PropTypes.func,
    onEditClick: PropTypes.func,
    onShowLogsClick: PropTypes.func
}

export default RoleFormPageHeader;
