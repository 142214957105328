import React from 'react';
import { FormattedMessage } from "react-intl";
import PropTypes from 'prop-types';

import { DeleteDialog } from '../../../common/dialogs';

const DeleteMatrixHeaderDialog = ({ open, matrixHeaderId , onCancelClick, onDeleteClick}) => {
    return (
        <DeleteDialog
            open={open}
            title={<FormattedMessage id="delete-matrix-header-dialog.title" />}
            text={
                <FormattedMessage
                    id="delete-matrix-header-dialog.text"
                    values={{ matrixHeaderId }}
                />
            }
            onCancelClick={onCancelClick}
            onDeleteClick={onDeleteClick}
        />
    );
};

DeleteMatrixHeaderDialog.propTypes = {
    open: PropTypes.bool,
    matrixHeaderId: PropTypes.string,
    onCancelClick: PropTypes.func,
    onDeleteClick: PropTypes.func
}

export default DeleteMatrixHeaderDialog;
