import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { nanoid } from "@reduxjs/toolkit";
import { produce } from "immer";
import PropTypes from "prop-types";

import { CommonDialog, CommonDialogActions, CommonDialogContent, CommonDialogTitle } from "../../common/dialogs";
import { Button } from "../../common/buttons";

import OrgLevelsDialogTable from "./org-levels-dialog-table.jsx";

import "./styles.less";

const createOrgLevelRow = (id, from, to, description, deletable) => ({
    rowId: nanoid(),
    id,
    from,
    to,
    description,
    deletable
});

const parseOrgLevelToRows = (orgLevel) => {
    return orgLevel.values.map((value, index) => createOrgLevelRow(
        orgLevel.id,
        value.from,
        value.to,
        index === 0 ? orgLevel.description : "",
        index !== 0
    ));
};

const modifyFieldValueArray = (rows, modifiedRow, field, valueArray) => {
    const [modifiedValue, ...newValues] = valueArray;

    modifiedRow[field] = modifiedValue;

    if (!newValues) {
        return;
    }

    const lastOrgLevelIndex = rows.findLastIndex(
        row => row.id === modifiedRow.id
    );

    const newRows = newValues.map(newValue => createOrgLevelRow(
        modifiedRow.id,
        field === "from" ? newValue : "",
        field === "to" ? newValue : "",
        "",
        true
    ));

    rows.splice(lastOrgLevelIndex + 1, 0, ...newRows);
};

const modifyFieldValue = (rows, rowId, field, value) => {
    const isValueArray = Array.isArray(value);

    if (isValueArray && value.length === 0) {
        return;
    }

    const modifiedRow = rows.find(row => row.rowId === rowId);

    if (!modifiedRow) {
        return;
    }

    if (isValueArray) {
        modifyFieldValueArray(rows, modifiedRow, field, value);
    } else {
        modifiedRow[field] = value;
    }
};

const addOrgLevelRow = (rows, rowId) => {
    const rowIndex = rows.findIndex(row => row.rowId === rowId);

    if (rowIndex === -1) {
        return;
    }

    const newRow = createOrgLevelRow(
        rows[rowIndex].id, "", "", "", true
    );

    rows.splice(rowIndex + 1, 0, newRow);
};

const parseRowsToOrgLevelsValues = (rows) => {
    const orgLevels = {};

    for (const row of rows) {
        if (!(row.id in orgLevels)) {
            orgLevels[row.id] = [];
        }

        orgLevels[row.id].push({
            rowId: row.rowId,
            from: row.from ?? "",
            to: row.to ?? ""
        });
    }

    return orgLevels;
};

const OrgLevelsDialog = ({
    open,
    systemId,
    orgLevels,
    onClose,
    onApply
}) => {
    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (!orgLevels) {
            setRows([]);
        }

        const newRows = Object.values(orgLevels).reduce((acc, orgLevel) => {
            const orgLevelRows = parseOrgLevelToRows(orgLevel);
            acc.push(...orgLevelRows);
            return acc;
        }, []);

        setRows(newRows);
    }, [orgLevels]);

    const handleChangeCell = (rowId, field, value) => {
        setRows(produce(
            rows => modifyFieldValue(rows, rowId, field, value)
        ));
    };

    const handleAddOrgLevel = (rowId) => {
        setRows(produce(
            rows => addOrgLevelRow(rows, rowId)
        ));
    };

    const handleDeleteOrgLevel = (rowId) => {
        setRows(rows => rows.filter(row => row.rowId !== rowId));
    };

    const handleApply = () => {
        const values = parseRowsToOrgLevelsValues(rows);
        onApply(values);
    };

    const intl = useIntl();
    const title = intl.formatMessage({ id: "org-levels-dialog.title" });
    const applyTitle = intl.formatMessage({ id: "org-levels-dialog.actions.apply" });
    const cancelTitle = intl.formatMessage({ id: "common.btn-cancel" });

    return (
        <CommonDialog
            className="org-levels-dialog"
            open={open}
        >
            <CommonDialogTitle
                title={title}
                onClose={onClose}
            />
            <CommonDialogContent>
                <OrgLevelsDialogTable
                    systemId={systemId}
                    rows={rows}
                    onChangeCell={handleChangeCell}
                    onAddRow={handleAddOrgLevel}
                    onDeleteRow={handleDeleteOrgLevel}
                />
            </CommonDialogContent>
            <CommonDialogActions>
                <Button
                    className="org-levels-dialog-cancel-button"
                    variant="outlined"
                    onClick={onClose}
                >
                    {cancelTitle}
                </Button>
                <Button
                    className="org-levels-dialog-apply-button"
                    variant="contained"
                    onClick={handleApply}
                >
                    {applyTitle}
                </Button>
            </CommonDialogActions>
        </CommonDialog>
    );
};

OrgLevelsDialog.propTypes = {
    open: PropTypes.bool,
    systemId: PropTypes.string,
    orgLevels: PropTypes.object,
    onClose: PropTypes.func,
    onApply: PropTypes.func,
};

export default OrgLevelsDialog;