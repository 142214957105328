import React from "react";
import { FormattedMessage } from "react-intl";
import { Typography, Box } from "@mui/material";
import PropTypes from 'prop-types'; 

import { PageContentBox } from "../../common/page-content-box";

import VariantsList from "./variants-list.jsx";
import VariantsSearchInput from "./variants-search-input.jsx";

import "./styles.less";

const VariantsListBoxHeader = ({ onSearch }) => {
    return (
        <Box className="variants-list-box-header">
            <Typography
                component="h2"
                className="report-page-title"
            >
                <FormattedMessage
                    id="show-variants-dialog.title"
                />
            </Typography>

            <VariantsSearchInput
                onSearch={onSearch}
            />
        </Box>
    );
};

const VariantsListBox = ({
    variants,
    onDeleteClick,
    onFavoriteClick,
    onItemClick,
    onSearch
}) => {
    return (
        <PageContentBox className="variants-list-box">
            <VariantsListBoxHeader
                onSearch={onSearch}
            />

            <VariantsList
                variants={variants}
                onDeleteClick={onDeleteClick}
                onFavoriteClick={onFavoriteClick}
                onItemClick={onItemClick}
            />
        </PageContentBox>
    );
};

VariantsListBoxHeader.propTypes = {
    onSearch: PropTypes.func,
};

VariantsListBox.propTypes = {
    ...VariantsList.propTypes,
    ...VariantsListBoxHeader.propTypes
};

export default VariantsListBox;
