import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { systemsService } from "../services/systems-service";
import { riskLevelsService } from "../services/risk-levels-service";
import { usersService } from "../services/users-service";

const initialState = {
    user: null, 
    busy: false, 
    error: null,
    riskLevels: [],
    systems: [],
    userRoles: [],
};

export const fetchUser = createAsyncThunk(
    "app/fetchUser",
    async () => {
        return 123;
    }
);

export const fetchRiskLevels = createAsyncThunk(
    "app/fetchRiskLevels",
    async () => {
        const response = await riskLevelsService.getRiskLevels();
 
        return response;
    }
);

export const fetchSystems = createAsyncThunk(
    "app/fetchSystems",
    async () => {
        const {systems} = await systemsService.getSystems();
 
        return systems;
    }
);

export const fetchUserRoles = createAsyncThunk(
    "app/fetchUserRoles",
    async () => {
        const userRoles = await usersService.getUserRoles();
        return userRoles;
    }
);

const appSlice = createSlice({
    name: "app",
    initialState: initialState,
    reducers: {
        setBusy: (state, action) => {
            state.busy = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    },
    extraReducers: {
        [fetchUser.pending]: (state) => {
            state.busy = true;
        },
        [fetchUser.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },
        [fetchUser.fulfilled]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },

        [fetchRiskLevels.pending]: (state) => {
            state.busy = true;
        },
        [fetchRiskLevels.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },
        [fetchRiskLevels.fulfilled]: (state, action) => {
            state.busy = false;
            state.riskLevels = action.payload;
        },

        [fetchSystems.pending]: (state) => {
            state.busy = true;
        },
        [fetchSystems.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },
        [fetchSystems.fulfilled]: (state, action) => {
            state.busy = false;
            state.systems = action.payload;
        },

        [fetchUserRoles.pending]: (state) => {
            state.busy = true;
        },
        [fetchUserRoles.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },
        [fetchUserRoles.fulfilled]: (state, action) => {
            state.busy = false;
            state.userRoles = action.payload;
        },
    }
})

export const { setBusy, setError } = appSlice.actions;
export default appSlice.reducer;