import React from "react";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import PropTypes from 'prop-types';

import ReportAccordion from "../report-accordion";
import { Button } from "../../common/buttons";
import { ExportIcon } from "../../common/icons";

import "./style.less";

const ReportResultsHeader = ({ open, onOpenClick, onExportClick }) => {
    const intl = useIntl();
    const accordionTitle = intl.formatMessage({ id: "report-results.accordion-title" });

    return (
        <Box className="report-results-header">
            <ReportAccordion
                open={open}
                onOpenClick={onOpenClick}
                title={accordionTitle}
            />

            <Button
                className="export-report-button"
                variant="outlined"
                startIcon={<ExportIcon />}
                onClick={onExportClick}
            >
                Export
            </Button>
        </Box>
    );
};

ReportResultsHeader.propTypes = {
    open: PropTypes.bool,
    onOpenClick: PropTypes.func,
    onExportClick: PropTypes.func
};

export default ReportResultsHeader;
