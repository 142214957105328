import React from "react";
import { Box } from "@mui/material";
import PropTypes from 'prop-types';

import Tag from "./tag.jsx";

const TagsListDetailed = ({
    tags,
    onDelete,
    onClick=(id) => (id),
    getTagText=(key) => (key),
    selectedTagKey
}) => {
    return (
        <>
            {tags.length > 0 && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        alignItems: "center",
                        gap: "10px",
                        marginTop: "6px"
                    }}
                >
                    {tags && tags.map(tag => (
                        <Tag
                            selected={selectedTagKey === tag.key}
                            tag={getTagText(tag)}
                            onClick={() => onClick(tag.key)}
                            key={tag.key}
                            onDelete={() => onDelete(tag.key)}
                        />
                    ))}
                </Box>
            )}
        </>
    );
};

TagsListDetailed.propTypes = {
    tags: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string, 
            text: PropTypes.string
    })
    ),
    onDelete: PropTypes.func,
    getTagText: PropTypes.func,
    onClick: PropTypes.func,
    selectedTagKey: PropTypes.string
};

export default TagsListDetailed;
