import React from "react";

import ReportModelingHeader from "../../report-modeling-header";

import UserLevelModelingUserSearchHelp from "./user-level-modeling-user-search-help.jsx";
import UserLevelModelingRoleSearchHelp from "./user-level-modeling-role-search-help.jsx";

const UserLevelModelingHeader = () => {

    return (
        <ReportModelingHeader>
            <UserLevelModelingUserSearchHelp />
            <UserLevelModelingRoleSearchHelp />
        </ReportModelingHeader>
    );
};

export default UserLevelModelingHeader;