import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { riskLevelsService } from "../../services/risk-levels-service";
import LockManager from "../../utils/lockManager";
import { BUSY_TYPES } from "../../utils/busy";

const lockManager = new LockManager("risk-levels");

const initialState = {
    riskLevels: [],
    busy: false,
    busyType: null,
    error: null,
    validationErrors: null,
    riskLevelsEditable: false
};

export const fetchRiskLevels = createAsyncThunk(
    "admin/fetchRiskLevels",
    async () => {
        const riskLevels = await riskLevelsService.getRiskLevels();
        const lock = await lockManager.lockObject();
        return { riskLevels, lock };
    }
);

export const updateRiskLevels = createAsyncThunk(
    "admin/updateRiskLevels",
    async (args, { rejectWithValue }) => {
        let riskLevels;

        try {
            riskLevels = await riskLevelsService.updateRiskLevels(args);
        } catch (messages) {
            return rejectWithValue(messages);
        }

        return riskLevels;
    }
);

export const clearRiskLevels = createAsyncThunk(
    "admin/clearRiskLevels",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        const riskLevelsEditable = state.admin.riskLevels.riskLevelsEditable;

        if (!riskLevelsEditable) return;

        const lockResponse = await lockManager.unlockObject();

        if (!lockResponse.result) {
            throw Error(lockResponse.messages);
        }
    }
);

const riskLevelsSlice = createSlice({
    name: "riskLevels",
    initialState: initialState,
    reducers: {
        setError(state, action){
            state.error = action.payload;
        }
    },
    extraReducers: {
        //get all riskLevels
        [fetchRiskLevels.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.riskLevelsEditable = false;
        },

        [fetchRiskLevels.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },

        [fetchRiskLevels.fulfilled]: (state, action) => {
            const { riskLevels, lock } = action.payload;

            state.riskLevels = riskLevels;
            state.riskLevelsEditable = lock.result;
            state.busy = false;
            state.error = lock.result ? null : lock.messages;
        },

        //update riskLevels
        [updateRiskLevels.fulfilled]: (state, action) => {
            state.busy = false;
            state.riskLevels = action.payload;
            state.error = null;
            state.validationErrors = null;
        },

        [updateRiskLevels.rejected]: (state, action) => {
            state.busy = false;

            const error = action.payload;

            if (!error) return;

            const validationCodes = new Set(["validation"]);

            if (error.code && validationCodes.has(error.code)) {
                state.validationErrors = error.errors;
            }

            state.error = error;
        },

        [updateRiskLevels.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
        },

        // clear risk levels
        [clearRiskLevels.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [clearRiskLevels.fulfilled]: (state) => {
            state.riskLevels = [];
            state.busy = false;
            state.error = null;
            state.riskLevelsEditable = false;
        },

        [clearRiskLevels.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },
    }
});

export const { setSubmit, setError } = riskLevelsSlice.actions;
export default riskLevelsSlice.reducer;
