import React from "react";
import { Typography, Box, useMediaQuery } from "@mui/material";
import PropTypes from 'prop-types';

import { Button } from "../buttons"
import { SelectInput, SearchInput } from "../form-controls";
import { MenuItem } from "../menu";
import breakpoints from "../styles/breakpoints.js";
import {DatePicker} from "../form-controls";

import "./styles.less"

const position = [
    "begin",
    "center",
    "end"
];

export const TableToolbar = ({children, className}) => {
    let classNameFinal = "toolbar-actions";

    if (className) {
        classNameFinal += ` ${className}`;
    }    

    return (
        <Box className={classNameFinal}>
            <Box className="toolbar-actions-position">
                {children}
            </Box>
        </Box>
    )
}

const ToolbarActions = ({ toolbarActions }) => {
    return (
        <Box className="toolbar-actions">
            {position.map(position => (
                <ToolbarActionsPosition
                    key={position}
                    position={position}
                    toolbarActions={toolbarActions}
                />
            ))}
        </Box>
    );
};

const ToolbarActionsPosition = ({ position, toolbarActions=[] }) => {
    const positionActions = toolbarActions.filter(action => action.position === position);

    if (positionActions.length === 0) return null;

    return(
        <Box className="toolbar-actions-position">
            {toolbarActions
                .filter(action => action.position === position)
                .map(action => (
                    <ToolbarAction
                        key={action.id}
                        action={action}
                    />
                )
            )}
        </Box>
    )
};

export const ToolbarDatePicker = ({action}) => {
    return (
        <DatePicker 
            value={action.value}
            onChange={action.onChange}
            disabled={action.disabled}
            sx={{
                ...action.sx
            }} />
    )
}

const ToolbarAction = ({ action }) => {
    const matches1024 = useMediaQuery(`(min-width:  ${breakpoints.minLaptopBreakpoint})`);

    let Component;

    switch (action.type) {
        case "search":
            Component = ToolbarSearch;
            break;

        case "text":
            Component = ToolbarText;
            break;

        case "select":
            Component = ToolbarSelect;
            break;

        default:
            Component = ToolbarButton;
            break;
    }

    const sx = matches1024
        ? null
        : {
            width: "100%"
        };

    return(
        <Component
            action={action}
            sx={sx}
        />
    );
};

const ToolbarSelect = ({ action, sx }) => {
    return (
        <SelectInput
            value={action.value}
            onChange={(event) => action.callback(event.target.value)}
            sx={{...sx, ...action.sx}}
        >
            {action.options.map(item => (
                <MenuItem
                    value={item.value}
                    key={item.value}
                    sx={{
                        minHeight: "initial",
                        height: "36px"
                    }}
                >
                    {item.title}
                </MenuItem>
            ))}
        </SelectInput>
    );
};

export const ToolbarSearch = ({ action, sx }) => {
    const handleSearchClick = (event) => {
        action.callback(event.target.value);
    };

    return(
        <SearchInput
            sx={{
                "&.MuiInputBase-root": {
                    backgroundColor: "#DFDFDF",
                    ...sx,
                    ...action.sx
                }
            }}
            value={action.value}
            onSearchClick={handleSearchClick}
        />
    );
};

export const ToolbarText = ({ action, sx }) => {
    return(
        <Typography className="table-toolbar-text" sx={{...sx, ...action.sx}}>
            {action.title}
        </Typography>
    );
};

export const ToolbarButton = ({ action, sx }) => {
    const variant = action.variant || "outlined";

    const buttonSx = action.variant === "contained"
        ? {
            backgroundColor: "var(--font-2)",
            color: "var(--bg-2)",

            "&:hover": {
                backgroundColor: "var(--font-2)",
            },
        }
        : {
            color: '#363636',
            borderColor: '#363636',
            "&:hover": {
                color: "#FFFFFF",
                backgroundColor: "#363636"
            }
        };

    return(
        <Button
            variant={variant}
            sx={{
                ...buttonSx,
                ...sx,
                ...action.sx
            }}
            onClick={(event) => {
                action.callback(event)
            }}
            disabled={action.disabled}
        >
            {action.title}
        </Button>
    );
};

const actionType = PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.oneOf(['search', 'text', 'select', 'button']),
    position: PropTypes.oneOf(['begin', 'center', 'end']),
    title: PropTypes.any,
    value: PropTypes.string,
    variant: PropTypes.string,
    callback: PropTypes.func,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    sx: PropTypes.object,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        title: PropTypes.string
    }))
})

ToolbarActions.propTypes = {
    toolbarActions: PropTypes.arrayOf(actionType)
};

ToolbarActionsPosition.propTypes = {
    position: PropTypes.string,
    toolbarActions: PropTypes.arrayOf(actionType)
};

ToolbarAction.propTypes = {
    action: actionType
};

ToolbarSelect.propTypes = {
    action: actionType,
    sx: PropTypes.object
};

ToolbarSearch.propTypes = {
    action: actionType,
    sx: PropTypes.object
};

ToolbarText.propTypes = {
    action: actionType,
    sx: PropTypes.object
};

ToolbarButton.propTypes = {
    action: actionType,
    sx: PropTypes.object
};

TableToolbar.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any
}

ToolbarDatePicker.propTypes = {
    action: actionType,
    sx: PropTypes.object
}

export default ToolbarActions;