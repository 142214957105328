import React from "react";
import { FormattedMessage } from "react-intl";


const StartPage = () => {
    return (
        <div>
            <FormattedMessage id="common-start-page" />
        </div>
    )
}

export default StartPage;