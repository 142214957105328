import React from "react";
import { useIntl } from "react-intl";
import { Collapse } from "@mui/material";
import PropTypes from "prop-types";

import { PageContentBox } from "../../common/page-content-box";
import ReportAccordion from "../../sod-reports/report-accordion";

import "./styles.less";

const ReportModelingBox = ({
    open,
    onOpenClick,
    children
}) => {
    const intl = useIntl();
    const accordionTitle = intl.formatMessage({ id: "report-modeling-box.accordion-title" });

    return (
        <PageContentBox className="report-modeling-box">
            <ReportAccordion
                open={open}
                onOpenClick={onOpenClick}
                title={accordionTitle}
            />

            <Collapse in={open}>
                {children}
            </Collapse>
        </PageContentBox>
    );
};

ReportModelingBox.propTypes = {
    open: PropTypes.bool,
    onOpenClick: PropTypes.func,
    children: PropTypes.node
};

export default ReportModelingBox;