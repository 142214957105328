import React, { useState, useMemo, createContext } from "react";
import PropTypes from "prop-types";

import FilterMenu from "./filter-menu.jsx";
import FilterSelectDialog from "./filter-select-dialog.jsx";
import DateFilterPopover from "./date-filter-popover.jsx";
import UserRolesPopover from "./user-roles-popover.jsx";

import "./styles.less";

export const initialFilterState = {
    column: null,
    currentFilters: [],
    anchorEl: null,
    filterDialogOpen: false,
};

export const FiltersContext = createContext(); 

const FiltersProvider = ({
    children,
    getFilters,
    filterData,
    setFilterData,
    sortData,
    setSortData,
}) => {
    const [filterState, setFilterState] = useState(initialFilterState);

    const handleFilterClick = (event, column) => {
        if (!column.filterable && !column.sortable) return;

        setFilterState({
            anchorEl: event.currentTarget,
            column: column,
            currentFilters: filterData.filter(filter => filter.field === column.id),
            currentSort: sortData?.find(sort => sort.field === column.id),
            filterDialogOpen: false
        });
    };

    const handleResetAllFilters = () => {
        setFilterData([]);
        initFilterState();
    };

    const handleSortClick = () => {
        if (setSortData) {
            const newSortData = [{
                field: filterState.column.id,
                sign: filterState.currentSort?.sign === "desc" ? "asc" : "desc"
            }];

            setSortData(newSortData);
        }

        initFilterState();
    };

    const handleResetSortClick = () => {
        if (setSortData) {
            const newSortData = sortData.filter(sort => sort.field !== filterState.column.id);
            setSortData(newSortData);
        }

        initFilterState();
    };

    const handleAddFilterClick = () => {
        setFilterState({
            ...filterState,
            anchorEl: null,
            filterDialogOpen: true
        });
    };

    const handleResetFilterClick = () => {
        const newFilterData = filterData.filter(filter => filter.field !== filterState.column.id);
        setFilterData(newFilterData);
        initFilterState();
    };

    const handleSubmitFilterDialog = (selectedIds) => {
        const newFilterData = filterData.filter(filter => filter.field !== filterState.column.id);

        if (selectedIds.length > 1) {
            newFilterData.push({
                field: filterState.column.id,
                sign: "IN",
                value: selectedIds
            });
        } else if (selectedIds.length === 1) {
            newFilterData.push({
                field: filterState.column.id,
                sign: "EQ",
                value: selectedIds[0]
            });
        }

        setFilterData(newFilterData);
        initFilterState();
    };

    const handleCloseDateFilter = (startDate, endDate) => {
        const newFilterData = filterData.filter(filter => filter.field !== filterState.column.id);

        if (startDate) {
            newFilterData.push({
                field: filterState.column.id,
                sign: "GE",
                value: startDate
            });
        }

        if (endDate) {
            newFilterData.push({
                field: filterState.column.id,
                sign: "LE",
                value: endDate
            });
        }

        setFilterData(newFilterData);
        initFilterState();
    };

    const handleSelectUserRoleFilter = (userRoleName) => {
        const newFilterData = filterData.filter(filter => filter.field !== filterState.column.id);

        if (userRoleName) {
            newFilterData.push({
                field: filterState.column.id,
                sign: "EQ",
                value: userRoleName
            });
        }

        setFilterData(newFilterData);
        initFilterState();
    };

    const initFilterState = () => setFilterState(initialFilterState);

    const showPopover = filterState.anchorEl;
    const showDatepicker = showPopover && filterState.column?.type === "datetime";
    const showUserRoles = showPopover && filterState.column?.id === "role";
    const showFilterMenu = showPopover && !showDatepicker && !showUserRoles;

    const filtersNumber = useMemo(() => {
        if (!filterData) {
            return 0;
        }

        return filterData.reduce((number, filter) => {
            if (Array.isArray(filter.value)) {
                return number + filter.value.length;
            }

            return number + 1;
        }, 0);
    }, [filterData]);

    return (
        <> 
            <FiltersContext.Provider
                value={{
                    filtersNumber,
                    handleFilterClick,
                    handleResetAllFilters
                }}
            >
                {children}
            </FiltersContext.Provider>

            {showDatepicker && (
                <DateFilterPopover
                    anchorEl={filterState.anchorEl}
                    open={showDatepicker}
                    currentFilters={filterState.currentFilters}
                    onClose={handleCloseDateFilter}
                />
            )}

            {showUserRoles && (
                <UserRolesPopover
                    anchorEl={filterState.anchorEl}
                    open={showUserRoles}
                    currentFilters={filterState.currentFilters}
                    onClose={initFilterState}
                    onSelect={handleSelectUserRoleFilter}
                />
            )}

            {showFilterMenu && (
                <FilterMenu
                    anchorEl={filterState.anchorEl}
                    open={showFilterMenu}
                    showResetFilters={filterState.currentFilters?.length > 0}
                    showResetSort={!!filterState.currentSort}
                    sortSign={filterState.currentSort?.sign}
                    isFilterable={filterState.column.filterable}
                    isSortable={filterState.column.sortable}
                    onClose={initFilterState}
                    onAdd={handleAddFilterClick}
                    onReset={handleResetFilterClick}
                    onSort={handleSortClick}
                    onResetSort={handleResetSortClick}
                />
            )}

            {filterState.filterDialogOpen && (
                <FilterSelectDialog
                    open={filterState.filterDialogOpen}
                    currentFilters={filterState.currentFilters}
                    column={filterState.column}
                    onClose={initFilterState}
                    onSubmit={handleSubmitFilterDialog}
                    getFilters={getFilters}
                />
            )}
        </>
    );
};

FiltersProvider.propTypes = {
    children: PropTypes.node,
    getFilters: PropTypes.func,
    filterData: PropTypes.arrayOf(PropTypes.shape({
        field: PropTypes.string,
        sign: PropTypes.oneOf(['EQ', "GE", "LE", "CO", "NE"]),
        value: PropTypes.string
    })),
    setFilterData: PropTypes.func,
    sortData: PropTypes.arrayOf(PropTypes.shape({
        field: PropTypes.string,
        sign: PropTypes.oneOf(['asc', "desc"]),
    })),
    setSortData: PropTypes.func
};

export default FiltersProvider;