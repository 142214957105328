import React from "react";
import { useIntl } from "react-intl";
import { Box, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";

import { ColorInput, FormHelperTextEmpty, FormHelperTextError, TextInput } from "../../../common/form-controls";
import { TrashIcon } from "../../../common/icons";
import { IconButton } from "../../../common/buttons";
import breakpoints from "../../../common/styles/breakpoints.js";

import "./style.less";

const DeleteButton = ({ onClick, disabled }) => (
    <IconButton
        className="delete-risk-button" 
        onClick={onClick}
        disabled={disabled}
    >
        <TrashIcon className="delete-risk-icon" />
    </IconButton>
);

const RiskLevelInput = ({ showLabel, riskLevel, setRiskLevel, onDeleteClick, disabled }) => {
    const intl = useIntl();

    const matches1024 = useMediaQuery(`(min-width:  ${breakpoints.minLaptopBreakpoint})`);

    const handleChangeDescription = event => setRiskLevel({...riskLevel, description: event.target.value});
    const handleChangeColor = value => setRiskLevel({...riskLevel, color: value});

    const hasDescriptionError = Boolean(riskLevel.descriptionError);
    const hasColorError = Boolean(riskLevel.colorError);
    const hasError = hasDescriptionError ||  hasColorError;

    const colorInputClassName = hasColorError
        ? "risk-level-color-input risk-level-color-input-error"
        : "risk-level-color-input";

    return(
        <Box className="risk-level-item-wrapper">
            <Box className="risk-level-item">
                <TextInput
                    className="risk-level-input"
                    label={showLabel && matches1024
                        ? intl.formatMessage({ id: "risk-level-input.description.text" })
                        : null
                    }
                    value={riskLevel.description}
                    onChange={handleChangeDescription}
                    disabled={disabled}
                    endAdornment={matches1024 ? null : (
                        <DeleteButton
                            onClick={onDeleteClick}
                            disabled={disabled}
                        />)
                    }
                    error={hasDescriptionError}
                />

                <ColorInput
                    className={colorInputClassName}
                    color={riskLevel.color}
                    setColor={handleChangeColor}
                    error={hasColorError}
                    disabled={disabled}
                />

                {matches1024 && (
                    <DeleteButton
                        onClick={onDeleteClick}
                        disabled={disabled}
                    />
                )}
            </Box>

            {hasError && (
                <FormHelperTextEmpty>
                    {hasDescriptionError && (
                        <FormHelperTextError message={riskLevel.descriptionError.message} />
                    )}
                    {hasColorError && (
                        <FormHelperTextError message={riskLevel.colorError.message} />
                    )}
                </FormHelperTextEmpty>
            )}
        </Box>
    );
};

DeleteButton.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool
};

RiskLevelInput.propTypes = {
    showLabel: PropTypes.bool,
    riskLevel: PropTypes.object,
    setRiskLevel: PropTypes.func,
    onDeleteClick: PropTypes.func,
    disabled: PropTypes.bool,
};

export default RiskLevelInput;
