import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import {
    setSelectedIds as setSelectedIdsAction,
    setSearchString as setSearchStringAction,
    setPageNumber as setPageNumberAction,
    setLimitNumber as setLimitNumberAction,
    setSortData as setSortDataAction,
    setFilterData as setFilterDataAction,
    setError as setErrorAction,
    fetchReportColumns,
    fetchReportResults,
    getTodayDateString,
    getSummaryReportRowId,
} from "../../../reducers/reports/userBusynessLevelSlice";

import useReportBusynessReduxTable from "../../common/hooks/useReportBusynessReduxTable";

import CommonTable from "../../common/table/table.jsx";
import { getCellClassNameInner } from "../../common/table/table-components.jsx";
import useFilters from "../../common/hooks/useFilters.js";


const getCellClassName = (row, column, selected) => {
    let className = getCellClassNameInner(row, column, selected);

    if (row["err_status"] === "in_sap") {
        className += " table-cell-in-sap";
    }

    if (row["err_status"] === "in_sod") {
        className += " table-cell-in-sod";
    }

    if (row["err_status"] === "in_late") {
        className += " table-cell-in-late";
    }

    if (row["err_status"] === "in_early") {
        className += " table-cell-in-early";
    }

    return className;
};

const UserLevelBusynessReportResults = () => {

    const {
        rows, columns,
        total,
        selectedIds, setSelectedIds,
        pageNumber, setPageNumber,
        limitNumber, setLimitNumber,
        filterData, setFilterData,
        sortData, setSortData,
    } = useReportBusynessReduxTable(
        (state) => state.reports.userBusynessLevel.rows, fetchReportResults,
        (state) => state.reports.userBusynessLevel.columns, fetchReportColumns,
        (state) => state.reports.userBusynessLevel.selectedIds, setSelectedIdsAction,
        (state) => state.reports.userBusynessLevel.searchString, setSearchStringAction,
        (state) => state.reports.userBusynessLevel.pageNumber, setPageNumberAction,
        (state) => state.reports.userBusynessLevel.limitNumber, setLimitNumberAction,
        (state) => state.reports.userBusynessLevel.sortData, setSortDataAction,
        (state) => state.reports.userBusynessLevel.filterData, setFilterDataAction,
        (state) => state.reports.userBusynessLevel.error, setErrorAction,
        (state) => state.reports.userBusynessLevel.total,
        (state) => state.reports.userBusynessLevel.busy,
        (state) => state.reports.userBusynessLevel.calcDate
    );

    const calcDateStr = useSelector(state => state.reports.userBusynessLevel.calcDate);
    const compareDates = useSelector(state => state.reports.userBusynessLevel.compareDates)
    const isComparing = useSelector(state => state.reports.userBusynessLevel.isComparing)

    let viewType = null

    if (isComparing) {
        viewType = "comparing"
    }

    if (compareDates) {
        viewType = "delta"
    }

    const filterParams = {
        calcDate: calcDateStr,
        viewType: viewType
    }

    if (compareDates) {
        filterParams.earlyDate = compareDates.earlyDate
        filterParams.lateDate = compareDates.lateDate
    }

    const getFilters = useFilters("user-busyness-report", filterParams);

    //const navigate = useNavigate()

    // const handleRowClick = useCallback((row) => {
    //     const pageKey = JSON.stringify([{
    //         riskId: row.risk_id,
    //         employee: row.employee_id,
    //         systemId: row.system_id
    //     }])

    //     navigate(`${encodeURIComponent(pageKey)}`)
    // }, [navigate])


    const handleSelectRow = useCallback((row, value) => {
        if (value) {
            setSelectedIds([...selectedIds, row.id]);
            return;
        }

        setSelectedIds(selectedIds.filter(id => id !== row.id));
    }, [selectedIds, setSelectedIds]);

    const handleSelectAll = useCallback((value) => {
        if (!value) {

            const newIds = []
            const rowIdSet = new Set(rows.map(row => row.id))

            selectedIds.forEach(id => {
                if (rowIdSet.has(id)) {
                    return
                }

                newIds.push(id)
            })

            setSelectedIds(newIds);

            return;
        }

        setSelectedIds([...selectedIds, ...rows.map(row => row.id)]);

    }, [rows, setSelectedIds, selectedIds]);

    const isToday = getTodayDateString() === calcDateStr

    const finalColumns = useMemo(() => {
        const selectColumn = {
            "id": "select",
            "title": "",
            "type": "select",
            "active": true
        }

        const dateColumn = {
            "id": "calc_date",
            "title": "Дата рассчета",
            "type": "date",
            "active": true,
            "width": "100px",
            "sortable": true,
            "filterable": true,
        }

        if (compareDates) {
            return [dateColumn, ...columns]
        }

        if (isToday) {
            return [selectColumn, ...columns]
        }

        return columns
    }, [isToday, columns, compareDates])

    return (
        <CommonTable
            className="report-results-table"
            toolbarActions={null}
            rows={rows}
            getRowId={getSummaryReportRowId}
            columns={finalColumns}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            limitNumber={limitNumber}
            setLimitNumber={setLimitNumber}
            total={total}
            showFiltersActions={false}
            filterData={filterData}
            setFilterData={setFilterData}
            sortData={sortData}
            setSortData={setSortData}
            selectedIds={selectedIds}
            onSelectRow={handleSelectRow}
            onSelectAll={handleSelectAll}
            getFilters={getFilters}
            getCellClassName={getCellClassName}
            handleFilterBeforeApply={(filter) => {
                if (filter.field === "risk_level_text") {
                    return {
                        ...filter,
                        field: "risk_level_id"
                    }
                }

                return filter
            }}
        />

        // <ReportBusynessResults
        //     open={open}
        //     setOpen={setOpen}
        //     rows={rows}
        //     columns={columns}
        //     total={total}
        //     busy={busy}
        //     selectedIds={selectedIds}
        //     setSelectedIds={setSelectedIds}
        //     searchString={searchString}
        //     setSearchString={setSearchString}
        //     pageNumber={pageNumber}
        //     setPageNumber={setPageNumber}
        //     limitNumber={limitNumber}
        //     setLimitNumber={setLimitNumber}
        //     filterData={filterData}
        //     setFilterData={setFilterData}
        //     sortData={sortData}
        //     setSortData={setSortData}
        //     onExportClick={handleExportClick}
        //     onCalcDetailedReports={onCalcDetailedReports}
        //     filterId="user-busyness-report"
        // />
    );
};

export default UserLevelBusynessReportResults;
