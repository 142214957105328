import React from "react";
import { useNavigate } from "react-router";
import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { PageContentBox } from "../page-content-box";
import AppDrawerHeader from "../app-drawer/app-drawer-header.jsx";
import { Button } from "../buttons";

import "./styles.less";

const ErrorPage = ({
    title,
    subtitle,
    description
}) => {
    const intl = useIntl();
    const navigateToHomeTitle = intl.formatMessage({ id: "error-page.navigate-to-home.title" });

    const navigate = useNavigate();
    const handleNavigateToHomeClick = () => {
        navigate("/");
    };

    return (
        <Box className="error-page">
            <AppDrawerHeader className="error-page-header" />

            <Box className="error-page-content-wrapper">
                <PageContentBox className="error-page-content">
                    {title && (
                        <Typography className="error-page-title">
                            {title}
                        </Typography>
                    )}

                    {subtitle && (
                        <Typography className="error-page-subtitle">
                            {subtitle}
                        </Typography>
                    )}

                    {description && (
                        <Typography className="error-page-description">
                            {description}
                        </Typography>
                    )}

                    <Button
                        variant="contained"
                        className="error-page-button"
                        onClick={handleNavigateToHomeClick}
                    >
                        {navigateToHomeTitle}
                    </Button>
                </PageContentBox>
            </Box>
        </Box>
    );
};

ErrorPage.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string
};

export default ErrorPage;