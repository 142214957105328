import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

import { ReportModelingLeafIcon } from "../../common/icons";

import "./styles.less";

const PfcgViewLeafIconBox = ({ sx, endContent }) => {
    return (
        <Box
            className="pfcg-view-leaf-icon-box"
            sx={sx}
        >
            <ReportModelingLeafIcon className="pfcg-view-leaf-icon-box-leaf-icon" />
            {endContent}
        </Box>
    );
};

PfcgViewLeafIconBox.propTypes = {
    sx: PropTypes.object,
    endContent: PropTypes.node
};

export default PfcgViewLeafIconBox;