import React from "react";
import { Box } from "@mui/material";

import "./style.less";

const EntityFormHeader = (props) => (
    <Box
        className="entity-form-header"
        {...props}
    />
);

export default EntityFormHeader;
