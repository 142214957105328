import React from 'react'
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import { SearchInput } from "../form-controls";
import { ResetFiltersButton } from '../buttons';

import "./styles.less";

const FiltersActions = ({ searchString, onSearch, onReset, filtersNumber }) => {
    const handleSearchClick = (event) => {
        onSearch(event.target.value);
    };

    return (
        <Box className="filters-actions">
            <SearchInput
                value={searchString}
                onSearchClick={handleSearchClick}
                className="filters-actions-search-input"
            />

            {filtersNumber > 0 && (
                <ResetFiltersButton
                    onClick={onReset}
                    filtersNumber={filtersNumber}
                />
            )}
        </Box>
    );
};

FiltersActions.propTypes = {
    searchString: PropTypes.string, 
    onSearch: PropTypes.func, 
    onReset: PropTypes.func, 
    filtersNumber: PropTypes.number
}

export default FiltersActions;
