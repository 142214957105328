import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { Button } from "../buttons";

import { CommonDialog, CommonDialogTitle, CommonDialogContent, CommonDialogActions } from "./common-dialog.jsx";

import "./style.less";

const ErrorsDialogContentList = ({ errors }) => {
    return (
        <List>
            {errors.map(error => (
                <ListItem
                    key={error}
                    className="errors-dialog-message-item"
                >
                    <ListItemText className="errors-dialog-message-item-text">
                        {error}
                    </ListItemText>
                </ListItem>
            ))}
        </List>
    )
};

const ErrorsDialogContentError = ({ error }) => {
    return (
        <Typography component="p" className="errors-dialog-message">
            {error}
        </Typography>
    )
};

const ErrorsDialog = ({
    error,
    open,
    onClose,
    ...props
}) => {
    const intl = useIntl();
    
    if (!error) {
        return null;
    }

    const finalTitle = error.title || intl.formatMessage({ id: "common.error.title" });
    const detail = error.detail;

    return (
        <CommonDialog open={!!open} className="erros-dialog" {...props}>
            <CommonDialogTitle
                title={finalTitle}
                onClose={onClose}
            />

            <CommonDialogContent>
                {Array.isArray(detail) ? (
                    <ErrorsDialogContentList errors={detail} />
                ) : (
                    <ErrorsDialogContentError error={detail} />
                )}
            </CommonDialogContent>

            <CommonDialogActions>
                <Button
                    variant="outlined"
                    onClick={onClose}
                    sx={{
                        color: 'var(--font-4)',
                        borderColor: 'var(--font-4)',
                    }}
                >
                    <FormattedMessage id="common.btn-cancel" />
                </Button>
            </CommonDialogActions>
        </CommonDialog>
    )
};

ErrorsDialogContentList.propTypes = {
    errors: PropTypes.arrayOf(PropTypes.string)
};

ErrorsDialogContentError.propTypes = {
    error: PropTypes.string
};

ErrorsDialog.propTypes = {
    error: PropTypes.shape({
        title: PropTypes.string,
        detail: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string)
        ]),
    }), 
    open: PropTypes.bool,
    onClose: PropTypes.func,
}

export default ErrorsDialog;
