import React, { useRef, useEffect, useCallback, useState } from "react";
import { Box, Typography, } from "@mui/material";
import PropTypes from "prop-types";

import { CloseIcon } from "../icons";

import { Input, InputLabel, FormControl } from "./index";

import "./styles.less"

const FileTag = ({ filename, onDelete, size = "medium" }) => {
    const classes = ["tag-file-container", `tag-file-container-${size}`];

    return (
        <Box className={classes.join(" ")}>
            <Typography className="tag-text">
                {filename}
            </Typography>

            <CloseIcon
                className="tag-close-icon"
                onClick={onDelete}
            />
        </Box>
    )
}

const FileUploadInput = ({ id, file, setFile, error }) => {
    const dndAreaRef = useRef(null);
    const inputRef = useRef(null);
    const [inputNode, setInputNode] = useState()

    const removeFile = () => {
        setFile(null)
        const inputEl = inputRef.current.children[0]

        if (inputEl) {
            inputEl.value = ""
        }
    }

    const handleCommonDnd = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDrop = useCallback((e) => {
        const files = e.dataTransfer.files;

        if (files && files.length > 0) {
            setFile(files[0]);
        }
    }, [setFile]);

    const handleDrag = useCallback( () => {
        dndAreaRef.current.classList.add("is-dragover");
    }, []);

    const handleDragEnd = useCallback( () => {
        dndAreaRef.current.classList.remove("is-dragover");
    }, []);

    useEffect(() => {
        if (!inputNode) return

        if (!dndAreaRef.current) return;

        const dndAreaRefCurrent = dndAreaRef.current;

        dndAreaRefCurrent.addEventListener("drag", handleCommonDnd);
        dndAreaRefCurrent.addEventListener("dragstart", handleCommonDnd);
        dndAreaRefCurrent.addEventListener("dragend", handleCommonDnd);
        dndAreaRefCurrent.addEventListener("dragover", handleCommonDnd);
        dndAreaRefCurrent.addEventListener("dragenter", handleCommonDnd);
        dndAreaRefCurrent.addEventListener("dragleave", handleCommonDnd);
        dndAreaRefCurrent.addEventListener("drop", handleCommonDnd);


        dndAreaRefCurrent.addEventListener("dragover", handleDrag);
        dndAreaRefCurrent.addEventListener("dragenter", handleDrag);


        dndAreaRefCurrent.addEventListener("dragleave", handleDragEnd);
        dndAreaRefCurrent.addEventListener("dragend", handleDragEnd);
        dndAreaRefCurrent.addEventListener("drop", handleDragEnd);

        dndAreaRefCurrent.addEventListener("drop", handleDrop);

        return () => {
            dndAreaRefCurrent.removeEventListener("drag", handleCommonDnd);
            dndAreaRefCurrent.removeEventListener("dragstart", handleCommonDnd);
            dndAreaRefCurrent.removeEventListener("dragend", handleCommonDnd);
            dndAreaRefCurrent.removeEventListener("dragover", handleCommonDnd);
            dndAreaRefCurrent.removeEventListener("dragenter", handleCommonDnd);
            dndAreaRefCurrent.removeEventListener("dragleave", handleCommonDnd);
            dndAreaRefCurrent.removeEventListener("drop", handleCommonDnd);

            dndAreaRefCurrent.removeEventListener("dragover", handleDrag);
            dndAreaRefCurrent.removeEventListener("dragenter", handleDrag);


            dndAreaRefCurrent.removeEventListener("dragleave", handleDragEnd);
            dndAreaRefCurrent.removeEventListener("dragend", handleDragEnd);
            dndAreaRefCurrent.removeEventListener("drop", handleDragEnd);

            dndAreaRefCurrent.removeEventListener("drop", handleDrop);
        };
    }, [inputNode, handleCommonDnd, handleDrag, handleDragEnd, handleDrop])

    const onChangeFile = (e) => {
        const files = e.target.files;

        if (!files || files.length === 0) {
            removeFile();
            return
        }

        setFile(files[0])
    }

    const dndAreaMounted = useCallback((node) => {
        dndAreaRef.current = node
        setInputNode(node)
    }, []);

    return (
        <Box className={`common-file-uploader-container ${file ? "with-file" : ""} ${error ? "with-error" : ""}`}>

            <Input type="file" id={id} className="common-file-uploader-input" onChange={onChangeFile} />

            <InputLabel ref={dndAreaMounted} htmlFor="fileInput" className="common-file-uploader-dnd">
                {
                    file && <FileTag filename={file.name} onDelete={removeFile} />
                }

                {
                    !file && <Typography className="common-file-uploader-nofile-title">Перетащите файл в область</Typography>
                }
            </InputLabel>

            <InputLabel shrink htmlFor={id} className="common-file-uploader-field">
                Выбрать на диске
            </InputLabel>
        </Box>
    )
}

const FileUploadField = ({title, error, errorMessage,  ...rest}) => {
    return (
        <FormControl variant>
            <InputLabel shrink htmlFor="fileInput" sx={{
                display: "flex",
                flexDirection: "row"
            }}>
                {title}

                {error && <Typography className="common-file-uploader-error-text">*{errorMessage}</Typography>}
            </InputLabel>

            <FileUploadInput 
                error={error} 
                {...rest}/>
        </FormControl>
    )
}

FileTag.propTypes = {
    filename: PropTypes.string,
    onDelete: PropTypes.func,
    size: PropTypes.string
};

FileUploadInput.propTypes = {
    id: PropTypes.string,
    file: PropTypes.object,
    setFile: PropTypes.func,
    error: PropTypes.bool
};

FileUploadField.propTypes = {
    title: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string
};

export {FileUploadInput}
export default FileUploadField