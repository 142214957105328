import React from "react";
import { TableToolbar, ToolbarButton, ToolbarDatePicker } from "../../common/table/action-toolbar.jsx";
import PropTypes from 'prop-types';
import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import CloseIcon from "../../common/icons/CloseIcon.jsx";
import IconButton from "../../common/buttons/icon-button.jsx";
import "./style.less"

const dateFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
};

const getStringFromDate = (date, intl) => {
    if (!date) return "";

    return intl.formatDate(date, dateFormatOptions);
};

const CompareDateTag = ({ earlyDate, lateDate, resetCompareDates }) => {
    const intl = useIntl()

    const earlyDateStr = getStringFromDate(earlyDate, intl)
    const lateDateStr = getStringFromDate(lateDate, intl)

    return (
        <Box className="user-busyness-report-comoare-date-tag-container">
            <Typography className="user-busyness-report-comoare-date-tag-text">
                {`${earlyDateStr}-${lateDateStr}`}
            </Typography>
            <IconButton sx={{
                marginLeft: "5px",
                width: "16px",
                height: "16px"
            }} onClick={resetCompareDates}>
                <CloseIcon />
            </IconButton>
        </Box>
    )
}

const UserLevelBusynessReportToolbar = ({
    calcDate,
    onChangeCalcDate,
    disabled,
    onCalcDetailedReport,
    calcDetailedReportBtnVisible = true,
    setOnOpenCompareReport,
    compareDates,
    resetCompareDates,
    isCalcDateVisible
}) => {

    return (
        <TableToolbar className="user-busyness-report-toolbar">
            {isCalcDateVisible && <ToolbarDatePicker action={{
                value: calcDate,
                onChange: onChangeCalcDate,
                disabled: disabled,
                sx: {}
            }} />} 

            {calcDetailedReportBtnVisible && <ToolbarButton action={{
                callback: onCalcDetailedReport,
                title: "Перейти на детальный отчет",
                disabled: disabled,
                sx: {
                    marginRight: "auto",
                    border: "none",
                }
            }} />}

            <ToolbarButton action={{
                callback: () => { setOnOpenCompareReport() },
                title: "Сравнить отчет по датам",
                disabled: disabled,
                sx: {
                    marginRight: "auto",
                    border: "none"
                }
            }} />

            {
                compareDates && <CompareDateTag
                    earlyDate={compareDates.earlyDate}
                    lateDate={compareDates.lateDate}
                    resetCompareDates={resetCompareDates} />
            }

        </TableToolbar>
    )
}

UserLevelBusynessReportToolbar.propTypes = {
    searchString: PropTypes.string,
    setSearchString: PropTypes.func,
    calcDate: PropTypes.object,
    onChangeCalcDate: PropTypes.func,
    disabled: PropTypes.bool,
    onCalcDetailedReport: PropTypes.func,
    calcDetailedReportBtnVisible: PropTypes.bool,
    compareDates: PropTypes.shape({
        earlyDate: PropTypes.object,
        lateDate: PropTypes.object,
    }),
    setOnOpenCompareReport: PropTypes.func,
    resetCompareDates: PropTypes.func,
    isCalcDateVisible: PropTypes.bool
}

CompareDateTag.propTypes = {
    earlyDate: PropTypes.object,
    lateDate: PropTypes.object,
    resetCompareDates: PropTypes.func
}

export default UserLevelBusynessReportToolbar