import React from "react";
import PropTypes from "prop-types";

import CommonTable from "../../common/table";

import "./styles.less";

const ReportModelingEditDialogTable = ({
    className="",
    ...props
}) => {
    return (
        <CommonTable
            className={`report-modeling-edit-dialog-table ${className}`}
            showPagination={false}
            showFiltersActions={false}
            {...props}
        />
    );
};

ReportModelingEditDialogTable.propTypes = {
    className: PropTypes.string,
    ...CommonTable.propTypes
};

export default ReportModelingEditDialogTable;