import SodService from "./sod-service";

class CriticalRuleService extends SodService{
    async getCriticalFilters(objectType, params) {
        return this.getFilters("/sod/critical-objects/filters", {
            ...params,
            object_type: objectType
        });
    }

    async getCriticalRoles(params, signal) {
        const config = this.generateConfigForGetEntities({
            ...params,
            object_type: "critical_role"
        }, signal);

        const data = await this.get("/sod/critical-objects/list", config);

        const roles = data && data.rows.map(item => this.parseCriticalRole(item));
        const total = data.total

        return { roles, total }
    }

    async getCriticalRoleChangeHistory(key) {
        const data = await this.get(`/sod/critical-objects/crrole-change-history/${key}`)
        return data && data.map(item => this.parseUserActionLog(item))
    }

    async getCriticalProfileChangeHistory(key) {
        const data = await this.get(`/sod/critical-objects/crprofile-change-history/${key}`)
        return data && data.map(item => this.parseUserActionLog(item))
    }

    async getCriticalRoleColumns() {
        return await this.getColumns("/sod/critical-objects/crrole-columns");
    }

    async getCriticalRoleFilters(params) {
        return this.getCriticalFilters("critical_role", params);
    }

    async getCriticalObjectDetailed(type, name, systemId) {
        const config = {
            params: {
                object_type: type,
                crit_object_id: name,
                system_id: systemId
            }
        }

        const data = await this.get("/sod/critical-objects/", config);
        return data;
    }

    async checkRelatedObjsLock(type, name, systemId) {
        const config = {
            params: {
                object_type: type,
                crit_object_id: name,
                system_id: systemId
            }
        }

        const data = await this.get("/sod/critical-objects/check-related-objs-lock", config);
        return data;
    }

    async getCriticalRoleDetailed(name, systemId) {
        const data = await this.getCriticalObjectDetailed("critical_role", name, systemId);
        return this.parseCriticalRole(data);
    }

    async getCriticalProfileDetailed(name, systemId) {
        const data = await this.getCriticalObjectDetailed("critical_profile", name, systemId);
        return this.parseCriticalProfile(data);
    }

    async getCriticalProfileColumns() {
        return await this.getColumns("/sod/critical-objects/crprofile-columns");
    }

    async getCriticalProfileFilters(params) {
        return this.getCriticalFilters("critical_profile", params);
    }

    async createCriticalRole(role, warningCheck) {
        const config = {
            params: {
                object_type: "critical_role",
                crit_object_id: role.name,
                system_id: role.system,
                warningCheck: warningCheck
            }
        }

        const data = await this.post(`/sod/critical-objects/`, this.parseCriticalRoleToServer(role), config);

        return this.parseCriticalRole(data);
    }

    async editCriticalRole(role, warningCheck) {
        const config = {
            params: {
                object_type: "critical_role",
                crit_object_id: role.name,
                system_id: role.system,
                warningCheck: warningCheck
            }
        }

        const data = await this.put(`/sod/critical-objects/`, this.parseCriticalRoleToServer(role), config);

        return this.parseCriticalRole(data);
    }

    async deleteCriticalRole(role){
        const config = {
            params: {
                object_type: "critical_role",
                crit_object_id: role.name,
                system_id: role.system
            }
        }

        return this.delete(`/sod/critical-objects/`, null, config);
    }

    parseCriticalRoleToServer(item) {
        return {
            crit_role_name: item.name,
            system_id: item.system,
            description: item.description,
            risk_level_id: item.riskLevel,
            active: item.active,
            matrix_set: item.matrices.map(matrixId => ({ matrix_id: matrixId }))
        }
    }

    parseCriticalRole(item) {
        const matrices = item.matrix_set?.map(matrix => matrix.matrix_id) || [];

        return {
            name: item.crit_role_name,
            description: item.description,
            active: item.active,
            system: item.system_id,
            riskLevel: item.risk_level_id,
            matrices,
            updTime: item.upd_time,
            updUser: item.upd_user
        }
    }

    /////


    async getCriticalProfiles(params, signal) {
        const config = this.generateConfigForGetEntities({
            ...params,
            object_type: "critical_profile"
        }, signal);

        const data = await this.get("/sod/critical-objects/list", config);

        const profiles = data && data.rows.map(item => this.parseCriticalProfile(item));
        const total = data.total

        return { profiles, total }
    }

    async createCriticalProfile(profile, warningCheck) {
        const config = {
            params: {
                object_type: "critical_profile",
                crit_object_id: profile.name,
                system_id: profile.system,
                warningCheck: warningCheck
            }
        }

        const data = await this.post(`/sod/critical-objects/`, this.parseCriticalProfileToServer(profile), config);

        return this.parseCriticalProfile(data);
    }

    async editCriticalProfile(profile, warningCheck) {
        const config = {
            params: {
                object_type: "critical_profile",
                crit_object_id: profile.name,
                system_id: profile.system,
                warningCheck: warningCheck
            }
        }

        const data = await this.put(`/sod/critical-objects/`, this.parseCriticalProfileToServer(profile), config);

        return this.parseCriticalProfile(data);
    }

    async deleteCriticalProfile(profile){
        const config = {
            params: {
                object_type: "critical_profile",
                crit_object_id: profile.name,
                system_id: profile.system
            }
        }

        return this.delete(`/sod/critical-objects/`, undefined, config);
    }

    parseCriticalProfileToServer(item) {
        return {
            crit_profile_name: item.name,
            system_id: item.system,
            description: item.description,
            risk_level_id: item.riskLevel,
            active: item.active,
            matrix_set: item.matrices.map(matrixId => ({ matrix_id: matrixId }))
        }
    }

    parseCriticalProfile(item) {
        const matrices = item.matrix_set?.map(matrix => matrix.matrix_id) || [];

        return {
            name: item.crit_profile_name,
            description: item.description,
            active: item.active,
            system: item.system_id,
            riskLevel: item.risk_level_id,
            matrices,
            updTime: item.upd_time,
            updUser: item.upd_user
        }
    }
}

const criticalRuleService = new CriticalRuleService();

export { criticalRuleService };
export default CriticalRuleService;