import React from "react";
import { createSvgIcon } from "@mui/material";

const SelectIcon = createSvgIcon(
    <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6 10L12.5 15L19 10"
            stroke="currentColor"
            strokeWidth="2"
        />
    </svg>,
    "Select"
);

export default SelectIcon;
