import React from "react";
import { SvgIcon } from "@mui/material";
import PropTypes from "prop-types";

const VisibilityIcon = ({ off=false, ...props }) => (
    <SvgIcon
        viewBox="0 0 30 30"
        {...props}
    >
        <svg
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.4232 17.1469C4.31132 17.4524 4.08474 17.7022 3.79161 17.8433C3.49849 17.9843 3.16191 18.0055 2.8534 17.9024C2.54489 17.7992 2.28877 17.5798 2.13945 17.2907C1.99012 17.0017 1.95937 16.6658 2.05373 16.3544C2.02873 16.4294 2.05373 16.3519 2.05373 16.3519C2.0983 16.2143 2.15002 16.0791 2.20869 15.9469C2.30867 15.7095 2.45614 15.382 2.65859 14.9945C3.0685 14.2196 3.71336 13.1871 4.65815 12.1547C6.57523 10.0674 9.70703 8 14.486 8C19.2674 8 22.3992 10.0674 24.3113 12.1547C25.3321 13.2744 26.1599 14.5559 26.7608 15.9469C26.8107 16.0644 26.8482 16.1594 26.8732 16.2294C26.8832 16.2494 26.9082 16.3794 26.9332 16.5044C26.9557 16.6194 26.9757 16.7294 26.9832 16.7494C26.9832 16.7494 27.1932 17.5818 26.1284 17.9343C25.8148 18.039 25.4725 18.0152 25.1765 17.8681C24.8804 17.721 24.6547 17.4626 24.5487 17.1494V17.1419L24.5312 17.1019C24.4057 16.7807 24.2622 16.4669 24.1013 16.1619C23.6577 15.3234 23.1093 14.5447 22.4692 13.8446C20.9445 12.1822 18.4526 10.4998 14.486 10.4998C10.5194 10.4998 8.02741 12.1822 6.50274 13.8446C5.67728 14.7497 5.00599 15.7842 4.51568 16.9069C4.48945 16.9714 4.46445 17.0364 4.4407 17.1019L4.4232 17.1469Z"
                fill="currentColor"
            />
            <path
                d="M14.4872 13C13.1614 13 11.8899 13.5267 10.9524 14.4644C10.0149 15.402 9.48828 16.6737 9.48828 17.9996C9.48828 19.3256 10.0149 20.5973 10.9524 21.5349C11.8899 22.4726 13.1614 22.9993 14.4872 22.9993C15.813 22.9993 17.0845 22.4726 18.0219 21.5349C18.9594 20.5973 19.4861 19.3256 19.4861 17.9996C19.4861 16.6737 18.9594 15.402 18.0219 14.4644C17.0845 13.5267 15.813 13 14.4872 13ZM11.9877 17.9996C11.9877 17.3367 12.2511 16.7008 12.7198 16.232C13.1885 15.7632 13.8243 15.4998 14.4872 15.4998C15.1501 15.4998 15.7858 15.7632 16.2545 16.232C16.7233 16.7008 16.9866 17.3367 16.9866 17.9996C16.9866 18.6626 16.7233 19.2985 16.2545 19.7673C15.7858 20.2361 15.1501 20.4995 14.4872 20.4995C13.8243 20.4995 13.1885 20.2361 12.7198 19.7673C12.2511 19.2985 11.9877 18.6626 11.9877 17.9996Z"
                fill="currentColor"
            />
            <rect
                x="24.9297"
                y="3.29289"
                width="3"
                height="32.0119"
                rx="1.5"
                transform="rotate(45 24.9297 3.29289)"
                fill={off ? "currentColor" : "none"}
                stroke="none"
            />
        </svg>
    </SvgIcon>
);

VisibilityIcon.propTypes = {
    off: PropTypes.bool
};

export default VisibilityIcon;
