import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const RequireAuth = () => {
    const user = useSelector(state => state.auth.user);
    const signedIn = useSelector(state => state.auth.signedIn);

    if (signedIn && !user) {
        return null;
    }
    
    if (signedIn) {
        return <Outlet />;  
    } else {
        return <Navigate to="/sign-in" />
    }
};

export default RequireAuth;
