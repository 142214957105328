import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { reportsModelingService } from "../../../services/reports-modeling-service";

import { getManualSelectPermissionsSearchHelp } from "../../../utils/searchHelps";

import SearchHelpDialog from "../../common/search-helps";

import PermissionWithoutClassErrorDialog from "./permission-without-class-error-dialog.jsx";

import "./styles.less";

const ManualSelectPermissionsDialog = ({
    open,
    systemId,
    onClose,
    onApply
}) => {
    const intl = useIntl();
    const title = intl.formatMessage({ id: "manual-select-permissions-dialog.title" });
    const [error, setError] = useState(null);
    const [selectedPermissions, setSelectedPermissions] = useState([]);

    const columns = [
        {
            id: "key",
            title: intl.formatMessage({ id: "manual-select-permissions-dialog.columns.key" })
        },

        {
            id: "text",
            title: intl.formatMessage({ id: "manual-select-permissions-dialog.columns.text" })
        }
    ];

    const readRows = useCallback(
        (params, signal) => getManualSelectPermissionsSearchHelp(params, signal, systemId),
        [systemId]
    );

    const handleSubmit = async (permissionIds) => {
        setSelectedPermissions(permissionIds);

        if (permissionIds.length === 0) {
            onApply([]);
            return;
        }

        try {
            const response = await reportsModelingService.getPermissionsTree(
                null, null, systemId, permissionIds
            );
            onApply(response.rows);
        } catch (error) {
            if (error.code === "permission_without_classes") {
                setError(error);
            } else {
                console.error(error);
            }
        }
    };

    const handleRemoveUnvalid = () => {
        const unvalidPermissions = error.detail;
        setSelectedPermissions(permissions => permissions.filter(
            permission => !unvalidPermissions.includes(permission)
        ));
        setError(null);
    };

    return (
        <>
            <SearchHelpDialog
                className="manual-select-permissions-dialog"
                title={title}
                columns={columns}
                open={open}
                readRows={readRows}
                onSubmit={handleSubmit}
                onCancel={onClose}
                selectOneRow={false}
                selectOnRowClick={true}
                currentIds={selectedPermissions}
            />

            {error && (
                <PermissionWithoutClassErrorDialog
                    open={Boolean(error)}
                    error={error}
                    onClose={() => setError(null)}
                    onRemoveUnvalid={handleRemoveUnvalid}
                />
            )}
        </>
    );
};

ManualSelectPermissionsDialog.propTypes = {
    open: PropTypes.bool,
    systemId: PropTypes.string,
    onClose: PropTypes.func,
    onApply: PropTypes.func
};

export default ManualSelectPermissionsDialog;