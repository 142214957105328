import React from "react";
import PropTypes from "prop-types";
import { Box, useMediaQuery } from "@mui/material";

import { TableRowCellText } from "../../common/table";
import breakpoints from "../../common/styles/breakpoints";

import PfcgViewNodeButton from "../../reports-modeling/pfcg-view/pfcg-view-node-button.jsx";

import RoleMenuLeafIconBox from "./role-menu-leaf-icon-box.jsx";

import "./styles.less";

const RoleMenuNodeCellContent = ({
    open,
    title,
    level,
    leaf,
    onClick
}) => {
    const matchesLaptop = useMediaQuery(`(min-width: ${breakpoints.minLaptopBreakpoint})`);

    const marginStep = matchesLaptop ? 24 : 16;

    const levelInner = leaf && level > 0
        ? level - 1
        : level;

    const firstItemSx = {
        marginLeft: `${levelInner * marginStep}px`
    };

    return (
        <Box className="role-menu-node-cell-content">
            {leaf ? (
                <RoleMenuLeafIconBox sx={firstItemSx} />
            ) : (
                <PfcgViewNodeButton
                    sx={firstItemSx}
                    open={open}
                    onClick={onClick}
                />
            )}
            
            <TableRowCellText>
                {title}
            </TableRowCellText>
        </Box>
    );
};

RoleMenuNodeCellContent.propTypes = {
    ...PfcgViewNodeButton.propTypes,
    leaf: PropTypes.bool,
    title: PropTypes.string
};

export default RoleMenuNodeCellContent;