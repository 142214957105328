import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { Button } from "../../common/buttons";
import { SelectIcon, DropdownListIcon } from "../../common/icons";

export const OpenSelectIcon = ({ open, ...props }) => {
    return(
        <SelectIcon
            style={{ transform: open ? "" : "rotate(-90deg)" }}
            {...props}
        />
    );
};

export const PlanIntegrationButton = ({ className="", onClick }) => {
    return (
        <Button
            className={`planned-integration-button ${className}`}
            onClick={onClick}
            endIcon={<SelectIcon />}
        >
            <FormattedMessage id="integration-details-row.btn-sheduled" />
        </Button>
    );
};

export const RunIntegrationButton = ({ onClick, disabled }) => (
    <Button
        variant="contained"
        className="run-integration-button"
        onClick={onClick}
        disabled={disabled}
    >
        <FormattedMessage id="integration.actions-box.btn-run-sync" />
    </Button>
);

export const SetUpPlanIntegrationButton = ({ onClick }) => (
    <Button
        startIcon={<DropdownListIcon color="var(--font-2)" />}
        variant="outlined"
        className="plan-integration-button"
        onClick={onClick}
    >
        <FormattedMessage id="integration.actions-box.btn-set-up-sync-scheduling" />
    </Button>
);

OpenSelectIcon.propTypes = {
    open: PropTypes.bool
};

PlanIntegrationButton.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func
};

RunIntegrationButton.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool
};

SetUpPlanIntegrationButton.propTypes = {
    onClick: PropTypes.func
};
