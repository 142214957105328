import React from "react";
import { SvgIcon } from "@mui/material";

const ReportModelingNodeSelectIcon = (props) => (
    <SvgIcon
        viewBox="0 0 8 4"
        {...props}
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 8 4"
            fill="none"
        >
            <path
                d="M1 1L3.70878 2.80586C3.88513 2.92342 4.11487 2.92342 4.29122 2.80585L7 1"
                stroke="currentColor"
                strokeLinecap="round"
            />
        </svg>
    </SvgIcon>
);

export default ReportModelingNodeSelectIcon;