import React from 'react';
import { createSvgIcon } from '@mui/material';

const ReportsModelingIcon = createSvgIcon(
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M4 21.5C3.45 21.5 2.97933 21.3043 2.588 20.913C2.19667 20.5217 2.00067 20.0507 2 19.5V5.5C2 4.95 2.196 4.47933 2.588 4.088C2.98 3.69667 3.45067 3.50067 4 3.5H18C18.55 3.5 19.021 3.696 19.413 4.088C19.805 4.48 20.0007 4.95067 20 5.5V7.5H22V9.5H20V11.5H22V13.5H20V15.5H22V17.5H20V19.5C20 20.05 19.8043 20.521 19.413 20.913C19.0217 21.305 18.5507 21.5007 18 21.5H4ZM6 17.5H11V13.5H6V17.5ZM12 10.5H16V7.5H12V10.5ZM6 12.5H11V7.5H6V12.5ZM12 17.5H16V11.5H12V17.5Z"
            fill="currentColor"
        />
    </svg>,
    "ReportsModeling"
);

export default ReportsModelingIcon;
