import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchSystems } from "../../../reducers/appSlice";

const useSystems = () => {
    const systems = useSelector(state => state.app.systems);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchSystems());
    }, [dispatch]);

    return systems;
};

export default useSystems;