import { useCallback, useEffect, useState } from "react";

const useScrollPagination = ({
    open=true,
    getData
}) => {
    const [items, setItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [readAll, setReadAll] = useState(false);
    const [busy, setBusy] = useState(false);

    const handleScroll = useCallback((event) => {
        if (readAll | busy) {
            return;
        }

        const { scrollTop, scrollHeight, clientHeight } = event.target;

        if (Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
            setCurrentPage(page => page + 1);
        }
    }, [readAll, busy]);

    useEffect(() => {
        if (open) {
            setItems([]);
            setCurrentPage(1);
            setReadAll(false);
            setBusy(false);
        }
    }, [open, getData]);

    useEffect(() => {
        if (!open) {
            return;
        }

        const getDataInner = async (signal) => {
            setBusy(true);

            try {
                const { rows, total } = await getData(currentPage, signal);
    
                setItems(items => {
                    setReadAll(
                        items.length + rows.length >= total
                    );
    
                    return [...items, ...rows];
                });
            } catch (error) {
                console.error(error);
            }

            setBusy(false);
        };

        const controller = new AbortController();
        getDataInner(controller.signal);
        return () => controller.abort();
    }, [open, currentPage, getData]);

    return {
        items,
        setItems,
        busy,
        handleScroll
    };
};

export default useScrollPagination;