import React from "react";
import { InputLabel as MuiInputLabel } from "@mui/material";
import PropTypes from "prop-types";

export const inputLabelClassName = "input-label";

const InputLabel = React.forwardRef(({
    className="",
    ...props
}, ref) => {
    return (
        <MuiInputLabel
            ref={ref}
            className={`${inputLabelClassName} ${className}`}
            {...props}
        />
    )
});

InputLabel.propTypes = {
    className: PropTypes.string
};

export default InputLabel;
