import React from "react"
import { useIntl, FormattedMessage } from "react-intl";
import { Box, Typography } from "@mui/material";
import {CircularProgress} from "@mui/material";
import PropTypes from 'prop-types';


const UserLevelBusynessReportTitle = ({ lastCalcDate, calcStatus }) => {
    const intl = useIntl()

    const lastCalcDateText = lastCalcDate && intl.formatDate(lastCalcDate, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        // hour: "2-digit",
        // minute: "2-digit"
    });

    return (
        <Box sx={{ marginRight: "auto", gap: "15px", display: "flex", flexDirection: "row" }}>
            <Typography className="summary-report-header-box-title" sx={{}}>
                <FormattedMessage id="summary-report.header.title" />
            </Typography>

            {
                calcStatus && calcStatus === "pending" &&

                <CircularProgress
                    sx={{ color: "var(--font-4)" }}
                    size="24px" />
            }

            {
                calcStatus && calcStatus === "success" &&

                <Typography className="summary-report-header-box-descripttion" sx={{}}>
                    <FormattedMessage id="summary-report.header.description" values={{ date: lastCalcDateText }} />
                </Typography>
            }

            {
                calcStatus && calcStatus === "error" &&

                <Typography className="summary-report-header-box-descripttion" sx={{}}>
                    <FormattedMessage id="summary-report.header.error" />
                </Typography>
            }
        </Box>
    )
}

UserLevelBusynessReportTitle.propTypes = {
    calcStatus: PropTypes.string,
    lastCalcDate: PropTypes.string,
}

export default UserLevelBusynessReportTitle