export const ADD_MODE = "ADD_MODE";
export const EDIT_MODE = "EDIT_MODE";
export const READ_MODE = "READ_MODE";

export const getCurrentMode = (isNew, isEditable) => {
    if (isNew) return ADD_MODE;

    if (isEditable) return EDIT_MODE;

    return READ_MODE;
}
