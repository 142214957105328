import React, { useCallback, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { produce } from "immer";
import { Box, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";

import { reportsModelingService } from "../../../services/reports-modeling-service";

import { modifySelectPermissionsTree } from "../../../utils/reports-modeling-utils";

import useScrollPagination from "../../common/hooks/useScrollPagination";
import { CommonDialog, CommonDialogActions, CommonDialogContent, DialogSearchTitle } from "../../common/dialogs";
import { Button } from "../../common/buttons";
import { MinusIcon, PlusIcon } from "../../common/icons";

import SelectPermissionsDialogTable from "./select-permissions-dialog-table.jsx";

import "./styles.less";

const SelectPermissionsDialog = ({
    systemId,
    open,
    onClose,
    onApply
}) => {
    const intl = useIntl();
    const title = intl.formatMessage({ id: "select-permissions-dialog.title" });
    const applyTitle = intl.formatMessage({ id: "select-permissions-dialog.actions.apply" });
    const cancelTitle = intl.formatMessage({ id: "select-permissions-dialog.actions.cancel" });

    const [searchString, setSearchString] = useState("");
    const [isApplying, setIsApplying] = useState(false);
    const checkedPermissions = useRef(new Set());

    const getData = useCallback((page, signal) => {
        const params = {
            page,
            limit: 50,
            search: searchString
        };

        return reportsModelingService.getPermissionsTree(
            params,
            signal,
            systemId,
            null,
            checkedPermissions.current
        );
    }, [searchString, systemId]);

    const {
        items,
        setItems,
        busy,
        handleScroll
    } = useScrollPagination({ getData });

    const handleItemFieldChange = (itemPath, field, value) => {
        setItems(produce(draft => {
            modifySelectPermissionsTree(draft, itemPath, field, value, checkedPermissions.current);
        }));
    };

    const handleApply = async () => {
        if (checkedPermissions.current.size === 0) {
            onApply([]);
            return;
        }

        setIsApplying(true);

        try {
            const response = await reportsModelingService.getPermissionsTree(
                null, null, systemId, [...checkedPermissions.current]
            );
            onApply(response.rows);
        } catch (error) {
            console.error(error);
        }

        setIsApplying(false);
    };

    const handleSearch = (event) => {
        setSearchString(event.target.value);
    };

    return (
        <>
            <CommonDialog
                className="select-permissions-dialog"
                open={open}
            >
                <DialogSearchTitle
                    title={title}
                    onClose={onClose}
                    onSearch={handleSearch}
                />
                <CommonDialogContent onScrollCapture={handleScroll}>
                    <SelectPermissionsDialogTable
                        tree={items}
                        onItemFieldChange={handleItemFieldChange}
                    />
                    {busy && (
                        <Box className="select-permissions-dialog-busy">
                            <CircularProgress
                                size="24px"
                                disableShrink
                            />
                        </Box>
                    )}
                </CommonDialogContent>
                <CommonDialogActions>
                    <Button
                        className="select-permissions-dialog-cancel-button"
                        variant="outlined"
                        onClick={onClose}
                        startIcon={<MinusIcon />}
                        disabled={isApplying}
                    >
                        {cancelTitle}
                    </Button>
                    <Button
                        className="select-permissions-dialog-apply-button"
                        variant="contained"
                        onClick={handleApply}
                        startIcon={<PlusIcon />}
                        disabled={isApplying}
                    >
                        {applyTitle}
                    </Button>
                </CommonDialogActions>
            </CommonDialog>
        </>
    );
};

SelectPermissionsDialog.propTypes = {
    systemId: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onApply: PropTypes.func
};

export default SelectPermissionsDialog;