import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

import "./style.less";

const EntityFormPageViews = ({
    className="",
    errorScroll,
    setCurrentView,
    ...props
}) => {
    const ref = useRef();

    useEffect(() => {
        if (!errorScroll) return;

        const { errorTab, errorIndex } = errorScroll;

        if (errorTab === null || errorIndex === null) return

        setCurrentView(errorScroll.errorTab);
        ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }, [errorScroll, setCurrentView]);

    return (
        <Box
            ref={ref}
            className={`entity-form-page-views ${className}`}
            {...props}
        />
    )
};

EntityFormPageViews.propTypes = {
    className: PropTypes.string,
    errorScroll: PropTypes.shape({
        errorTab: PropTypes.string,
        errorIndex: PropTypes.number
    }),
    setCurrentView: PropTypes.func,
    setErrorScroll: PropTypes.func,
};

export default EntityFormPageViews;