import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { EntitiesSelectDialog, EntitiesSelectDialogContent, EntitiesSelectDialogSearch } from "../../entities-select-dialog";

import { selectModelingSelectedUserId, setModelingSelectedUserById } from "../../../../reducers/reports-modeling/userLevelSlice";
import { CommonDialogTitle } from "../../../common/dialogs";

import UsersTable from "./users-table.jsx";
import UserLevelModelingUsersDialogActions from "./user-level-modeling-users-dialog-actions.jsx";

const UserLevelModelingUsersDialog = ({ open, onClose }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const savedSelectedUserId = useSelector(selectModelingSelectedUserId);
    const [selectedUserId, setSelectedUserId] = useState("");
    const [searchString, setSearchString] = useState("");

    const title = intl.formatMessage({ id: "user-level-modeling-user-search-help.dialog.title" });

    useEffect(() => {
        setSelectedUserId(savedSelectedUserId);
    }, [savedSelectedUserId]);
    
    const handleSubmit = () => {
        dispatch(setModelingSelectedUserById(
            selectedUserId
        ));
        onClose();
    };

    return (
        <EntitiesSelectDialog open={open}>
            <CommonDialogTitle
                title={title}
                onClose={onClose}
            />

            <EntitiesSelectDialogContent>
                <EntitiesSelectDialogSearch
                    value={searchString}
                    onSearchClick={(event) => setSearchString(event.target.value)}
                />
                <UsersTable
                    searchString={searchString}
                    selectedId={selectedUserId}
                    onSelectRow={(e) => setSelectedUserId(e.target.value)}
                    onRowClick={(row) => setSelectedUserId(row.id)}
                />
            </EntitiesSelectDialogContent>

            <UserLevelModelingUsersDialogActions
                selectedUserId={selectedUserId}
                onSubmitClick={handleSubmit}
                onUnselectUser={() => setSelectedUserId("")}
                onCancelClick={onClose}
            />
        </EntitiesSelectDialog>
    );
};

UserLevelModelingUsersDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
};

export default UserLevelModelingUsersDialog;