import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { DeleteDialog } from "../../common/dialogs";

const DeleteVariantDialog = ({ open, variant, onDeleteClick, onCancelClick }) => {
    const intl = useIntl();

    const title = intl.formatMessage({ id: "user-level-report-page.delete-dialog.title" });

    const text = intl.formatMessage(
        { id: "user-level-report-page.delete-dialog.text" },
        { name: variant.variantName }
    )

    return(
        <DeleteDialog
            open={open}
            title={title}
            text={text}
            onCancelClick={onCancelClick}
            onDeleteClick={onDeleteClick}
        />
    );
};

DeleteVariantDialog.propTypes = {
    open: PropTypes.bool,
    variant: PropTypes.object,
    onDeleteClick: PropTypes.func,
    onCancelClick: PropTypes.func
};

export default DeleteVariantDialog;
