import { useCallback, useMemo } from "react";

const useScrollableTabs = (tabs, value, oneVisibleTab, onChange) => {
    const currentTabIndex = useMemo(
        () => tabs.findIndex(tab => tab.id === value),
        [tabs, value]
    );

    const currentTab = useMemo(
        () => currentTabIndex === -1 ? null : tabs[currentTabIndex],
        [tabs, currentTabIndex]
    );

    const visibleTabs = useMemo(() => (
        oneVisibleTab ? [currentTab] : tabs
    ), [currentTab, oneVisibleTab, tabs]);

    const handleLeftScrollClick = useCallback(() => {
        const nextTab = currentTabIndex === 0 || currentTabIndex === -1
            ? tabs[tabs.length - 1]
            : tabs[currentTabIndex - 1];

        onChange(nextTab.id);
    }, [tabs, currentTabIndex, onChange]);

    const handleRightScrollClick = useCallback(() => {
        const nextTab = currentTabIndex === tabs.length - 1 || currentTabIndex === -1
            ? tabs[0]
            : tabs[currentTabIndex + 1];

        onChange(nextTab.id);
    }, [tabs, currentTabIndex, onChange]);

    return {
        visibleTabs,
        currentTabIndex,
        currentTab,
        handleLeftScrollClick,
        handleRightScrollClick
    }
};

export default useScrollableTabs;
