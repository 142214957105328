import { useState } from "react";


class Sorter{
    static ASC = "asc";
    static DSC = "dsc";

    constructor(field, order){
        this.field = field;
        this.order = order;
    }

    checkRows(a, b){
        const a_value = a[this.field];
        const b_value = b[this.field];

        const valueType = typeof a_value;

        switch(valueType){
            case "number":
                return this.order === Sorter.ASC ? a - b : b - a;
            case "string":
                return this.order === Sorter.ASC ?  a_value.localeCompare(b_value) : b_value.localeCompare(a_value)
            case "boolean":
                if (a_value === b_value) return 0;

                return this.order === Sorter.ASC ? 1 : -1
            default:
                return -1;
        }
    }
}
class Filter {

    static EQ = "eq";
    static NE = "ne";
    static CO = "co";
    static GE = "ge";
    static LE = "le";
    static GT = "gt";
    static LT = "lt";
    static IN = "in";

    constructor(field, operation, value){
        this.field = field;
        this.operation = operation; // eq, co, ge, le, gt, lt
        this.value = value;
    }

    convertRowValue(row){
        let value = row[this.field]

        if (value === null) return value;

        switch(typeof this.value){
            case "number":
                return parseFloat(new Number(value))
            case "string":
                return value
            case "boolean":
                return !!value
            default:
                return value   
        }
    }

    checkValue(row){
        const value = this.convertRowValue(row)

        const filterValueType = typeof this.value;

        switch(this.operation){
            case Filter.EQ:
                return value === this.value
            case Filter.NE:
                return value !== this.value
            case Filter.CO:
                if (!["string"].includes(filterValueType)) return false

                return (new String(value).toLowerCase()).includes(this.value.toLowerCase())
            case Filter.GE:
                if (!["number"].includes(filterValueType)) return false

                return value >= this.value
            case Filter.LE:
                if (!["number"].includes(filterValueType)) return false

                return value <= this.value
            case Filter.IN:
                return Array.isArray(this.value) && this.value.includes(value)
        }
    }
}

class ComplexFilter {

    static AND = "and"
    static OR = "or"

    constructor(filters, sign){
        this.filters = filters;
        this.sign = sign; // and/or
    }

    checkFilters(row) {
        const results = this.filters.map(filter => {
            if (filter instanceof Filter){
                return filter.checkValue(row)
            }

            if (filter instanceof ComplexFilter){
                return filter.checkFilters(row)
            }

            return false;
        })

        switch(this.sign){
            case ComplexFilter.AND:
                return results.every(item => item)
            case ComplexFilter.OR:
                return results.some(item => item)
        }
    }
}

const applyFiltersToRow = (row, filters) => {
    if (Array.isArray(filters)){
        return new ComplexFilter(filters, "and").checkFilters(row)
    }

    if (filters instanceof ComplexFilter){
        return filters.checkFilters(row)
    }

    if (filters instanceof Filter){
        return Filter.checkValue(row)
    }
}

const applyFilters = (rows, filters) => {
    const result = [];

    rows.forEach(row => {
        const rowIsFiltered = applyFiltersToRow(row, filters)

        if (rowIsFiltered){
            result.push(row)
        }
    })

    return result
}

const applyPagination = (rows, page, rowsPerPage) => {
    return rows.slice((page - 1) * rowsPerPage, page * rowsPerPage)
}

const applySorters = (rows, sorters) => {
    const sorterFunc = (a, b) => {
        return sorters.map(sorter => sorter.checkRows(a, b)).reduce((p, n) => p ? p : n, 0)
    }

    return rows.sort(sorterFunc);
}

const useLocalTable = (rows, usePagination=true) => {
    const [filters, setFilters] = useState([])
    const [sorters, setSorters] = useState([])
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    let result = []

    result = applyFilters(rows, filters);
    const total = result.length;

    result = applySorters(result, sorters);

    if (usePagination) {
        result = applyPagination(result, page, rowsPerPage)
    }

    const onSetPage = (pageNum) => {
        setPage(pageNum)
    }

    const onSetRowsPerPage = (rowsPerPageNum) => {
        setPage(1);
        setRowsPerPage(rowsPerPageNum);
    }

    const onFilter = (filters) => {
        setPage(1)
        setFilters(filters)
    }

    const onSort = (sorters) => {
        setPage(1);
        setSorters(sorters);
    }


    return {
        rows: result,
        filters,
        onFilter,
        onSort,
        pagination: {
            total,
            page,
            rowsPerPage,
            onSetPage,
            onSetRowsPerPage
        }
    }
}

export default useLocalTable;
export {
    Filter, ComplexFilter, Sorter
}