import React from "react";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

import { REPORT_LEVELS, REPORT_TYPES } from "../../../utils/sodReports";

import { SelectInput } from "../../common/form-controls";
import { MenuItem } from "../../common/menu";
import { Button } from "../../common/buttons";

import "./styles.less";

const ReportLevelInput = ({ disabled, value, onChange }) => {
    const intl = useIntl();

    const reportLevelsList = [
        {
            key: REPORT_LEVELS.OPERATIONS,
            text: intl.formatMessage({ id: "report-results.tabs.operations-level"})
        },
        {
            key: REPORT_LEVELS.PERMISSIONS,
            text: intl.formatMessage({ id: "report-results.tabs.permissions-level"})
        },
        {
            key: REPORT_LEVELS.CRITICAL_OPERATIONS,
            text: intl.formatMessage({ id: "report-results.tabs.critical-operations-level"})
        },
        {
            key: REPORT_LEVELS.CRITICAL_PERMISSIONS,
            text: intl.formatMessage({ id: "report-results.tabs.critical-permissions-level"})
        },
        {
            key: REPORT_LEVELS.CRITICAL_OBJECTS,
            text: intl.formatMessage({ id: "report-results.tabs.critical-objects-level"})
        }
    ];

    const label = intl.formatMessage({ id: "report-columns-dialog.report-level.label" });

    return (
        <SelectInput
            label={label}
            value={value}
            disabled={disabled}
            onChange={onChange}
        >
            {reportLevelsList.map(({ key, text }) => (
                <MenuItem
                    key={key}
                    value={key}
                >
                    {text}
                </MenuItem>
            ))}
        </SelectInput>
    )
};

const ReportTypeInput = ({ disabled, value, onChange }) => {
    const intl = useIntl();

    const reportTypesList = [
        {
            key: REPORT_TYPES.FINAL,
            text: intl.formatMessage({ id: "report-results.report-types.final" })
        },
        {
            key: REPORT_TYPES.DETAILED,
            text: intl.formatMessage({ id: "report-results.report-types.detailed" })
        },
    ];

    const label = intl.formatMessage({ id: "report-columns-dialog.report-type.label" });

    return (
        <SelectInput
            label={label}
            value={value}
            disabled={disabled}
            onChange={onChange}
        >
            {reportTypesList.map(({ key, text }) => (
                <MenuItem
                    key={key}
                    value={key}
                >
                    {text}
                </MenuItem>
            ))}
        </SelectInput>
    )
};

const SubmitButton = ({ disabled, onClick }) => {
    const intl = useIntl();
    const text = intl.formatMessage({ id: "report-columns-dialog.submit.text" });

    return (
        <Button
            disabled={disabled}
            variant="contained"
            className="report-columns-dialog-parameters-submit"
            onClick={onClick}
        >
            {text}
        </Button>
    )
};

const ReportForm = ({
    reportLevel,
    setReportLevel,
    reportType,
    setReportType,
    disabled,
    onSubmit
}) => {
    const submitDisabled = disabled || !reportLevel || !reportType;

    return (
        <Box className="report-columns-dialog-report-form">
            <ReportLevelInput
                value={reportLevel}
                disabled={disabled}
                onChange={(e) => setReportLevel(e.target.value)}
            />
            <ReportTypeInput
                value={reportType}
                disabled={disabled}
                onChange={(e) => setReportType(e.target.value)}
            />
            <SubmitButton
                disabled={submitDisabled}
                onClick={onSubmit}
            />
        </Box>
    );
};

ReportLevelInput.propTypes = {
    value: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func
};

ReportTypeInput.propTypes = {
    value: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func
};

SubmitButton.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func
};

ReportForm.propTypes = {
    reportLevel: PropTypes.string,
    setReportLevel: PropTypes.func,
    reportType: PropTypes.string,
    setReportType: PropTypes.func,
    disabled: PropTypes.bool,
    onSubmit: PropTypes.func
};

export default ReportForm;