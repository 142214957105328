import SodService from "./sod-service";
import { nanoid } from "@reduxjs/toolkit";

class FunctionsService extends SodService {
    async getFunctions(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/sod/functions/list", config);

        const functions = data && data.rows.map(item => this.parseFunction(item));
        const total = data.total

        return {
            functions, total
        }
    }

    async getFunctionsByRiskType(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/sod/functions/search-help", config);

        const functions = data && data.rows.map(item => this.parseFunction(item));
        const total = data.total

        return {
            functions, total
        }
    }

    async getFunctionColumns() {
        return await this.getColumns("/sod/functions/columns");
    }

    async getFunctionsFilters(params) {
        return this.getFilters("/sod/functions/filters", params);
    }

    async getFunctionDetailed(id) {
        const data = await this.get(`/sod/functions/detailed/${id}`);

        const functionData = this.parseFunctionDetailed(data);

        return functionData
    }

    async getFunctionChangeHistory(key) {
        const data = await this.get(`/sod/functions/detailed/change-history/${key}`)
        return data && data.map(item => this.parseUserActionLog(item))
    }

    async createFunction(func, warningCheck) {
        const data = await this.post(`/sod/functions/detailed/${func.id}`, this.parseFunctionToServer(func), {
            params: { warningCheck }
        });

        return this.parseFunctionDetailed(data);
    }

    async editFunction(func, warningCheck) {
        const data = await this.put(`/sod/functions/detailed/${func.id}`, this.parseFunctionToServer(func), { params: { warningCheck } });

        return this.parseFunctionDetailed(data);
    }

    async deleteFunction(id) {
        return this.delete(`/sod/functions/detailed/${id}`);
    }

    parseFunctionToServer(item) {
        return {
            function_id: item.id,
            system_id: item.systemId,
            is_group: !!(item.isGroup),
            description: item.description || "",
            actions: item.actions.map(item => this.parseOperationToServer(item)),
            permissions: item.permissions.map(item => this.parsePermissionsToServer(item)),
            business_process_id: item.process
        }
    }

    parseOperationToServer(item) {
        return {
            action: item.operation,
            active: item.active,
            change_status: item.changeStatus
        }
    }

    parsePermissionsToServer(item) {
        return {
            permission_group: item.permissionGroup,
            permission: item.permission,
            sequence: item.sequence,
            fieldname: item.field,
            value_from: item.valueFrom,
            value_to: item.valueTo,
            condition: item.sign,
            active: item.active,
            change_status: item.changeStatus
        }
    }

    parseFunction(item) {
        return {
            id: item.function_id,
            systemId: item.system_id,
            description: item.description,
            updatedTime: item.upd_time,
            user: item.upd_user ? item.upd_user : "",
            busynessProcess: item.business_process_id
        }
    }

    parseFunctionDetailed(item) {
        return {
            id: item.function_id,
            systemId: item.system_id,
            description: item.description,
            updatedTime: item.upd_time,
            user: item.upd_user ? item.upd_user : "",
            isGroup: !!(item.is_group),
            volume: null,
            process: item.business_process_id,
            operations: item.actions.map(item => this.parseOperation(item)),
            permissions: item.permissions.map(item => this.parseFunctionPermission(item))
        }
    }

    parseFunctionPermission(item) {
        return {
            id: nanoid(),
            sequence: item.sequence,
            permissionGroup: item.permission_group,
            permission: item.permission,
            field: item.fieldname,
            valueFrom: item.value_from,
            valueTo: item.value_to,
            sign: item.condition,
            active: item.active,
            new: false
        }
    }

    parseOperation(item) {
        return {
            id: nanoid(),
            operation: item.action,
            description: item.description,
            active: item.active,
            changeStatus: null
        }
    }
}

const functionsService = new FunctionsService();

export { functionsService };
export default FunctionsService;