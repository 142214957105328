import React from "react";
import { createSvgIcon } from "@mui/material";

const EditIcon = createSvgIcon(
    <path
        d="M7.12986 17.686C7.04356 17.6858 6.95823 17.6669 6.87931 17.6305C6.80039 17.5941 6.7296 17.5409 6.67143 17.4743C6.61222 17.4084 6.56703 17.3301 6.53884 17.2446C6.51064 17.1591 6.50008 17.0684 6.50784 16.9783L6.66024 15.2293L13.7002 7.88386L15.9002 10.1796L8.86216 17.5243L7.18646 17.6834C7.16764 17.6852 7.14875 17.6861 7.12986 17.686ZM16.3394 9.72055L14.1399 7.42486L15.4592 6.04783C15.517 5.98747 15.5856 5.93958 15.6611 5.90691C15.7366 5.87424 15.8176 5.85742 15.8993 5.85742C15.981 5.85742 16.062 5.87424 16.1375 5.90691C16.213 5.93958 16.2816 5.98747 16.3394 6.04783L17.6587 7.42486C17.7165 7.48515 17.7624 7.55676 17.7937 7.63557C17.825 7.71439 17.8411 7.79887 17.8411 7.88419C17.8411 7.96951 17.825 8.05399 17.7937 8.13281C17.7624 8.21162 17.7165 8.28323 17.6587 8.34352L16.34 9.7199L16.3394 9.72055Z"
        fill="currentColor"
    />,
    "Edit"
);

export default EditIcon;
