import React from "react";

import { SearchHelpInput } from "../../common/form-controls";
import { ReportModelingSearchHelpIcon } from "../../common/icons";

import "./styles.less";

const ReportModelingHeaderSearchHelpInput = (props) => {
    return (
        <SearchHelpInput
            className="report-modeling-header-search-help-input"
            IconComponent={ReportModelingSearchHelpIcon}
            {...props}
        />
    )
}

export default ReportModelingHeaderSearchHelpInput;